import React from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import Layout from '../component/antdComponent/Layout';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getProfile } from '../services/user';
import { useSelector } from 'react-redux';

/* Middleware that checks if user has permission to access particular routes & views. */
const AuthGuardMiddleWare = ({ allowedRoles }) => {
  const { entityCategory, userType } = { ...allowedRoles };

  const { profile } = useSelector(state => ({
    profile: state.user.profile
  }));

  const location = useLocation();
  const isAuth = Cookies.get('ACCESS_TOKEN');

  let hasAccess;

  if (isAuth) {
    const { token_payload } = jwt_decode(isAuth);
    if (
      entityCategory.includes(token_payload.entityCategory) &&
      userType.includes(token_payload.userType)
    ) {
      hasAccess = true;
    } else {
      hasAccess = false;
      Cookies.remove('ACCESS_TOKEN');
    }
  }

  useEffect(() => {
    if (hasAccess && !profile) getProfile();
  }, []);

  return (
    <Fragment>
      {hasAccess && (
        <Layout>
          <Outlet />
        </Layout>
      )}
      {!hasAccess && <Navigate to='/login' state={{ from: location }} replace />}
    </Fragment>
  );
};

export default AuthGuardMiddleWare;
