import React from 'react';
import TextInput from '../antdComponent/Input';
import IsdCode from '../antdComponent/Mobile';
import Date from '../antdComponent/Date';
import PasswordInput from '../antdComponent/Password';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Form, Card, Button, Spin, Row, Col } from 'antd';
import { tw } from 'twind';
import { RULES } from '../../utils/formValidations';
import { showPassword } from '../../services/auth';
import { Permissions } from '../../configs/permissions';
import { getIsdCode, getMobileNo } from '../../utils/helpers';

const FinancierUserProfileForms = ({
  onSubmit,
  onSubmitLoader,
  defaultValue: { adminDetails = {}, entityDetails = {} }
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [isdCode, setIsdCode] = useState(getIsdCode(adminDetails?.mobileNo));

  const initialValue = useMemo(
    () =>
      Object.keys(entityDetails).length !== 0 && Object.keys(adminDetails).length !== 0
        ? {
          entityName: entityDetails.entityName,
          registrationNumber: entityDetails.registrationNo,
          panNumber: entityDetails.pan,
          udyamNumber: entityDetails.udyamRegNo,
          incorporationDate: entityDetails.dateOfIncorporation
            ? dayjs(entityDetails.dateOfIncorporation)
            : null,
          adminName: `${adminDetails.firstName || ""} ${adminDetails.lastName || ""}`,
          mobileNo: getMobileNo(adminDetails.mobileNo),
          email: adminDetails.emailId
        }
        : {},
    [adminDetails]
  );

  const onSubmitHandler = value => {
    onSubmit({
      ...value,
      mobileNo: isdCode + value.mobileNo
    });
  };

  const showUserPassword = async id => {
    setLoading(true);
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
    setLoading(false);
  };

  useEffect(() => {
    if (adminDetails?.id) {
      showUserPassword(adminDetails.id);
    }
  }, [adminDetails?.id]);

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [initialValue]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={loading} />
      </div>
    );
  }

  return (
    <div>
      <Form
        form={form}
        size='large'
        initialValues={initialValue}
        onFinish={onSubmitHandler}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        onValuesChange={() => form.validateFields(['repassword'])}
        autoComplete='off'
      >
        <Card title='Entity Details' style={{ width: '100%' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='entityName' rules={RULES.entityName}>
                <TextInput label='Entity Name' placeholder='Entity Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='registrationNumber' rules={RULES.registrationNumber}>
                <TextInput label='Registration Number' placeholder='Enter Number' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='panNumber' rules={RULES.panNumber} required>
                <TextInput
                  onChange={e => form.setFieldValue('panNumber', e.target.value.toUpperCase())}
                  label='PAN Number'
                  placeholder='Enter PAN'
                  required
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='udyamNumber' rules={RULES.udyamNumber}>
                <TextInput label='Udyam Number' placeholder='Enter Udyam No' maxLength={19} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='incorporationDate' rules={RULES.date}>
                <Date
                  required
                  label='Date of Incorporation'
                  placeholder='Select Date'
                  disabledDate={current => current.isAfter(dayjs().subtract(1, 'day'))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title='Admin Details' style={{ width: '100%', marginTop: '20px' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='adminName' rules={RULES.name}>
                <TextInput label='Admin Name' placeholder='Enter Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name='mobileNo'
                rules={RULES.mobileNo}
                style={{ position: 'relative', bottom: 7 }}
              >
                <TextInput
                  label='Mobile No'
                  placeholder='Enter Number'
                  required
                  addonBefore={<IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='email' rules={RULES.email}>
                <TextInput label='Email' placeholder='Enter Email' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='password' rules={RULES.password} hasFeedback>
                <PasswordInput label='Password' placeholder='Enter Password' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                <PasswordInput label='Re-Password' placeholder='Enter Password' required />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <br />
        {Permissions('profile', 'editProfile') && (
          <Button type='primary' htmlType='submit' size='middle' loading={onSubmitLoader}>
            {'Update'}
          </Button>
        )}
        <br />
      </Form>
    </div>
  );
};

export default FinancierUserProfileForms;
