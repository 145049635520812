import React, { Fragment, useEffect, useState } from 'react';
import TextInput from '../../../antdComponent/Input';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Button, Card, Upload, Form, Row, Col, Progress } from 'antd';
import { tw } from 'twind';
import { RULES } from '../../../../utils/formValidations';
import { UploadOutlined } from '@ant-design/icons';
import { invoiceDocumentColumns } from './documentsColumns';
import { Permissions } from '../../../../configs/permissions';
import { getAllInvoiceDocuments } from '../../../../services/factoringUnit';
import { useSelector } from 'react-redux';
import { notifications } from '../../../../utils/notifications';
import { getURL } from '../../../../configs/apiURL';
import TableComponent from '../../../antdComponent/Table';

const DOC_EXT = process.env.REACT_APP_DOC_EXT;
const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE * 1024;

const DocumentInvoice = ({ showDocuments, data }) => {
  const {
    params,
    documentsLoading = false,
    documentsData = []
  } = useSelector(state => ({
    params: state.mutations.modalParams,
    documentsLoading: state.factoringUnit.loadingInvoiceDocument,
    documentsData: state.factoringUnit.invoiceDocument
  }));

  const [loader, setLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();

  const currentTab = localStorage.getItem('currentTab');

  const getFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = file => {
    const fileSizeKiloBytes = file.size / 1024;
    setError(fileSizeKiloBytes > MAX_FILE_SIZE ? 'File size is greater than maximum limit' : false);
    return false;
  };

  const onSubmit = async value => {
    if (error) return;
    setLoader(true);
    const file = value.uploadDocuments[0];
    const enityId = Cookies.get('entityId');
    const formData = new FormData();
    formData.append('title', value.title);
    formData.append('document', file.originFileObj, file.originFileObj.name);
    formData.append('userId', localStorage.getItem('userId'));
    formData.append('entityId', enityId);
    formData.append('documentType', 'INVOICE');

    const ACCESS_TOKEN = Cookies.get('ACCESS_TOKEN');

    let config = {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadPercentage(() => percentCompleted);
      }
    };

    const res = await axios.post(getURL(`invoices/${data.id}/documents`), formData, config);

    if (res && res.status === 200) {
      notifications.success({ message: 'document uploaded successfully' });
      getAllInvoiceDocuments(data.id, params);
      form.resetFields();
    }
    setUploadPercentage(0);
    setLoader(false);
  };

  useEffect(() => {
    if (showDocuments) {
      getAllInvoiceDocuments(data.id, params);
    }
  }, [showDocuments, params]);

  return (
    <Fragment>
      {Permissions('invoices', 'uploadInvoice') && currentTab !== 'BANK_FINANCE' && (
        <Form
          form={form}
          name='documents'
          size='large'
          onFinish={onSubmit}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          autoComplete='off'
        >
          <Card title='Upload Documents'>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='title' rules={RULES.inputRequired}>
                  <TextInput label='Document Title' placeholder='Document Title' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='uploadDocuments' getValueFromEvent={getFile} rules={RULES.upload}>
                  <Upload accept={DOC_EXT} beforeUpload={beforeUpload} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Browse</Button>
                    <br />
                    <p className={tw`text-red-500`}>
                      {uploadPercentage ? (
                        <Progress className={tw`w-36`} percent={uploadPercentage} />
                      ) : (
                        error
                      )}
                    </p>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <p style={{ marginTop: 10, marginLeft: 5, color: '#f5222d' }}>{error}</p>
              </Col>
            </Row>
            <div>
              <Button
                type='primary'
                htmlType='submit'
                size='middle'
                disabled={!showDocuments}
                loading={loader}
              >
                Upload
              </Button>
            </div>
          </Card>
        </Form>
      )}
      <Card title='Document Details' className={tw`mt-4`}>
        <TableComponent
          columns={invoiceDocumentColumns}
          data={{
            ...documentsData,
            data: documentsData?.data?.map(document => ({
              ...document,
              invoice: data
            }))
          }}
          loading={documentsLoading}
          isModalTable={true}
        />
      </Card>
    </Fragment>
  );
};

export default DocumentInvoice;
