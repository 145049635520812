import dayjs from 'dayjs';
import { Space, Divider } from 'antd';
import { Typography } from 'antd';
import { ActionViewModal } from './approveFactoredUnit';
import { ViewModal } from './viewFactoredUnit/viewFactoredUnit';
import { DateFilter, TextFilter } from '../../../utils/formFilters';
import { MdOutlinePreview } from 'react-icons/md';

const { Text } = Typography;

export const factoredUnitColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Factoring Unit No</Text>
        <Divider />
        <TextFilter type='factoringUnitNo' />
      </div>
    ),
    key: 'factoringUnitNo',
    render: (_, record) => {
      return (
        <Text>
          <ViewModal label={record.factoringUnitNo} data={record} />
        </Text>
      );
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Seller Name</Text>
        <Divider />
        <TextFilter type='sellerName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.seller.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Factoring Amount</Text>
        <Divider />
        <TextFilter type='factoringAmount' />
      </div>
    ),
    dataIndex: 'factoringAmount',
    key: 'factoringAmount',
    render: (_, record) => {
      return <Text>{record.factoringAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Factored Date</Text>
        <Divider />
        <DateFilter type='factoredDate' />
      </div>
    ),
    dataIndex: 'factoredDate',
    key: 'factoredDate',
    render: (_, record) => {
      const factoredDate = dayjs(record.factoredDate).format('DD-MM-YYYY');
      return <Text>{factoredDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Due Date</Text>
        <Divider />
        <DateFilter type='fuDueDate' />
      </div>
    ),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_, record) => {
      const dueDate = dayjs(record.invoices[0]?.invoiceDueDate).format('DD-MM-YYYY');
      return <Text>{dueDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Rate</Text>
        <Divider />
        <TextFilter type='rateOfInterest' />
      </div>
    ),
    dataIndex: 'rate',
    key: 'rate',
    render: (_, record) => {
      return <Text>{record.rateOfInterest}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      return (
        <Space size='small' style={{ display: 'flex', flexDirection: 'column' }}>
          <ActionViewModal label={<MdOutlinePreview size='20px' color='black' />} data={record} />
        </Space>
      );
    }
  }
];

export const activeFactoredUnitColumns = [
  ...factoredUnitColumns.slice(0, -1),
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Disbursement Date</Text>
        <Divider />
        <TextFilter type='disbursementDate' />
      </div>
    ),
    dataIndex: 'disbursementDate',
    key: 'disbursementDate',
    render: (_, record) => {
      return <Text>{dayjs(record.payment[0]?.dateOfTransaction).format('DD-MM-YYYY')}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Disbursed Amount</Text>
        <Divider />
        <TextFilter type='disburseAmount' />
      </div>
    ),
    dataIndex: 'disburseAmount',
    key: 'disburseAmount',
    render: (_, record) => {
      return (
        <Text>
          {record.payment.reduce(
            (prevPymt, currPymt) =>
              currPymt.status === 'SUCCESSFUL' ? prevPymt + currPymt.paymentAmount : prevPymt,
            0
          )}
        </Text>
      );
    }
  },
  factoredUnitColumns[factoredUnitColumns.length - 1]
];

export const overdueFactoredUnitColumns = [
  ...factoredUnitColumns.slice(0, -1),
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Overdue Days</Text>
        <Divider />
        <TextFilter type='overdueDays' />
      </div>
    ),
    dataIndex: 'overdueDays',
    key: 'overdueDays',
    render: (_, record) => {
      let currDate = new Date();
      currDate.setHours(0, 0, 0, 0);
      currDate = dayjs(currDate);

      let dueDate = new Date(record.invoices[0]?.invoiceDueDate);
      dueDate.setHours(0, 0, 0, 0);
      dueDate = dayjs(dueDate);

      return <Text>{currDate.diff(dueDate, 'days')}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Disbursement Date</Text>
        <Divider />
        <TextFilter type='paymentDate' />
      </div>
    ),
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (_, record) => {
      return <Text>{dayjs(record.payment[0]?.dateOfTransaction).format('DD-MM-YYYY')}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Disbursement Amount</Text>
        <Divider />
        <TextFilter type='disburseDate' />
      </div>
    ),
    dataIndex: 'disburseDate',
    key: 'disburseDate',
    render: (_, record) => {
      return <Text>{dayjs(record.payment[0]?.dateOfTransaction).format('DD-MM-YYYY')}</Text>;
    }
  },
  factoredUnitColumns[factoredUnitColumns.length - 1]
];
