import { notification } from 'antd';

const error = args => {
  return notification.error({
    placement: 'topRight',
    duration: 3,
    rtl: true,
    ...args
  });
};

const success = args => {
  return notification.success({
    placement: 'topRight',
    duration: 3,
    rtl: true,
    ...args
  });
};

const warning = args => {
  return notification.warning({
    placement: 'topRight',
    duration: 3,
    rtl: true,
    ...args
  });
};

const info = args => {
  return notification.info({
    placement: 'topRight',
    duration: 3,
    rtl: true,
    ...args
  });
};

export const notifications = { error, success, info, warning };
