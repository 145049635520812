import dayjs from 'dayjs';
import { Button, Divider, Space, Switch } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { Typography } from 'antd';
import { editFinancier } from '../../redux/financier/actions';
import { updateFinancierStatus } from '../../services/financier';
import { DateFilter, TextFilter } from '../../utils/formFilters';
import { platformRolesPermission } from '../../configs/permissions';

const { Text } = Typography;

export const financierColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Financier Name</Text>
        <Divider />
        <TextFilter type='entityName' />
      </div>
    ),
    key: 'financier',
    render: (_, record) => {
      return <Text>{record.entityDetails.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>PAN Number</Text>
        <Divider />
        <TextFilter type='pan' />
      </div>
    ),
    dataIndex: 'financierpan',
    key: 'financierpan',
    render: (_, record) => {
      return <Text>{record.entityDetails.pan}</Text>;
    }
  },

  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Registered Date</Text>
        <Divider />
        <DateFilter type='createdAt' />
      </div>
    ),
    dataIndex: 'register',
    key: 'register',
    render: (_, record) => {
      return <Text>{dayjs(record.entityDetails.createdAt).format('DD-MM-YYYY')}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Expiry Date</Text>
        <Divider />
        <DateFilter type='dateOfExpiry' />
      </div>
    ),
    dataIndex: 'expiry',
    key: 'expiry',
    render: (_, record) => {
      return (
        <Text>
          {record.approvalDetails.dateOfExpiry
            ? dayjs(record.approvalDetails.dateOfExpiry).format('DD-MM-YYYY')
            : null}
        </Text>
      );
    }
  },

  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Action</Text>
        <Divider />
      </div>
    ),
    key: 'action',
    render: (_, record) => (
      <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {platformRolesPermission('financier', 'editFinancier') && (
          <Button
            name='update-financier'
            style={{ borderRadius: '8px', padding: '4px' }}
            onClick={() => editFinancier(true, record)}
          >
            <MdModeEditOutline size='20px' color='black' />
          </Button>
        )}
        {platformRolesPermission('financier', 'editFinancierStatus') && (
          <Switch
            name='update-status'
            checked={record.entityDetails.active ? true : false}
            onChange={value =>
              updateFinancierStatus(record.entityDetails.id, {
                entityDetails: {
                  ...record.entityDetails,
                  active: value ? 1 : 0
                },
                adminDetails: record.adminDetails
              })
            }
          />
        )}
      </Space>
    )
  }
];
