import React from 'react';
import SelectComponent from '../antdComponent/Select';
import TextInput from '../antdComponent/Input';
import IsdCode from '../antdComponent/Mobile';
import TableComponent from '../antdComponent/Table';
import PasswordInput from '../antdComponent/Password';
import './style.css';
import { Form, Card, Button, Spin, Row, Col, Typography } from 'antd';
import { PLATFORM_USER_TYPES } from '../../utils/helpers';
import { tw } from 'twind';
import { useEffect, useMemo, useState } from 'react';
import { RULES } from '../../utils/formValidations';
import { showPassword } from '../../services/auth';
import { useSelector } from 'react-redux';
import { getAllUsers } from '../../services/user';
import { platformUserColumns } from './platformUserColumns';
import { platformRolesPermission } from '../../configs/permissions';
import { getIsdCode, getMobileNo } from '../../utils/helpers';

const { Text } = Typography;

const FinancierPlatformSetting = ({ isUpdate, defaultValue, users, loading, onSubmit }) => {
  const [form] = Form.useForm();
  const [isdCode, setIsdCode] = useState(getIsdCode(defaultValue?.mobileNo));
  const [passwordLoading, setPasswordLoading] = useState(false);

  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  const initialValues = useMemo(() => {
    return isUpdate
      ? {
          name: `${defaultValue.firstName} ${defaultValue.lastName}`,
          emailId: defaultValue.emailId,
          mobileNo: getMobileNo(defaultValue.mobileNo),
          userType: defaultValue.userType
        }
      : {};
  }, [defaultValue]);

  const showUserPassword = async id => {
    setPasswordLoading(true);
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
    setPasswordLoading(false);
  };

  const handleOnSubmit = async value => {
    const res = await onSubmit({
      ...value,
      mobileNo: isdCode + value.mobileNo
    });
    if (res && res.status === 200) {
      form.resetFields();
    }
  };

  useEffect(() => {
    if (isUpdate) {
      showUserPassword(defaultValue.id);
    }
  }, [isUpdate]);

  useEffect(() => {
    getAllUsers(params);
  }, [params]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  if (passwordLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={passwordLoading} />
      </div>
    );
  }

  return (
    <div className='content-divider'>
      {platformRolesPermission('settings', isUpdate ? 'editUsers' : 'addUsers') && (
        <Form
          form={form}
          size='large'
          onFinish={handleOnSubmit}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          onValuesChange={() => form.validateFields(['repassword'])}
          autoComplete='off'
          style={{
            paddingTop: '20px',
            width: '100%',
            marginBottom: '40px'
          }}
        >
          <Card title='User Details' style={{ width: '100%' }}>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={8} md={6}>
                <Form.Item name='userType' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='User Type'
                    placeholder='Select'
                    required
                    allowClear={true}
                    options={PLATFORM_USER_TYPES}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item name='name' rules={RULES.name}>
                  <TextInput label='Name' placeholder='Enter Name' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item
                  name='mobileNo'
                  rules={RULES.mobileNo}
                  style={{ position: 'relative', bottom: 7 }}
                >
                  <TextInput
                    label='Mobile No'
                    placeholder='Enter Number'
                    required
                    addonBefore={
                      <IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item name='emailId' rules={RULES.email}>
                  <TextInput label='Email' placeholder='Enter Email' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item name='password' rules={RULES.password} hasFeedback>
                  <PasswordInput label='Password' placeholder='Enter Password' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                  <PasswordInput label='Re-Password' placeholder='Enter Password' required />
                </Form.Item>
              </Col>
            </Row>
            <Button type='primary' htmlType='submit' size='middle'>
              {isUpdate ? 'Update' : 'Add'}
            </Button>
          </Card>
        </Form>
      )}
      <div className={tw`content-divider`}>
        <Text className={tw`text-xl`}>Users</Text>
      </div>
      <TableComponent columns={platformUserColumns} data={users} loading={loading} />
    </div>
  );
};

export default FinancierPlatformSetting;
