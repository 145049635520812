import React from 'react';
import TextInput from '../../antdComponent/Input';
import Cookies from 'js-cookie';
import TableComponent from '../../antdComponent/Table';
import axios from 'axios';
import useFetch from '../../../hooks/useFetch';
import { useEffect, useState } from 'react';
import { Form, Card, Button, Upload, Row, Col, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RULES } from '../../../utils/formValidations';
import { documentColumns } from '../columns/documents';
import { tw } from 'twind';
import { Permissions } from '../../../configs/permissions';
import { getURL } from '../../../configs/apiURL';
import { getBuyerSellerDocuments } from '../../../services/buyerSeller';
import { notifications } from '../../../utils/notifications';
import { message } from '../../../utils/messages';

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE * 1024;
const DOC_EXT = process.env.REACT_APP_DOC_EXT;

const BuyerSellerDocumentsForm = ({
  tableData,
  continueButton,
  setBuyerSellerAdd,
  currentTab,
  setCurrentTab,
  editBuyerSellerData,
  setActiveTabs,
  params,
  isAllDetailsPending
}) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [updateBuyerSeller] = useFetch();
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const getFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = file => {
    const fileSizeKiloBytes = file.size / 1024;
    setError(fileSizeKiloBytes > MAX_FILE_SIZE ? 'File size is greater than maximum limit' : false);
    return false;
  };

  const onSubmit = async value => {
    if (error) return;
    setLoader(true);
    const file = value.uploadDocuments[0];
    const enityId = editBuyerSellerData.entityDetails.id;
    const formData = new FormData();
    formData.append('title', value.title);
    formData.append('document', file.originFileObj, file.originFileObj.name);
    formData.append('userId', editBuyerSellerData.adminDetails.id);
    formData.append('entityId', enityId);
    formData.append('documentType', 'ENTITY_DOCUMENT');

    const ACCESS_TOKEN = Cookies.get('ACCESS_TOKEN');

    let config = {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadPercentage(() => percentCompleted);
      }
    };

    const res = await axios.post(getURL(`buyer-seller/${enityId}/documents`), formData, config);

    if (res && res.status === 200) {
      notifications.success({ message: message.DOCUMENT_UPLOADED });
      getBuyerSellerDocuments(enityId, params);
      form.resetFields();
      if (continueButton) setBuyerSellerAdd(false);
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
    setUploadPercentage(0);

    setLoader(false);
  };

  const handleFinish = async () => {
    if (
      Permissions('approveBuyerSeller', 'approveBuyerSeller') &&
      !isAllDetailsPending &&
      editBuyerSellerData.approve
    ) {
      setCurrentTab('7');
    } else {
      if (isAllDetailsPending) {
        const res = await updateBuyerSeller(
          getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}`),
          {
            method: 'PUT',
            body: JSON.stringify({
              entityDetails: {
                ...editBuyerSellerData.entityDetails,
                allDetailsFilled: 1
              },
              adminDetails: editBuyerSellerData.adminDetails
            })
          }
        );

        if (res && res.status === 200) {
          setCurrentTab('8');
          setActiveTabs('8');
          notifications.success({
            message: 'Your application has been submitted successfully.'
          });
        } else {
          notifications.error({
            message: res.data.error.message || 'something went wrong'
          });
        }
      } else {
        setCurrentTab('8');
        setActiveTabs('8');
      }
    }
  };

  useEffect(() => {
    if (currentTab === '6') {
      getBuyerSellerDocuments(editBuyerSellerData.entityDetails.id, params);
    }
  }, [params]);

  return (
    <div className={tw`mb-5`}>
      <Form
        form={form}
        size='large'
        onFinish={value => {
          onSubmit(value);
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title='Document Details'>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='title' rules={RULES.inputRequired}>
                <TextInput label='Document Title' placeholder='Document Title' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='uploadDocuments' getValueFromEvent={getFile} rules={RULES.upload}>
                <Upload accept={DOC_EXT} beforeUpload={beforeUpload} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Browse</Button>
                  <br />
                  <p className={tw`text-red-500`}>
                    {uploadPercentage ? (
                      <Progress className={tw`w-36`} percent={uploadPercentage} />
                    ) : (
                      error
                    )}
                  </p>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Button type='primary' size='middle' htmlType='submit' loading={loader}>
              Add
            </Button>
          </div>
        </Card>
      </Form>
      <br />
      <TableComponent columns={documentColumns} data={tableData} loading={false} />
      <div>
        <Button
          className={tw`mt-${tableData?.data?.length ? '0' : '5'}`}
          type='primary'
          disabled={!tableData?.data?.length && isAllDetailsPending}
          onClick={handleFinish}
        >
          {Permissions('approveBuyerSeller', 'approveBuyerSeller') && editBuyerSellerData.approve
            ? 'Continue'
            : 'Finish'}
        </Button>
      </div>
    </div>
  );
};

export default BuyerSellerDocumentsForm;
