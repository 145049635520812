import DeletePopup from '../../../deletePopUp';
import { Space, Typography, Divider } from 'antd';
import { GoLinkExternal } from 'react-icons/go';
import { ModalTextFilter } from '../../../../utils/formFilters';
import { Link } from 'react-router-dom';
import { tw } from 'twind';
import { Permissions } from '../../../../configs/permissions';

const { Text } = Typography;

export const invoiceDocumentColumns = [
  {
    title: <Text>#</Text>,
    key: 'documentNo',
    render: (_, record, index) => {
      return <Text>{index + 1}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Document Title</Text>
        <Divider />
        <ModalTextFilter type='title' />
      </div>
    ),
    dataIndex: 'documentTitle',
    key: 'documentTitle',
    render: (_, record) => {
      return <Text>{record.title}</Text>;
    }
  },

  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const invoiceDocumentDetail = `${record.title} - ${record.invoice.buyerSellerLink.buyer.entityName} - ${record.invoice.buyerSellerLink.seller.entityName} `;
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            <Link
              target={'_blank'}
              to={{
                pathname: `/invoices/${record.entityId}/documents/${record.id}/signed-url`,
                search: '?action=VIEW&expiresIn=300'
              }}
            >
              <GoLinkExternal size={24} color='#228be6' />
            </Link>
            {Permissions('invoices', 'deleteInvoice') && (
              <DeletePopup
                type='invoice'
                id={record.id}
                entityId={record.entityId}
                invoiceId={record.invoice.id}
                data={invoiceDocumentDetail}
              />
            )}
          </Space>
        </div>
      );
    }
  }
];
