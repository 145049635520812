import React from 'react';
import TableComponent from '../../antdComponent/Table';
import { useState, useEffect, useMemo } from 'react';
import { tw } from 'twind';
import { PaymentForm } from './paymentsFactoredUnit/paymentForm';
import { TransactionForm } from './transactionsFactoredUnit/transactionForm';
import { useSelector } from 'react-redux';
import { paymentColumns } from './paymentsFactoredUnit/paymentColumns';
import { transactionColumns } from './transactionsFactoredUnit/transactionColumns';
import { Button, Modal, Tabs } from 'antd';
import { getAllTransactionHistory, getFUPayments } from '../../../services/factoringUnit';
import { getBuyerSellerBanks } from '../../../services/buyerSeller';
import { mutateClearModalParams } from '../../../redux/mutations/actions';

const { TabPane } = Tabs;

export const ActionViewModal = ({ label, data }) => {
  const {
    params,
    modalParams,
    fUPayment = {},
    loadingFUPayment = false,
    transactionHistoryData = {},
    loadingTransactionHistory = false
  } = useSelector(state => ({
    params: state.mutations.params,
    modalParams: state.mutations.modalParams,
    fUPayment: state.factoringUnit.fUPayment,
    loadingFUPayment: state.factoringUnit.loadingFUPayment,
    transactionHistoryData: state.factoringUnit.transactionHistory,
    loadingTransactionHistory: state.factoringUnit.loadingTransactionHistory
  }));

  const { currentTab } = localStorage;

  const getActiveTab = useMemo(() => {
    switch (currentTab) {
      case 'OPEN_FOR_DISBURSEMENT':
        return '1';
      case 'PAYMENT_CLOSED':
        return '2';
      case 'ACTIVE_DISBURSEMENT':
      case 'OVERDUE_DISBURSEMENT':
        return '3';
      default:
        return '1';
    }
  }, [currentTab]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(getActiveTab);

  const fetchPaymentData = async () => {
    const response = await getFUPayments(data.factoringUnitNo);
    if (response.data.length) {
      getBuyerSellerBanks(
        data.buyerSellerLink[response.data[0].releaseTo.toLowerCase()].id,
        null,
        true
      );
    } else {
      getBuyerSellerBanks(data.buyerSellerLink.seller.id, null, true);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      switch (activeTab) {
        case '2':
          getFUPayments(data.factoringUnitNo, modalParams);
          break;
        case '4':
          getAllTransactionHistory(data.factoringUnitNo, modalParams);
          break;
        default:
          fetchPaymentData();
          getAllTransactionHistory(data.factoringUnitNo, modalParams);
          break;
      }
    }
  }, [isModalVisible, modalParams, params, activeTab]);

  return (
    <div className={tw`flex flex-col`}>
      <Button
        style={{ borderRadius: '8px', padding: '4px' }}
        onClick={async () => {
          setIsModalVisible(true);
          localStorage.setItem('activeTab', activeTab);
        }}
      >
        {label}
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          localStorage.removeItem('activeTab');
        }}
        footer={null}
      >
        <Tabs
          type='line'
          activeKey={activeTab}
          onChange={async key => {
            setActiveTab(key);
            mutateClearModalParams();
            localStorage.setItem('activeTab', key);
          }}
        >
          {['OPEN_FOR_DISBURSEMENT'].includes(currentTab) && (
            <TabPane tab='Release Payment' key='1'>
              <PaymentForm
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                fetchPaymentData={fetchPaymentData}
                data={data}
              />
            </TabPane>
          )}
          {['ACTIVE_DISBURSEMENT', 'OVERDUE_DISBURSEMENT'].includes(currentTab) && (
            <TabPane tab='Collect Payment' key='3'>
              <TransactionForm
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                fetchTransactionData={getAllTransactionHistory}
                data={data}
              />
            </TabPane>
          )}
          {[
            'OPEN_FOR_DISBURSEMENT',
            'ACTIVE_DISBURSEMENT',
            'OVERDUE_DISBURSEMENT',
            'PAYMENT_CLOSED'
          ].includes(currentTab) && (
            <TabPane tab='View Payment' key='2'>
              <TableComponent
                columns={paymentColumns}
                data={fUPayment}
                loading={loadingFUPayment}
                isModalTable={true}
              />
            </TabPane>
          )}
          {['ACTIVE_DISBURSEMENT', 'OVERDUE_DISBURSEMENT', 'PAYMENT_CLOSED'].includes(
            currentTab
          ) && (
            <TabPane tab='View Receipts' key='4'>
              <TableComponent
                columns={transactionColumns}
                data={transactionHistoryData}
                loading={loadingTransactionHistory}
              />
            </TabPane>
          )}
        </Tabs>
      </Modal>
    </div>
  );
};
