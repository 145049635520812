import React, { useState } from 'react';
import { Layout } from 'antd';
import { BsFillBellFill, BsFillPersonFill } from 'react-icons/bs';
import { Button, Dropdown } from 'antd';
import { useNavigate } from 'react-router';
import { tw } from 'twind';
import { notifications } from '../../../utils/notifications';
import { logout } from '../../../services/auth';
import { message } from '../../../utils/messages';
import { Link } from 'react-router-dom';
import WhiteLogo from '../Logo/WhiteLogo';

const THEME = process.env.REACT_APP_THEME;

const { Header, Content } = Layout;

const DashboardLayout = props => {
  const { children } = props;

  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const themeHexcode = localStorage.getItem('themeHexcode');

  const handleLogout = () => {
    logout();
    notifications.success({ message: message.LOGOUT });
    navigate('/login');
  };

  const items = [
    {
      key: '1',
      label: (
        <div className={tw`px-6 py-1`}>
          <Link to='/profile' onClick={() => setVisible(show => !show)}>
            My Profile
          </Link>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div className={tw`logout px-6 py-1`} onClick={handleLogout}>
          Logout
        </div>
      )
    }
  ];

  return (
    <Layout className={tw`h-screen bg-white`}>
      <Header
        style={{
          backgroundColor: themeHexcode || THEME,
          padding: '0px 20px 0px 30px'
        }}
        className={tw`z-10 shadow-lg`}
      >
        <div className={tw`flex justify-between items-center`}>
          <WhiteLogo />
          <div style={{ position: 'relative', top: 2 }}>
            <Dropdown menu={{ items }} trigger={['click']} placement='bottom' open={visible}>
              <Button type='text' onClick={e => setVisible(visible => !visible)}>
                <BsFillPersonFill size={20} color='white' />
              </Button>
            </Dropdown>
            <Button type='text'>
              <BsFillBellFill size={18} color='white' />
            </Button>
          </div>
        </div>
      </Header>
      <Content className={tw`px-3.5 md:px-14 pt-2.5 md:pt-5`}>{children}</Content>
    </Layout>
  );
};

export default DashboardLayout;
