import { Typography } from 'antd';

const { Text } = Typography;

export const factoringUnitViewColumns = [
  {
    key: 'key1',
    dataIndex: 'key1',
    render: (_, record) => {
      return <Text style={{ fontWeight: 600 }}>{record.key1}</Text>;
    }
  },
  {
    dataIndex: 'value1',
    key: 'value1',
    render: (_, record) => {
      return <Text>{record.value1}</Text>;
    }
  },
  {
    dataIndex: 'key2',
    key: 'key2',
    render: (_, record) => {
      return <Text style={{ fontWeight: 600 }}>{record.key2}</Text>;
    }
  },
  {
    dataIndex: 'value2',
    key: 'value2',
    render: (_, record) => {
      return <Text>{record.value2}</Text>;
    }
  }
];
