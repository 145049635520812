import React from 'react';
import useFetch from '../../hooks/useFetch';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { tw } from 'twind';
import { getURL } from '../../configs/apiURL';
import { notifications } from '../../utils/notifications';
import { Modal, Button, Form, Checkbox, Statistic, Space, Input } from 'antd';
import { RiErrorWarningFill } from 'react-icons/ri';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { consentInit } from '../../utils/handlers';
import { useSelector } from 'react-redux';
import { getMobileNo } from '../../utils/helpers';

const { Countdown } = Statistic;

const PromoterConsent = ({
  isUpdate,
  onSubmit,
  consent: {
    agree,
    stage,
    formInitial,
    formData,
    aadhaarNo,
    entityName,
    aadhaarKyc,
    aadhaarVerified,
    otpAadhaar,
    otpAadhaaarVerified,
    panKyc,
    panVerified,
    otpReport,
    otpReportVerified,
    creditReportVerified
  },
  setConsent,
  resetForm
}) => {
  const apiProviders = JSON.parse(localStorage.getItem('apiProviders'));

  const { editPromoters, editBuyerSeller } = useSelector(state => ({
    editPromoters: state.buyerSeller.editPromter,
    editBuyerSeller: state.buyerSeller.editBuyerSeller
  }));

  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();
  const [checked, setChecked] = useState(false);
  const [otp, setOtp] = useState('');
  const [pending, setPending] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [requestHitIds, setRequestHitIds] = useState();
  const [timer, setTimer] = useState({
    disabled: true,
    value: Date.now() + 60 * 1000
  });

  const { panApiProvider, aadhaarApiProvider } =
    editBuyerSeller?.data?.apiProviders || apiProviders || {};

  const onConfirm = async action => {
    let response, next;
    switch (action) {
      case 'panSubmit':
        response = await fetch(getURL(`${panApiProvider.toLowerCase()}/kyc/pan`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            pan: formData.panNumber,
            name: formData.nameOrentityName,
            dob: formData.dobOrDateOfIncorporation.format('YYYY-MM-DD'),
            entityOrPromoterId: editPromoters?.data?.id
          })
        });
        setConsent({
          panNumber: formData.panNumber,
          panKyc: 1,
          dob: formData.dobOrDateOfIncorporation,
          entityName: formData.nameOrentityName,
          panVerified: response.status === 200 ? 1 : 0
        });

        const isAadhaarMismatch = () => {
          return (
            (formData.promoterType === 'INDIVIDUAL' &&
              formData.adhaarOrRegistrationNumber &&
              !aadhaarKyc) ||
            (aadhaarKyc &&
              (formData.adhaarOrRegistrationNumber !== aadhaarNo ||
                formData.nameOrentityName !== entityName))
          );
        };

        const isUdpateAadhaarMismatch = () => {
          return (
            formData.promoterType === 'INDIVIDUAL' &&
            formData.adhaarOrRegistrationNumber &&
            (formData.adhaarOrRegistrationNumber !== formInitial.aadhaarOrRegNo ||
              formData.nameOrentityName !== formInitial.nameOrEntityName) &&
            !formInitial.aadhaarVerified
          );
        };

        if (!isUpdate) {
          if (isAadhaarMismatch()) {
            next = 'adhaarNumber';
          } else {
            next = response.status === 200 ? '' : 'submit';
          }
        } else {
          if (isUdpateAadhaarMismatch() && isAadhaarMismatch()) {
            next = 'adhaarNumber';
          } else {
            next = response.status === 200 ? '' : 'submit';
          }
        }
        break;
      case 'adhaarSubmit':
        response = await fetch(getURL(`${aadhaarApiProvider.toLowerCase()}/kyc/aadhaar/otp`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            aadhaarNo: formData.adhaarOrRegistrationNumber,
            promoterId: editPromoters?.data?.id
          })
        });
        if (response.status === 200) {
          setRequestId(response.data.data.requestId);
          setTimer({ disabled: true, value: Date.now() + 60 * 1000 });
          if (formData.promoterType === 'INDIVIDUAL' && !otpAadhaar) {
            next = 'otp';
          } else {
            next = panVerified ? '' : 'submit';
          }
          notifications.success({
            message: 'OTP Sended Successfully.'
          });
        } else {
          next = panVerified ? '' : 'submit';
        }
        break;
      case 'otpSubmit':
        response = await fetch(getURL(`${aadhaarApiProvider.toLowerCase()}/kyc/aadhaar`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            name: formData.nameOrentityName,
            aadhaarNo: formData.adhaarOrRegistrationNumber,
            otp: otp,
            requestId: requestId,
            promoterId: editPromoters.data.id
          })
        });
        if (response.status === 200) {
          setConsent({
            entityName: formData.nameOrentityName,
            aadhaarNo: formData.adhaarOrRegistrationNumber,
            aadhaarKyc: 1,
            aadhaarVerified: response.status === 200 ? 1 : 0,
            otpAadhaar: 1,
            otpAadhaaarVerified: response.status === 200 ? 1 : 0
          });
          next = panVerified ? '' : 'submit';
        } else {
          notifications.error({
            message: response.data.error || 'Something went wrong'
          });
          next = panVerified ? '' : 'submit';
        }
        break;
      case 'optReportSubmit':
        response = await fetch(getURL(`creditap/credit-report`), {
          method: 'POST',
          body: JSON.stringify({
            name: formData.nameOrentityName,
            dob: formData.dobOrDateOfIncorporation,
            stgOneHitId: requestHitIds.stgOneHitId,
            stgTwoHitId: requestHitIds.stgTwoHitId,
            otp: otp,
            pan: formData.panNumber,
            contactNo: getMobileNo(formData.contactNo)
          })
        });
        if (response.status === 200) {
          setConsent({
            creditReport: 1,
            creditReportVerified: response.status === 200 ? 1 : 0,
            otpReport: 1,
            otpReportVerified: response.status === 200 ? 1 : 0
          });
          next = 'submit';
        } else {
          notifications.error({
            message: response.data.error || 'Something went wrong'
          });
          next = 'submit';
        }
        break;
      default:
        next = 'panNumber';
        break;
    }
    setConsent({ stage: next });
  };

  const sendAadhaarOTP = async () => {
    setPending(true);
    const response = await fetch(getURL(`${aadhaarApiProvider.toLowerCase()}/kyc/aadhaar/otp`), {
      method: 'POST',
      body: JSON.stringify({
        consent: 'Y',
        aadhaarNo: formData.adhaarOrRegistrationNumber,
        promoterId: editPromoters?.data?.id
      })
    });
    if (response.status === 200) {
      setRequestId(response.data.data.requestId);
      notifications.success({ message: 'OTP Sended Successfully.' });
    } else {
      notifications.error({
        message: response.data.error.message || 'Something went wrong.'
      });
    }
    setTimer(timer => ({
      ...timer,
      disabled: !timer.disabled,
      value: Date.now() + 60 * 1000
    }));
    setPending(false);
  };

  const handleSubmit = async () => {
    const response = await onSubmit({
      ...formData,
      panVerified: panVerified,
      aadhaarVerified: aadhaarVerified
    });
    if (response) {
      setConsent({ ...consentInit });
      resetForm();
    }
  };

  const sendReportOTP = async (event, refetch = false) => {
    if (event.target.checked || refetch) {
      if (creditReportVerified) return;
      if (refetch) setPending(true);
      const response = await fetch(getURL(`creditap/credit-report/otp`), {
        method: 'POST',
        body: JSON.stringify({
          name: formData.nameOrentityName,
          dob: formData.dobOrDateOfIncorporation,
          pan: formData.panNumber,
          contactNo: getMobileNo(formData.contactNo),
          emailId: formData.email
        })
      });
      if (response.status === 200) {
        setRequestHitIds(response.data.data);
        setConsent({ stage: 'optReport' });
        setTimer({ disabled: true, value: Date.now() + 60 * 1000 });
        notifications.success({ message: 'OTP Sended Successfully.' });
      } else {
        notifications.error({
          message: response.data.error.message || 'Something went wrong.'
        });
      }
      if (refetch) {
        setTimer(timer => ({
          ...timer,
          disabled: !timer.disabled,
          value: Date.now() + 60 * 1000
        }));
        setPending(false);
      }
    }
  };

  const show = type => {
    switch (type) {
      case 'panNumber':
        if (
          isUpdate &&
          formData.panNumber &&
          (formData.panNumber !== formInitial.pan ||
            formData.nameOrentityName !== formInitial.nameOrEntityName ||
            formData.dobOrDateOfIncorporation.format('YYYY-MM-DD') !==
              dayjs(formInitial.dobOrDoi).format('YYYY-MM-DD')) &&
          !formInitial.entityDetails.panVerified
        ) {
          return true;
        }
        return !isUpdate && formData.panNumber ? true : false;
      case 'adhaarNumber':
        if (
          isUpdate &&
          formData.promoterType === 'INDIVIDUAL' &&
          formData.adhaarOrRegistrationNumber &&
          (formData.adhaarOrRegistrationNumber !== formInitial.aadhaarOrRegNo ||
            formData.nameOrentityName !== formInitial.nameOrEntityName) &&
          !formInitial.aadhaarVerified
        ) {
          return true;
        }
        return !isUpdate &&
          formData.promoterType === 'INDIVIDUAL' &&
          formData.adhaarOrRegistrationNumber
          ? true
          : false;
      default:
        return false;
    }
  };

  const isLoading = useCallback(
    action => {
      return stage === action && loading;
    },
    [loading, stage]
  );

  const isDisable = useCallback(
    action => {
      return stage !== action;
    },
    [loading, stage]
  );

  useEffect(() => {
    form.setFieldsValue({
      panNumber: formData.panNumber,
      adhaarNumber: formData.adhaarOrRegistrationNumber
    });
  }, [form]);

  return (
    <Modal
      open={agree}
      className='ant-consent-modal'
      onCancel={() => setConsent({ agree: !agree })}
      footer={null}
    >
      {checked && <h1 className={tw`text-center text-lg font-serif`}>KYC Consent & Policy</h1>}
      <Form form={form} size='medium' onFinishFailed={err => console.log(err)} autoComplete='off'>
        {checked && (
          <div>
            <br />
            {show('panNumber') && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>PAN Number</h3>
                <Space>
                  <Form.Item name='panNumber' style={{ margin: 0 }}>
                    <Input
                      placeholder='ENTER PAN'
                      disabled
                      suffix={
                        (panKyc && panVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{ height: 31 }}
                    onClick={() => onConfirm('panSubmit')}
                    loading={isLoading('panNumber')}
                    disabled={isDisable('panNumber')}
                  >
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
            {show('adhaarNumber') && formData.promoterType === 'INDIVIDUAL' && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>Aadhaar Number</h3>
                <Space>
                  <Form.Item name='adhaarNumber' style={{ margin: 0 }}>
                    <Input
                      placeholder='Enter Aadhaar Number'
                      disabled
                      suffix={
                        (aadhaarKyc && aadhaarVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{ height: 31 }}
                    onClick={() => onConfirm('adhaarSubmit')}
                    loading={isLoading('adhaarNumber')}
                    disabled={isDisable('adhaarNumber')}
                  >
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
            {stage === 'otp' && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>OTP Number</h3>
                <Space
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <Form.Item
                    name='otp'
                    style={{
                      margin: 0,
                      alignItems: 'start'
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Enter 6 digit OTP'
                      },
                      {
                        pattern: /^\d+$/,
                        whitespace: false,
                        message: 'must be number!'
                      }
                    ]}
                  >
                    <Input
                      placeholder='ENTER OTP*'
                      onChange={e => setOtp(e.target.value)}
                      suffix={
                        (otpAadhaar && otpAadhaaarVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    onClick={() => onConfirm('otpSubmit')}
                    style={{
                      height: 31
                    }}
                    loading={isLoading('otp') && !pending}
                    disabled={isDisable('otp')}
                  >
                    Confirm
                  </Button>
                  <Button
                    type='primary'
                    loading={loading && pending}
                    disabled={timer.disabled}
                    onClick={sendAadhaarOTP}
                  >
                    Resend OTP
                    <Countdown
                      value={timer.value}
                      valueStyle={{
                        fontSize: '14.55px',
                        marginTop: '10px'
                      }}
                      onFinish={() =>
                        setTimer(timer => ({
                          ...timer,
                          disabled: !timer.disabled
                        }))
                      }
                    />
                  </Button>
                </Space>
              </div>
            )}
            {stage === 'optReport' && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>OTP Number</h3>
                <Space
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <Form.Item
                    name='optReport'
                    style={{
                      margin: 0,
                      alignItems: 'start'
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Enter 6 digit OTP'
                      },
                      {
                        pattern: /^\d+$/,
                        whitespace: false,
                        message: 'must be number!'
                      }
                    ]}
                  >
                    <Input
                      placeholder='ENTER OTP*'
                      onChange={e => setOtp(e.target.value)}
                      suffix={
                        (otpReport && otpReportVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{
                      height: 31
                    }}
                    onClick={() => onConfirm('optReportSubmit')}
                    loading={isLoading('optReport') && !pending}
                    disabled={isDisable('optReport')}
                  >
                    Confirm
                  </Button>
                  <Button
                    type='primary'
                    loading={loading && pending}
                    disabled={timer.disabled}
                    onClick={event => sendReportOTP(event, true)}
                  >
                    Resend OTP
                    <Countdown
                      value={timer.value}
                      valueStyle={{
                        fontSize: '14.55px',
                        marginTop: '10px'
                      }}
                      onFinish={() =>
                        setTimer(timer => ({
                          ...timer,
                          disabled: !timer.disabled
                        }))
                      }
                    />
                  </Button>
                </Space>
              </div>
            )}
            {panVerified === 1 && (
              <div className={tw`mb-4`}>
                <Space>
                  {console.log(show('adhaarNumber'))}
                  <Form.Item name='creditReport' style={{ margin: 0 }}>
                    <Checkbox
                      onChange={sendReportOTP}
                      disabled={show('adhaarNumber') && !aadhaarKyc}
                    >
                      You agreed to pull credit report from credit bureaus
                    </Checkbox>
                  </Form.Item>
                </Space>
              </div>
            )}
            {checked && (
              <div className={tw`flex flex-row-reverse gap-2`}>
                <Button
                  danger
                  name='delete'
                  type='primary'
                  onClick={() => setChecked(checked => !checked)}
                  htmlType='submit'
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  size='md'
                  loading={isLoading('submit')}
                  disabled={isDisable('submit')}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        )}
        {!checked && (
          <div>
            <div className={classNames.center}>
              <RiErrorWarningFill size={50} color='darkorange' />
              <p className={classNames.text}>Are you sure ?</p>
            </div>
            <div className={classNames.container}>
              <Form.Item>
                <Checkbox onChange={() => setChecked(() => !checked)}>
                  Yes, I understand and agree for the KYC Consent & Policy.
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default PromoterConsent;

const classNames = {
  center: tw`flex flex-col justify-center items-center`,
  container: tw`flex justify-center mt-2`,
  text: tw`text-xl font-serif`
};
