import { store } from '../index';
import { financierTypes } from './types';

export const editFinancier = (openEdit, data) => {
  store.dispatch({
    type: financierTypes.EDIT_FINANCIER,
    payload: { data, openEdit }
  });
};

export const editFinancierBank = (openEdit, data) => {
  store.dispatch({
    type: financierTypes.EDIT_FINANCIER_BANK,
    payload: { data, openEdit }
  });
};

export const editFinancierConfigurationSector = (openEdit, data) => {
  store.dispatch({
    type: financierTypes.EDIT_FINANCIER_CONFIGRATIONS_SECTOR,
    payload: { data, openEdit }
  });
};

export const editFinancierConfigurationType = (openEdit, data) => {
  store.dispatch({
    type: financierTypes.EDIT_FINANCIER_CONFIGRATIONS_TYPE,
    payload: { data, openEdit }
  });
};
