import useFetch from '../../hooks/useFetch';
import TextInput from '../antdComponent/Input';
import TableComponent from '../antdComponent/Table';
import SelectComponent from '../antdComponent/Select';
import { dropDownInitialState, dropDownReducer, ACTION_TYPES } from '../../utils/helpers';
import { Button, Form, Card, Row, Col } from 'antd';
import { useEffect, useReducer } from 'react';
import { tw } from 'twind';
import { configurationSectorColumns } from './configurationSectorColumns';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { getURL } from '../../configs/apiURL';
import { RULES } from '../../utils/formValidations';
import { notifications } from '../../utils/notifications';
import { getFinancierConfigurationSector } from '../../services/financier';
import { editFinancierConfigurationSector } from '../../redux/financier/actions';

const ConfigurationSector = ({
  type,
  title,
  params,
  configurationSector,
  loadingConfigurationSector,
  editConfigurationSector
}) => {
  const [fetch] = useFetch();
  const [form] = Form.useForm();
  const [state, dispatch] = useReducer(dropDownReducer, dropDownInitialState);

  const onSubmit = async value => {
    if (editConfigurationSector?.openEdit) {
      const data = { name: value.name };
      if (type === 'industry-sectors') {
        data['businessSectorId'] = value.sector;
      } else {
        data['industrySectorId'] = value.sector;
      }
      const res = await fetch(getURL(`${type}/${editConfigurationSector.data.id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({
          message: `${capitalizeFirstLetter(title)} Updated Successfully`
        });
        getFinancierConfigurationSector(type, params);
        editFinancierConfigurationSector(false, {});
        form.resetFields();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      const data = {
        name: value.name,
        active: 1,
        createdByUserId: localStorage.getItem('userId')
      };
      if (type === 'industry-sectors') {
        data['businessSectorId'] = value.sector;
      } else {
        data['industrySectorId'] = value.sector;
      }
      const res = await fetch(getURL(`${type}`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({
          message: `${capitalizeFirstLetter(title)} Created Successfully`
        });
        getFinancierConfigurationSector(type, params);
        form.resetFields();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }
  };

  const getDropDown = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      const res = await fetch(
        new URL(getURL(type === 'industry-sectors' ? 'business-sectors' : 'industry-sectors'))
      );
      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: {
          sectorDropdown: res.data.data.map(row => ({
            value: row.id,
            label: row.name
          }))
        }
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  useEffect(() => {
    if (editConfigurationSector?.openEdit) {
      form.setFieldsValue({
        name: editConfigurationSector.data.name,
        sector:
          type === 'industry-sectors'
            ? editConfigurationSector.data.businessSector.id
            : editConfigurationSector.data.industrySector.id
      });
    }
  }, [editConfigurationSector]);

  useEffect(() => {
    getDropDown();
  }, []);

  useEffect(() => {
    if (type) {
      getFinancierConfigurationSector(type, params);
    }
  }, [params]);

  return (
    <>
      <Form
        form={form}
        size='large'
        autoComplete='off'
        onFinish={onSubmit}
        onFinishFailed={errorInfo => console.log(errorInfo)}
      >
        <Card style={{ width: '100%', paddingTop: '20px', marginTop: '10px' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='sector' rules={RULES.selectRequired}>
                <SelectComponent
                  label={type === 'industry-sectors' ? 'Business Sectors' : 'Industry Sectors'}
                  placeholder='Select'
                  required
                  allowClear={true}
                  options={state.dropDownData.sectorDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='name' rules={RULES.inputRequired}>
                <TextInput label={title} placeholder={`Enter ${title}`} required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Button type='primary' htmlType='submit' className={tw`w-full md:w-auto px-12`}>
                {editConfigurationSector?.openEdit ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
      <div className={tw`mt-5`}>
        <TableComponent
          columns={configurationSectorColumns(type, title)}
          data={configurationSector}
          loading={loadingConfigurationSector}
        />
      </div>
    </>
  );
};

export default ConfigurationSector;
