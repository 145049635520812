import React, { Fragment, useState } from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import * as ROUTES from '../../configs/routes';
import TextInput from '../../component/antdComponent/Input';
import PasswordInput from '../../component/antdComponent/Password';
import WhiteLogo from '../../component/antdComponent/Logo/WhiteLogo';
import ColorLogo from '../../component/antdComponent/Logo/ColorLogo';
import { Button, Form } from 'antd';
import { tw } from 'twind';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { theme, ConfigProvider, Layout, Typography } from 'antd';
import { login, sendResetPasswordLink } from '../../services/auth';
import IsdCode from '../../component/antdComponent/Mobile';

const { useToken } = theme;

const { Header, Content } = Layout;

const { Text } = Typography;

const initialValue = {
  value: null,
  label: 'Email/Mobile Number',
  placeholder: 'Enter Email or Mobile Number'
};

const redirectPaths = {
  null: ROUTES['FINANCIER_PLATFORM']['path'],
  FINANCIER: ROUTES['FINANCIER']['path'],
  BUYER: ROUTES['BUYER']['path'],
  SELLER: ROUTES['SELLER']['path']
};

const LoginContainer = () => {
  const { token } = useToken();

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [forgetPassword, setForgetPassword] = useState(false);
  const [contactIsdCode, setContactIsdCode] = useState('+91');
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(initialValue);

  const onSubmit = async value => {
    setLoading(true);

    let response,
      formData = {};
    formData[value.email.includes('@') ? 'emailId' : 'mobileNo'] = value.email.includes('@')
      ? value.email
      : `${contactIsdCode}${value.email}`;
    formData['password'] = value.password;

    if ((formData.emailId || formData.mobileNo) && formData.password) {
      response = await login(formData);
      if (response) {
        navigate(redirectPaths[localStorage.getItem('entityCategory')]);
      }
    } else {
      delete formData['password'];
      response = await sendResetPasswordLink(formData);
      if (response) {
        form.resetFields();
      }
    }

    setLoading(false);
  };

  const handleOnChange = e => {
    setInput(prev => {
      if (e.target.value) {
        return {
          ...prev,
          value: e.target.value,
          label: /^\d+$/.test(e.target.value) ? 'Mobile Number' : 'Email',
          placeholder: /^\d+$/.test(e.target.value) ? 'Enter Mobile Number' : 'Enter Email'
        };
      }
      return initialValue;
    });
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: token.defaultThemeColor } }}>
      {Cookies.get('ACCESS_TOKEN') ? (
        <Navigate
          to={redirectPaths[jwt_decode(Cookies.get('ACCESS_TOKEN')).token_payload.entityCategory]}
        />
      ) : (
        <Layout>
          <Header
            style={{
              zIndex: 2,
              width: '100%',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0px 30px',
              display: 'flex',
              background: token.defaultThemeColor
            }}
          >
            <WhiteLogo />
          </Header>
          <Content className={tw`bg-gray-50 h-screen`}>
            <div
              className={tw`flex flex-col items-center justify-center mt-6 px-4 py-8 mx-auto md:h-screen lg:py-0`}
            >
              <div
                className={tw`w-full bg-white md:mb-40 rounded-lg shadow h-[65vh] md:mt-0 flex flex-col justify-center sm:max-w-md xl:p-0`}
              >
                <div className={tw`p-4 space-y-12 md:space-y-12 sm:p-8`}>
                  <h1
                    className={tw`text-xl text-center flex justify-center items-center text-gray-900 md:text-2xl`}
                  >
                    <ColorLogo />
                  </h1>
                  <Form
                    form={form}
                    size='large'
                    className={tw`space-y-4 md:space-y-6 text-zinc-200`}
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    onFinishFailed={errorInfo => console.log(errorInfo)}
                    autoComplete='off'
                  >
                    <div>
                      <Form.Item
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Please input valid Email/Mobile Number'
                          }
                        ]}
                        style={{
                          paddingBottom: forgetPassword ? 0 : 20
                        }}
                      >
                        <TextInput
                          label={input.label}
                          placeholder={input.placeholder}
                          addonBefore={
                            /^\d+$/.test(input.value) && (
                              <IsdCode
                                isdCode={contactIsdCode}
                                setIsdCode={code => setContactIsdCode(code)}
                              />
                            )
                          }
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Item>
                      {!forgetPassword && (
                        <Fragment>
                          <Form.Item
                            name='password'
                            rules={[
                              {
                                required: true,
                                message: 'Please input valid Password!'
                              }
                            ]}
                          >
                            <PasswordInput label='Password' placeholder='Enter Password' required />
                          </Form.Item>
                          <Text
                            className={tw`block text-right mb-4 cursor-pointer`}
                            onClick={() => setForgetPassword(true)}
                          >
                            Forget password ?
                          </Text>
                        </Fragment>
                      )}
                      {forgetPassword && (
                        <Text
                          className={tw`block text-right mb-4 cursor-pointer`}
                          onClick={() => setForgetPassword(false)}
                        >
                          Continue to Sign in ?
                        </Text>
                      )}
                      <Button
                        type='primary'
                        htmlType='submit'
                        className={tw`w-full rounded`}
                        loading={loading}
                      >
                        {forgetPassword ? 'Send Reset Link' : 'Login'}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </ConfigProvider>
  );
};

export default LoginContainer;
