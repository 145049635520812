import React from 'react';
import useFetch from '../../hooks/useFetch';
import BuyerSeller from '../../component/buyerSeller';
import UserSettings from '../../component/userSettings';
import FinancierUserProfile from '../../component/financierUserProfile';
import FactoringUnitContainer from '../../component/finance/factoringUnit';
import BuyerSellerLink from '../../component/buyerSellerLink';
import OpenForFinanceContainer from '../../component/finance/openForFinance';
import FactoredUnitContainer from '../../component/finance/factoredUnit';
import NotFactoredUnitContainer from '../../component/finance/notFactoredUnit';
import CheckerLevel from '../../component/checkerLevel';
import FinancierUserBankAccountForm from '../../component/financierUserBankAccount';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getURL } from '../../configs/apiURL';
import { Permissions } from '../../configs/permissions';
import { Menu, notification } from 'antd';
import { message } from '../../utils/messages';
import { getFinancierBanks } from '../../services/financier';
import { notifications } from '../../utils/notifications';
import { editFinancierBank } from '../../redux/financier/actions';
import { mutateClearParams, mutateClearModalParams } from '../../redux/mutations/actions';

const getItem = (label, key, children, component) => {
  return {
    key,
    label,
    children,
    component
  };
};

const FinancierContainer = () => {
  const {
    profile = {},
    userLoading = false,
    users = {},
    editUser = {},
    bankLoading = false,
    banksData = [],
    editBanks = {},
    ifscBanksData = [],
    ifscDataloading = false,
    params
  } = useSelector(state => ({
    profile: state.user.profile,
    userLoading: state.user.loadingUsers,
    users: state.user.users,
    editUser: state.user.editUser,
    bankLoading: state.financier.loadingFinancierBank,
    banksData: state.financier.financierBank,
    editBanks: state.financier.editFinancierBank,
    ifscBanksData: state.buyerSeller.ifscData,
    ifscDataloading: state.buyerSeller.loadingifscData,
    params: state.mutations.params
  }));

  const [current, setCurrent] = useState('HOME');
  const [create] = useFetch();
  const [update] = useFetch();

  const onClick = e => {
    setCurrent(e.key);
    mutateClearParams();
    mutateClearModalParams();
    localStorage.setItem('currentTab', e.key);
  };

  const entityId = Cookies.get('entityId');

  const bankOnSubmit = async value => {
    const data = {
      entityId: entityId,
      bankAccountTypeId: value.accountTypeId,
      accountTitle: value.accountTitle,
      accountNo: value.accountNo,
      ifsc: value.ifscCode,
      bankName: value.bankName,
      branchName: value.branchName,
      micrCode: value.micrCode,
      swiftCode: value.swiftCode,
      isDefault: value.isDefault ? 1 : 0
    };
    if (editBanks.openEdit) {
      const res = await update(getURL(`financiers/profile/bank-details/${editBanks.data.id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notification.success({ message: message.BANK_UPDATED });
        editFinancierBank(false, {});
        getFinancierBanks(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    } else {
      const res = await create(getURL(`financiers/profile/bank-details`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notification.success({ message: message.BANK_CREATED });
        getFinancierBanks(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    }
    return true;
  };

  const items = [
    Permissions(null, null, 'homeTab') &&
      Permissions('home', 'visibility') &&
      getItem('Home', 'HOME'),
    Permissions(null, null, 'buyerSellerTab') &&
      getItem('Buyer Seller', 'buyerSeller', [
        Permissions('manageBuyerSeller', 'visibility') &&
          getItem('Manage Buyer-Seller', 'BUYER_SELLER', [
            getItem('Completed', 'COMPLETED'),
            getItem('Not Completed', 'NOT_COMPLETED')
          ]),
        Permissions('factoringUnit', 'visibility') && getItem('Factoring Unit', 'FACTORING_UNIT'),
        Permissions('manageBuyerSellerLink', 'visibility') &&
          getItem('Buyer-Seller Link', 'BUYER_SELLER_LINK', [
            getItem('Approved', 'APPROVED'),
            getItem('In Progress', 'IN_PROGRESS')
          ])
      ]),
    Permissions(null, null, 'financeTab') &&
      getItem(' Finance', 'finance', [
        Permissions('openForFinance', 'visibility') &&
          getItem('Open for Finance', 'OPEN_FOR_FINANCE'),
        Permissions('factoredUnit', 'visibility') &&
          getItem('Successful Factored', 'FACTORED_UNIT', [
            Permissions('factoredUnit', 'openDisbursement') &&
              getItem('Open for disbursement', 'OPEN_FOR_DISBURSEMENT'),
            Permissions('factoredUnit', 'activeDisbursement') &&
              getItem('Active disbursementt', 'ACTIVE_DISBURSEMENT'),
            Permissions('factoredUnit', 'overdue') && getItem('Overdue', 'OVERDUE_DISBURSEMENT'),
            Permissions('factoredUnit', 'paymentClosed') &&
              getItem('Payment closed', 'PAYMENT_CLOSED')
          ]),
        Permissions('notFactoredUnit', 'visibility') &&
          getItem('Not Factored Unit', 'NOT_FACTORED_UNIT')
      ]),
    Permissions(null, null, 'operationsTab') &&
      getItem('Operations', 'operations', [
        Permissions('approveBuyerSeller', 'visibility') &&
          getItem('Buyer-Seller', 'BUYER_SELLER_OPERATION'),
        Permissions('approveBuyerSellerLink', 'visibility') &&
          getItem('Buyer-Seller Link', 'BUYER_SELLER_LINK_OPERATION')
      ]),
    Permissions(null, null, 'settingsTab') &&
      getItem('Settings', 'settings', [
        Permissions('profile', 'visibility') && getItem('Profile', 'PROFILE'),
        Permissions('bankAccount', 'visibility') && getItem('Bank Account', 'BANK_ACCOUNT'),
        Permissions('users', 'visibility') && getItem('Users', 'USERS'),
        Permissions('checkerLevel', 'visibility') && getItem('Checker Level', 'CHECKER_LEVEL')
      ]),
    Permissions(null, null, 'reportsTab') &&
      Permissions('reports', 'visibility') &&
      getItem('Reports', 'REPORTS')
  ];

  return (
    <React.Fragment>
      <Menu
        defaultSelectedKeys={['home']}
        defaultOpenKeys={['home']}
        mode='horizontal'
        items={items}
        onClick={onClick}
        selectedKeys={[current]}
      />
      {current === 'COMPLETED' && <BuyerSeller />}
      {current === 'NOT_COMPLETED' && <BuyerSeller />}
      {current === 'BUYER_SELLER_OPERATION' && <BuyerSeller />}
      {current === 'APPROVED' && <BuyerSellerLink />}
      {current === 'IN_PROGRESS' && <BuyerSellerLink />}
      {current === 'BUYER_SELLER_LINK_OPERATION' && <BuyerSellerLink />}
      {current === 'OPEN_FOR_DISBURSEMENT' && <FactoredUnitContainer />}
      {current === 'ACTIVE_DISBURSEMENT' && <FactoredUnitContainer />}
      {current === 'OVERDUE_DISBURSEMENT' && <FactoredUnitContainer />}
      {current === 'PAYMENT_CLOSED' && <FactoredUnitContainer />}
      {current === 'PROFILE' && <FinancierUserProfile />}
      {current === 'CHECKER_LEVEL' && <CheckerLevel />}
      {current === 'FACTORING_UNIT' && <FactoringUnitContainer />}
      {current === 'OPEN_FOR_FINANCE' && <OpenForFinanceContainer />}
      {current === 'NOT_FACTORED_UNIT' && <NotFactoredUnitContainer />}
      {current === 'BANK_ACCOUNT' && (
        <FinancierUserBankAccountForm
          profile={profile}
          onSubmit={bankOnSubmit}
          tableData={banksData}
          loading={bankLoading}
          editBanks={editBanks}
          ifscBanksData={ifscBanksData}
          ifscDataloading={ifscDataloading}
          params={params}
        />
      )}
      {current === 'USERS' && (
        <UserSettings
          isUpdate={editUser?.openEdit}
          defaultValue={editUser?.data}
          loading={userLoading}
          users={users}
        />
      )}
    </React.Fragment>
  );
};

export default FinancierContainer;
