import React from 'react';
import Cookies from 'js-cookie';
import InvoiceAdd from './addInvoice/addInvoice';
import useFetch from '../../../hooks/useFetch';
import UploadModal from './uploadInvoice/uploadInvoice';
import TableComponent from '../../antdComponent/Table';
import { Button, Typography } from 'antd';
import { RiAddCircleFill } from 'react-icons/ri';
import { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { tw } from 'twind';
import { dropDownInitialState, dropDownReducer, ACTION_TYPES } from '../../../utils/helpers';
import { invoiceColumns } from './addInvoice/invoiceColumns';
import { getAllInvoices } from '../../../services/buyerSeller';
import { editInvoice } from '../../../redux/buyerSeller/actions';
import { Permissions } from '../../../configs/permissions';
import { getURL } from '../../../configs/apiURL';
import { notifications } from '../../../utils/notifications';
import { message } from '../../../utils/messages';

const { Text } = Typography;

const InvoiceManagement = () => {
  const [state, dispatch] = useReducer(dropDownReducer, dropDownInitialState);

  const {
    params,
    invoiceData = {},
    loadingInvoiceData = false,
    editInvoiceData = {}
  } = useSelector(state => ({
    params: state.mutations.params,
    loadingInvoiceData: state.buyerSeller.loadingInvoiceData,
    invoiceData: state.buyerSeller.invoiceData,
    editInvoiceData: state.buyerSeller.editInvoice
  }));

  const [fetch] = useFetch();
  const [showInvoiceAdd, setShowInvoiceAdd] = useState(false);

  const entityId = Cookies.get('entityId');
  const { userId, entityCategory } = localStorage;

  const onSubmitInvoiceAdd = async value => {
    const data = {
      invoiceNo: value.invoiceNumber,
      buyerId: value.buyerName,
      sellerId: value.sellerName,
      invoiceDate: value.invoiceDate,
      creditPeriod: value.creditPeriod,
      invoiceDueDate: value.dueDate,
      invoiceAmount: parseFloat(value.invoiceAmount),
      discountAmount: parseFloat(value.discountAmount),
      taxAmount: parseFloat(value.taxAmount),
      createdByUserId: userId
    };
    if (editInvoiceData.openEdit) {
      const _id = editInvoiceData.data.id;
      const res = await fetch(getURL(`invoices/${_id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({ message: message.INVOICE_UPDATED });
        setShowInvoiceAdd(false);
        editInvoice(false, {});
        getAllInvoices(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      const res = await fetch(getURL(`invoices`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({ message: message.INVOICE_CREATED });
        editInvoice(true, res.data.data);
        getAllInvoices(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }
  };

  const fetchBuyerSellerData = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      switch (entityCategory) {
        case 'SELLER': {
          const resp = await fetch(getURL('buyer-seller/profile'));
          const sellerDropdown = [
            {
              value: resp.data.data.entityDetails.id,
              label: resp.data.data.entityDetails.entityName
            }
          ];
          const res = await fetch(getURL(`buyer-seller/links?approved=1&sellerId=${entityId}`));
          const buyerDropdown = res.data.data.map(item => ({
            value: item.buyer.id,
            label: item.buyer.entityName
          }));
          dispatch({
            type: ACTION_TYPES.FETCH_SUCCESS,
            payload: { buyerDropdown, sellerDropdown }
          });
          break;
        }
        case 'BUYER': {
          const resp = await fetch(getURL('buyer-seller/profile'));
          const buyerDropdown = [
            {
              value: resp.data.data.entityDetails.id,
              label: resp.data.data.entityDetails.entityName
            }
          ];
          const res = await fetch(getURL(`buyer-seller/links?approved=1&buyerId=${entityId}`));
          const sellerDropdown = res.data.data.map(item => ({
            value: item.seller.id,
            label: item.seller.entityName
          }));
          dispatch({
            type: ACTION_TYPES.FETCH_SUCCESS,
            payload: { buyerDropdown, sellerDropdown }
          });
          break;
        }
        default:
          break;
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  useEffect(() => {
    fetchBuyerSellerData();
  }, []);

  useEffect(() => {
    getAllInvoices(params);
  }, [params]);

  return (
    <div>
      {showInvoiceAdd || editInvoiceData.openEdit ? (
        <InvoiceAdd
          setShowInvoiceAdd={setShowInvoiceAdd}
          onSubmit={onSubmitInvoiceAdd}
          defaultValue={editInvoiceData.openEdit ? editInvoiceData.data : []}
          isUpdate={editInvoiceData.openEdit}
          buyerDropdown={state.dropDownData.buyerDropdown}
          sellerDropdown={state.dropDownData.sellerDropdown}
        />
      ) : (
        <div>
          <div className={tw`flex flex-col md:flex-row justify-between content-divider`}>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '600px',
                textAlign: 'center'
              }}
            >
              Invoice
            </Text>
            <div className={tw`flex flex-row justify-between gap-2`}>
              {Permissions('invoices', 'uploadInvoice') && (
                <UploadModal
                  title={'Upload Invoice'}
                  buyerDropdown={state.dropDownData.buyerDropdown}
                  sellerDropdown={state.dropDownData.sellerDropdown}
                  params={params}
                />
              )}
              {Permissions('invoices', 'addInvoice') && (
                <Button
                  name='add-invoice'
                  type='primary'
                  className='rounded'
                  onClick={() => {
                    setShowInvoiceAdd(true);
                  }}
                >
                  <div className={tw`text-xs md:text-sm flex gap-1 items-center`}>
                    <RiAddCircleFill size='20px' /> Add Invoice
                  </div>
                </Button>
              )}
            </div>
          </div>
          <TableComponent
            data={invoiceData}
            columns={invoiceColumns}
            loading={loadingInvoiceData}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceManagement;
