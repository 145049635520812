import { Table } from 'antd';
import { tw } from 'twind';
import { useSelector } from 'react-redux';
import { generateFilterQuery } from '../../../utils/generateQuery';
import { mutateModalParams, mutateParams } from '../../../redux/mutations/actions';
import { useState } from 'react';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

const TableComponent = ({
  columns = [],
  data = {},
  loading = false,
  isModalTable = false,
  ...rest
}) => {
  const { params, modalParams } = useSelector(state => ({
    params: state.mutations.params,
    modalParams: state.mutations.modalParams
  }));

  const searchParams = new URLSearchParams(isModalTable ? modalParams : params);
  const offsetValue = searchParams.get('offset');

  const [currentPage, setCurrPage] = useState(
    offsetValue ? Math.floor(Number(offsetValue) / PAGE_LIMIT + 1) : 1
  );

  const { count = 0, data: tableData = [] } = data;

  return (
    <div className={tw`table-responsive`}>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={loading || false}
        rowKey='key'
        bordered
        pagination={{
          current: offsetValue ? currentPage : 1,
          pageSize: PAGE_LIMIT,
          total: Math.max(tableData.length ? count || tableData.length : currentPage * PAGE_LIMIT),
          onChange: currPage => {
            if (isModalTable) {
              const searchQuery = generateFilterQuery(
                { offset: (currPage - 1) * PAGE_LIMIT },
                modalParams
              );
              mutateModalParams(searchQuery);
              localStorage.setItem('modalParams', searchQuery);
            } else {
              const searchQuery = generateFilterQuery(
                { offset: (currPage - 1) * PAGE_LIMIT },
                params
              );
              mutateParams(searchQuery);
              localStorage.setItem('params', searchQuery);
            }
            setCurrPage(currPage);
          }
        }}
        {...rest}
      />
    </div>
  );
};

export default TableComponent;
