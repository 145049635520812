import { financierTypes } from './types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case financierTypes.GET_FINANCIER_LOADING:
      return {
        ...state,
        loadingFinancier: true,
        financiers: {}
      };

    case financierTypes.GET_FINANCIER_SUCCESS: {
      const financiers = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingFinancier: false,
        financiers: {
          data: financiers,
          count: action.payload.count
        }
      };
    }

    case financierTypes.GET_FINANCIER_PROFILE_LOADING:
      return {
        ...state,
        loadingFinancierProfile: true,
        financierProfile: {}
      };

    case financierTypes.GET_FINANCIER_PROFILE_SUCCESS: {
      return {
        ...state,
        loadingFinancierProfile: false,
        financierProfile: action.payload
      };
    }

    case financierTypes.GET_FINANCIER_PROFILE_FAILURE:
      return {
        ...state,
        loadingFinancierProfile: false,
        financierProfile: {}
      };

    case financierTypes.GET_FINANCIER_FAILURE:
      return {
        ...state,
        loadingFinancier: false,
        financiers: {}
      };

    case financierTypes.GET_FINANCIER_BANKS_SUCCESS: {
      const banks = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingFinancierBank: false,
        financierBank: {
          data: banks,
          count: action.payload.count
        }
      };
    }

    case financierTypes.GET_FINANCIER_BANKS_LOADING:
      return {
        ...state,
        loadingFinancierBank: true,
        financierBank: {}
      };

    case financierTypes.GET_FINANCIER_BANKS_FAILURE:
      return {
        ...state,
        loadingFinancierBank: false,
        financierBank: {}
      };

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_SUCCESS: {
      const configurationSector = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingConfigurationSector: false,
        configurationSector: {
          data: configurationSector,
          count: action.payload.count
        }
      };
    }

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_LOADING:
      return {
        ...state,
        loadingConfigurationSector: true,
        configurationSector: {}
      };

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_FAILURE:
      return {
        ...state,
        loadingConfigurationSector: false,
        configurationSector: {}
      };

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_SUCCESS: {
      const configurationType = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingConfigurationType: false,
        configurationType: {
          data: configurationType,
          count: action.payload.count
        }
      };
    }

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_LOADING:
      return {
        ...state,
        loadingConfigurationType: true,
        configurationType: {}
      };

    case financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_FAILURE:
      return {
        ...state,
        loadingConfigurationsType: false,
        configurationsType: {}
      };

    case financierTypes.EDIT_FINANCIER:
      return {
        ...state,
        editFinancier: action.payload
      };

    case financierTypes.EDIT_FINANCIER_BANK:
      return {
        ...state,
        editFinancierBank: action.payload
      };

    case financierTypes.EDIT_FINANCIER_BANK:
      return {
        ...state,
        editFinancierBank: action.payload
      };

    case financierTypes.EDIT_FINANCIER_CONFIGRATIONS_SECTOR:
      return {
        ...state,
        editConfigurationSector: action.payload
      };

    case financierTypes.EDIT_FINANCIER_CONFIGRATIONS_TYPE:
      return {
        ...state,
        editConfigurationType: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
