import React from 'react';
import './style.css';
import { useState, useEffect } from 'react';
import { Button, Form, Typography, Tabs } from 'antd';
import { MdOutlineArrowBack } from 'react-icons/md';
import { tw } from 'twind';
import { editFinancier } from '../../redux/financier/actions';
import { FinancierDetailForm } from './financierDetailsForm';
import { FinancierApiForm } from './financierApiForm';
import { getIsdCode } from '../../utils/helpers';

const { Text } = Typography;
const { TabPane } = Tabs;

const FinancierAdd = ({ isUpdate, defaultValue, onSubmit, onSubmitLoader, setFinancierAdd }) => {
  const [form] = Form.useForm();
  const [isdCode, setIsdCode] = useState(getIsdCode(defaultValue?.adminDetails?.mobileNo));
  const [error, setError] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');

  const onSubmitHandler = async ({ type }) => {

    if (error) return;

    const value = await form.validateFields();

    const data = {
      ...value,
      mobileNo: isdCode + value.mobileNo,
      password: form.getFieldValue('password'),
      panVerified: 0,
      udyamRegNoVerified: 0
    };

    switch (type) {
      case 'FINANCIER_DETAILS':
        if (isUpdate) {
          onSubmit({ ...data, ...defaultValue.apiProviders });
        } else {
          setCurrentTab('2');
        }
        break;
      case 'FINANCIER_API_SETTINGS':
        if (isUpdate) {
          onSubmit(data);
        } else {
          const response = await onSubmit({ ...data });
          if (response.status === 200) {
            setFinancierAdd(false);
            editFinancier(false, {});
          }
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIsdCode(getIsdCode(defaultValue?.adminDetails?.mobileNo));
  }, []);

  return (
    <div>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Button
          type='default'
          style={{ borderRadius: '4px', padding: '5px' }}
          onClick={() => {
            setFinancierAdd(false);
            editFinancier(false, {});
          }}
        >
          <MdOutlineArrowBack size='20px' />
        </Button>
        <Text className={tw`text-lg`}>{isUpdate ? 'Update' : 'Add'} Financier</Text>
      </div>
      <Form
        form={form}
        size='large'
        onValuesChange={() => form.validateFields(['repassword'])}
        autoComplete='off'
      >
        <Tabs
          type='line'
          activeKey={currentTab}
          onChange={async key => {
            setCurrentTab(key);
            localStorage.setItem('activeTab', key)
          }}
        >
          <TabPane tab='Financier' key='1'>
            <FinancierDetailForm
              isUpdate={isUpdate}
              defaultValue={defaultValue}
              form={form}
              onSubmitHandler={onSubmitHandler}
              onSubmitLoader={onSubmitLoader}
              isdCode={isdCode}
              setIsdCode={setIsdCode}
              error={error}
              setError={setError}
            />
          </TabPane>
          <TabPane tab='API Settings' key='2' disabled={!isUpdate && currentTab !== '2'}>
            <FinancierApiForm
              isUpdate={isUpdate}
              defaultValue={defaultValue}
              form={form}
              onSubmitHandler={onSubmitHandler}
              onSubmitLoader={onSubmitLoader}
            />
          </TabPane>
        </Tabs>
      </Form>
    </div>
  );
};

export default FinancierAdd;
