import React from 'react';
import TextInput from '../antdComponent/Input';
import PasswordInput from '../antdComponent/Password';
import SelectComponent from '../antdComponent/Select';
import useFetch from '../../hooks/useFetch';
import Cookies from 'js-cookie';
import IsdCode from '../antdComponent/Mobile';
import TableComponent from '../antdComponent/Table';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form, Spin, Row, Col, Typography } from 'antd';
import { editUser as updateUser } from '../../redux/user/actions';
import { showPassword } from '../../services/auth';
import { tw } from 'twind';
import { userSettingsColumn } from './userSettingsColumn';
import { RULES } from '../../utils/formValidations';
import { getAllUsers } from '../../services/user';
import { getURL } from '../../configs/apiURL';
import { notifications } from '../../utils/notifications';
import { useSelector } from 'react-redux';
import { message } from '../../utils/messages';
import { getIsdCode, getMobileNo } from '../../utils/helpers';
import { FINANCIER_USER_TYPES, BUYER_SELLER_USER_TYPES } from '../../utils/helpers';
import { Permissions } from '../../configs/permissions';

const { Text } = Typography;

const UserSettings = ({ isUpdate, defaultValue, loading, users }) => {
  const [fetch] = useFetch();
  const [form] = Form.useForm();
  const [isdCode, setIsdCode] = useState(getIsdCode(defaultValue?.mobileNo));
  const [passwordLoading, setPasswordLoading] = useState(false);

  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  const entityCategory = localStorage.getItem('entityCategory');

  const initialValues = useMemo(() => {
    return isUpdate
      ? {
          name: `${defaultValue.firstName} ${defaultValue.lastName}`,
          emailId: defaultValue.emailId,
          mobileNo: getMobileNo(defaultValue.mobileNo),
          userType: defaultValue.userType
        }
      : {};
  }, [defaultValue]);

  const showUserPassword = async id => {
    setPasswordLoading(true);
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
    setPasswordLoading(false);
  };

  const onSubmit = async value => {
    const name = value.name.split(' ');
    const firstName = name[0] ? name[0] : '';
    const lastName = name[1] ? name[1] : '';
    const _data = {
      firstName: firstName,
      lastName: lastName,
      emailId: value.emailId,
      mobileNo: isdCode + value.mobileNo,
      userType: value.userType,
      entityId: Cookies.get('entityId'),
      entityCategory: entityCategory,
      active: 1,
      password: value.password
    };

    if (isUpdate === true) {
      const res = await fetch(getURL(`platform/users/${defaultValue.id}`), {
        method: 'PUT',
        body: JSON.stringify(_data)
      });
      if (res && res.status === 200) {
        updateUser(false, {});
        getAllUsers(params);
        form.resetFields();
        notifications.success({ message: message.USER_UPDATED });
      } else {
        notifications.error({ message: res.data.error.message });
      }
    } else {
      const res = await fetch(getURL(`platform/users`), {
        method: 'POST',
        body: JSON.stringify(_data)
      });

      if (res && res.status === 200) {
        getAllUsers(params);
        form.resetFields();
        notifications.success({ message: message.USER_CREATED });
      } else {
        notifications.error({ message: res.data.error.message });
      }
    }
  };

  useEffect(() => {
    if (isUpdate) {
      showUserPassword(defaultValue.id);
    }
  }, [isUpdate]);

  useEffect(() => {
    getAllUsers(params);
  }, [params]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  if (passwordLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={passwordLoading} />
      </div>
    );
  }

  return (
    <div className={tw`content-divider`}>
      {Permissions('users', isUpdate ? 'editUsers' : 'addUsers') && (
        <Form
          form={form}
          size='large'
          onFinish={onSubmit}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          onValuesChange={() => form.validateFields(['repassword'])}
          autoComplete='off'
        >
          <Card title='User Details' style={{ width: '100%' }}>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='userType' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='User Type'
                    placeholder='Select'
                    required
                    allowClear={true}
                    options={
                      localStorage.getItem('entityCategory') === 'FINANCIER'
                        ? FINANCIER_USER_TYPES
                        : BUYER_SELLER_USER_TYPES
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='name' rules={RULES.name}>
                  <TextInput label='Name' placeholder='Enter Name' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name='mobileNo'
                  rules={RULES.mobileNo}
                  style={{ position: 'relative', bottom: 7 }}
                >
                  <TextInput
                    label='Mobile No'
                    placeholder='Enter Number'
                    required
                    addonBefore={
                      <IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='emailId' rules={RULES.email}>
                  <TextInput label='Email' placeholder='Enter Email' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='password' rules={RULES.password} hasFeedback>
                  <PasswordInput label='Password' placeholder='Enter Password' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                  <PasswordInput label='Re-Password' placeholder='Enter Password' required />
                </Form.Item>
              </Col>
            </Row>
            <Button size='middle' type='primary' htmlType='submit'>
              {isUpdate ? 'Update' : 'Add'}
            </Button>
          </Card>
        </Form>
      )}
      <div className={tw`content-divider`}>
        <Text className={tw`text-xl`}>Users</Text>
      </div>
      <TableComponent columns={userSettingsColumn} data={users} loading={loading} />
    </div>
  );
};

export default UserSettings;
