import React from 'react';
import { useEffect } from 'react';
import { EntityKycDetails } from '../modals/viewEntity';
import {
  getAllLinkedGst,
  getBuyerSellerBanks,
  getBuyerSellerPromoters,
  getCreditReportDetails,
  getPanKycDetails,
  getUdyamKycDetails
} from '../../../services/buyerSeller';
import { PromoterCardViewModal } from '../modals/promoterCard';
import { BankCardViewModal } from '../modals/bankCard';
import { useSelector } from 'react-redux';
import { store } from '../../../redux';
import { buyerSellerTypes } from '../../../redux/buyerSeller/types';

const Analysis = ({ defaultValue, banksData, promotersData }) => {
  const {
    editBuyerSeller = {},
    bankKycDetails = {},
    loadingBankKycDetails = false
  } = useSelector(state => ({
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    bankKycDetails: state.buyerSeller.bankKycDetails,
    loadingBankKycDetails: state.buyerSeller.loadingBankKycDetails
  }));

  const {
    panApiProvider = '',
    creditReportApiProvider = [],
    udyamRegNoApiProvider = ''
  } = editBuyerSeller.data.apiProviders || {};

  const fetchPanKycDetails = verified => {
    if (verified) {
      getPanKycDetails(panApiProvider, defaultValue.entityDetails.pan);
      getAllLinkedGst(panApiProvider, defaultValue.entityDetails.pan);
      getCreditReportDetails(creditReportApiProvider, defaultValue.entityDetails.pan);
    } else {
      store.dispatch({
        type: buyerSellerTypes.GET_PAN_KYC_FAILURE
      });
      store.dispatch({
        type: buyerSellerTypes.GET_LINKED_GST_FAILURE
      });
    }
  };

  const fetchUdyamKycDetails = verified => {
    if (verified) {
      getUdyamKycDetails(udyamRegNoApiProvider, defaultValue.entityDetails.udyamRegNo);
    } else {
      store.dispatch({
        type: buyerSellerTypes.GET_UDYAM_KYC_FAILURE
      });
    }
  };

  useEffect(() => {
    fetchPanKycDetails(defaultValue.entityDetails.panVerified);
    fetchUdyamKycDetails(defaultValue.entityDetails.udyamRegNoVerified);
    getBuyerSellerBanks(defaultValue.entityDetails.id);
    getBuyerSellerPromoters(defaultValue.entityDetails.id);
  }, []);

  return (
    <div style={styles.container}>
      <EntityKycDetails data={defaultValue} />

      {banksData?.data?.map((record, index) => (
        <BankCardViewModal
          customFetch={true}
          bankData={record}
          kycData={bankKycDetails}
          loadingBankKycDetails={loadingBankKycDetails}
          index={index + 1}
        />
      ))}

      {promotersData?.data?.map((record, index) => (
        <PromoterCardViewModal customFetch={true} promoterData={record} index={index + 1} />
      ))}
    </div>
  );
};

export default Analysis;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginBottom: 20
  }
};
