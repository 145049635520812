import { TextFilter, StatusFilter } from '../../utils/formFilters';
import { platformRolesPermission } from '../../configs/permissions';
import { editFinancierConfigurationSector } from '../../redux/financier/actions';
import { updateFinancierConfigSectorStatus } from '../../services/financier';
import { MdModeEditOutline } from 'react-icons/md';
import { tw } from 'twind';
import { Typography, Divider, Space, Button, Switch } from 'antd';

const { Text } = Typography;

export const configurationSectorColumns = (type, title) => {
  return [
    {
      title: (
        <div>
          <Text>{type === 'industry-sectors' ? 'Business Sectors' : 'Industry Sectors'}</Text>
          <Divider />
          <TextFilter
            type={type === 'industry-sectors' ? 'businessSectorName' : 'industrySectorName'}
          />
        </div>
      ),
      dataIndex: 'parent',
      key: 'parent',
      render: (_, record) => (
        <Text>
          {type === 'industry-sectors'
            ? `${record?.businessSector?.name}`
            : `${record?.industrySector?.name}`}
        </Text>
      )
    },
    {
      title: (
        <div>
          <Text>{title}</Text>
          <Divider />
          <TextFilter type='name' />
        </div>
      ),
      dataIndex: 'level',
      key: 'level',
      render: (_, record) => <Text>{record.name}</Text>
    },
    {
      title: (
        <div>
          <Text>Actions</Text>
          <Divider />
          <StatusFilter type='active' />
        </div>
      ),
      key: 'action',
      render: (_, record) => (
        <div className={tw`flex justify-center`}>
          <Space size='middle' align='center'>
            {platformRolesPermission('configurations', 'editConfiguration') && (
              <Button
                name='update-configuration'
                style={{ borderRadius: '8px', padding: '5px' }}
                onClick={() => editFinancierConfigurationSector(true, record)}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
            {platformRolesPermission('configurations', 'editConfigurationStatus') && (
              <Switch
                checked={record.active ? true : false}
                onChange={checked =>
                  updateFinancierConfigSectorStatus(type, record.id, {
                    active: checked ? 1 : 0
                  })
                }
              />
            )}
          </Space>
        </div>
      )
    }
  ];
};
