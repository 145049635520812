import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { getURL } from '../configs/apiURL';
import { notifications } from '../utils/notifications';
import { store } from '../redux';
import { message } from '../utils/messages';

const handleResponse = async res => {
  try {
    if (res.ok) {
      return await res.json();
    } else {
      const { error } = await res.json();
      throw new Error(error.message || 'something went wrong');
    }
  } catch (error) {
    console.warn(error);
    throw new Error(error.message || 'something went wrong');
  }
};

const fetchData = async (url, method, data, token) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const options = {
    method,
    headers,
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    return await handleResponse(response);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message || 'something went wrong');
  }
};

export const login = async formData => {
  try {
    const url = getURL('platform/auth/login');
    const response = await fetchData(url, 'POST', formData);

    const {
      data: { entityCategory, userType, id, entityId },
      token
    } = response;
    const { finLogoDocId } = jwt_decode(token).token_payload;

    if (finLogoDocId) {
      const logoUrl = getURL(
        `/financiers/logo/${finLogoDocId}/signed-url?action=VIEW&expiresIn=1800`
      );
      const logoResponse = await fetchData(logoUrl, 'GET', null, token);

      if (logoResponse) {
        const { url } = logoResponse.data;
        localStorage.setItem('logoImage', url);
      }
    }

    localStorage.setItem('entityCategory', entityCategory);
    localStorage.setItem('userType', userType);
    localStorage.setItem('userId', id);
    Cookies.set('entityId', entityId);
    Cookies.set('ACCESS_TOKEN', token);

    notifications.success({
      message: message.LOGIN
    });

    return response;
  } catch (error) {
    notifications.error({
      message: error.message
    });
  }
};

export const sendResetPasswordLink = async formData => {
  try {
    const url = getURL('platform/auth/reset-password');
    const response = await fetchData(url, 'POST', formData);

    notifications.success({
      message: `Password reset link successfully sent on ${formData.emailId || formData.mobileNo}`
    });

    return response;
  } catch (error) {
    notifications.error({
      message: error.message
    });
  }
};

export const resetPassword = async (userId, token, formData) => {
  try {
    const url = getURL(`platform/auth/reset-password/${userId}/${token}`);
    const response = await fetchData(url, 'POST', {
      password: formData.password
    });

    notifications.success({
      message: 'Password reset successfully.'
    });

    return response;
  } catch (error) {
    notifications.error({
      message: error.message
    });
  }
};

export const showPassword = async id => {
  try {
    const url = getURL(`platform/users/${id}/password`);
    const response = await fetchData(url, 'GET', null, Cookies.get('ACCESS_TOKEN'));

    return response.data.password;
  } catch (error) {
    notifications.error({
      message: error.message
    });
  }
};

export const logout = () => {
  Cookies.remove('ACCESS_TOKEN');
  Cookies.remove('entityId');
  localStorage.clear();
  store.dispatch({ type: 'USER_LOGOUT' });
};
