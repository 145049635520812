import React from 'react';
import BuyerSellerApprove from './approve';
import BuyerSellerSanctioned from './limit';
import BuyerSellerDocumentsForm from './documents';
import BuyerSellerAddressForm from './address';
import BuyerSellerEntityForms from './entity';
import BuyerSellerPromotersForm from './promoters';
import BuyerSellerAnalysis from './analysis';
import BuyerSellerBankForm from './banks';
import useFetch from '../../../hooks/useFetch';
import Cookies from 'js-cookie';
import { consentInit } from '../../../utils/handlers';
import { useState, useReducer } from 'react';
import { Button, Tabs, Typography } from 'antd';
import { MdOutlineArrowBack } from 'react-icons/md';
import { notifications } from '../../../utils/notifications';
import { tw } from 'twind';
import { message } from '../../../utils/messages';
import { useSelector } from 'react-redux';
import { dropDownInitialState, dropDownReducer } from '../../../utils/helpers';
import { getURL } from '../../../configs/apiURL';
import {
  getBuyerSellerAddress,
  getBuyerSellerBanks,
  getBuyerSellerPromoters
} from '../../../services/buyerSeller';
import {
  editAddress,
  editBank,
  editBuyerSeller,
  editPromter
} from '../../../redux/buyerSeller/actions';
import { mutateClearModalParams } from '../../../redux/mutations/actions';

const { Text } = Typography;

const { TabPane } = Tabs;

const BuyerSellerAdd = ({ setBuyerSellerAdd, isUpdate, editBuyerSellerData, selfOnBoard }) => {
  const {
    modalParams,
    editaddress = { data: {}, openEdit: false },
    editBanks = { data: {}, openEdit: false },
    editPromoters = { data: {}, openEdit: false },
    addressData = {},
    banksData = {},
    promotersData = {},
    documentsData = {},
    ifscBanksData = {},
    pinCodeData = {},
    addressLoading = false,
    bankLoading = false,
    promoterLoading = false,
    documentsLoading = false,
    ifscDataloading = false,
    pinCodeDataloading = false
  } = useSelector(state => ({
    modalParams: state.mutations.modalParams,
    editaddress: state.buyerSeller.editAddress,
    editBanks: state.buyerSeller.editBank,
    editPromoters: state.buyerSeller.editPromter,
    addressData: state.buyerSeller.address,
    banksData: state.buyerSeller.bank,
    promotersData: state.buyerSeller.promoters,
    documentsData: state.buyerSeller.documents,
    ifscBanksData: state.buyerSeller.ifscData,
    pinCodeData: state.buyerSeller.pinData,
    addressLoading: state.buyerSeller.loadingAddress,
    bankLoading: state.buyerSeller.loadingBank,
    promoterLoading: state.buyerSeller.loadingPromoters,
    documentsLoading: state.buyerSeller.loadingDocument,
    ifscDataloading: state.buyerSeller.loadingifscData,
    pinCodeDataloading: state.buyerSeller.loadingpinCodeData
  }));

  const [state, dispatch] = useReducer(dropDownReducer, dropDownInitialState);
  const [currentTab, setCurrentTab] = useState('1');
  const [activeTabs, setActiveTabs] = useState(['1']);
  const [entity, setEntity] = useState(null);
  const [fetch, loading] = useFetch();
  const [consent, setConsent] = useState({ ...consentInit });

  const entityCategory = localStorage.getItem('entityCategory');
  const { approved = false, allDetailsFilled = false } = editBuyerSellerData.entityDetails || {};

  const entityOnSubmit = async value => {
    const name = value.adminName.split(' ');
    const firstName = name[0] ? name[0] : '';
    const lastName = name[1] ? name[1] : '';

    const {
      panApiProvider,
      aadhaarApiProvider,
      gstApiProvider,
      gstItrApiProvider,
      bankAccApiProvider,
      bankStmtApiProvider,
      creditReportApiProvider,
      udyamApiProvider
    } = JSON.parse(localStorage.getItem('apiProviders'));

    const data = {
      entityDetails: {
        entityCategory: value.buyerSeller,
        entityName: value.entityName,
        pan: value.panNumber,
        panVerified: editBuyerSellerData?.entityDetails?.panVerified || value.panVerified,
        active: isUpdate ? editBuyerSellerData.entityDetails.active : 1,
        approved: isUpdate ? editBuyerSellerData.entityDetails.approved : 0,
        registrationNo: value.registrationNumber || null,
        entityTypeId: value.entityTypeId || null,
        udyamRegNo: value.udyamNumber || null,
        udyamRegNoVerified:
          editBuyerSellerData?.entityDetails?.udyamRegNoVerified || value.udyamRegNoVerified,
        dateOfIncorporation: value.incorporationDate || null,
        startOfOperation: value.startOfOperation || null,
        businessSectorId: value.businessSector || null,
        industrySectorId: value.industrySector || null,
        industrySubSectorId: value.industrySubsector || null,
        salesInLastFy: value.salesInLastFY || null,
        profitInLastFy: value.profitInLastFY || null,
        contactNo: value.contactNo || null,
        emailId: value.primaryEmail || null
      },
      adminDetails: {
        entityCategory: value.buyerSeller,
        userType: 'ADMIN',
        firstName: firstName,
        lastName: lastName,
        active: 1,
        mobileNo: value.mobileNo,
        emailId: value.email,
        password: value.password
      }
    };

    if (isUpdate) {
      data['adminDetails'].id = editBuyerSellerData.adminDetails.id;

      const res = await fetch(getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });

      if (res && res.status === 200) {
        if (['BUYER', 'SELLER'].includes(entityCategory)) {
          setEntity({
            ...res.data.data,
            apiProviders: editBuyerSellerData.apiProviders
          });
        } else {
          editBuyerSeller(true, {
            ...res.data.data,
            apiProviders: editBuyerSellerData.apiProviders
          });
        }
        notifications.success({ message: message.ENTITY_UPDATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    } else {
      const entityId = Cookies.get('entityId');
      data['entityDetails']['createdByFinancierId'] = entityId;
      data['adminDetails']['entityId'] = entityId;

      data.apiProviders = {
        panApiProvider,
        aadhaarApiProvider,
        gstApiProvider,
        gstItrApiProvider,
        bankAccApiProvider,
        bankStmtApiProvider,
        creditReportApiProvider,
        udyamRegNoApiProvider: udyamApiProvider
      };

      const res = await fetch(getURL(`buyer-seller`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        editBuyerSeller(true, {
          ...res.data.data,
          apiProviders: editBuyerSellerData.apiProviders
        });
        notifications.success({ message: message.ENTITY_CREATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    }
    return true;
  };

  const addressOnSubmit = async value => {
    const data = {
      entityId: editBuyerSellerData.entityDetails.id,
      addressTypeId: value.addressType,
      addressLineOne: value.addressLine1,
      addressLineTwo: value.addressLine2 || null,
      pinCode: value.pinNo,
      state: value.state,
      district: value.district,
      subDistrict: value.subDist,
      postOffice: value.postOffice
    };

    if (editaddress.openEdit) {
      const res = await fetch(
        getURL(
          `buyer-seller/${editBuyerSellerData.entityDetails.id}/address-details/${editaddress.data.id}`
        ),
        {
          method: 'PUT',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.ADDRESS_UPDATED });
        editAddress(false, {});
        getBuyerSellerAddress(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false
      }
    } else {
      const res = await fetch(
        getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}/address-details`),
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.ADDRESS_CREATED });
        getBuyerSellerAddress(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    }
    return true;
  };

  const bankOnSubmit = async value => {
    const data = {
      entityId: editBuyerSellerData.entityDetails.id,
      bankAccountTypeId: value.accountTypeId,
      accountTitle: value.accountTitle,
      bankAccVerified: editBanks?.data?.bankAccVerified || value.bankAccVerified,
      accountNo: value.accountNo,
      ifsc: value.ifscCode,
      bankName: value.bankName,
      branchName: value.branchName,
      micrCode: value.micrCode,
      swiftCode: value.swiftCode,
      isDefault: value.isDefault ? 1 : 0
    };
    if (editBanks.openEdit) {
      const res = await fetch(
        getURL(
          `buyer-seller/${editBuyerSellerData.entityDetails.id}/bank-details/${editBanks.data.id}`
        ),
        {
          method: 'PUT',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.BANK_UPDATED });
        editBank(false, {});
        getBuyerSellerBanks(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    } else {
      const res = await fetch(
        getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}/bank-details`),
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.BANK_CREATED });
        getBuyerSellerBanks(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    }
    return true;
  };

  const promoterOnSubmit = async value => {
    const data = {
      entityId: editBuyerSellerData.entityDetails.id,
      promoterType: value.promoterType,
      nameOrEntityName: value.nameOrentityName,
      gender: value.gender,
      dobOrDoi: value.dobOrDateOfIncorporation,
      aadhaarOrRegNo: value.adhaarOrRegistrationNumber,
      aadhaarVerified: editPromoters?.data?.aadhaarVerified || value.aadhaarVerified,
      dinOrCinNo: value.dinCin,
      pan: value.panNumber,
      panVerified: editPromoters?.data?.panVerified || value.panVerified,
      sharePercentage: parseInt(value.shareholding),
      emailId: value.email,
      contactNo: value.contactNo,
      addressLineOne: value.addressLine1,
      addressLineTwo: value.addressLine2,
      pinCode: value.pinNo,
      state: value.state,
      district: value.district,
      subDistrict: value.subDist,
      postOffice: value.postOffice
    };

    if (editPromoters.openEdit) {
      const res = await fetch(
        getURL(
          `buyer-seller/${editBuyerSellerData.entityDetails.id}/promoter-details/${editPromoters.data.id}`
        ),
        {
          method: 'PUT',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.PROMOTER_UPDATED });
        editPromter(false, {});
        getBuyerSellerPromoters(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    } else {
      const res = await fetch(
        getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}/promoter-details`),
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      );

      if (res && res.status === 200) {
        notifications.success({ message: message.PROMOTER_CREATED });
        getBuyerSellerPromoters(editBuyerSellerData.entityDetails.id, modalParams);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
        return false;
      }
    }
    return true;
  };

  const includeActiveTabs = tabName => setActiveTabs(tabs => [...tabs, tabName]);

  return (
    <div>
      {!selfOnBoard && (
        <div className={tw`flex items-center gap-4 content-divider`}>
          <Button
            type='default'
            onClick={() => {
              setBuyerSellerAdd(false);
              editBuyerSeller(false, {});
              editAddress(false, {});
              editBank(false, {});
              editPromter(false, {});
            }}
          >
            <MdOutlineArrowBack size='20px' />
          </Button>
          <Text className={tw`text-lg`}>{isUpdate && allDetailsFilled ? 'Update' : 'Add'} Buyer Seller</Text>
        </div>
      )}
      <div className='card-container'>
        <Tabs
          type='line'
          activeKey={currentTab}
          onChange={async key => {
            setCurrentTab(key);
            mutateClearModalParams();
          }}
        >
          <TabPane tab='Entity' key='1' disabled={!activeTabs.includes('1') && !allDetailsFilled}>
            {currentTab === '1' && (
              <BuyerSellerEntityForms
                consent={consent}
                setConsent={setConsent}
                onSubmit={entityOnSubmit}
                onSubmitLoader={loading}
                defaultValue={entity || editBuyerSellerData}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                isUpdate={isUpdate}
                state={state}
                dispatch={dispatch}
                setActiveTabs={includeActiveTabs}
              />
            )}
          </TabPane>
          <TabPane tab='Address' key='2' disabled={!activeTabs.includes('2') && !allDetailsFilled}>
            {currentTab === '2' && (
              <BuyerSellerAddressForm
                onSubmit={addressOnSubmit}
                onSubmitLoader={loading}
                tableData={addressData}
                loading={addressLoading}
                editAddress={editaddress}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                pinCodeData={pinCodeData}
                pinCodeDataloading={pinCodeDataloading}
                editBuyerSellerData={editBuyerSellerData}
                params={modalParams}
                state={state}
                dispatch={dispatch}
                setActiveTabs={includeActiveTabs}
                isAllDetailsPending={!allDetailsFilled}
              />
            )}
          </TabPane>
          <TabPane tab='Banks' key='3' disabled={!activeTabs.includes('3') && !allDetailsFilled}>
            {currentTab === '3' && (
              <BuyerSellerBankForm
                consent={consent}
                setConsent={setConsent}
                onSubmit={bankOnSubmit}
                onSubmitLoader={loading}
                tableData={banksData}
                loading={bankLoading}
                editBanks={editBanks}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                ifscBanksData={ifscBanksData}
                ifscDataloading={ifscDataloading}
                editBuyerSellerData={editBuyerSellerData}
                params={modalParams}
                state={state}
                dispatch={dispatch}
                setActiveTabs={includeActiveTabs}
                isAllDetailsPending={!allDetailsFilled}
              />
            )}
          </TabPane>
          <TabPane
            tab='Promoters'
            key='4'
            disabled={!activeTabs.includes('4') && !allDetailsFilled}
          >
            {currentTab === '4' && (
              <BuyerSellerPromotersForm
                consent={consent}
                setConsent={setConsent}
                onSubmit={promoterOnSubmit}
                onSubmitLoader={loading}
                tableData={promotersData}
                loading={promoterLoading}
                editPromoters={editPromoters}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                pinCodeData={pinCodeData}
                pinCodeDataloading={pinCodeDataloading}
                editBuyerSellerData={editBuyerSellerData}
                params={modalParams}
                state={state}
                dispatch={dispatch}
                setActiveTabs={includeActiveTabs}
                isAllDetailsPending={!allDetailsFilled}
              />
            )}
          </TabPane>
          {approved && (
            <TabPane
              tab='Sanctioned Limit'
              key='5'
              disabled={!activeTabs.includes('5') && !allDetailsFilled}
            >
              {currentTab === '5' && (
                <BuyerSellerSanctioned
                  setCurrentTab={setCurrentTab}
                  editBuyerSellerData={editBuyerSellerData}
                  setActiveTabs={includeActiveTabs}
                  isAllDetailsPending={!allDetailsFilled}
                />
              )}
            </TabPane>
          )}
          <TabPane
            tab='Documents'
            key='6'
            disabled={!activeTabs.includes('6') && !allDetailsFilled}
          >
            {currentTab === '6' && (
              <BuyerSellerDocumentsForm
                loading={documentsLoading}
                tableData={documentsData}
                setBuyerSellerAdd={setBuyerSellerAdd}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                editBuyerSellerData={editBuyerSellerData}
                params={modalParams}
                setActiveTabs={includeActiveTabs}
                isAllDetailsPending={!allDetailsFilled}
              />
            )}
          </TabPane>
          <TabPane tab='Analysis' key='8' disabled={!activeTabs.includes('8') && !allDetailsFilled}>
            {currentTab === '8' && (
              <BuyerSellerAnalysis
                currentTab={currentTab}
                defaultValue={entity || editBuyerSellerData}
                banksData={banksData}
                loadingBanks={bankLoading}
                promotersData={promotersData}
                loadingPromoters={promoterLoading}
              />
            )}
          </TabPane>
          {localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION' &&
            editBuyerSellerData.approve && (
              <TabPane
                tab='Approve-Reject'
                key='7'
                disabled={!activeTabs.includes('7') && !allDetailsFilled}
              >
                {currentTab === '7' && (
                  <BuyerSellerApprove
                    setBuyerSellerAdd={setBuyerSellerAdd}
                    editBuyerSellerData={editBuyerSellerData}
                  />
                )}
              </TabPane>
            )}
        </Tabs>
      </div>
    </div>
  );
};

export default BuyerSellerAdd;
