export const ACTION_TYPES = {
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  EDIT: 'EDIT'
};

export const dropDownInitialState = {
  dropDownData: {},
  loading: false,
  error: false,
  edit: null
};

export const dropDownReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_START:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        dropDownData: action.payload
      };
    case ACTION_TYPES.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ACTION_TYPES.EDIT:
      return {
        ...state,
        edit: action.payload
      };
    default:
      return state;
  }
};

export const ENTITY_TYPES = ["Private Limited Company", "Public Company", "Sole Proprietorship", "One Person Company", "Partnership", "Limited Liability Partnership"]

export const BANK_ACC_TYPES = ["Current Account", "NRI", "Saving"]

export const BUSINESS_SECTOR = ["Manufacturing", "Industry", "Finance", "Production", "Agriculture", "Investment", "Food industry", "Economics", "Technology", "Trade", "Construction", "Health care", "Financial services", "Telecommunications", "Education", "Stock market", "Retail", "Real Estate", "Computers and information technology", "Mechanical Engineering", "Insurance", "Market research", "Logistics", "Consumer service"]

export const ADDRESS_TYPES = ["Home", "Work", "Office"]

export const FINANCIER_USER_TYPES = [
  {
    label: 'Maker',
    value: 'MAKER'
  },
  {
    label: 'Checker',
    value: 'CHECKER'
  },
  {
    label: 'Operation Manager',
    value: 'OPERATION_MANAGER'
  }
];

export const BUYER_SELLER_USER_TYPES = [
  {
    label: 'Maker',
    value: 'MAKER'
  },
  {
    label: 'Checker',
    value: 'CHECKER'
  }
];

export const PLATFORM_USER_TYPES = [
  {
    label: 'Admin',
    value: 'ADMIN'
  },
  {
    label: 'Operation Manager',
    value: 'OPERATION_MANAGER'
  }
];

export const KYC_API_PROVIDERS = [
  { label: 'Karza', value: 'KARZA' },
  { label: 'On-Grid', value: 'GRIDLINES' },
  { label: 'Score-Me', value: 'SCOREME' }
];

export const BANK_STATEMENT_API_PROVIDER = [
  { label: 'Finbox', value: 'FINBOX' },
  { label: 'Score-Me', value: 'SCOREME' }
];

export const ITR_REPORTS_API_PROVIDER = [
  { label: 'Karza', value: 'KARZA' },
  { label: 'Score-Me', value: 'SCOREME' }
];

export const PROMOTER_TYPES = {
  default: {
    nameOrentityName: {
      name: 'Name/Entity Name',
      placeholder: 'Name/Entity Name'
    },
    dobOrDateOfIncorporation: {
      name: 'DOB/ DO Incorporation',
      placeholder: 'DOB/ DO Incorporation'
    },
    adhaarOrRegistrationNumber: {
      name: 'Aadhaar/Registration Number',
      placeholder: 'Aadhaar/Registration Number'
    },
    dinCin: {
      name: 'CIN/DIN',
      placeholder: 'CIN/DIN'
    },
    gender: true
  },
  individual: {
    nameOrentityName: {
      name: 'Name',
      placeholder: 'Enter Name'
    },
    dobOrDateOfIncorporation: {
      name: 'Date of Birth',
      placeholder: 'Select Date'
    },
    adhaarOrRegistrationNumber: {
      name: 'Aadhaar Number',
      placeholder: 'Enter Aadhaar Number'
    },
    dinCin: {
      name: 'Director Identification Number',
      placeholder: 'Enter DIN'
    },
    gender: true
  },
  entity: {
    nameOrentityName: {
      name: 'Entity Name',
      placeholder: 'Enter Entity Name'
    },
    dobOrDateOfIncorporation: {
      name: 'Date of Incorporation',
      placeholder: 'Select Date'
    },
    adhaarOrRegistrationNumber: {
      name: 'Registration Number',
      placeholder: 'Enter Registration Number'
    },
    dinCin: {
      name: 'Corporate Identification  Number',
      placeholder: 'Enter CIN'
    },
    gender: false
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getIsdCode(mobile) {
  return mobile && mobile.length > 10 ? mobile.slice(0, mobile.length - 10) : '+91';
}

export function getMobileNo(mobile) {
  return mobile ? mobile.slice(mobile.length - 10) : '';
}

export function getCollectionAmount(data) {
  return parseFloat(
    (data.factoringAmount + parseFloat((data.buyerFees + data.buyerInterest).toFixed(2))).toFixed(2)
  );
}

export function getDisbursementAmount(data) {
  return parseFloat(
    (data.factoringAmount - parseFloat((data.sellerFees + data.sellerInterest).toFixed(2))).toFixed(
      2
    )
  );
}
