import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { tw } from 'twind';
import { getAllNotFactoredUnit } from '../../../services/factoringUnit';
import { notFactoredUnitColumns } from './notFactoredColumns';
import { useSelector } from 'react-redux';
import TableComponent from '../../antdComponent/Table';

const { Text } = Typography;

const NotFactoredUnit = () => {
  const {
    params,
    loadingNotFactoredUnit = false,
    notFactoredUnit = []
  } = useSelector(state => ({
    params: state.mutations.params,
    loadingNotFactoredUnit: state.factoringUnit.loadingNotFactoredUnit,
    notFactoredUnit: state.factoringUnit.notFactoredUnit
  }));

  useEffect(() => {
    getAllNotFactoredUnit(params);
  }, [params]);

  return (
    <>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Text className=' text-xl'>Not Factored Unit</Text>
      </div>
      <TableComponent
        columns={notFactoredUnitColumns}
        data={notFactoredUnit}
        loading={loadingNotFactoredUnit}
      />
    </>
  );
};

export default NotFactoredUnit;
