import React from 'react';
import useFetch from '../../../hooks/useFetch';
import TextInput from '../../antdComponent/Input';
import { useState } from 'react';
import { Form, Button, Input } from 'antd';
import { tw } from 'twind';
import { getAllBuyerSeller } from '../../../services/buyerSeller';
import { RULES } from '../../../utils/formValidations';
import { notifications } from '../../../utils/notifications';
import { getURL } from '../../../configs/apiURL';
import { message } from '../../../utils/messages';
import {
  editAddress,
  editBank,
  editBuyerSeller,
  editPromter
} from '../../../redux/buyerSeller/actions';
import { useSelector } from 'react-redux';

const { TextArea } = Input;

const BuyerSellerApprove = ({ setBuyerSellerAdd, editBuyerSellerData }) => {
  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  const [fetch] = useFetch();
  const [form] = Form.useForm();

  const onSubmit = async ({ approved }) => {
    try {
      const { remarks, sanctionedLimit } = await form.validateFields();
      const data = {
        entityDetails: {
          ...editBuyerSellerData.entityDetails,
          approved,
          remarks,
          sanctionedLimit
        },
        adminDetails: editBuyerSellerData.adminDetails
      };
      const res = await fetch(getURL(`buyer-seller/${editBuyerSellerData.entityDetails.id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        getAllBuyerSeller(`approved=0&${params ? params : ''}`);
        notifications.success({ message: message.BUYER_SELLER_APPROVED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
      setBuyerSellerAdd(false);
      editAddress(false, {});
      editBank(false, {});
      editPromter(false, {});
      editBuyerSeller(false, {});
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  return (
    <Form form={form} size='large' autoComplete='off'>
      <div className={tw`flex flex-col gap-1`}>
        <div className={tw`md:w-1/5 mt-3`}>
          <Form.Item name='sanctionedLimit' rules={RULES.number}>
            <TextInput rows={4} label='Sanctioned Limit*' placeholder='Enter Number' />
          </Form.Item>
        </div>
        <div style={{ flex: 1 }} className={tw`md:w-2/4`}>
          <Form.Item name='remarks' rules={RULES.inputRequired}>
            <TextArea rows={4} placeholder='Enter Remarks*' />
          </Form.Item>
        </div>
      </div>
      <div className={tw`flex gap-3 mt-1`}>
        <Button type='primary' size='middle' onClick={() => onSubmit({ approved: 1 })}>
          Approve
        </Button>
        <Button danger type='primary' size='middle' onClick={() => onSubmit({ approved: 0 })}>
          Reject
        </Button>
      </div>
    </Form>
  );
};

export default BuyerSellerApprove;
