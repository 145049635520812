import React from 'react';
import { Link } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Modal, Tabs, Space, Table, Typography } from 'antd';
import { tw } from 'twind';
import { GoLinkExternal } from 'react-icons/go';
import { FaRegAddressCard } from 'react-icons/fa';
import { getGstItrDetails } from '../../../services/buyerSeller';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const { Text } = Typography;

export const GstItrKycViewModal = ({ data }) => {
  const { profile, editBuyerSeller, gstItrDetails, loadingGstItrDetails } = useSelector(state => ({
    profile: state.user.profile,
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    gstItrDetails: state.buyerSeller.gstItrDetails,
    loadingGstItrDetails: state.buyerSeller.loadingGstItrDetails
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');

  const { gstItrApiProvider = '' } = editBuyerSeller.data.apiProviders || {};
  const entityCategory = localStorage.getItem('entityCategory');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      if (data.kycData) getGstItrDetails(gstItrApiProvider, data.gstinId);
    }
  }, [isModalVisible]);

  const GstItrExcelColumns = [
    {
      title: <div className={tw`text-center flex flex-col items-center`}>#</div>,
      key: 'ExcelReportNo',
      render: (_, record, index) => <Text>{index + 1}</Text>
    },
    {
      title: <Text>Download</Text>,
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        const entityId = ['BUYER', 'SELLER'].includes(entityCategory)
          ? profile.entity.id
          : editBuyerSeller.data.entityDetails.id;
        return (
          <div className={tw`flex justify-center`}>
            <Space>
              <Link
                target={'_blank'}
                to={{
                  pathname: `/buyer-seller/${entityId}/documents/${record.excelDownloadLink}/signed-url`,
                  search: '?action=DOWNLOAD&expiresIn=300'
                }}
              >
                <GoLinkExternal size={24} color='#228be6' />
              </Link>
            </Space>
          </div>
        );
      }
    }
  ];

  const GstItrPdfColumns = [
    {
      title: <div className={tw`text-center flex flex-col items-center`}>#</div>,
      key: 'PDFReportNo',
      render: (_, record, index) => <Text>{index + 1}</Text>
    },
    {
      title: <Text>Download</Text>,
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        const entityId = ['BUYER', 'SELLER'].includes(entityCategory)
          ? profile.entity.id
          : editBuyerSeller.data.entityDetails.id;
        return (
          <div className={tw`flex justify-center`}>
            <Space>
              <Link
                target={'_blank'}
                to={{
                  pathname: `/buyer-seller/${entityId}/documents/${record.pdfDownloadLink}/signed-url`,
                  search: '?action=DOWNLOAD&expiresIn=300'
                }}
              >
                <GoLinkExternal size={24} color='#228be6' />
              </Link>
            </Space>
          </div>
        );
      }
    }
  ];

  return (
    <Fragment>
      <Button
        style={{
          borderRadius: '8px',
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
        onClick={showModal}
      >
        <FaRegAddressCard size='20px' color='black' />
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        centered
        footer={
          <Button
            key='back'
            size='middle'
            onClick={handleCancel}
            type='primary'
            className={tw`ml-auto`}
          >
            Close
          </Button>
        }
      >
        <br />
        <Card
          className='gst-itr-card'
          title={loadingGstItrDetails ? 'Preparing GST-ITR Reports ...' : 'GST-ITR Reports'}
        >
          <Tabs
            type='line'
            size='middle'
            tabPosition={'left'}
            activeKey={currentTab}
            onChange={key => {
              setCurrentTab(key);
            }}
          >
            <TabPane tab='Excel Reports' key='1'>
              <Table
                columns={GstItrExcelColumns}
                dataSource={
                  gstItrDetails
                    ? [
                        {
                          id: gstItrDetails.gstin,
                          excelDownloadLink: gstItrDetails.excelDownloadLink
                        }
                      ]
                    : []
                }
                loading={loadingGstItrDetails}
                pagination={false}
              />
            </TabPane>
            <TabPane tab='PDF Reports' key='2'>
              <Table
                columns={GstItrPdfColumns}
                dataSource={
                  gstItrDetails
                    ? [
                        {
                          id: gstItrDetails.gstin,
                          pdfDownloadLink: gstItrDetails.pdfDownloadLink
                        }
                      ]
                    : []
                }
                loading={loadingGstItrDetails}
                pagination={false}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Modal>
    </Fragment>
  );
};
