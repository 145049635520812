import { store } from '../index';
import { buyerSellerTypes } from './types';

export const editBuyerSeller = (openEdit, data, type) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_BUYER_SELLER,
    payload: {
      data,
      openEdit
    }
  });
};

export const editInvoice = (openEdit, data) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_INVOICE,
    payload: { data, openEdit }
  });
};

export const editBank = (openEdit, data) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_BANK,
    payload: { data, openEdit }
  });
};

export const editAddress = (openEdit, data) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_ADDRESS,
    payload: { data, openEdit }
  });
};

export const editPromter = (openEdit, data) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_PROMOTER,
    payload: { data, openEdit }
  });
};

export const editBuyerSellerLink = (openEdit, data) => {
  store.dispatch({
    type: buyerSellerTypes.EDIT_BUYER_SELLER_LINK,
    payload: { data, openEdit }
  });
};
