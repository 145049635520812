import React, { useMemo } from 'react';
import useFetch from '../../../hooks/useFetch';
import { notifications } from '../../../utils/notifications';
import { getURL } from '../../../configs/apiURL';
import { Fragment } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FcCheckmark } from 'react-icons/fc';
import { Button, Descriptions, Typography, Space, Spin, Table } from 'antd';
import { tw } from 'twind';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { getAllLinkedGst } from '../../../services/buyerSeller';
import { useSelector } from 'react-redux';
import { GstItrKycViewModal } from './viewGstItr';
import { getMobileNo } from '../../../utils/helpers';

const { Text } = Typography;

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const EntityKycDetails = ({ data }) => {
  const {
    linkedGst = {},
    udyamKycDetails = {},
    editBuyerSeller = {},
    panKycDetails = {},
    creditReportDetails = {},
    loadingPanKycDetails = false,
    loadingLinkedGst = false,
    loadingUdyamKycDetails = false
  } = useSelector(state => ({
    linkedGst: state.buyerSeller.linkedGst,
    udyamKycDetails: state.buyerSeller.udyamKycDetails,
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    panKycDetails: state.buyerSeller.panKycDetails,
    creditReportDetails: state.buyerSeller.creditReportDetails,
    loadingPanKycDetails: state.buyerSeller.loadingPanKycDetails,
    loadingLinkedGst: state.buyerSeller.loadingLinkedGst,
    loadingUdyamKycDetails: state.buyerSeller.loadingUdyamKycDetails,
    loadingCreditReportDetails: state.buyerSeller.loadingCreditReportDetails
  }));

  const {
    panApiProvider = '',
    udyamRegNoApiProvider = '',
    gstApiProvider = '',
    gstItrApiProvider = ''
  } = editBuyerSeller.data.apiProviders || {};

  const loading = loadingPanKycDetails || loadingUdyamKycDetails;
  const [fetch, pending] = useFetch();

  console.log(creditReportDetails);

  const kycDetails = useMemo(() => {
    let details = {};

    switch (panApiProvider.toUpperCase()) {
      case 'KARZA':
        if (data.entityDetails.panVerified && Object.keys(panKycDetails).length) {
          details = {
            ...details,
            panNumber: panKycDetails.pan,
            entityName: panKycDetails.name,
            panDoi: panKycDetails.dob
          };
        }
        break;
      case 'GRIDLINES':
        if (data.entityDetails.panVerified && Object.keys(panKycDetails).length) {
          details = {
            ...details,
            panNumber: panKycDetails.document_id,
            entityName: panKycDetails.name,
            panDoi: panKycDetails.date_of_birth
          };
        }
        break;
      default:
        return {};
    }
    switch (udyamRegNoApiProvider.toUpperCase()) {
      case 'KARZA':
        if (data.entityDetails.udyamRegNoVerified && Object.keys(udyamKycDetails).length) {
          details = {
            ...details,
            udyamNumber: udyamKycDetails.udyamRegistrationNo,
            udyamName: udyamKycDetails.profile.name,
            enterpriseType: udyamKycDetails.profile.enterpriseType,
            majorActivity: udyamKycDetails.profile.majorActivity,
            organizationType: udyamKycDetails.profile.organizationType,
            udyamDoi: udyamKycDetails.profile.dateOfIncorporation,
            udyamDoc: udyamKycDetails.profile.dateOfCommencement,
            udyamAddress: udyamKycDetails.officialAddress
              ? Object.values(udyamKycDetails.officialAddress).join(' , ')
              : ''
          };
        }
        break;
      case 'GRIDLINES':
        if (data.entityDetails.udyamRegNoVerified && Object.keys(udyamKycDetails).length) {
          details = {
            ...details,
            udyamNumber: udyamKycDetails.enterprise_data.document_id,
            udyamName: udyamKycDetails.enterprise_data.name,
            enterpriseType: udyamKycDetails.enterprise_data.enterprise_type,
            majorActivity: udyamKycDetails.enterprise_data.major_activity,
            organizationType: udyamKycDetails.enterprise_data.organization_type,
            udyamDoi: udyamKycDetails.enterprise_data.date_of_udyam_registration,
            udyamDoc: udyamKycDetails.enterprise_data.date_of_commencement,
            udyamAddress: udyamKycDetails.enterprise_data.address
              ? Object.values(udyamKycDetails.enterprise_data.address).join(' , ')
              : ''
          };
        }
        break;
      default:
        return {};
    }
    return details;
  }, [panApiProvider, udyamRegNoApiProvider, panKycDetails, udyamKycDetails]);

  const handleGstKyc = async gstinId => {
    const res = await fetch(getURL(`${gstApiProvider}/kyc/gst`), {
      method: 'POST',
      body: JSON.stringify({
        consent: 'Y',
        pan: data.entityDetails.pan,
        name: data.entityDetails.entityName,
        gstin: gstinId,
        entityId: editBuyerSeller.data.entityDetails.id
      })
    });
    if (res && res.status === 200) {
      const itrRes = await fetch(getURL(`${gstItrApiProvider}/kyc/gst-itr`), {
        method: 'POST',
        body: JSON.stringify({
          consent: 'Y',
          gstin: gstinId,
          contactNo: getMobileNo(data.entityDetails.contactNo),
          emailId: data.entityDetails.emailId,
          userId: editBuyerSeller.data.adminDetails.id,
          entityId: editBuyerSeller.data.entityDetails.id
        })
      });
      if (itrRes && itrRes.status === 200) {
        getAllLinkedGst(panApiProvider, data.entityDetails.pan);
      }
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
  };

  const getValue = (key, from = 'kyc') => {
    if (from === 'kyc') {
      return kycDetails[key] ? kycDetails[key] : 'NA';
    } else if (from === 'entity') {
      return data.entityDetails[key] ? data.entityDetails[key] : null;
    }
    return null;
  };

  const Columns = [
    {
      title: <Text>Status</Text>,
      dataIndex: 'gstinNo',
      key: 'gstinNo',
      render: (_, record) => {
        const active =
          gstApiProvider === 'KARZA' ? record.authStatus === 'Active' : record.status === 'Active';
        return (
          <div className={tw`flex items-center justify-center gap-2`}>
            {active ? <FcCheckmark /> : <RxCross2 color='red' />} {active ? 'Active' : 'InActive'}{' '}
          </div>
        );
      }
    },
    {
      title: <Text>GST No.</Text>,
      dataIndex: 'gstinNo',
      key: 'gstinNo',
      render: (_, record) => <Text>{record.gstinId}</Text>
    },
    {
      title: <Text>Entity Name</Text>,
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Text>
          {gstApiProvider === 'KARZA'
            ? record?.kycData?.lgnm || 'NA'
            : record?.kycData?.legal_name || 'NA'}{' '}
        </Text>
      )
    },
    {
      title: <Text>Entity Address</Text>,
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => (
        <Text>
          {gstApiProvider === 'KARZA'
            ? record?.kycData?.pradr?.adr || 'NA'
            : record?.kycData?.principal_address?.address || 'NA'}{' '}
        </Text>
      )
    },
    {
      title: <Text>Action</Text>,
      key: 'action',
      render: (_, record) => {
        const inActive =
          gstApiProvider === 'KARZA' ? record.authStatus !== 'Active' : record.status !== 'Active';
        return (
          <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {record.kycData ? (
              <div className={tw`flex items-center gap-6`}>
                <MdCheckCircle size='20px' color='green' />
                <GstItrKycViewModal data={record} />
              </div>
            ) : (
              <Button
                onClick={() => handleGstKyc(record.gstinId)}
                loading={pending}
                disabled={inActive}
                size='middle'
                style={{
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Confirm
              </Button>
            )}
          </Space>
        );
      }
    }
  ];

  return (
    <Fragment>
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Spin spinning={loading} />
        </div>
      )}
      {!loading && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <h3 className={tw`text-lg font-medium flex items-center gap-4`}>
            {'PAN Number'}{' '}
            {getValue('panVerified', 'entity') ? (
              <MdCheckCircle color='green' />
            ) : (
              <MdCancel color='red' />
            )}
          </h3>
          <Descriptions layout='horizontal' bordered size='middle'>
            <Descriptions.Item label='PAN' span={3}>
              {getValue('panNumber')}
            </Descriptions.Item>
            <Descriptions.Item label='Name' span={3}>
              {getValue('entityName')}
            </Descriptions.Item>
            <Descriptions.Item label='DOI' span={3}>
              {getValue('panDoi')}
            </Descriptions.Item>
          </Descriptions>
          <h3 className={tw`text-lg font-medium flex items-center gap-4`}>
            {'UDYAM Number'}{' '}
            {getValue('udyamRegNoVerified', 'entity') ? (
              <MdCheckCircle color='green' />
            ) : (
              <MdCancel color='red' />
            )}
          </h3>
          <Descriptions layout='horizontal' bordered size='middle'>
            <Descriptions.Item label='UDYAM NO.' span={3}>
              {getValue('udyamNumber')}
            </Descriptions.Item>
            <Descriptions.Item label='Name' span={3}>
              {getValue('udyamName')}
            </Descriptions.Item>
            <Descriptions.Item label='Enterprise Type' span={3}>
              {getValue('enterpriseType')}
            </Descriptions.Item>
            <Descriptions.Item label='Activity' span={3}>
              {getValue('majorActivity')}
            </Descriptions.Item>
            <Descriptions.Item label='Organisation Type' span={3}>
              {getValue('organizationType')}
            </Descriptions.Item>
            <Descriptions.Item label='DOI' span={3}>
              {getValue('udyamDoi')}
            </Descriptions.Item>
            <Descriptions.Item label='DOC' span={3}>
              {getValue('udyamDoc')}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {getValue('udyamAddress')}
            </Descriptions.Item>
          </Descriptions>
          <h3 className={tw`text-lg font-medium flex items-center gap-4`}>
            {'PAN-Linked GST'}{' '}
            {getValue('panVerified', 'entity') ? (
              <MdCheckCircle color='green' />
            ) : (
              <MdCancel color='red' />
            )}
          </h3>
          <div className={tw`table-responsive`}>
            <Table
              columns={Columns}
              dataSource={linkedGst.data}
              loading={loadingLinkedGst}
              pagination={{
                pageSize: PAGE_LIMIT,
                total: linkedGst?.data?.length
              }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
