import React from 'react';
import dayjs from 'dayjs';
import { Divider, Space, Typography } from 'antd';
import { ModalDateFilter, ModalEntityFilter, ModalTextFilter } from '../../../../utils/formFilters';
import { TransactionViewModal } from './transactionModal';
import { MdOutlinePreview } from 'react-icons/md';

const { Text } = Typography;

export const transactionColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Collected From</Text>
        <Divider />
        <ModalEntityFilter type='collectFrom' etcType='transaction' />
      </div>
    ),
    key: 'collectFrom',
    render: (_, record) => {
      return <Text>{record.collectFrom}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Amount</Text>
        <Divider />
        <ModalTextFilter type='transactionAmount' etcType='transaction' />
      </div>
    ),
    dataIndex: 'transactionAmount',
    key: 'transactionAmount',
    render: (_, record) => {
      return <Text>{record.transactionAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Transaction Date</Text>
        <Divider />
        <ModalDateFilter type='dateOfTransaction' etcType='transaction' />
      </div>
    ),
    dataIndex: 'transactionDate',
    key: 'transactionDate',
    render: (_, record) => {
      const transactionDate = dayjs(record.dateOfTransaction).format('YYYY-MM-DD');
      return <Text>{transactionDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Remaining Amount</Text>
        <Divider />
        <ModalTextFilter type='remainingAmount' etcType='transaction' />
      </div>
    ),
    dataIndex: 'remainingAmount',
    key: 'remainingAmount',
    render: (_, record) => {
      return <Text>{record.remainingAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Narration</Text>
        <Divider />
        <ModalTextFilter type='narration' etcType='transaction' />
      </div>
    ),
    dataIndex: 'narration',
    key: 'narration',
    render: (_, record) => {
      return <Text>{record.narration}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      return (
        <Space size='small' style={{ display: 'flex', flexDirection: 'column' }}>
          <TransactionViewModal
            label={<MdOutlinePreview size='20px' color='black' />}
            data={record}
          />
        </Space>
      );
    }
  }
];
