import DeletePopup from '../deletePopUp';
import { Button, Divider, Space, Switch } from 'antd';
import { MdModeEditOutline, MdCreditScore } from 'react-icons/md';
import { Typography } from 'antd';
import { Permissions } from '../../configs/permissions';
import { editBuyerSeller } from '../../redux/buyerSeller/actions';
import { TextFilter, StatusFilter, EntityFilter } from '../../utils/formFilters';
import { updateBuyerSellerStatus } from '../../services/buyerSeller';

const { Text } = Typography;

export const Columns = [
  {
    title: (
      <div>
        <Text>Buyer/Seller</Text>
        <Divider />
        <EntityFilter type='entityCategory' />
      </div>
    ),
    key: 'buyer_seller',
    render: (_, record) => {
      return <Text>{record.entityDetails.entityCategory}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>PAN Number</Text>
        <Divider />
        <TextFilter type='pan' />
      </div>
    ),
    dataIndex: 'pan',
    key: 'pan',
    render: (_, record) => {
      return <Text>{record.entityDetails.pan}</Text>;
    }
  },

  {
    title: (
      <div>
        <Text>Entity Name</Text>
        <Divider />
        <TextFilter type='entityName' />
      </div>
    ),
    dataIndex: 'entityName',
    key: 'entityName',
    render: (_, record) => {
      return <Text>{record.entityDetails.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Status</Text>
        <Divider />
        <StatusFilter type='active' />
      </div>
    ),
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => {
      return (
        <Text>
          {(() => {
            if (
              (!record.entityDetails.active && !record.entityDetails.approved) ||
              (record.entityDetails.active && !record.entityDetails.approved)
            ) {
              return 'INPROGRESS';
            } else if (!record.entityDetails.active && record.entityDetails.approved) {
              return 'INACTIVE';
            } else {
              return 'ACTIVE';
            }
          })()}
        </Text>
      );
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => (
      <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {Permissions(
          localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION'
            ? 'approveBuyerSeller'
            : 'manageBuyerSeller',
          'editBuyerSeller'
        ) && (
          <Button
            name='update-entity'
            style={{
              borderRadius: '8px',
              padding: '4px'
            }}
            onClick={() => editBuyerSeller(true, { ...record, edit: true })}
          >
            <MdModeEditOutline size='20px' color='black' />
          </Button>
        )}
        {['BUYER_SELLER_OPERATION', 'COMPLETED'].includes(localStorage.getItem('currentTab')) ? (
          <>
            {Permissions(
              localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION'
                ? 'approveBuyerSeller'
                : 'manageBuyerSeller',
              'editBuyerSellerStatus'
            ) && (
              <Switch
                name='update-entity-status'
                checked={record.entityDetails.active === 1 ? true : false}
                onChange={value =>
                  updateBuyerSellerStatus(record.entityDetails.id, {
                    entityDetails: {
                      ...record.entityDetails,
                      active: value ? 1 : 0
                    },
                    adminDetails: record.adminDetails,
                    apiProviders: record.apiProviders
                  })
                }
              />
            )}
          </>
        ) : (
          <>
            {Permissions(
              localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION'
                ? 'approveBuyerSeller'
                : 'manageBuyerSeller',
              'deleteBuyerSeller'
            ) && (
              <DeletePopup
                type='entity'
                id={record.entityDetails.id}
                data={record.entityDetails.entityName}
              />
            )}
          </>
        )}
        {Permissions(
          localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION'
            ? 'approveBuyerSeller'
            : 'manageBuyerSeller',
          'approveBuyerSeller'
        ) && (
          <Button
            name='approve-entity'
            style={{
              borderRadius: '8px',
              padding: '4px'
            }}
            onClick={() => editBuyerSeller(true, { ...record, approve: true })}
          >
            <MdCreditScore size='20px' color='black' />
          </Button>
        )}
      </Space>
    )
  }
];
