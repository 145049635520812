import isdCodeList from '../../../configs/_isd.json';
import { useMemo } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const IsdCode = ({ isdCode, setIsdCode }) => {
  const data = useMemo(() => isdCodeList, []);
  return (
    <Select
      defaultValue={isdCode}
      style={{ width: 90, zIndex: 2 }}
      onChange={code => setIsdCode(code)}
    >
      {data.map((code, index) => {
        return (
          <Option key={index} value={code.dial_code}>
            {code.dial_code}
          </Option>
        );
      })}
    </Select>
  );
};

export default IsdCode;
