import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const ColorLogo = () => (
  <Title level={1} style={styles.title}>
    Invoices
  </Title>
);

export default ColorLogo;

const styles = {
  title: {
    fontFamily: 'Didot',
    color: '#0E353D',
    maxWidth: '150px',
    fontSize: '30px',
    marginBottom: '-7px'
  }
};

// ["Factoring Fin", "SCF" , "Minko"]
