import React from 'react';
import useFetch from '../../../hooks/useFetch';
import TextInput from '../../antdComponent/Input';
import { useEffect } from 'react';
import { Form, Button } from 'antd';
import { tw } from 'twind';
import { getAllBuyerSeller } from '../../../services/buyerSeller';
import { RULES } from '../../../utils/formValidations';
import { notifications } from '../../../utils/notifications';
import { getURL } from '../../../configs/apiURL';
import { message } from '../../../utils/messages';
import { useSelector } from 'react-redux';

const BuyerSellerSanctioned = ({ setCurrentTab, editBuyerSellerData }) => {
  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  const [form] = Form.useForm();
  const [updateBuyerSeller] = useFetch();

  const onSubmit = async value => {
    const data = {
      entityDetails: {
        ...editBuyerSellerData.entityDetails,
        sanctionedLimit: value.sanctionedLimit
      },
      adminDetails: editBuyerSellerData.adminDetails
    };
    const _id = editBuyerSellerData.entityDetails.id;
    const res = await updateBuyerSeller(getURL(`buyer-seller/${_id}`), {
      method: 'PUT',
      body: JSON.stringify(data)
    });
    if (res && res.status === 200) {
      getAllBuyerSeller(`approved=0&${params ? params : ''}`);
      notifications.success({
        message: message.SANCTIONED_LIMIT_UPDATED
      });
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      sanctionedLimit: editBuyerSellerData.entityDetails.sanctionedLimit
    });
  }, [form]);

  return (
    <>
      <Form
        form={form}
        size='large'
        defaultValue={{ isDefault: true }}
        onFinish={value => {
          onSubmit(value);
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <div className={tw`flex flex-row items-center gap-3`}>
          <div className={tw`md:w-1/5 mt-3`}>
            <Form.Item name='sanctionedLimit' rules={RULES.number}>
              <TextInput rows={4} label='Sanctioned Limit*' placeholder='Enter Number' />
            </Form.Item>
          </div>
        </div>
        <div className={tw`flex gap-2 mt-5`}>
          <Button type='primary' size='middle' htmlType='submit'>
            Update
          </Button>
          <Button
            type='primary'
            size='middle'
            onClick={() => {
              setCurrentTab('6');
            }}
          >
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BuyerSellerSanctioned;
