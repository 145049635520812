import useFetch from '../../../hooks/useFetch';
import TextInput from '../../antdComponent/Input';
import './styles.css';
import { Button, Card, Modal, Typography, Form, Table, Input } from 'antd';
import { Permissions } from '../../../configs/permissions';
import { getAllOpenForFinance } from '../../../services/factoringUnit';
import { getURL } from '../../../configs/apiURL';
import { tw } from 'twind';
import { useState } from 'react';
import { notifications } from '../../../utils/notifications';
import { RULES } from '../../../utils/formValidations';
import { message } from '../../../utils/messages';
import { factoringUnitViewColumns } from '../factoringUnit/viewFactoringUnit/viewFactoringUnitColumns';
import { invoiceViewColumns } from '../factoringUnit/viewFactoringUnit/viewInvoiceColumns';
import { FUDetails, FUBreakupData } from '../fuDetails';

const { Text } = Typography;
const { TextArea } = Input;

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const ApproveOpenForFinance = ({ label, data }) => {
  const [update] = useFetch();
  const [roiForm] = Form.useForm();
  const [approveForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onROISubmit = async value => {
    const res = await update(getURL(`factoring-units/${data.factoringUnitNo}`), {
      method: 'PUT',
      body: JSON.stringify({
        buyerFees: parseFloat(value.buyerFees),
        sellerFees: parseFloat(value.sellerFees),
        rateOfInterest: parseFloat(value.rateOfInterest)
      })
    });
    if (res && res.status === 200) {
      notifications.success({
        message: message.FEE_AND_ROI_ADDED
      });
      roiForm.resetFields();
      setIsModalVisible(false);
      getAllOpenForFinance();
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
  };

  const onApproveSubmit = async ({ approved }) => {
    try {
      const { remarks } = await approveForm.validateFields();
      const res = await update(getURL(`factoring-units/${data.factoringUnitNo}/validate-roi`), {
        method: 'PUT',
        body: JSON.stringify({
          status: 'OPEN_FOR_FINANCE',
          approved,
          remarks
        })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: message.OPEN_FOR_FINANCE_APPROVED
        });
        approveForm.resetFields();
        setIsModalVisible(false);
        getAllOpenForFinance();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  return (
    <>
      <Button
        name='approve-btn'
        style={{ borderRadius: '8px', padding: '4px' }}
        onClick={showModal}
      >
        {label}
      </Button>
      <Modal
        title={
          data.buyerFees === null && data.sellerFees === null && data.rateOfInterest === null
            ? 'Add Fees & ROI'
            : 'Approve Factoring Unit'
        }
        open={isModalVisible}
        footer={
          <>
            {Permissions('openForFinance', 'approveOpenForFinance') &&
              data.buyerFees !== null &&
              data.sellerFees !== null &&
              data.rateOfInterest !== null && (
                <Form
                  form={approveForm}
                  size='large'
                  className={tw`flex flex-col md:flex-row justify-center items-start md:items-center gap-3`}
                  onFinishFailed={errorInfo => {
                    console.log(errorInfo);
                  }}
                  autoComplete='off'
                >
                  <div style={{ flex: 1 }} className={tw`w-full`}>
                    <Form.Item name='remarks' rules={RULES.inputRequired}>
                      <TextArea rows={4} label='Remarks' placeholder='Enter Remarks' />
                    </Form.Item>
                  </div>
                  <div className={tw`flex md:pt-14 gap-1`}>
                    <Button
                      type='primary'
                      size='middle'
                      htmlType='submit'
                      onClick={() => onApproveSubmit({ approved: 1 })}
                    >
                      Approve
                    </Button>
                    <Button
                      danger
                      type='primary'
                      size='middle'
                      onClick={() => onApproveSubmit({ approved: 0 })}
                    >
                      Reject
                    </Button>
                  </div>
                </Form>
              )}
          </>
        }
        onCancel={handleCancel}
        centered
      >
        <div className={tw`table-responsive`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUDetails(data)}
            rowKey='key'
            loading={false}
            bordered
            showHeader={false}
            pagination={false}
          />
        </div>
        <div className={tw`table-responsive my-6`}>
          <Table
            columns={invoiceViewColumns}
            dataSource={data.invoices}
            rowKey='key'
            loading={false}
            bordered
            pagination={{
              pageSize: PAGE_LIMIT,
              total: data.invoices?.length
            }}
          />
        </div>
        <div className={tw`table-responsive mb-6`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUBreakupData(data)}
            rowKey='key'
            loading={false}
            bordered
            pagination={false}
          />
        </div>
        <Form
          form={roiForm}
          onFinish={onROISubmit}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          autoComplete='off'
        >
          {Permissions('openForFinance', 'addOpenForFinanceFeesAndROI') &&
            data.buyerFees === null &&
            data.sellerFees === null &&
            data.rateOfInterest === null && (
              <Card>
                <div className={tw`flex flex-col md:flex-row justify-between`}>
                  <div className={tw`flex flex-col xl:flex-row gap-1`}>
                    <Text className={tw`font-semibold mt-3`}>Buyer Fees* : </Text>
                    <Form.Item name='buyerFees' rules={RULES.amount}>
                      <TextInput type='text' placeholder='Fee Amount' />
                    </Form.Item>
                  </div>
                  <div className={tw`flex flex-col xl:flex-row gap-1`}>
                    <Text className={tw`font-semibold mt-3`}>Seller Fees* : </Text>
                    <Form.Item name='sellerFees' rules={RULES.amount}>
                      <TextInput type='text' placeholder='Fee Amount' />
                    </Form.Item>
                  </div>
                  <div className={tw`flex flex-col xl:flex-row gap-1`}>
                    <Text className={tw`font-semibold mt-3`}>Rate of Interest* : </Text>
                    <Form.Item name='rateOfInterest' rules={RULES.rateOfInterest}>
                      <TextInput placeholder='Percentage' />
                    </Form.Item>
                  </div>
                </div>
                <div className={tw`flex justify-between`}>
                  <Button type='primary' name='process' htmlType='submit'>
                    Process
                  </Button>
                  <Button key='back' onClick={handleCancel} name='close' type='primary'>
                    Close
                  </Button>
                </div>
              </Card>
            )}
        </Form>
      </Modal>
    </>
  );
};
