import DeletePopup from '../../deletePopUp';
import { Button, Space, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { Typography } from 'antd';
import { tw } from 'twind';
import { BankKycViewModal } from '../modals/viewBank';
import { ModalTextFilter } from '../../../utils/formFilters';
import { editBank } from '../../../redux/buyerSeller/actions';

const { Text } = Typography;

export const bankColumns = [
  {
    title: <Text>Default Account</Text>,
    key: 'defaultAccount',
    render: (_, record) => {
      return <Text>{record.isDefault === 1 ? 'Yes' : 'No'}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Type</Text>
        <Divider />
        <ModalTextFilter type='bankAccountType' />
      </div>
    ),
    dataIndex: 'accountType',
    key: 'accountType',
    render: (_, record) => {
      return <Text>{record.bankAccountType.name}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Tittle</Text>
        <Divider />
        <ModalTextFilter type='accountTitle' />
      </div>
    ),
    dataIndex: 'accountTitle',
    key: 'accountTitle',
    render: (_, record) => {
      return <Text>{record.accountTitle}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Number</Text>
        <Divider />
        <ModalTextFilter type='accountNo' />
      </div>
    ),
    dataIndex: 'accountNo',
    key: 'accountNo',
    render: (_, record) => {
      return <Text>{record.accountNo}</Text>;
    }
  },

  {
    title: (
      <div>
        <Text>IFSC</Text>
        <Divider />
        <ModalTextFilter type='ifsc' />
      </div>
    ),
    dataIndex: 'ifsc',
    key: 'ifsc',
    render: (_, record) => {
      return <Text>{record.ifsc}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const bank = `${record.bankName} - ${record.accountNo}`;
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            <BankKycViewModal data={record} />
            <Button
              name='update-bank'
              style={{ borderRadius: '8px', padding: '0px 5px 0px 5px' }}
              onClick={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                editBank(true, record);
              }}
            >
              <MdModeEditOutline size='20px' color='black' />
            </Button>
            <DeletePopup type='bank' id={record.id} entityId={record.entity.id} data={bank} />
          </Space>
        </div>
      );
    }
  }
];
