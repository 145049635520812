import { Button, Space, Switch, Typography, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { UserTypeFilter, TextFilter } from '../../utils/formFilters';
import { editUserStatus } from '../../services/user';
import { editUser } from '../../redux/user/actions';
import { Permissions } from '../../configs/permissions';
const { Text } = Typography;

export const userSettingsColumn = [
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>User Type</Text>
        <Divider />
        <UserTypeFilter type='userType' />
      </div>
    ),
    key: 'userType',
    render: (_, record) => {
      return <Text>{record.userType}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Name </Text>
        <Divider />
        <TextFilter type='nameOfUser' />
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      return <Text>{`${record.firstName} ${record.lastName}`}</Text>;
    }
  },

  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Email </Text>
        <Divider />
        <TextFilter type='emailId' />
      </div>
    ),
    dataIndex: 'emailId',
    key: 'emailId',
    render: (_, record) => {
      return <Text>{record.emailId}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Mobile No </Text>
        <Divider />
        <TextFilter type='mobileNo' />
      </div>
    ),
    dataIndex: 'mobileNo',
    key: 'mobileNo',
    render: (_, record) => {
      return <Text>{record.mobileNo}</Text>;
    }
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {Permissions('users', 'editUsers') && (
          <Button
            name='update-user'
            style={{ borderRadius: '8px', padding: '4px' }}
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
              editUser(true, record);
            }}
          >
            <MdModeEditOutline size='20px' color='black' />
          </Button>
        )}
        {Permissions('users', 'editUsersStatus') && (
          <Switch
            name='update-status'
            checked={record.active ? true : false}
            onChange={value => {
              let data = record;
              delete data.entity;
              delete data.key;
              editUserStatus(record.id, { ...data, active: value ? 1 : 0 });
            }}
          />
        )}
      </Space>
    )
  }
];
