import dayjs from 'dayjs';
import { useState } from 'react';
import { tw } from 'twind';
import { Typography, Button, Modal, Table } from 'antd';
import { useSelector } from 'react-redux';

const { Text } = Typography;

export const PaymentViewModal = ({ label, data }) => {
  const { banksData = {} } = useSelector(state => ({
    banksData: state.buyerSeller.bank
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const fUPaymentData = [
    {
      key1: 'Released To',
      value1: data.releaseTo,
      key2: 'Bank Account',
      value2: banksData?.data?.find(bank => bank.id === data.bankDetailsId).bankName
    },
    {
      key1: 'Account Holder Name',
      value1: data.accountHolderName,
      key2: 'Account No.',
      value2: data.accountNo
    },
    {
      key1: 'IFSC',
      value1: data.ifsc,
      key2: 'Collected Amount',
      value2: data.collectedAmount
    },
    {
      key1: 'Payment Amount',
      value1: data.paymentAmount,
      key2: 'Remaining Amount',
      value2: data.remainingAmount
    },
    {
      key1: 'Date',
      value1: data.dateOfInitiate && dayjs(data.dateOfInitiate).format('DD-MM-YYYY'),
      key2: 'Narration',
      value2: data.narration
    },
    {
      key1: 'Status',
      value1: data.status,
      key2: 'Reference No.',
      value2: data.referenceNo
    },
    {
      key1: 'Transaction Date',
      value1: data.dateOfTransaction && dayjs(data.dateOfTransaction).format('DD-MM-YYYY')
    }
  ];

  const Columns = [
    {
      key: 'key1',
      dataIndex: 'key1',
      render: (_, record) => <Text style={{ fontWeight: 600 }}>{record.key1}</Text>
    },
    {
      dataIndex: 'value1',
      key: 'value1',
      render: (_, record) => <Text>{record.value1}</Text>
    },
    {
      dataIndex: 'key2',
      key: 'key2',
      render: (_, record) => <Text style={{ fontWeight: 600 }}>{record.key2}</Text>
    },
    {
      dataIndex: 'value2',
      key: 'value2',
      render: (_, record) => <Text>{record.value2}</Text>
    }
  ];

  return (
    <div className={tw`flex flex-col`}>
      <Button
        style={{ borderRadius: '8px', padding: '4px' }}
        onClick={() => setIsModalVisible(true)}
      >
        {label}
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={null}
      >
        <h3 className={tw`text-lg font-medium`}>Payment Details</h3>
        <div className={tw`table-responsive mt-4`}>
          <Table
            columns={Columns}
            dataSource={fUPaymentData}
            rowKey='key'
            bordered
            showHeader={false}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};
