import React from 'react';
import Input from 'antd/es/input';
import './style.css';

const TextInput = ({ label, required, uppercase, ...rest }) => {
  return (
    <div>
      <Input
        {...rest}
        style={{
          padding: '0.5rem',
          borderRadius: '8px',
          textTransform: uppercase ? 'uppercase' : 'none'
        }}
        spellCheck={false}
      />
      <span className='floatinglabel'>
        {label ? label : null}
        {required ? <span>*</span> : null}
      </span>
    </div>
  );
};

export default TextInput;
