import React, { useEffect, useMemo, useState } from 'react';
import TextInput from '../../../antdComponent/Input';
import Cookies from 'js-cookie';
import useFetch from '../../../../hooks/useFetch';
import SelectComponent from '../../../antdComponent/Select';
import DateComponent from '../../../antdComponent/Date';
import dayjs from 'dayjs';
import { Button, Card, Form, Typography, Row, Col, Input } from 'antd';
import { MdOutlineArrowBack } from 'react-icons/md';
import { tw } from 'twind';
import { getURL } from '../../../../configs/apiURL';
import { RULES } from '../../../../utils/formValidations';
import { editFactoringUnit } from '../../../../redux/factoringUnit/actions';

const { Text } = Typography;

const FactoringUnitAdd = ({
  setShowFactoringUnitAdd,
  onSubmit,
  defaultValue,
  isUpdate,
  buyerOnChange,
  setDisableDropdown,
  disableDropdown,
  buyerDropdown,
  sellerDropdown
}) => {
  const [fetch] = useFetch();
  const [form] = Form.useForm();
  const [buyerId, setBuyerId] = useState();
  const [sellerId, setSellerId] = useState();
  const [maxCreditPeriod, setMaxCreditPeriod] = useState();

  const invoiceAmount = Form.useWatch('invoiceAmount', form);
  const discountAmount = Form.useWatch('discountAmount', form);
  const taxAmount = Form.useWatch('taxAmount', form);

  const entityId = Cookies.get('entityId');
  const entityCategory = localStorage.getItem('entityCategory');

  const initialValue = useMemo(
    () =>
      Object.keys(defaultValue).length !== 0
        ? {
            sellerName: defaultValue.buyerSellerLink.seller.id,
            buyerName: defaultValue.buyerSellerLink.buyer.id,
            discountAmount: defaultValue.invoices.reduce(
              (prev, curr) => prev + curr.discountAmount,
              0
            ),
            dueDate: dayjs(defaultValue.invoices[0].invoiceDueDate),
            invoiceAmount: defaultValue.invoices.reduce(
              (prev, curr) => prev + curr.invoiceAmount,
              0
            ),
            invoiceDate: dayjs(defaultValue.invoices[0].invoiceDate),
            invoiceNumber: defaultValue.invoices[0].invoiceNo,
            taxAmount: defaultValue.invoices.reduce((prev, curr) => prev + curr.taxAmount, 0),
            totalAmount: defaultValue.invoices.reduce((prev, curr) => prev + curr.totalAmount, 0),
            creditPeriod: defaultValue.invoices[0].creditPeriod
          }
        : {},
    [defaultValue]
  );

  const fetchCreditPeriod = async (buyerId, sellerId) => {
    const res = await fetch(
      getURL(`buyer-seller/links?approved=1&buyerId=${buyerId}&sellerId=${sellerId}`)
    );
    if (res && res.status === 200) {
      const dueDate = form.getFieldValue('invoiceDate');
      const creditPeriod = res.data.data[0].creditPeriod;

      setMaxCreditPeriod(creditPeriod);

      const initialValue = {
        creditPeriod: defaultValue?.invoices?.[0]?.creditPeriod || creditPeriod,
        dueDate:
          dueDate && creditPeriod
            ? form.getFieldValue('invoiceDate').add(res.data.data[0].creditPeriod, 'days')
            : null
      };

      form.setFieldsValue(initialValue);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  useEffect(() => {
    switch (entityCategory) {
      case 'SELLER': {
        setSellerId(entityId);
        form.setFieldsValue({
          sellerName: entityId
        });
        break;
      }
      case 'BUYER': {
        setBuyerId(entityId);
        form.setFieldsValue({
          buyerName: entityId
        });
        break;
      }
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (invoiceAmount && discountAmount && taxAmount) {
      const totalAmount =
        parseInt(form.getFieldValue(['invoiceAmount'])) +
        parseInt(form.getFieldValue(['taxAmount'])) -
        parseInt(form.getFieldValue(['discountAmount']));
      form.setFieldsValue({
        totalAmount: totalAmount
      });
    }
  }, [invoiceAmount, discountAmount, taxAmount]);

  useEffect(() => {
    if (buyerId && sellerId) {
      fetchCreditPeriod(buyerId, sellerId);
    }
  }, [buyerId, sellerId]);

  return (
    <div>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Button
          type='default'
          onClick={() => {
            setDisableDropdown(true);
            setShowFactoringUnitAdd(false);
            editFactoringUnit(false, {});
          }}
        >
          <MdOutlineArrowBack size='20px' />
        </Button>
        <Text className={tw`text-lg`}>
          {isUpdate ? 'Update Factoring Unit' : ' Add Factoring Unit'}
        </Text>
      </div>
      <Form
        form={form}
        size='large'
        onFinish={onSubmit}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title={isUpdate ? 'Update Factoring Unit' : ' Add Factoring Unit'} className='mb-4'>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col span={24}>
              <Row
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 12, sm: 16, md: 24 }
                ]}
                className={tw`mb-4 md:mb-0`}
              >
                <Col xs={24} sm={12} md={6}>
                  <Form.Item name='buyerName' rules={RULES.selectRequired}>
                    <SelectComponent
                      label='Buyer Name'
                      placeholder='Select'
                      required
                      onChange={buyerId => {
                        buyerOnChange(buyerId);
                        setBuyerId(buyerId);
                        if (entityCategory === 'FINANCIER') {
                          form.setFieldsValue({
                            sellerName: undefined
                          });
                        }
                      }}
                      allowClear={true}
                      options={buyerDropdown}
                      disabled={entityCategory === 'BUYER'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item name='sellerName' rules={RULES.selectRequired}>
                    <SelectComponent
                      label='Seller Name'
                      placeholder='Select'
                      required
                      onChange={sellerId => setSellerId(sellerId)}
                      allowClear={true}
                      options={sellerDropdown}
                      disabled={
                        entityCategory === 'SELLER' ||
                        (disableDropdown && entityCategory === 'FINANCIER' && !isUpdate)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 12, sm: 16, md: 24 }
                ]}
                className={tw`mb-4 md:mb-0`}
              >
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='invoiceNumber' rules={RULES.invoiceNo}>
                    <TextInput
                      required
                      label='Invoice Number'
                      placeholder='Invoice Number'
                      uppercase
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='invoiceDate' rules={RULES.date}>
                    <DateComponent
                      required
                      onChange={date => {
                        const invoiceDate = date;
                        const creditPeriod = form.getFieldValue('creditPeriod');

                        const initialValue = {
                          invoiceDate: invoiceDate,
                          dueDate:
                            invoiceDate && creditPeriod
                              ? form.getFieldValue('invoiceDate').add(creditPeriod, 'days')
                              : null
                        };

                        form.setFieldsValue(initialValue);
                      }}
                      label='Invoice Date'
                      placeholder='Select Date'
                      className='w-full'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={2}>
                  <Form.Item
                    name='creditPeriod'
                    rules={[
                      ...RULES.inputRequired,
                      () => ({
                        validator(_, value) {
                          if (!value || (Number(value) >= 1 && value <= maxCreditPeriod)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            `Credit-period must be between ${1} and ${maxCreditPeriod}`
                          );
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder='Days'
                      type='number'
                      onChange={e => {
                        const invoiceDate = form.getFieldValue('invoiceDate');
                        const creditPeriod = e.target.value;

                        const initialValue = {
                          creditPeriod: creditPeriod,
                          dueDate:
                            invoiceDate && creditPeriod
                              ? form.getFieldValue('invoiceDate').add(creditPeriod, 'days')
                              : null
                        };

                        form.setFieldsValue(initialValue);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={4}>
                  <Form.Item name='dueDate' rules={RULES.date}>
                    <DateComponent
                      disabled
                      label='Due Date'
                      placeholder='Select Date'
                      className='w-full'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                gutter={[
                  { xs: 8, sm: 16, md: 24 },
                  { xs: 12, sm: 16, md: 24 }
                ]}
                className={tw`mb-4 md:mb-0`}
              >
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='invoiceAmount' rules={RULES.amount}>
                    <TextInput
                      required
                      label='Invoice Amount'
                      placeholder=' Invoice Amount'
                      onChange={e =>
                        !e.target.value ? form.setFieldValue('totalAmount', null) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='discountAmount' rules={RULES.amount}>
                    <TextInput
                      required
                      label='Discount Amount'
                      placeholder='Discount Amount'
                      onChange={e =>
                        !e.target.value ? form.setFieldValue('totalAmount', null) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='taxAmount' rules={RULES.amount}>
                    <TextInput
                      required
                      label='Tax Amount'
                      placeholder='Tax Amount'
                      onChange={e =>
                        !e.target.value ? form.setFieldValue('totalAmount', null) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6}>
                  <Form.Item name='totalAmount' rules={RULES.amount}>
                    <TextInput required label='Total Amount' placeholder=' Total Amount' disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button className={tw`mt-2`} size='middle' type='primary' htmlType='submit'>
            {isUpdate ? 'Update' : 'Add'}
          </Button>
        </Card>
      </Form>
    </div>
  );
};

export default FactoringUnitAdd;
