import { Button, Divider, Space } from 'antd';
import { MdModeEditOutline, MdCreditScore } from 'react-icons/md';
import { Typography } from 'antd';
import { Permissions } from '../../../../configs/permissions';
import { ApproveFactoringUnit } from '../approveFactoringUnit/approveFactoringUnit';
import { ViewModal } from '../viewFactoringUnit/viewFactoringUnit';
import { editFactoringUnit } from '../../../../redux/factoringUnit/actions';
import { tw } from 'twind';
import { DateFilter, TextFilter } from '../../../../utils/formFilters';
import DeletePopup from '../../../deletePopUp';
import dayjs from 'dayjs';

const { Text } = Typography;

export const factoringUnitColumns = [
  {
    title: (
      <div>
        <Text>Factoring Unit No</Text>
        <Divider />
        <TextFilter type='factoringUnitNo' />
      </div>
    ),
    key: 'factoringUnitNo',
    render: (_, record) => {
      return <ViewModal label={record.factoringUnitNo} data={record} showActionHistory={false} />;
    }
  },
  {
    title: (
      <div>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Seller Name</Text>
        <Divider />
        <TextFilter type='sellerName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.seller.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Factoring Amount</Text>
        <Divider />
        <TextFilter type='factoringAmount' />
      </div>
    ),
    dataIndex: 'factoringAmount',
    key: 'factoringAmount',
    render: (_, record) => {
      return <Text>{record.factoringAmount}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Invoice Date</Text>
        <Divider />
        <DateFilter type='invoiceDate' />
      </div>
    ),
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (_, record) => {
      const invoiceDate = dayjs(record.invoices[0].invoiceDate).format('DD-MM-YYYY');
      return <Text>{invoiceDate}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Due Date</Text>
        <Divider />
        <DateFilter type='invoiceDueDate' />
      </div>
    ),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_, record) => {
      const dueDate = dayjs(record.invoices[0].invoiceDueDate).format('DD-MM-YYYY');
      return <Text>{dueDate}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const factoringUnit = `${record.factoringUnitNo} - ${record.buyerSellerLink.buyer.entityName} - ${record.buyerSellerLink.seller.entityName} `;
      const nextCheckerUser = localStorage.getItem('userId');
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            {Permissions('factoringUnit', 'editFactoringUnit') &&
              (record.nextCheckerUser === null || record.nextCheckerUser.id === nextCheckerUser
                ? true
                : false) && (
                <Button
                  name='update-factoring'
                  style={{ borderRadius: '8px', padding: '4px' }}
                  onClick={() => {
                    editFactoringUnit(true, record);
                  }}
                >
                  <MdModeEditOutline size='20px' color='black' />
                </Button>
              )}
            {Permissions('factoringUnit', 'approveFactoringUnit') && (
              <ApproveFactoringUnit
                label={<MdCreditScore size='20px' color='black' />}
                data={record}
              />
            )}
            {Permissions('factoringUnit', 'deleteFactoringUnit') && (
              <DeletePopup
                type='factoringUnit'
                id={record.factoringUnitNo}
                data={factoringUnit}
                entityId={record.createdByEntityId}
              />
            )}
          </Space>
        </div>
      );
    }
  }
];
