import { store } from '../index';
import { mutateTypes } from './types';

export const mutateParams = params => {
  store.dispatch({
    type: mutateTypes.MUTATE_PARAMS,
    payload: params
  });
};

export const mutateModalParams = params => {
  store.dispatch({
    type: mutateTypes.MUTATE_MODAL_PARAMS,
    payload: params
  });
};

export const mutateClearParams = () => {
  localStorage.removeItem('params');
  store.dispatch({
    type: mutateTypes.MUTATE_CLEAR_PARAMS,
    payload: undefined
  });
};

export const mutateClearModalParams = () => {
  localStorage.removeItem('modalParams');
  store.dispatch({
    type: mutateTypes.MUTATE_CLEAR_MODAL_PARAMS,
    payload: undefined
  });
};
