import TableComponent from '../antdComponent/Table';
import useFetch from '../../hooks/useFetch';
import { ENTITY_TYPES, BANK_ACC_TYPES, ADDRESS_TYPES, BUSINESS_SECTOR } from '../../utils/helpers';
import { Button, Form, Card, Row, Col, AutoComplete } from 'antd';
import { RULES } from '../../utils/formValidations';
import { getURL } from '../../configs/apiURL';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { Fragment, useEffect } from 'react';
import { tw } from 'twind';
import { notifications } from '../../utils/notifications';
import { getFinancierConfigurationType } from '../../services/financier';
import { configurationTypeColumns } from './configurationTypeColumns';
import { editFinancierConfigurationType } from '../../redux/financier/actions';

const ConfigurationType = ({
  type,
  title,
  params,
  configurationType,
  loadingconfigurationType,
  editConfigurationType
}) => {
  const [fetch] = useFetch();
  const [form] = Form.useForm();

  const onSubmit = async value => {
    if (editConfigurationType?.openEdit) {
      const res = await fetch(getURL(`${type}/${editConfigurationType.data.id}`), {
        method: 'PUT',
        body: JSON.stringify({ name: value.name })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: `${capitalizeFirstLetter(title)} Updated Successfully`
        });
        getFinancierConfigurationType(type, params);
        editFinancierConfigurationType(false, {});
        form.resetFields();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      const res = await fetch(getURL(`${type}`), {
        method: 'POST',
        body: JSON.stringify({
          name: value.name,
          active: 1,
          createdByUserId: localStorage.getItem('userId')
        })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: `${capitalizeFirstLetter(title)} Created Successfully`
        });
        getFinancierConfigurationType(type, params);
        form.resetFields();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }
  };

  useEffect(() => {
    if (editConfigurationType?.openEdit) {
      form.setFieldsValue({ name: editConfigurationType.data.name });
    }
  }, [editConfigurationType]);

  useEffect(() => {
    if (type) {
      getFinancierConfigurationType(type, params);
    }
  }, [params]);

  return (
    <Fragment>
      <Form
        form={form}
        size='large'
        autoComplete='off'
        onFinish={onSubmit}
        onFinishFailed={errorInfo => console.log(errorInfo)}
      >
        <Card style={{ width: '100%', paddingTop: '20px', marginTop: '10px' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='name' rules={RULES.inputRequired}>
                <AutoComplete
                  allowClear
                  filterOption
                  dataSource={type === 'entity-types' ? ENTITY_TYPES : type === 'bank-account-types' ? BANK_ACC_TYPES : type === 'address-types' ? ADDRESS_TYPES : type === 'business-sectors' ? BUSINESS_SECTOR : []}
                  placeholder={`Enter ${title}`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Button type='primary' htmlType='submit' className={tw`w-full md:w-auto px-12`}>
                {editConfigurationType?.openEdit ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
      <div className={tw`mt-5`}>
        <TableComponent
          columns={configurationTypeColumns(type, title)}
          data={configurationType}
          loading={loadingconfigurationType}
        />
      </div>
    </Fragment>
  );
};

export default ConfigurationType;
