import React from 'react';
import TextInput from '../../antdComponent/Input';
import TableComponent from '../../antdComponent/Table';
import useFetch from '../../../hooks/useFetch';
import SelectComponent from '../../antdComponent/Select';
import { Form, Card, Button, Row, Col } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { tw } from 'twind';
import { getURL } from '../../../configs/apiURL';
import { ACTION_TYPES } from '../../../utils/helpers';
import { addressColumns } from '../columns/address';
import { RULES } from '../../../utils/formValidations';
import { getBuyerSellerAddress, getPinData } from '../../../services/buyerSeller';

const BuyerSellerAddressForm = ({
  editAddress,
  tableData,
  loading,
  onSubmit,
  onSubmitLoader,
  currentTab,
  setCurrentTab,
  pinCodeDataloading,
  pinCodeData,
  editBuyerSellerData,
  params,
  state,
  dispatch,
  setActiveTabs,
  isAllDetailsPending
}) => {
  const [form] = Form.useForm();
  const [pinValue, setPinValue] = useState('');
  const [fetch] = useFetch();

  const createdByFinancierId = localStorage.getItem('createdByFinancierId');

  const initialValue = useMemo(() => {
    return Object.keys(editAddress.data).length !== 0
      ? {
        addressType: editAddress.data.addressType.id,
        addressLine1: editAddress.data.addressLineOne,
        addressLine2: editAddress.data.addressLineTwo,
        pinNo: editAddress.data.pinCode,
        state: editAddress.data.state,
        district: editAddress.data.district,
        subDist: editAddress.data.subDistrict,
        postOffice: editAddress.data.postOffice
      }
      : {};
  }, [editAddress]);

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  useEffect(() => {
    if (currentTab === '2') {
      getBuyerSellerAddress(editBuyerSellerData.entityDetails.id, params);
    }
  }, [params]);

  useEffect(() => {
    if (pinValue.length === 6) {
      getPinData(pinValue);
    }
  }, [pinValue]);

  useEffect(() => {
    if (pinValue === pinCodeData?.Pincode && !pinCodeDataloading) {
      form.setFieldsValue({
        state: pinCodeData.State,
        district: pinCodeData.District,
        subDist: pinCodeData.Block,
        postOffice: pinCodeData.Name
      });
    }
  }, [pinCodeData]);

  const getDropDown = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      const url = new URL(getURL('address-types'));
      url.searchParams.set('active', 1);
      const res = await fetch(url);
      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: {
          addressDropdown: res.data.data.map(row => ({
            value: row.id,
            label: row.name
          }))
        }
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  useEffect(() => {
    getDropDown();
  }, [createdByFinancierId]);

  return (
    <div className={tw`mb-5`}>
      <Form
        form={form}
        size='large'
        initialValues={initialValue || {}}
        onFinish={async value => {
          const response = await onSubmit(value);
          if (response) {
            form.resetFields();
          }
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title='Address Details' style={{ width: '100%' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='addressType' rules={RULES.selectRequired}>
                <SelectComponent
                  label='Address Type'
                  placeholder='Select'
                  required
                  allowClear={true}
                  options={state.dropDownData.addressDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='addressLine1' rules={RULES.address}>
                <TextInput label='Address line 1' placeholder='Address line 1' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='addressLine2' rules={RULES.address}>
                <TextInput label='Address line 2' required placeholder='Address line 2' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='pinNo' rules={RULES.pinNo}>
                <TextInput
                  label='PIN Code'
                  placeholder='Enter PIN'
                  maxLength={6}
                  required
                  onChange={event => {
                    if (event.target.value.length === 6) {
                      setPinValue(event.target.value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='state' rules={RULES.numberString}>
                <TextInput label='State' placeholder='State' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='district' rules={RULES.numberString}>
                <TextInput label='District' placeholder='District' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='subDist' rules={RULES.numberString}>
                <TextInput label='Sub-District' placeholder='Sub-District' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='postOffice' rules={RULES.numberString}>
                <TextInput label='Post-Office' placeholder='Post-Office' required />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' size='middle' htmlType='submit' loading={onSubmitLoader}>
            {editAddress.openEdit ? 'Update' : 'Add'}
          </Button>
        </Card>
      </Form>
      <br />
      <TableComponent columns={addressColumns} data={tableData} loading={loading} />
      <Button
        className={tw`mt-${tableData.count ? '0' : '5'}`}
        type='primary'
        disabled={!tableData.count && isAllDetailsPending}
        onClick={() => {
          setCurrentTab('3');
          setActiveTabs('3');
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default BuyerSellerAddressForm;
