export const Permissions = (subMenu, actions, menuTabName = null) => {
  const entityCategory = localStorage.getItem('entityCategory');
  const userType = localStorage.getItem('userType');
  if (menuTabName) {
    return role[entityCategory][userType]?.['menuTab']?.[menuTabName];
  } else if (actions) {
    return role[entityCategory][userType][subMenu][actions];
  } else {
    return role[entityCategory][userType][subMenu];
  }
};

const role = {
  // FINANCIER
  FINANCIER: {
    ADMIN: {
      menuTab: {
        homeTab: true,
        buyerSellerTab: true,
        financeTab: true,
        operationsTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: true,
        addBuyerSeller: true,
        editBuyerSeller: true,
        editBuyerSellerStatus: true,
        deleteBuyerSeller: true,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: true,
        addFactoringUnit: true,
        combineFactoringUnit: true,
        editFactoringUnit: true,
        deleteFactoringUnit: true,
        approveFactoringUnit: false
      },
      manageBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: true,
        editBuyerSellerLink: true,
        deleteBuyerSellerLink: true
      },
      openForFinance: {
        visibility: true,
        addOpenForFinanceFeesAndROI: true,
        approveOpenForFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      approveBuyerSeller: {
        visibility: true,
        addBuyerSeller: true,
        editBuyerSeller: true,
        editBuyerSellerStatus: true,
        deleteBuyerSeller: false,
        approveBuyerSeller: true
      },
      approveBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: true,
        editBuyerSellerLink: true,
        deleteBuyerSellerLink: true,
        approveBuyerSellerLink: true
      },
      profile: {
        visibility: true,
        editProfile: true
      },
      bankAccount: {
        visibility: true,
        addBankAccount: true,
        editBankAccount: true,
        deleteBankAccount: true
      },
      users: {
        visibility: true,
        addUsers: true,
        editUsers: true,
        editUsersStatus: true,
        deleteUsers: true
      },
      checkerLevel: {
        visibility: true,
        addCheckerLevel: true,
        editCheckerLevel: true,
        deleteCheckerLevel: true
      },
      reports: {
        visibility: true
      }
    },
    MAKER: {
      menuTab: {
        homeTab: true,
        buyerSellerTab: true,
        financeTab: true,
        operationsTab: false,
        settingsTab: false,
        reportsTab: false
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: true,
        addFactoringUnit: true,
        combineFactoringUnit: true,
        editFactoringUnit: true,
        deleteFactoringUnit: true,
        approveFactoringUnit: false
      },
      manageBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      openForFinance: {
        visibility: true,
        addOpenForFinanceFeesAndROI: true,
        approveOpenForFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      profile: {
        visibility: false,
        editProfile: false
      },
      bankAccount: {
        visibility: false,
        addBankAccount: false,
        editBankAccount: false,
        deleteBankAccount: false
      },
      users: {
        visibility: false,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: false,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    },
    CHECKER: {
      menuTab: {
        homeTab: true,
        buyerSellerTab: true,
        financeTab: true,
        operationsTab: false,
        settingsTab: false,
        reportsTab: false
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: false,
        addFactoringUnit: false,
        combineFactoringUnit: false,
        editFactoringUnit: false,
        deleteFactoringUnit: false,
        approveFactoringUnit: true
      },
      manageBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      openForFinance: {
        visibility: true,
        addOpenForFinanceFeesAndROI: false,
        approveOpenForFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      profile: {
        visibility: false,
        editProfile: false
      },
      bankAccount: {
        visibility: false,
        addBankAccount: false,
        editBankAccount: false,
        deleteBankAccount: false
      },
      users: {
        visibility: false,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: false,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    },
    OPERATION_MANAGER: {
      menuTab: {
        homeTab: true,
        buyerSellerTab: true,
        financeTab: true,
        operationsTab: false,
        settingsTab: false,
        reportsTab: false
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: true,
        addFactoringUnit: true,
        combineFactoringUnit: true,
        editFactoringUnit: true,
        deleteFactoringUnit: true,
        approveFactoringUnit: false
      },
      manageBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      openForFinance: {
        visibility: true,
        addOpenForFinanceFeesAndROI: true,
        approveOpenForFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      profile: {
        visibility: false,
        editProfile: false
      },
      bankAccount: {
        visibility: false,
        addBankAccount: false,
        editBankAccount: false,
        deleteBankAccount: false
      },
      users: {
        visibility: false,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: false,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    }
  },

  // SELLER ROLES PERMISSIONS
  SELLER: {
    ADMIN: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: true,
        addBuyerSeller: true,
        editBuyerSeller: true,
        editBuyerSellerStatus: true,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: true,
        addInvoice: true,
        editInvoice: true,
        deleteInvoice: true,
        approveInvoice: true
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: false,
        addFactoringUnit: false,
        combineFactoringUnit: false,
        editFactoringUnit: false,
        deleteFactoringUnit: false,
        approveFactoringUnit: true
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: true,
        addUsers: true,
        editUsers: true,
        editUsersStatus: true,
        deleteUsers: true
      },
      checkerLevel: {
        visibility: true,
        addCheckerLevel: true,
        editCheckerLevel: true,
        deleteCheckerLevel: true
      },
      reports: {
        visibility: true
      }
    },
    MAKER: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: true,
        addInvoice: true,
        editInvoice: true,
        deleteInvoice: true,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: false,
        addFactoringUnit: false,
        combineFactoringUnit: false,
        editFactoringUnit: false,
        deleteFactoringUnit: false,
        approveFactoringUnit: true
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: true,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false
      },
      checkerLevel: {
        visibility: true,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    },
    CHECKER: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: true
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: false,
        addFactoringUnit: false,
        combineFactoringUnit: false,
        editFactoringUnit: false,
        deleteFactoringUnit: false,
        approveFactoringUnit: false
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        addFactoredUnitTransaction: false
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: false,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: false,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    }
  },
  // BUYER ROLES PERMISSION
  BUYER: {
    ADMIN: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: true,
        addBuyerSeller: true,
        editBuyerSeller: true,
        editBuyerSellerStatus: true,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: true
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: true,
        addFactoringUnit: true,
        combineFactoringUnit: true,
        editFactoringUnit: true,
        deleteFactoringUnit: true,
        approveFactoringUnit: true
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        openDisbursement: true,
        activeDisbursement: true,
        overdue: true,
        paymentClosed: true
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: true,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: true,
        addUsers: true,
        editUsers: true,
        editUsersStatus: true,
        deleteUsers: true
      },
      checkerLevel: {
        visibility: true,
        addCheckerLevel: true,
        editCheckerLevel: true,
        deleteCheckerLevel: true
      },
      reports: {
        visibility: true
      }
    },
    MAKER: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: false
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: true,
        addFactoringUnit: true,
        combineFactoringUnit: true,
        editFactoringUnit: true,
        deleteFactoringUnit: true,
        approveFactoringUnit: true
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        addFactoredUnitTransaction: true
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: true,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: true,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    },
    CHECKER: {
      menuTab: {
        homeTab: true,
        invoiceTab: true,
        factoringUnitTab: true,
        factoredTab: true,
        financeTab: true,
        settingsTab: true,
        reportsTab: true
      },
      home: {
        visibility: true
      },
      manageBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      approveBuyerSeller: {
        visibility: false,
        addBuyerSeller: false,
        editBuyerSeller: false,
        editBuyerSellerStatus: false,
        approveBuyerSeller: false
      },
      invoices: {
        visibility: true,
        uploadInvoice: false,
        addInvoice: false,
        editInvoice: false,
        deleteInvoice: false,
        approveInvoice: true
      },
      factoringUnit: {
        visibility: true,
        uploadFactoringUnit: false,
        addFactoringUnit: false,
        combineFactoringUnit: false,
        editFactoringUnit: false,
        deleteFactoringUnit: false,
        approveFactoringUnit: true
      },
      acceptBankFinance: {
        visibility: true,
        approveAcceptBankFinance: true
      },
      factoredUnit: {
        visibility: true,
        addFactoredUnitTransaction: false
      },
      notFactoredUnit: {
        visibility: true
      },
      manageBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false
      },
      approveBuyerSellerLink: {
        visibility: false,
        addBuyerSellerLink: false,
        editBuyerSellerLink: false,
        deleteBuyerSellerLink: false,
        approveBuyerSellerLink: false
      },
      users: {
        visibility: false,
        addUsers: false,
        editUsers: false,
        editUsersStatus: false,
        deleteUsers: false
      },
      checkerLevel: {
        visibility: false,
        addCheckerLevel: false,
        editCheckerLevel: false,
        deleteCheckerLevel: false
      },
      reports: {
        visibility: false
      }
    }
  }
};

export const platformRolesPermission = (subMenu, actions, menuTabName = null) => {
  const userType = localStorage.getItem('userType');
  if (menuTabName) {
    return platformRole[userType]?.['menuTab']?.[menuTabName];
  } else if (actions) {
    return platformRole[userType][subMenu][actions];
  } else {
    return platformRole[userType][subMenu];
  }
};

const platformRole = {
  ADMIN: {
    menuTab: {
      homeTab: true,
      financierTab: true,
      settingsTab: true,
      configurationsTab: true
    },
    home: {
      visibility: true
    },
    financier: {
      visibility: true,
      addFinancier: true,
      editFinancier: true,
      editFinancierStatus: true
    },
    settings: {
      visibility: true,
      addUsers: true,
      editUsers: true,
      editUsersStatus: true
    },
    configurations: {
      visibility: true,
      addConfiguration: true,
      editConfiguration: true,
      editConfigurationStatus: true
    }
  },
  OPERATION_MANAGER: {
    menuTab: {
      homeTab: true,
      financierTab: true,
      settingsTab: false,
      configurationsTab: true
    },
    home: {
      visibility: true
    },
    financier: {
      visibility: true,
      addFinancier: true,
      editFinancier: true,
      editFinancierStatus: true
    },
    settings: {
      visibility: false,
      addUsers: false,
      editUsers: false,
      editUsersStatus: false
    },
    configurations: {
      visibility: true,
      addConfiguration: true,
      editConfiguration: true,
      editConfigurationStatus: true
    }
  }
};
