import React, { useEffect } from 'react';
import { getURL } from '../../configs/apiURL';
import { fetchRequest } from '../../utils/fetchRequest';
import { useSearchParams } from 'react-router-dom';

const DocumentView = () => {
  const [searchParams] = useSearchParams();

  const viewDocumentHandler = async () => {
    const pathname = `${window.location.pathname}?action=${searchParams.get(
      'action'
    )}&expiresIn=${searchParams.get('expiresIn')}`;
    const { data } = await fetchRequest(getURL(pathname));

    window.location.assign(data.data.url);
  };

  useEffect(() => {
    viewDocumentHandler();
  }, []);

  return <div>{''}</div>;
};

export default DocumentView;
