import React from 'react';
import useFetch from '../../hooks/useFetch';
import { useState, useMemo } from 'react';
import { Button, Modal, notification } from 'antd';
import { MdDelete } from 'react-icons/md';
import { tw } from 'twind';
import { getAllFactoringUnit, getAllCheckerLevel } from '../../services/factoringUnit';
import { getAllInvoiceDocuments } from '../../services/factoringUnit';
import { getURL } from '../../configs/apiURL';
import { getAllUserByEntity } from '../../services/user';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { getFinancierBanks } from '../../services/financier';
import {
  getBuyerSellerAddress,
  getBuyerSellerBanks,
  getAllBuyerSellerLink,
  getBuyerSellerDocuments,
  getBuyerSellerPromoters,
  getAllInvoices,
  getAllBuyerSeller
} from '../../services/buyerSeller';
import { useSelector } from 'react-redux';

let path = '';

const DeletePopup = ({ entityId, type, id, data, invoiceId }) => {
  const { params, modalParams } = useSelector(state => ({
    params: state.mutations.params,
    modalParams: state.mutations.modalParams
  }));

  const [deleteDetail] = useFetch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { userType, currentTab } = localStorage;

  const url_extend = type === 'document' ? 'documents' : `${type}-details`;

  switch (type) {
    case 'entity':
      path = `buyer-seller/${id}`;
      break;
    case 'invoiceManagement':
      path = `invoices/${id}`;
      break;
    case 'invoice':
      path = `invoices/${invoiceId}/documents/${id}`;
      break;
    case 'checker':
      path = `platform/users/checker-levels/${id}`;
      break;
    case 'financier-bank':
      path = `financiers/profile/bank-details/${id}`;
      break;
    case 'factoringUnit':
      path = `factoring-units/${id}`;
      break;
    case 'buyerSellerLink':
      path = `buyer-seller/links/${id}`;
      break;
    default:
      path = `buyer-seller/${entityId}/${url_extend}/${id}`;
      break;
  }

  const buyerSellerRoot = useMemo(() => {
    let query;
    if (
      ['ADMIN', 'OPERATION_MANAGER'].includes(userType) &&
      ['BUYER_SELLER_OPERATION'].includes(currentTab)
    ) {
      query = `allDetailsFilled=1&approved=0`;
    } else if (['COMPLETED'].includes(currentTab)) {
      query = `approved=1`;
    } else {
      query = `allDetailsFilled=0&approved=0`;
    }
    return query;
  }, []);

  const BSLinkRoot = useMemo(() => {
    let query;
    if (
      ['ADMIN', 'OPERATION_MANAGER'].includes(userType) &&
      ['BUYER_SELLER_LINK_OPERATION'].includes(currentTab)
    ) {
      query = `approved=0`;
    } else if (['APPROVED'].includes(currentTab)) {
      query = `approved=1`;
    } else {
      query = `approved=0`;
    }
    return query;
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    const res = await deleteDetail(getURL(path), {
      method: 'DELETE'
    });
    if (res && res.status === 200) {
      notification.success({
        message: `${
          type === 'invoiceManagement'
            ? 'Invoice'
            : type === 'invoice'
            ? 'Doucment'
            : capitalizeFirstLetter(type)
        } Deleted Successfully`
      });
      setIsModalVisible(false);
      switch (type) {
        case 'address':
          getBuyerSellerAddress(entityId, params);
          break;
        case 'promoter':
          getBuyerSellerPromoters(entityId, params);
          break;
        case 'document':
          getBuyerSellerDocuments(entityId, params);
          break;
        case 'invoice':
          getAllInvoiceDocuments(invoiceId, modalParams);
          break;
        case 'bank':
          getBuyerSellerBanks(entityId, params);
          break;
        case 'entity':
          getAllBuyerSeller(`${buyerSellerRoot}&${params ? params : ''}`);
          break;
        case 'factoringUnit':
          getAllFactoringUnit(params);
          break;
        case 'financier-bank':
          getFinancierBanks(params);
          break;
        case 'buyerSellerLink':
          getAllBuyerSellerLink(`${BSLinkRoot}&${params ? params : ''}`);
          break;
        case 'invoiceManagement':
          getAllInvoices(params);
          break;
        case 'checker':
          getAllCheckerLevel(params);
          getAllUserByEntity(entityId);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={tw`flex`}>
      <Button
        name={`delete-${
          type === 'invoiceManagement' ? 'invoice' : type === 'invoice' ? 'doucment' : type
        }`}
        style={{ borderRadius: '8px', padding: '5px' }}
        onClick={showModal}
      >
        <MdDelete size='20px' color='red' />
      </Button>
      <Modal
        title={`Delete ${
          type === 'invoiceManagement' ? 'Invoice' : type === 'invoice' ? 'doucment' : type
        }`}
        className='ant-del-modal'
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <div className={tw`flex flex-row-reverse gap-2`}>
            <Button type='primary' name='cancel' onClick={handleCancel} htmlType='submit'>
              Cancel
            </Button>
            <Button danger name='delete' type='primary' onClick={handleOk} htmlType='submit'>
              Delete
            </Button>
          </div>
        }
      >
        <p>{data}</p>
      </Modal>
    </div>
  );
};

export default DeletePopup;
