export const buyerSellerTypes = {
  GET_BUYER_SELLER_LOADING: 'GET_BUYER_SELLER_LOADING',
  GET_BUYER_SELLER_SUCCESS: 'GET_BUYER_SELLER_SUCCESS',
  GET_BUYER_SELLER_FAILURE: 'GET_BUYER_SELLER_FAILURE',

  GET_BUYER_SELLER_PROFILE_LOADING: 'GET_BUYER_SELLER_PROFILE_LOADING',
  GET_BUYER_SELLER_PROFILE_SUCCESS: 'GET_BUYER_SELLER_PROFILE_SUCCESS',
  GET_BUYER_SELLER_PROFILE_FAILURE: 'GET_BUYER_SELLER_PROFILE_FAILURE',

  EDIT_BUYER_SELLER: 'EDIT_BUYER_SELLER',

  GET_BANKS_LOADING: 'GET_BANKS_LOADING',
  GET_BANKS_FAILURE: 'GET_BANKS_FAILURE',
  GET_BANKS_SUCCESS: 'GET_BANKS_SUCCESS',

  GET_ADDRESS_LOADING: 'GET_ADDRESS_LOADING',
  GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
  GET_ADDRESS_FAILURE: 'GET_ADDRESS_FAILURE',

  GET_PROMOTER_SUCCESS: 'GET_PROMOTER_SUCCESS',
  GET_PROMOTER_LOADING: 'GET_PROMOTER_LOADING',
  GET_PROMOTER_FAILURE: 'GET_PROMOTER_FAILURE',

  GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_LOADING: 'GET_DOCUMENT_LOADING',
  GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE',

  EDIT_INVOICE: 'EDIT_INVOICE',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  EDIT_BANK: 'EDIT_BANK',
  EDIT_PROMOTER: 'EDIT_PROMOTER',

  GET_BUYER_SELLER_LINK_FAILURE: 'GET_BUYER_SELLER_LINK_FAILURE',
  GET_BUYER_SELLER_LINK_LOADING: 'GET_BUYER_SELLER_LINK_LOADING',
  GET_BUYER_SELLER_LINK_SUCCESS: 'GET_BUYER_SELLER_LINK_SUCCESS',

  EDIT_BUYER_SELLER_LINK: 'EDIT_BUYER_SELLER_LINK',

  GET_IFSC_DETAILS_LOADING: 'GET_IFSC_DETAILS_LOADING',
  GET_IFSC_DETAILS_FAILURE: 'GET_IFSC_DETAILS_FAILURE',
  GET_IFSC_DETAILS_SUCCESS: 'GET_IFSC_DETAILS_SUCCESS',

  GET_PIN_CODE_DETAILS_LOADING: 'GET_PIN_CODE_DETAILS_LOADING',
  GET_PIN_CODE_DETAILS_FAILURE: 'GET_PIN_CODE_DETAILS_FAILURE',
  GET_PIN_CODE_DETAILS_SUCCESS: 'GET_PIN_CODE_DETAILS_SUCCESS',

  GET_BUYER_SELLER_INVOICE_FAILURE: 'GET_BUYER_SELLER_INVOICE_FAILURE',
  GET_BUYER_SELLER_INVOICE_LOADING: 'GET_BUYER_SELLER_INVOICE_LOADING',
  GET_BUYER_SELLER_INVOICE_SUCCESS: 'GET_BUYER_SELLER_INVOICE_SUCCESS',

  GET_BUYER_SELLER_INVOICE_FAILURE_BY_ID: 'GET_BUYER_SELLER_INVOICE_FAILURE_BY_ID',
  GET_BUYER_SELLER_INVOICE_LOADING_BY_ID: 'GET_BUYER_SELLER_INVOICE_LOADING_BY_ID',
  GET_BUYER_SELLER_INVOICE_SUCCESS_BY_ID: 'GET_BUYER_SELLER_INVOICE_SUCCESS_BY_ID',

  BUYER_SELLER_FILTER_QUERY: 'BUYER_SELLER_FILTER_QUERY',
  USER_FILTER_QUERY: 'USER_FILTER_QUERY',

  GET_LINKED_GST_LOADING: 'GET_LINKED_GST_LOADING',
  GET_LINKED_GST_FAILURE: 'GET_LINKED_GST_FAILURE',
  GET_LINKED_GST_SUCCESS: 'GET_LINKED_GST_SUCCESS',

  GET_PAN_KYC_LOADING: 'GET_PAN_LOADING',
  GET_PAN_KYC_FAILURE: 'GET_PAN_FAILURE',
  GET_PAN_KYC_SUCCESS: 'GET_PAN_SUCCESS',

  GET_UDYAM_KYC_LOADING: 'GET_UDYAM_LOADING',
  GET_UDYAM_KYC_FAILURE: 'GET_UDYAM_FAILURE',
  GET_UDYAM_KYC_SUCCESS: 'GET_UDYAM_SUCCESS',

  GET_GST_KYC_LOAING: 'GET_GET_LOADING',
  GET_GST_KYC_FAILURE: 'GET_GST_FAILURE',
  GET_GST_KYC_SUCCESS: 'GET_GST_SUCCESS',

  GET_GST_ITR_KYC_LOAING: 'GET_GST_ITR_KYC_LOAING',
  GET_GST_ITR_KYC_FAILURE: 'GET_GST_ITR_KYC_FAILURE',
  GET_GST_ITR_KYC_SUCCESS: 'GET_GST_ITR_KYC_SUCCESS',

  GET_BANK_KYC_LOADING: 'GET_BANK_KYC_LOADING',
  GET_BANK_KYC_FAILURE: 'GET_BANK_KYC_FAILURE',
  GET_BANK_KYC_SUCCESS: 'GET_BANK_KYC_SUCCESS',

  GET_BANK_STMT_LOADING: 'GET_BANK_STMT_LOADING',
  GET_BANK_STMT_FAILURE: 'GET_BANK_STMT_FAILURE',
  GET_BANK_STMT_SUCCESS: 'GET_BANK_STMT_SUCCESS',

  GET_AADHAAR_KYC_LOADING: 'GET_AADHAAR_KYC_LOADING',
  GET_AADHAAR_KYC_FAILURE: 'GET_AADHAAR_KYC_FAILURE',
  GET_AADHAAR_KYC_SUCCESS: 'GET_AADHAAR_KYC_SUCCESS',

  GET_CREDIT_REPORT_LOADING: 'GET_CREDIT_REPORT_LOADING',
  GET_CREDIT_REPORT_FAILURE: 'GET_CREDIT_REPORT_FAILURE',
  GET_CREDIT_REPORT_SUCCESS: 'GET_CREDIT_REPORT_SUCCESS'
};
