import TextInput from '../antdComponent/Input';
import SelectComponent from '../antdComponent/Select';
import { useState, useMemo, useEffect, Fragment } from 'react';
import {
  KYC_API_PROVIDERS,
  BANK_STATEMENT_API_PROVIDER,
  ITR_REPORTS_API_PROVIDER
} from '../../utils/helpers';
import { RULES } from '../../utils/formValidations';
import { tw } from 'twind';
import { Button, Form, Row, Col, Typography, Switch, Checkbox, Spin } from 'antd';

const TOGGLE = {
  bankStmt: { hidden: true, value: '' },
  pan: { hidden: true, value: '' },
  udyam: { hidden: true, value: '' },
  aadhaar: { hidden: true, value: '' },
  gst: { hidden: true, value: '' },
  gstItr: { hidden: true, value: '' },
  bankAcc: { hidden: true, value: '' },
  creditReport: { hidden: true, value: [] }
};

const { Text } = Typography;

export const FinancierApiForm = ({
  isUpdate,
  defaultValue,
  form,
  onSubmitHandler,
  onSubmitLoader
}) => {
  const [toggler, setToggler] = useState(TOGGLE);
  const [selectedValues, setSelectedValues] = useState(null);

  const initialValue = useMemo(() => {
    return Object.keys(defaultValue).length !== 0 ? { ...defaultValue.apiProviders } : {};
  }, [defaultValue]);

  const onSelectChange = (value, type, apiProviders) => {
    setToggler({
      ...toggler,
      [type]: { ...toggler[type], value: value }
    });
    form.setFieldValue(apiProviders, value);
  };

  const onSwitchChange = (hidden, type, apiProviders) => {
    setToggler({
      ...toggler,
      [type]: { hidden: !hidden, value: form.getFieldValue(apiProviders) }
    });
  };

  const handleCheckboxChange = (value, type, apiProviders) => {
    setSelectedValues(value.length ? value : null);
    setToggler({ ...toggler, [type]: { ...toggler[type], value } });
    form.setFieldValue(apiProviders, value);
  };

  const validateCheckboxGroup = (_, value) => {
    if (value && value.length < 2 && selectedValues) {
      return Promise.reject(new Error('Please select at least two options'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  if (onSubmitLoader) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={onSubmitLoader} />
      </div>
    );
  }

  return (
    <Fragment>
      <Row className={tw`mb-4 mt-4 md:mb-0`}>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Pan*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='panApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={KYC_API_PROVIDERS}
              onChange={e => onSelectChange(e, 'pan', 'panApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.pan.hidden}
              value={toggler.pan.hidden}
              onChange={hidden => onSwitchChange(hidden, 'pan', 'panApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='panKarzaApiKey'
              hidden={toggler.pan.hidden || toggler.pan.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='panGridlinesApiKey'
              hidden={toggler.pan.hidden || toggler.pan.value !== 'GRIDLINES'}
            >
              <TextInput placeholder='Enter On-Grid API Key' />
            </Form.Item>
            <Form.Item
              name='panScoreMeClientIdApiKey'
              hidden={toggler.pan.hidden || toggler.pan.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='panScoreMeClientSecretApiKey'
              hidden={toggler.pan.hidden || toggler.pan.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Udyam*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='udyamRegNoApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={KYC_API_PROVIDERS}
              onChange={hidden => onSelectChange(hidden, 'udyam', 'udyamRegNoApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.udyam.hidden}
              value={toggler.udyam}
              onChange={hidden => onSwitchChange(hidden, 'udyam', 'udyamRegNoApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='udyamRegNoKarzaApiKey'
              hidden={toggler.udyam.hidden || toggler.udyam.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='udyamRegNoGridlinesApiKey'
              hidden={toggler.udyam.hidden || toggler.udyam.value !== 'GRIDLINES'}
            >
              <TextInput placeholder='Enter On-Grid API Key' />
            </Form.Item>
            <Form.Item
              name='udyamRegNoScoreMeClientIdApiKey'
              hidden={toggler.udyam.hidden || toggler.udyam.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='udyamRegNoScoreMeClientSecretApiKey'
              hidden={toggler.udyam.hidden || toggler.udyam.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Aadhaar*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='aadhaarApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={KYC_API_PROVIDERS}
              onChange={hidden => onSelectChange(hidden, 'aadhaar', 'aadhaarApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.aadhaar.hidden}
              value={toggler.aadhaar}
              onChange={hidden => onSwitchChange(hidden, 'aadhaar', 'aadhaarApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='aadhaarKarzaApiKey'
              hidden={toggler.aadhaar.hidden || toggler.aadhaar.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='aadhaarGridlinesApiKey'
              hidden={toggler.aadhaar.hidden || toggler.aadhaar.value !== 'GRIDLINES'}
            >
              <TextInput placeholder='Enter On-Grid API Key' />
            </Form.Item>
            <Form.Item
              name='aadhaarScoreMeClientIdApiKey'
              hidden={toggler.aadhaar.hidden || toggler.aadhaar.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='aadhaarScoreMeClientSecretApiKey'
              hidden={toggler.aadhaar.hidden || toggler.aadhaar.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Gst*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='gstApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={KYC_API_PROVIDERS}
              onChange={hidden => onSelectChange(hidden, 'gst', 'gstApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.gst.hidden}
              value={toggler.gst}
              onChange={hidden => onSwitchChange(hidden, 'gst', 'gstApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='gstKarzaApiKey'
              hidden={toggler.gst.hidden || toggler.gst.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='gstGridlinesApiKey'
              hidden={toggler.gst.hidden || toggler.gst.value !== 'GRIDLINES'}
            >
              <TextInput placeholder='Enter On-Grid API Key' />
            </Form.Item>
            <Form.Item
              name='gstScoreMeClientIdApiKey'
              hidden={toggler.gst.hidden || toggler.gst.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='gstScoreMeClientSecretApiKey'
              hidden={toggler.gst.hidden || toggler.gst.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Bank Account*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='bankAccApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={KYC_API_PROVIDERS}
              onChange={hidden => onSelectChange(hidden, 'bankAcc', 'bankAccApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.bankAcc.hidden}
              value={toggler.bankAcc}
              onChange={hidden => onSwitchChange(hidden, 'bankAcc', 'bankAccApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='bankAccKarzaApiKey'
              hidden={toggler.bankAcc.hidden || toggler.bankAcc.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='bankAccGridlinesApiKey'
              hidden={toggler.bankAcc.hidden || toggler.bankAcc.value !== 'GRIDLINES'}
            >
              <TextInput placeholder='Enter On-Grid API Key' />
            </Form.Item>
            <Form.Item
              name='bankAccScoreMeClientIdApiKey'
              hidden={toggler.bankAcc.hidden || toggler.bankAcc.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='bankAccScoreMeClientSecretApiKey'
              hidden={toggler.bankAcc.hidden || toggler.bankAcc.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Bank Statement*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='bankStmtApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={BANK_STATEMENT_API_PROVIDER}
              onChange={e => onSelectChange(e, 'bankStmt', 'bankStmtApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.bankStmt.hidden}
              value={toggler.bankStmt}
              onChange={hidden => onSwitchChange(hidden, 'bankStmt', 'bankStmtApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='bankStmtFinboxApiKey'
              hidden={toggler.bankStmt.hidden || toggler.bankStmt.value !== 'FINBOX'}
            >
              <TextInput placeholder='Enter Finbox API Key' />
            </Form.Item>
            <Form.Item
              name='bankStmtScoreMeClientIdApiKey'
              hidden={toggler.bankStmt.hidden || toggler.bankStmt.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='bankStmtScoreMeClientSecretApiKey'
              hidden={toggler.bankStmt.hidden || toggler.bankStmt.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>ITR Report*</Text>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='gstItrApiProvider' rules={RULES.selectRequired}>
            <SelectComponent
              placeholder='Select'
              allowClear={true}
              options={ITR_REPORTS_API_PROVIDER}
              onChange={hidden => onSelectChange(hidden, 'gstItr', 'gstItrApiProvider')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={12}>
          <div className={tw`flex gap-6`}>
            <Switch
              defaultChecked={!toggler.gstItr.hidden}
              value={toggler.gstItr}
              onChange={hidden => onSwitchChange(hidden, 'gstItr', 'gstItrApiProvider')}
              className={tw`mt-2`}
            />
            <Form.Item
              name='gstItrKarzaApiKey'
              hidden={toggler.gstItr.hidden || toggler.gstItr.value !== 'KARZA'}
            >
              <TextInput placeholder='Enter Karza API Key' />
            </Form.Item>
            <Form.Item
              name='gstItrScoreMeClientIdApiKey'
              hidden={toggler.gstItr.hidden || toggler.gstItr.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Id API Key' />
            </Form.Item>
            <Form.Item
              name='gstItrScoreMeClientSecretApiKey'
              hidden={toggler.gstItr.hidden || toggler.gstItr.value !== 'SCOREME'}
            >
              <TextInput placeholder='Enter Score-Me Client-Secret API Key' />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <Text className={tw`md:absolute mt-3`}>Credit Report*</Text>
        </Col>
        <Col xs={24} md={7}>
          <Form.Item
            name='creditReportApiProvider'
            rules={[
              {
                required: true,
                message: 'Please select at least two options'
              },
              { validator: validateCheckboxGroup }
            ]}
          >
            <Checkbox.Group
              options={[
                { label: 'Equifax', value: 'EQUIFAX' },
                { label: 'Experian', value: 'EXPERIAN' },
                { label: 'Crif', value: 'CRIF', disabled: true },
                { label: 'Cibil', value: 'CIBIL', disabled: true }
              ]}
              onChange={value =>
                handleCheckboxChange(value, 'creditReport', 'creditReportApiProvider')
              }
              value={selectedValues}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={13}>
          <div className={tw`flex gap-6`}>
            <Switch
              className={tw`mt-2`}
              defaultChecked={!toggler.creditReport.hidden}
              value={toggler.creditReport}
              onChange={hidden => onSwitchChange(hidden, 'creditReport', 'creditReportApiProvider')}
            />
            <Form.Item
              name='creditReportEquifaxApiKey'
              hidden={
                toggler.creditReport.hidden || !toggler.creditReport.value?.includes('EQUIFAX')
              }
            >
              <TextInput placeholder='Enter Equifax API Key' />
            </Form.Item>
            <Form.Item
              name='creditReportExperianApiKey'
              hidden={
                toggler.creditReport.hidden || !toggler.creditReport.value?.includes('EXPERIAN')
              }
            >
              <TextInput placeholder='Enter Experian API Key' />
            </Form.Item>
          </div>
          <div className={tw`flex gap-6 ml-1`}>
            <Form.Item
              name='creditReportCrifApiKey'
              hidden={toggler.creditReport.hidden || !toggler.creditReport.value?.includes('CRIF')}
              className={tw`ml-16`}
            >
              <TextInput placeholder='Enter Crif API Key' />
            </Form.Item>
            <Form.Item
              name='creditReportCibilApiKey'
              hidden={toggler.creditReport.hidden || !toggler.creditReport.value?.includes('CIBIL')}
            >
              <TextInput placeholder='Enter Cibil API Key' />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Button
        className={tw`my-5 w-full sm:w-32 -ml-1`}
        loading={onSubmitLoader}
        size='middle'
        type='primary'
        onClick={() => onSubmitHandler({ type: "FINANCIER_API_SETTINGS" })}
      >
        {isUpdate ? 'Update' : 'Add'}
      </Button>
    </Fragment>
  );
};
