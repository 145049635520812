import React, { useState } from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import * as ROUTES from '../../configs/routes';
import PasswordInput from '../../component/antdComponent/Password';
import WhiteLogo from '../../component/antdComponent/Logo/WhiteLogo';
import ColorLogo from '../../component/antdComponent/Logo/ColorLogo';
import { tw } from 'twind';
import { useNavigate } from 'react-router';
import { Navigate, useParams } from 'react-router-dom';
import { theme, ConfigProvider, Layout, Button, Form } from 'antd';
import { resetPassword } from '../../services/auth';
import { RULES } from '../../utils/formValidations';

const { useToken } = theme;

const { Header, Content } = Layout;

const redirectPaths = {
  null: ROUTES['FINANCIER_PLATFORM']['path'],
  FINANCIER: ROUTES['FINANCIER']['path'],
  BUYER: ROUTES['BUYER']['path'],
  SELLER: ROUTES['SELLER']['path']
};

const ResetPasswordPage = () => {
  const { token } = useToken();

  const [form] = Form.useForm();
  const params = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = async value => {
    setLoading(true);
    const response = await resetPassword(params.userId, params.token, value);
    if (response) {
      navigate(ROUTES['LOGIN']['path']);
    }
    setLoading(false);
  };

  try {
    jwt_decode(params.token);
  } catch (err) {
    return <Navigate to={ROUTES['LOGIN']['path']} />;
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: token.defaultThemeColor } }}>
      {Cookies.get('ACCESS_TOKEN') ? (
        <Navigate
          to={redirectPaths[jwt_decode(Cookies.get('ACCESS_TOKEN')).token_payload.entityCategory]}
        />
      ) : (
        <Layout>
          <Header
            style={{
              zIndex: 2,
              width: '100%',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0px 30px',
              display: 'flex',
              background: token.defaultThemeColor
            }}
          >
            <WhiteLogo />
          </Header>
          <Content className={tw`bg-gray-50 h-screen`}>
            <div
              className={tw`flex flex-col items-center justify-center mt-6 px-4 py-8 mx-auto md:h-screen lg:py-0`}
            >
              <div
                className={tw`w-full bg-white md:mb-40 rounded-lg shadow h-[65vh] md:mt-0 flex flex-col justify-center sm:max-w-md xl:p-0`}
              >
                <div className={tw`p-4 space-y-12 md:space-y-12 sm:p-8`}>
                  <h1
                    className={tw`text-xl text-center flex justify-center items-center text-gray-900 md:text-2xl`}
                  >
                    <ColorLogo />
                  </h1>
                  <Form
                    form={form}
                    size='large'
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    className={tw`space-y-4 md:space-y-6 text-zinc-200`}
                    onFinishFailed={errorInfo => console.log(errorInfo)}
                    onValuesChange={() => form.validateFields(['repassword'])}
                    autoComplete='off'
                  >
                    <Form.Item name='password' rules={RULES.password} hasFeedback>
                      <PasswordInput
                        label='New Password'
                        placeholder='Enter New Password'
                        required
                      />
                    </Form.Item>

                    <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                      <PasswordInput
                        label='Confirm Password'
                        placeholder='Enter Password'
                        required
                      />
                    </Form.Item>

                    <Button
                      type='primary'
                      htmlType='submit'
                      className={tw`w-full rounded`}
                      loading={loading}
                    >
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </ConfigProvider>
  );
};

export default ResetPasswordPage;
