import React from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { Navigate, useLocation } from 'react-router-dom';

const Home = () => {
  const location = useLocation();

  if (!Cookies.get('ACCESS_TOKEN')) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  const { token_payload } = jwt_decode(Cookies.get('ACCESS_TOKEN'));

  switch (token_payload.entityCategory) {
    case 'FINANCIER':
      return <Navigate to='/financier' />;
    case 'BUYER':
      return <Navigate to='/buyer' />;
    case 'SELLER':
      return <Navigate to='/seller' />;
    default:
      return <Navigate to='/platform/financier' />;
  }
};

export default Home;
