import React, { useEffect, Fragment } from 'react';
import { Typography } from 'antd';
import { tw } from 'twind';
import { acceptBankFinanceColumns } from './acceptbankFinanceColumns';
import { useSelector } from 'react-redux';
import { getAllAcceptBankFinance } from '../../../services/factoringUnit';
import TableComponent from '../../antdComponent/Table';

const { Text } = Typography;

const BankFinance = () => {
  const { params, data = {} } = useSelector(state => ({
    params: state.mutations.params,
    data: state.factoringUnit.acceptBankFinance
  }));

  useEffect(() => {
    getAllAcceptBankFinance(params);
  }, [params]);

  return (
    <Fragment>
      <div className={tw`content-divider`}>
        <Text className={tw`text-xl`}>Accept Bank Finance</Text>
      </div>
      <TableComponent columns={acceptBankFinanceColumns} data={data} />
    </Fragment>
  );
};

export default BankFinance;
