import { store } from '../index';
import {
  factoredUnitTypes,
  factoringUnitTypes,
  notFactoredUnitTypes,
  openForFinanceTypes,
  checkerLevelTypes
} from './types';

export const editFactoringUnit = (openEdit, data) => {
  store.dispatch({
    type: factoringUnitTypes.EDIT_FACTORING_UNIT,
    payload: { data, openEdit }
  });
};

export const editChecker = (openEdit, data) => {
  store.dispatch({
    type: checkerLevelTypes.EDIT_CHECKER_LEVEL,
    payload: { data, openEdit }
  });
};

export const setFactoringUnitFilterQuery = params => {
  store.dispatch({
    type: factoringUnitTypes.FACTORING_UNIT_FILTER_QUERY,
    payload: params
  });
};

export const setFactoredUnitFilterQuery = params => {
  store.dispatch({
    type: factoredUnitTypes.FACTORED_UNIT_FILTER_QUERY,
    payload: params
  });
};

export const setOpenForFinanceFilterQuery = params => {
  store.dispatch({
    type: openForFinanceTypes.OPEN_FOR_FINANCE_FILTER_QUERY,
    payload: params
  });
};

export const setNotFactoredUnitFilterQuery = params => {
  store.dispatch({
    type: notFactoredUnitTypes.NOT_FACTORED_UNIT_FILTER_QUERY,
    payload: params
  });
};
