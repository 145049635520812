import dayjs from 'dayjs';

export const consentInit = {
  agree: false,
  stage: '',
  formInitial: {},
  formData: {},
  panNumber: null,
  entityName: null,
  panKyc: 0,
  panVerified: 0,
  otpReport: 0,
  otpReportVerified: 0,
  creditReport: 0,
  creditReportVerified: 0,
  dob: null,
  udyamNumber: null,
  udyamRegNoKyc: 0,
  udyamRegNoVerified: 0,
  gstinNumber: null,
  bankAccNo: null,
  bankAcckyc: 0,
  bankAccVerified: 0,
  aadhaarNo: null,
  aadhaarKyc: 0,
  aadhaarVerified: 0,
  otpAadhaar: 0,
  otpAadhaaarVerified: 0
};

export const onEntitySubmit = async ({
  value,
  consent,
  setConsent,
  defaultValue,
  isUpdate,
  onSubmit,
  mobileIsdCode,
  contactIsdCode
}) => {
  let data = {
    ...consent,
    formData: {
      ...value,
      mobileNo: mobileIsdCode + value.mobileNo,
      contactNo: contactIsdCode + value.contactNo
    }
  };

  const isPanKycMismatch = () => {
    const date1 = value.incorporationDate?.$d
      ? dayjs(value.incorporationDate.$d).format('YYYY-MM-DD')
      : value.incorporationDate.format('YYYY-MM-DD');

    const date2 = consent.dob?.$d
      ? dayjs(consent.dob.$d).format('YYYY-MM-DD')
      : consent.dob?.format('YYYY-MM-DD');

    return (
      (value.panNumber && !consent.panKyc) ||
      (consent.panKyc &&
        (value.panNumber !== consent.panNumber ||
          date1 !== date2 ||
          value.entityName !== consent.entityName))
    );
  };

  const isUdyamKycMismatch = () => {
    return (
      (value.udyamNumber && !consent.udyamRegNoKyc) ||
      (consent.udyamRegNoKyc &&
        (value.udyamNumber !== consent.udyamNumber || value.entityName !== consent.entityName))
    );
  };

  const isCreditKycMismatch = () => {
    return value.panNumber && consent.panVerified && !consent.creditReport;
  };

  const isUpdatePanKycMismatch = () => {
    const date1 = value.incorporationDate?.$d
      ? dayjs(value.incorporationDate.$d).format('YYYY-MM-DD')
      : value.incorporationDate.format('YYYY-MM-DD');

    const date2 = dayjs(defaultValue.entityDetails.dateOfIncorporation).format('YYYY-MM-DD');

    return (
      value.panNumber &&
      (value.panNumber !== defaultValue.entityDetails.pan ||
        date1 !== date2 ||
        value.entityName !== defaultValue.entityDetails.entityName) &&
      !defaultValue.entityDetails.panVerified
    );
  };

  const isUpdateUdyamKycMismatch = () => {
    return (
      value.udyamNumber &&
      (value.udyamNumber !== defaultValue.entityDetails.udyamRegNo ||
        value.entityName !== defaultValue.entityDetails.entityName) &&
      !defaultValue.entityDetails.udyamRegNoVerified
    );
  };

  const isUdpateCreditKycMismatch = () => {
    return (
      value.panNumber &&
      !defaultValue.entityDetails.panVerified &&
      consent.panVerified &&
      !consent.creditReport
    );
  };

  if (!isUpdate) {
    switch (true) {
      case isPanKycMismatch():
        setConsent({ ...data, stage: 'panNumber', agree: !consent.agree });
        return;
      case isUdyamKycMismatch():
        setConsent({ ...data, stage: 'udyamNumber', agree: !consent.agree });
        return;
      case isCreditKycMismatch():
        setConsent({ ...data, stage: '', agree: !consent.agree });
        return;
      default:
        break;
    }
  } else {
    switch (true) {
      case isUpdatePanKycMismatch() && isPanKycMismatch():
        setConsent({ ...data, stage: 'panNumber', agree: !consent.agree });
        return;
      case isUpdateUdyamKycMismatch() && isUdyamKycMismatch():
        setConsent({ ...data, stage: 'udyamNumber', agree: !consent.agree });
        return;
      case isUdpateCreditKycMismatch():
        setConsent({ ...data, stage: '', agree: !consent.agree });
        return;
      default:
        break;
    }
  }

  const response = await onSubmit({
    ...value,
    incorporationDate: value.incorporationDate?.$d
      ? dayjs(value.incorporationDate.$d)
      : value.incorporationDate,
    mobileNo: mobileIsdCode + value.mobileNo,
    contactNo: contactIsdCode + value.contactNo,
    panVerified: consent.panVerified,
    udyamRegNoVerified: consent.udyamRegNoVerified
  });
  if (response) {
    setConsent({ ...consentInit });
  }
};

export const onBankSubmit = async ({
  value,
  consent,
  setConsent,
  defaultValue,
  isUpdate,
  onSubmit,
  resetForm
}) => {
  let data = {
    ...consent,
    formData: { ...value }
  };

  const isBankAccMismatch = () => {
    return (
      (value.accountNo && !consent.bankAcckyc) ||
      (consent.bankAcckyc && value.accountNo !== consent.bankAccNo)
    );
  };

  const isUpdateBankAccMismatch = () => {
    return (
      value.accountNo &&
      value.accountNo !== defaultValue.accountNo &&
      !defaultValue.bankAccVerified &&
      !consent.bankAccKyc
    );
  };

  if (!isUpdate) {
    switch (true) {
      case isBankAccMismatch():
        setConsent({ ...data, stage: 'accountNo', agree: !consent.agree });
        return;
      default:
        break;
    }
  } else {
    switch (true) {
      case isUpdateBankAccMismatch() && isBankAccMismatch():
        setConsent({ ...data, stage: 'accountNo', agree: !consent.agree });
        return;
      default:
        break;
    }
  }

  const response = await onSubmit({
    ...value,
    bankAccVerified: consent.bankAccVerified
  });

  if (response && resetForm) {
    setConsent({ ...consentInit });
    resetForm();
  }
};

export const onPromoterSubmit = async ({
  value,
  consent,
  setConsent,
  defaultValue,
  isUpdate,
  onSubmit,
  isdCode,
  resetForm
}) => {
  let data = {
    ...consent,
    formData: { ...value, contactNo: isdCode + value.contactNo }
  };

  const isPanKycMismatch = () => {
    const date1 = value.dobOrDateOfIncorporation?.$d
      ? dayjs(value.dobOrDateOfIncorporation.$d).format('YYYY-MM-DD')
      : value.dobOrDateOfIncorporation.format('YYYY-MM-DD');

    const date2 = consent.dob?.$d
      ? dayjs(consent.dob.$d).format('YYYY-MM-DD')
      : consent.dob?.format('YYYY-MM-DD');

    return (
      (value.panNumber && !consent.panKyc) ||
      (consent.panKyc &&
        (value.panNumber !== consent.panNumber ||
          date1 !== date2 ||
          value.nameOrentityName !== consent.entityName))
    );
  };

  const isAadhaarMismatch = () => {
    return (
      value.promoterType === 'INDIVIDUAL' &&
      ((value.adhaarOrRegistrationNumber && !consent.aadhaarKyc) ||
        (consent.aadhaarKyc &&
          (value.adhaarOrRegistrationNumber !== consent.aadhaarNo ||
            value.nameOrentityName !== consent.entityName)))
    );
  };

  const isCreditKycMismatch = () => {
    return value.panNumber && consent.panVerified && !consent.creditReport;
  };

  const isUpdatePanKycMismatch = () => {
    const date1 = value.dobOrDateOfIncorporation?.$d
      ? dayjs(value.dobOrDateOfIncorporation.$d).format('YYYY-MM-DD')
      : value.dobOrDateOfIncorporation.format('YYYY-MM-DD');

    const date2 = dayjs(defaultValue.dobOrDoi).format('YYYY-MM-DD');

    return (
      value.panNumber &&
      (value.panNumber !== defaultValue.pan ||
        date1 !== date2 ||
        value.nameOrentityName !== defaultValue.nameOrEntityName) &&
      !defaultValue.panVerified
    );
  };

  const isUdpateAadhaarMismatch = () => {
    return (
      value.promoterType === 'INDIVIDUAL' &&
      value.adhaarOrRegistrationNumber &&
      (value.adhaarOrRegistrationNumber !== defaultValue.aadhaarOrRegNo ||
        value.nameOrentityName !== defaultValue.nameOrEntityName) &&
      !defaultValue.aadhaarVerified
    );
  };

  const isUdpateCreditKycMismatch = () => {
    return (
      value.panNumber && !defaultValue.panVerified && consent.panVerified && !consent.creditReport
    );
  };

  if (!isUpdate) {
    switch (true) {
      case isPanKycMismatch():
        setConsent({ ...data, stage: 'panNumber', agree: !consent.agree });
        return;
      case isAadhaarMismatch():
        setConsent({ ...data, stage: 'adhaarNumber', agree: !consent.agree });
        return;
      case isCreditKycMismatch():
        setConsent({ ...data, stage: '', agree: !consent.agree });
        return;
      default:
        break;
    }
  } else {
    switch (true) {
      case isUpdatePanKycMismatch() && isPanKycMismatch():
        setConsent({ ...data, stage: 'panNumber', agree: !consent.agree });
        return;
      case isUdpateAadhaarMismatch() && isAadhaarMismatch():
        setConsent({ ...data, stage: 'adhaarNumber', agree: !consent.agree });
        return;
      case isUdpateCreditKycMismatch():
        setConsent({ ...data, stage: '', agree: !consent.agree });
        return;
      default:
        break;
    }
  }

  const response = await onSubmit({
    ...value,
    dobOrDateOfIncorporation: value.dobOrDateOfIncorporation?.$d
      ? dayjs(value.dobOrDateOfIncorporation.$d)
      : value.dobOrDateOfIncorporation,
    contactNo: isdCode + value.contactNo,
    panVerified: consent.panVerified,
    aadhaarVerified: consent.aadhaarVerified
  });

  if (response && resetForm) {
    setTimeout(() => {
      resetForm();
      setConsent({ ...consentInit });
    }, 500);
  }
};
