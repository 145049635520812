import React from 'react';
import TextInput from '../../component/antdComponent/Input';
import useFetch from '../../hooks/useFetch';
import IsdCode from '../../component/antdComponent/Mobile';
import PasswordInput from '../../component/antdComponent/Password';
import { useNavigate } from 'react-router-dom';
import { tw } from 'twind';
import { Button, Card, Form, Typography, Row, Col, Spin } from 'antd';
import { RULES } from '../../utils/formValidations';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { showPassword } from '../../services/auth';
import { getProfile } from '../../services/user';
import { getURL } from '../../configs/apiURL';
import { notifications } from '../../utils/notifications';
import { message } from '../../utils/messages';
import { getIsdCode, getMobileNo } from '../../utils/helpers';

const { Text } = Typography;

const ProfileComponent = () => {
  const navigate = useNavigate();

  const { profile = {} } = useSelector(state => ({
    profile: state.user.profile
  }));

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isdCode, setIsdCode] = useState(getIsdCode(profile.mobileNo));
  const [updateProfile] = useFetch();

  const userId = localStorage.getItem('userId');

  const initialValue = useMemo(
    () => ({
      name: `${profile.firstName || ""} ${profile.lastName || ""}`,
      emailId: profile.emailId,
      mobileNo: getMobileNo(profile.mobileNo)
    }),
    [profile]
  );

  const onSubmit = async value => {
    const name = value.name.split(' ');
    const firstName = name[0] ? name[0] : '';
    const lastName = name[1] ? name[1] : '';

    const _data = {
      ...value,
      firstName: firstName,
      lastName: lastName,
      mobileNo: isdCode + value.mobileNo
    };
    const res = await updateProfile(
      getURL(`platform/users/profile
          `),
      {
        method: 'PUT',
        body: JSON.stringify(_data)
      }
    );

    if (res && res.status === 200) {
      notifications.success({ message: message.PROFILE_UPDATED });
      getProfile();
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
  };

  const showUserPassword = async id => {
    setLoading(true);
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      showUserPassword(userId);
    }
  }, [initialValue]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={loading} />
      </div>
    );
  }

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Text style={{ fontSize: '20px', fontWeight: '600px' }}>My Profile</Text>
      </div>
      <div className='mb-5 w-full font-grey'>
        <Button
          type='default'
          style={{ borderRadius: '4px', padding: '5px' }}
          onClick={() => navigate(-1)}
        >
          <MdOutlineArrowBack size='20px' />
        </Button>
      </div>
      <Form
        form={form}
        size='large'
        initialValues={initialValue}
        onFinish={onSubmit}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        onValuesChange={() => form.validateFields(['repassword'])}
        autoComplete='off'
        style={{
          width: '100%'
        }}
      >
        <Card title='User Details' style={{ width: '100%', marginTop: '20px' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='name' rules={RULES.name}>
                <TextInput label='Admin Name' placeholder='Enter Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name='mobileNo'
                rules={RULES.mobileNo}
                style={{ position: 'relative', bottom: 7 }}
              >
                <TextInput
                  label='Mobile No'
                  placeholder='Enter Number'
                  required
                  addonBefore={<IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='emailId' rules={RULES.email}>
                <TextInput label='Email' placeholder='Enter Email' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='password' rules={RULES.password} hasFeedback>
                <PasswordInput label='Password' placeholder='Enter Password' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                <PasswordInput label='Re-Password' placeholder='Re-enter Password' required />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' size='middle' htmlType='submit'>
            Update
          </Button>
        </Card>
      </Form>
    </div>
  );
};

export default ProfileComponent;
