import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { tw } from 'twind';
import { FaRegAddressCard } from 'react-icons/fa';
import { getPanKycDetails, getAadhaarKycDetails } from '../../../services/buyerSeller';
import { useSelector } from 'react-redux';
import { PromoterCardViewModal } from './promoterCard';

export const PromoterKycViewModal = ({ data }) => {
  const {
    editBuyerSeller,
    panKycDetails = {},
    loadingPanKycDetails,
    aadhaarKycDetails = {},
    loadingAadhaarKycDetails
  } = useSelector(state => ({
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    panKycDetails: state.buyerSeller.panKycDetails,
    loadingPanKycDetails: state.buyerSeller.loadingPanKycDetails,
    aadhaarKycDetails: state.buyerSeller.aadhaarKycDetails,
    loadingAadhaarKycDetails: state.buyerSeller.loadingAadhaarKycDetails
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { panApiProvider = '', aadhaarApiProvider = '' } = editBuyerSeller.data.apiProviders || {};

  useEffect(() => {
    if (isModalVisible) {
      if (data.panVerified) getPanKycDetails(panApiProvider, data.pan);
      if (data.aadhaarVerified) getAadhaarKycDetails(aadhaarApiProvider, data.aadhaarOrRegNo);
    }
  }, [panApiProvider, aadhaarApiProvider, isModalVisible]);

  return (
    <Fragment>
      <div className={tw`flex justify-center`}>
        <Button
          style={{ borderRadius: '8px', padding: '0px 5px 0px 5px' }}
          onClick={() => setIsModalVisible(true)}
        >
          <FaRegAddressCard size='20px' color='black' />
        </Button>
      </div>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <Button
            key='back'
            onClick={() => setIsModalVisible(false)}
            type='primary'
            className={tw`ml-auto`}
          >
            Close
          </Button>
        }
        centered
      >
        {(loadingPanKycDetails || loadingAadhaarKycDetails) && (
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <Spin spinning={loadingPanKycDetails || loadingAadhaarKycDetails} />
          </div>
        )}
        {!loadingPanKycDetails && !loadingAadhaarKycDetails && (
          <PromoterCardViewModal
            promoterData={data}
            panKycData={panKycDetails}
            aadhaarKycData={aadhaarKycDetails}
          />
        )}
      </Modal>
    </Fragment>
  );
};
