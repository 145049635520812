import React from 'react';
import dayjs from 'dayjs';
import BuyerSellerPromoterConsent from '../../buyerSellerConsent/promoterConsent';
import TextInput from '../../antdComponent/Input';
import SelectComponent from '../../antdComponent/Select';
import DateComponent from '../../antdComponent/Date';
import IsdCode from '../../antdComponent/Mobile';
import TableComponent from '../../antdComponent/Table';
import { PROMOTER_TYPES } from '../../../utils/helpers';
import { onPromoterSubmit } from '../../../utils/handlers';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { Form, Card, Button, Row, Col } from 'antd';
import { promoterColumns } from '../columns/promoters';
import { tw } from 'twind';
import { RULES } from '../../../utils/formValidations';
import { getBuyerSellerPromoters, getPinData } from '../../../services/buyerSeller';
import { getIsdCode, getMobileNo } from '../../../utils/helpers';

const BuyerSellerPromotersForm = ({
  editPromoters,
  consent,
  setConsent,
  tableData,
  loading,
  onSubmit,
  onSubmitLoader,
  currentTab,
  setCurrentTab,
  pinCodeData,
  pinCodeDataloading,
  editBuyerSellerData,
  params,
  setActiveTabs,
  isAllDetailsPending
}) => {
  const [form] = Form.useForm();
  const [pinValue, setPinValue] = useState('');
  const [isdCode, setIsdCode] = useState(getIsdCode(editPromoters?.data?.contactNo));
  const [labels, setLabels] = useState({ ...PROMOTER_TYPES.default });

  const initialValue = useMemo(
    () =>
      Object.keys(editPromoters.data).length !== 0
        ? {
            promoterType: editPromoters.data.promoterType,
            nameOrentityName: editPromoters.data.nameOrEntityName,
            gender: editPromoters.data.gender,
            dobOrDateOfIncorporation: dayjs(editPromoters.data.dobOrDoi),
            adhaarOrRegistrationNumber: editPromoters.data.aadhaarOrRegNo,
            dinCin: editPromoters.data.dinOrCinNo,
            panNumber: editPromoters.data.pan,
            shareholding: editPromoters.data.sharePercentage,
            email: editPromoters.data.emailId,
            contactNo: getMobileNo(editPromoters.data.contactNo),
            addressLine1: editPromoters.data.addressLineOne,
            addressLine2: editPromoters.data.addressLineTwo,
            pinNo: editPromoters.data.pinCode,
            state: editPromoters.data.state,
            district: editPromoters.data.district,
            subDist: editPromoters.data.subDistrict,
            postOffice: editPromoters.data.postOffice
          }
        : {},
    [editPromoters]
  );

  const handleSubmit = async value => {
    onPromoterSubmit({
      value,
      consent,
      setConsent,
      onSubmit,
      isdCode,
      isUpdate: editPromoters.openEdit,
      defaultValue: editPromoters.data,
      resetForm: () => form.resetFields()
    });
  };

  const handlePromoterChange = id => {
    let newLabels;
    switch (id) {
      case 'INDIVIDUAL':
        newLabels = PROMOTER_TYPES.individual;
        break;
      case 'ENTITY':
        newLabels = PROMOTER_TYPES.entity;
        break;
      default:
        newLabels = PROMOTER_TYPES.default;
        break;
    }
    setLabels(newLabels);
  };

  useEffect(() => {
    if (pinValue === pinCodeData?.Pincode && !pinCodeDataloading) {
      form.setFieldsValue({
        state: pinCodeData.State,
        district: pinCodeData.District,
        subDist: pinCodeData.Block,
        postOffice: pinCodeData.Name
      });
    }
  }, [pinCodeData]);

  useEffect(() => {
    form.setFieldsValue(initialValue);
    setIsdCode(getIsdCode(editPromoters.data.contactNo));
  }, [initialValue]);

  useEffect(() => {
    if (currentTab === '4') {
      getBuyerSellerPromoters(editBuyerSellerData.entityDetails.id, params);
    }
  }, [params]);

  useEffect(() => {
    if (pinValue.length === 6) {
      getPinData(pinValue);
    }
  }, [pinValue]);

  return (
    <div className={tw`mb-5`}>
      {consent.agree && (
        <BuyerSellerPromoterConsent
          isUpdate={editPromoters.openEdit}
          onSubmit={onSubmit}
          consent={{ ...consent, formInitial: editPromoters.data }}
          setConsent={data => setConsent(consent => ({ ...consent, ...data }))}
          resetForm={() => form.resetFields()}
        />
      )}
      <Form
        form={form}
        size='large'
        initialValues={initialValue || {}}
        onFinish={handleSubmit}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title='Promoter Details'>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='promoterType' rules={RULES.selectRequired}>
                <SelectComponent
                  label='Promoter Type'
                  placeholder='Promoter Type'
                  required
                  onChange={handlePromoterChange}
                  allowClear={true}
                  options={[
                    {
                      label: 'Individual',
                      value: 'INDIVIDUAL'
                    },
                    {
                      label: 'Entity',
                      value: 'ENTITY'
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='nameOrentityName' rules={RULES.name}>
                <TextInput
                  required
                  label={labels.nameOrentityName.name}
                  placeholder={labels.nameOrentityName.placeholder}
                  disabled={editPromoters.data.panVerified || consent.panVerified}
                  suffix={
                    editPromoters.data.panVerified || consent.panVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      editPromoters.openEdit && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            {labels.gender && (
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='gender' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='Gender'
                    placeholder='Gender'
                    required
                    allowClear={true}
                    options={[
                      { label: 'Male', value: 'MALE' },
                      {
                        label: 'Female',
                        value: 'FEMALE'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='dobOrDateOfIncorporation' rules={RULES.date}>
                <DateComponent
                  required
                  label={labels.dobOrDateOfIncorporation.name}
                  placeholder={labels.dobOrDateOfIncorporation.placeholder}
                  disabled={editPromoters.data.panVerified || consent.panVerified}
                  disabledDate={current => current.isAfter(dayjs().subtract(1, 'day'))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='adhaarOrRegistrationNumber' rules={RULES.numberString}>
                <TextInput
                  label={labels.adhaarOrRegistrationNumber.name}
                  placeholder={labels.adhaarOrRegistrationNumber.placeholder}
                  disabled={editPromoters.data.aadhaarVerified || consent.aadhaarVerified}
                  required
                  maxLength='12'
                  suffix={
                    editPromoters.data.aadhaarVerified || consent.aadhaarVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      editPromoters.openEdit && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='dinCin' rules={RULES.dinCin}>
                <TextInput
                  label={labels.dinCin.name}
                  placeholder={labels.dinCin.placeholder}
                  maxLength='21'
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='panNumber' rules={RULES.panNumber}>
                <TextInput
                  onChange={e => form.setFieldValue('panNumber', e.target.value.toUpperCase())}
                  disabled={editPromoters.data.panVerified || consent.panVerified}
                  label='PAN Number'
                  placeholder='Enter PAN'
                  maxLength={10}
                  suffix={
                    editPromoters.data.panVerified || consent.panVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      editPromoters.openEdit && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='shareholding' rules={RULES.number}>
                <TextInput label='Share holding %' placeholder='Enter Percentage' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='email' rules={RULES.email}>
                <TextInput label='Email' placeholder='Enter Email' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='contactNo' rules={RULES.mobileNo}>
                <TextInput
                  label='Contact Number'
                  placeholder='Enter Number'
                  addonBefore={<IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />}
                  required
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='addressLine1' rules={RULES.address}>
                <TextInput label='Address line 1' placeholder='Address line 1' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='addressLine2' rules={RULES.optionalAddress}>
                <TextInput label='Address line 2' placeholder='Address line 2' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='pinNo' rules={RULES.pinNo}>
                <TextInput
                  label='PIN Code'
                  placeholder='Enter PIN'
                  required
                  maxLength='6'
                  onChange={event => {
                    if (event.target.value.length === 6) {
                      setPinValue(event.target.value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='state' rules={RULES.numberString}>
                <TextInput label='State' placeholder='State' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='district' rules={RULES.numberString}>
                <TextInput label='District' placeholder='District' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='subDist' rules={RULES.numberString}>
                <TextInput label='Sub-District' placeholder='Sub-District' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='postOffice' rules={RULES.numberString}>
                <TextInput
                  label='Post-Office'
                  placeholder='Post-Office'
                  style={{ width: '200px' }}
                  required
                />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' size='middle' htmlType='submit' loading={onSubmitLoader}>
            {editPromoters.openEdit ? 'Update' : 'Add'}
          </Button>
        </Card>
      </Form>
      <br />
      <TableComponent columns={promoterColumns} data={tableData} loading={loading} />
      <Button
        className={tw`mt-${tableData.count ? '0' : '5'}`}
        type='primary'
        onClick={() => {
          setCurrentTab(editBuyerSellerData.entityDetails.approved ? '5' : '6');
          setActiveTabs(editBuyerSellerData.entityDetails.approved ? '5' : '6');
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default BuyerSellerPromotersForm;
