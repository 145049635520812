import dayjs from 'dayjs';
import { useState } from 'react';
import { tw } from 'twind';
import { Typography, Button, Modal, Table } from 'antd';

const { Text } = Typography;

export const TransactionViewModal = ({ label, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const factoredViewData = [
    {
      key1: 'Factored Amount',
      value1: data.factoredAmount,
      key2: 'Disbursement Amount',
      value2: data.disbursementAmount
    },
    {
      key1: 'Factored Date',
      value1: dayjs(data.factoredDate).format('DD-MM-YYYY'),
      key2: 'Disbursement Date',
      value2: dayjs(data.disbursementDate).format('DD-MM-YYYY')
    },
    {
      key1: 'ROI',
      value1: data.rateOfInterest,
      key2: 'Collected Amount',
      value2: data.collectedAmount
    },
    {
      key1: 'Fees',
      value1: data.fees,
      key2: 'Accrued Interest',
      value2: data.accruedInterest
    }
  ];

  const TransactionViewData = [
    {
      key1: 'Collected From',
      value1: data.collectFrom,
      key2: 'Transaction Amount',
      value2: data.transactionAmount
    },
    {
      key1: 'Transaction Date',
      value1: dayjs(data.dateOfTransaction).format('DD-MM-YYYY'),
      key2: 'Reference No',
      value2: data.referenceNo
    },
    {
      key1: 'Narration',
      value1: data.narration,
      key2: 'Remaining Amount',
      value2: data.remainingAmount
    }
  ];

  const Columns = [
    {
      key: 'key1',
      dataIndex: 'key1',
      render: (_, record) => <Text style={{ fontWeight: 600 }}>{record.key1}</Text>
    },
    {
      dataIndex: 'value1',
      key: 'value1',
      render: (_, record) => <Text>{record.value1}</Text>
    },
    {
      dataIndex: 'key2',
      key: 'key2',
      render: (_, record) => <Text style={{ fontWeight: 600 }}>{record.key2}</Text>
    },
    {
      dataIndex: 'value2',
      key: 'value2',
      render: (_, record) => <Text>{record.value2}</Text>
    }
  ];

  return (
    <div className={tw`flex flex-col`}>
      <Button
        style={{ borderRadius: '8px', padding: '4px' }}
        onClick={() => setIsModalVisible(true)}
      >
        {label}
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={null}
      >
        <h3 className={tw`text-lg font-medium`}>Transaction Details</h3>
        <div className={tw`table-responsive mt-4`}>
          <Table
            columns={Columns}
            dataSource={factoredViewData}
            rowKey='key'
            bordered
            loading={false}
            showHeader={false}
            pagination={false}
          />
        </div>
        <div className={tw`table-responsive mt-6`}>
          <Table
            columns={Columns}
            dataSource={TransactionViewData}
            rowKey='key'
            bordered
            loading={false}
            showHeader={false}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};
