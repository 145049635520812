import React from 'react';
import TextInput from '../../antdComponent/Input';
import BuyerSellerBankConsent from '../../buyerSellerConsent/bankConsent';
import useFetch from '../../../hooks/useFetch';
import SelectComponent from '../../antdComponent/Select';
import TableComponent from '../../antdComponent/Table';
import { Form, Card, Button, Checkbox, Row, Col } from 'antd';
import { useEffect, useMemo } from 'react';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { tw } from 'twind';
import { bankColumns } from '../columns/banks';
import { ACTION_TYPES } from '../../../utils/helpers';
import { getURL } from '../../../configs/apiURL';
import { RULES } from '../../../utils/formValidations';
import { getBuyerSellerBanks } from '../../../services/buyerSeller';
import { useState } from 'react';
import { getIfscData } from '../../../services/buyerSeller';
import { onBankSubmit } from '../../../utils/handlers';

const BuyerSellerBankForm = ({
  editBanks,
  consent,
  setConsent,
  tableData,
  loading,
  onSubmit,
  onSubmitLoader,
  currentTab,
  setCurrentTab,
  ifscBanksData,
  ifscDataloading,
  editBuyerSellerData,
  params,
  state,
  dispatch,
  setActiveTabs,
  isAllDetailsPending
}) => {
  const [form] = Form.useForm();
  const [ifscFieldValue, setIfscFieldValue] = useState('');
  const [fetch] = useFetch();

  const initialValue = useMemo(() => {
    return Object.keys(editBanks.data).length !== 0
      ? {
          accountTypeId: editBanks.data.bankAccountType.id,
          accountTitle: editBanks.data.accountTitle,
          accountNo: editBanks.data.accountNo,
          ifscCode: editBanks.data.ifsc,
          bankName: editBanks.data.bankName,
          branchName: editBanks.data.branchName,
          micrCode: editBanks.data.micrCode,
          swiftCode: editBanks.data.swiftCode,
          isDefault: editBanks.data.isDefault === 1 ? true : false
        }
      : {};
  }, [editBanks]);

  const onIfscChangeHandler = value => {
    if (value.length === 11) {
      setIfscFieldValue(value);
    }
  };

  const getDropDown = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      const url = new URL(getURL('bank-account-types'));
      url.searchParams.set('active', 1);
      const res = await fetch(url);
      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: {
          bankDropdown: res.data.data.map(row => ({
            value: row.id,
            label: row.name
          }))
        }
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  const onSubmitHandler = async value => {
    onBankSubmit({
      value,
      consent,
      setConsent,
      onSubmit,
      isUpdate: editBanks.openEdit,
      defaultValue: editBanks.data,
      resetForm: () => form.resetFields()
    });
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (currentTab === '3') {
      getBuyerSellerBanks(editBuyerSellerData.entityDetails.id, params);
    }
  }, [params]);

  useEffect(() => {
    if (ifscFieldValue.length === 11) {
      getIfscData(ifscFieldValue);
    }
  }, [ifscFieldValue]);

  useEffect(() => {
    if (ifscFieldValue === ifscBanksData?.IFSC && !ifscDataloading) {
      form.setFieldsValue({
        bankName: ifscBanksData.BANK,
        branchName: ifscBanksData.BRANCH,
        micrCode: ifscBanksData.MICR,
        swiftCode: ifscBanksData.SWIFT
      });
    }
  }, [ifscBanksData]);

  useEffect(() => {
    getDropDown();
  }, []);

  return (
    <div className={tw`mb-5`}>
      {consent.agree && (
        <BuyerSellerBankConsent
          isUpdate={editBanks.openEdit}
          onSubmit={onSubmit}
          consent={{ ...consent, formInitial: editBanks.data }}
          setConsent={data => setConsent(consent => ({ ...consent, ...data }))}
          editBuyerSellerData={editBuyerSellerData}
          resetForm={() => form.resetFields()}
        />
      )}
      <Form
        form={form}
        size='large'
        initialValues={initialValue || {}}
        onFinish={onSubmitHandler}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title='Bank Details'>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountTypeId' rules={RULES.selectRequired}>
                <SelectComponent
                  label='Account Type'
                  placeholder='Select'
                  required
                  allowClear={true}
                  options={state.dropDownData.bankDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountTitle' rules={[]}>
                <TextInput label='Account Title' placeholder='Account Title' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountNo' rules={RULES.accountNo}>
                <TextInput
                  label='Account Number'
                  placeholder='Account Number'
                  required
                  disabled={editBanks.data.bankAccVerified || consent.bankAccVerified}
                  suffix={
                    editBanks.data.bankAccVerified || consent.bankAccVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      editBanks.openEdit && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='ifscCode' rules={RULES.ifsc}>
                <TextInput
                  onChange={event => {
                    onIfscChangeHandler(event.target.value);
                  }}
                  label='IFSC'
                  placeholder='IFSC'
                  required
                  maxLength={11}
                  uppercase
                  disabled={editBanks.data.bankAccVerified || consent.bankAccVerified}
                  suffix={
                    editBanks.data.bankAccVerified || consent.bankAccVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      editBanks.openEdit && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='bankName' rules={RULES.inputRequired}>
                <TextInput label='Bank Name' placeholder='Bank Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='branchName' rules={RULES.inputRequired}>
                <TextInput label='Branch Name' placeholder='Branch Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='micrCode' rules={RULES.micrCode}>
                <TextInput label='MICR Code' placeholder='MICR Code' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='swiftCode' rules={RULES.swiftCode}>
                <TextInput label='SWIFT Code' placeholder='SWIFT Code' uppercase />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='isDefault' valuePropName='checked'>
                <Checkbox className='mlv5'>Mark as default account</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' size='middle' htmlType='submit' loading={onSubmitLoader}>
            {editBanks.openEdit ? 'Update' : 'Add'}
          </Button>
        </Card>
      </Form>
      <br />
      <TableComponent columns={bankColumns} data={tableData} loading={loading} />
      <Button
        className={tw`mt-${tableData.count ? '0' : '5'}`}
        type='primary'
        disabled={!tableData.count && isAllDetailsPending}
        onClick={() => {
          setCurrentTab('4');
          setActiveTabs('4');
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default BuyerSellerBankForm;
