import React from 'react';
import useFetch from '../../hooks/useFetch';
import FinancierUserProfileForms from './financierUserProfileForms';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getURL } from '../../configs/apiURL';
import { tw } from 'twind';
import { notifications } from '../../utils/notifications';
import { message } from '../../utils/messages';
import { getFinancierProfile } from '../../services/financier';
import { Spin } from 'antd';

const FinancierUserProfile = () => {
  const { financierProfile = {}, loadingFinancierProfile } = useSelector(state => ({
    financierProfile: state.financier.financierProfile,
    loadingFinancierProfile: state.financier.loadingFinancierProfile
  }));

  const [fetch, loading] = useFetch();

  const onSubmit = async value => {
    const name = value.adminName.split(' ');
    const firstName = name[0] ? name[0] : '';
    const lastName = name[1] ? name[1] : '';

    const data = {
      entityDetails: {
        entityName: value.entityName,
        dateOfIncorporation: value.incorporationDate || null,
        pan: value.panNumber,
        registrationNo: value.registrationNumber,
        udyamRegNo: value.udyamNumber || null
      },
      adminDetails: {
        id: financierProfile.data.adminDetails.id,
        firstName: firstName,
        lastName: lastName,
        emailId: value.email,
        mobileNo: value.mobileNo,
        password: value.password
      }
    };

    const res = await fetch(getURL(`financiers/profile`), {
      method: 'PUT',
      body: JSON.stringify(data)
    });
    if (res && res.status === 200) {
      notifications.success({ message: message.PROFILE_UPDATED });
      getFinancierProfile();
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
  };

  useEffect(() => {
    getFinancierProfile();
  }, []);

  if (loadingFinancierProfile) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={loading} />
      </div>
    );
  }

  return (
    <div className={tw`content-divider`}>
      <FinancierUserProfileForms
        onSubmit={onSubmit}
        onSubmitLoader={loading}
        defaultValue={financierProfile?.data || {}}
      />
    </div>
  );
};

export default FinancierUserProfile;
