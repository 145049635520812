import DeletePopup from '../../deletePopUp';
import { Space, Divider } from 'antd';
import { Typography } from 'antd';
import { tw } from 'twind';
import { ModalTextFilter } from '../../../utils/formFilters';
import { GoLinkExternal } from 'react-icons/go';
import { Link } from 'react-router-dom';

const { Text } = Typography;

export const documentColumns = [
  {
    title: <div className={tw`text-center flex flex-col items-center`}>#</div>,
    key: 'documentNo',
    render: (_, record, index) => {
      return <Text>{index}</Text>;
    }
  },
  {
    title: (
      <div className={tw`text-center flex flex-col items-center`}>
        <Text>Document Title</Text>
        <Divider />
        <ModalTextFilter type='title' />
      </div>
    ),
    dataIndex: 'documentTitle',
    key: 'documentTitle',
    render: (_, record) => {
      return <Text>{record.title}</Text>;
    }
  },

  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => (
      <div className={tw`flex justify-center`}>
        <Space size='middle'>
          <Link
            target={'_blank'}
            to={{
              pathname: `/buyer-seller/${record.entityId}/documents/${record.id}/signed-url`,
              search: '?action=VIEW&expiresIn=300'
            }}
          >
            <GoLinkExternal size={24} color='#228be6' />
          </Link>
          <DeletePopup
            type='document'
            id={record.id}
            entityId={record.entityId}
            data={record.title}
          />
        </Space>
      </div>
    )
  }
];
