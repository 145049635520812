import { Select, DatePicker } from 'antd';
import { useState, useCallback, useMemo } from 'react';
import { tw } from 'twind';
import { mutateParams, mutateModalParams } from '../redux/mutations/actions';
import { generateFilterQuery } from './generateQuery';
import Input from 'antd/es/input';
import dayjs from 'dayjs';

let timeOutId;

/**
 * @type params
 */

export const TextFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('params'));

  const [value, setValue] = useState(params.get(type) || '');

  const handleSearch = value => {
    let obj = {};
    obj[type] = value;
    obj['offset'] = 0;
    const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
    localStorage.setItem('params', searchQuery);
    mutateParams(searchQuery);
  };

  const debounce = (func, delay) => {
    return (...args) => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
      timeOutId = setTimeout(() => func.apply(null, args), delay);
    };
  };

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  const onChange = e => {
    let value;
    switch (type) {
      case 'pan':
        value = e.target.value.toUpperCase();
        break;
      default:
        value = e.target.value;
        break;
    }
    setValue(value);
    debounceSearch(value);
  };

  return (
    <div className={tw`w-full flex justify-center`}>
      <Input value={value} allowClear={true} placeholder='Search' onChange={onChange} />
    </div>
  );
};

export const DateFilter = ({ type }) => {
  const { RangePicker } = DatePicker;

  const params = new URLSearchParams(localStorage.getItem('params'));

  const date = useMemo(() => (params.get(type) ? params.get(type).split(',') : null), []);

  return (
    <div className={tw`w-full flex justify-center`}>
      <RangePicker
        defaultValue={date ? [dayjs(date[0], 'YYYY/MM/DD'), dayjs(date[1], 'YYYY/MM/DD')] : null}
        placeholder='Search'
        allowClear={true}
        onChange={value => {
          console.log(value);
          const date = value
            ? `${value[0].format('YYYY-MM-DD')},${value[1].format('YYYY-MM-DD')}`
            : '';
          let obj = {};
          obj[type] = date;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
          localStorage.setItem('params', searchQuery);
          mutateParams(searchQuery);
        }}
      />
    </div>
  );
};

export const UserTypeFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('params'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
          localStorage.setItem('params', searchQuery);
          mutateParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Admin', value: 'ADMIN' },
          { label: 'Operation Manager', value: 'OPERATION_MANAGER' }
        ]}
      />
    </div>
  );
};

export const StatusFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('params'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
          localStorage.setItem('params', searchQuery);
          mutateParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Active', value: '1' },
          { label: 'Inactive', value: '0' }
        ]}
      />
    </div>
  );
};

export const EntityFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('params'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
          localStorage.setItem('params', searchQuery);
          mutateParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Buyer', value: 'BUYER' },
          { label: 'Seller', value: 'SELLER' }
        ]}
      />
    </div>
  );
};

export const BSLinkCostBearer = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('params'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('params'));
          localStorage.setItem('params', searchQuery);
          mutateParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Buyer', value: 'BUYER' },
          { label: 'Seller', value: 'SELLER' },
          { label: 'Percentage Split', value: 'PERCENTAGE_SPLIT' },
          { label: 'Periodic Split', value: 'PERIODIC_SPLIT' }
        ]}
      />
    </div>
  );
};

/**
 * @type modal-params
 */

export const ModalTextFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('modalParams'));

  const [value, setValue] = useState(params.get(type) || '');

  const handleSearch = value => {
    let obj = {};
    obj[type] = value;
    obj['offset'] = 0;
    const searchQuery = generateFilterQuery(obj, localStorage.getItem('modalParams'));
    localStorage.setItem('modalParams', searchQuery);
    mutateModalParams(searchQuery);
  };

  const debounce = (func, delay) => {
    return (...args) => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
      timeOutId = setTimeout(() => func.apply(null, args), delay);
    };
  };

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  const onChange = e => {
    let value;
    switch (type) {
      case 'pan':
        value = e.target.value.toUpperCase();
        break;
      default:
        value = e.target.value;
        break;
    }
    setValue(value);
    debounceSearch(value);
  };

  return (
    <div className={tw`w-full flex justify-center`}>
      <Input value={value} placeholder='Search' allowClear={true} onChange={onChange} />
    </div>
  );
};

export const ModalDateFilter = ({ type }) => {
  const { RangePicker } = DatePicker;

  const params = new URLSearchParams(localStorage.getItem('modalParams'));

  const date = useMemo(() => (params.get(type) ? params.get(type).split(',') : null), []);

  return (
    <div className={tw`w-full flex justify-center`}>
      <RangePicker
        defaultValue={date ? [dayjs(date[0], 'YYYY/MM/DD'), dayjs(date[1], 'YYYY/MM/DD')] : null}
        placeholder='Search'
        allowClear={true}
        onChange={value => {
          const date = value
            ? `${value[0].format('YYYY-MM-DD')},${value[1].format('YYYY-MM-DD')}`
            : '';
          let obj = {};
          obj[type] = date;
          obj['offset'] = 0;
          console.log(localStorage.getItem('modalParams'));
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('modalParams'));
          localStorage.setItem('modalParams', searchQuery);
          mutateModalParams(searchQuery);
        }}
      />
    </div>
  );
};

export const ModalEntityFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('modalParams'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        defaultValue={''}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('modalParams'));
          localStorage.setItem('modalParams', searchQuery);
          mutateModalParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Buyer', value: 'BUYER' },
          { label: 'Seller', value: 'SELLER' }
        ]}
      />
    </div>
  );
};

export const ModalPaymentStatusFilter = ({ type }) => {
  const params = new URLSearchParams(localStorage.getItem('modalParams'));

  const [value, setValue] = useState(params.get(type) || '');

  return (
    <div className={tw`w-full flex justify-center`}>
      <Select
        value={value}
        className={tw`w-full`}
        defaultValue={''}
        onChange={value => {
          let obj = {};
          obj[type] = value;
          obj['offset'] = 0;
          const searchQuery = generateFilterQuery(obj, localStorage.getItem('modalParams'));
          localStorage.setItem('modalParams', searchQuery);
          mutateModalParams(searchQuery);
          setValue(value);
        }}
        allowClear={true}
        options={[
          { label: 'All', value: '' },
          { label: 'Initiated', value: 'INITIATED' },
          { label: 'Successful', value: 'SUCCESSFUL' },
          { label: 'Failed', value: 'FAILED' },
          { label: 'Cancelled', value: 'CANCELLED' }
        ]}
      />
    </div>
  );
};
