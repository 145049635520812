import React from 'react';
import useFetch from '../../hooks/useFetch';
import dayjs from 'dayjs';
import { consentInit } from '../../utils/handlers';
import { useEffect, useState } from 'react';
import { tw } from 'twind';
import { getURL } from '../../configs/apiURL';
import { Modal, Button, Form, Checkbox, Space, Input, Statistic } from 'antd';
import { RiErrorWarningFill } from 'react-icons/ri';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { notifications } from '../../utils/notifications';
import { getMobileNo } from '../../utils/helpers';

const { Countdown } = Statistic;

const BuyerSellerConsent = ({
  isUpdate,
  onSubmit,
  onSubmitLoader,
  consent: {
    agree,
    stage,
    formInitial,
    formData,
    udyamNumber,
    udyamRegNoKyc,
    udyamRegNoVerified,
    panKyc,
    entityName,
    panVerified,
    otpReport,
    otpReportVerified,
    creditReportVerified
  },
  setConsent
}) => {
  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();
  const [checked, setChecked] = useState(false);
  const [otp, setOtp] = useState('');
  const [pending, setPending] = useState(false);
  const [requestHitIds, setRequestHitIds] = useState();
  const [timer, setTimer] = useState({
    disabled: true,
    value: Date.now() + 60 * 1000
  });

  const apiProviders = JSON.parse(localStorage.getItem('apiProviders'));

  const { editBuyerSeller = {} } = useSelector(state => ({
    editBuyerSeller: state.buyerSeller.editBuyerSeller
  }));

  const { panApiProvider, udyamApiProvider } =
    editBuyerSeller?.data?.apiProviders || apiProviders || {};

  // console.log(creditReportApiProvider);

  const onConfim = async action => {
    let response, next;
    switch (action) {
      case 'panSubmit':
        response = await fetch(getURL(`${panApiProvider.toLowerCase()}/kyc/pan`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            pan: formData.panNumber,
            name: formData.entityName,
            dob: formData.incorporationDate.format('YYYY-MM-DD'),
            entityOrPromoterId: editBuyerSeller?.data?.entityDetails?.id
          })
        });
        setConsent({
          panNumber: formData.panNumber,
          panKyc: 1,
          dob: formData.incorporationDate,
          entityName: formData.entityName,
          panVerified: response.status === 200 ? 1 : 0
        });

        const isUdyamKycMismatch = () => {
          return (
            (formData.udyamNumber && !udyamRegNoKyc) ||
            (udyamRegNoKyc &&
              (formData.udyamNumber !== udyamNumber || formData.entityName !== entityName))
          );
        };

        const isUpdateUdyamKycMismatch = () => {
          return (
            formData.udyamNumber &&
            (formInitial.entityDetails.udyamRegNo !== formData.udyamNumber ||
              formInitial.entityDetails.entityName !== formData.entityName)
          );
        };

        if (!isUpdate) {
          if (isUdyamKycMismatch()) {
            next = 'udyamNumber';
          } else {
            next = response.status === 200 ? '' : 'submit';
          }
        } else {
          if (isUpdateUdyamKycMismatch() && isUdyamKycMismatch()) {
            next = 'udyamNumber';
          } else {
            next = response.status === 200 ? '' : 'submit';
          }
        }
        break;
      case 'udyamSubmit':
        response = await fetch(getURL(`${udyamApiProvider.toLowerCase()}/kyc/udyam`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            udyamRegistrationNo: formData.udyamNumber,
            name: formData.entityName,
            entityId: editBuyerSeller?.data?.entityDetails?.id
          })
        });
        setConsent({
          udyamNumber: formData.udyamNumber,
          udyamRegNoKyc: 1,
          entityName: formData.entityName,
          udyamRegNoVerified: response.status === 200 ? 1 : 0
        });
        next = panVerified ? '' : 'submit';
        break;
      case 'optReportSubmit':
        response = await fetch(getURL(`creditap/credit-report`), {
          method: 'POST',
          body: JSON.stringify({
            name: formData.entityName,
            dob: formData.incorporationDate,
            stgOneHitId: requestHitIds.stgOneHitId,
            stgTwoHitId: requestHitIds.stgTwoHitId,
            otp: otp,
            pan: formData.panNumber,
            contactNo: getMobileNo(formData.contactNo)
          })
        });
        if (response.status === 200) {
          setConsent({
            creditReport: 1,
            creditReportVerified: response.status === 200 ? 1 : 0,
            otpReport: 1,
            otpReportVerified: response.status === 200 ? 1 : 0
          });
          next = 'submit';
        } else {
          notifications.error({
            message: response.data.error || 'Something went wrong'
          });
        }
        break;
      default:
        next = 'panNumber';
        break;
    }
    setConsent({ stage: next });
  };

  const sendReportOTP = async (event, refetch = false) => {
    if (event.target.checked || refetch) {
      if (creditReportVerified) return;
      if (refetch) setPending(true);
      const response = await fetch(getURL(`creditap/credit-report/otp`), {
        method: 'POST',
        body: JSON.stringify({
          name: formData.entityName,
          dob: formData.incorporationDate,
          pan: formData.panNumber,
          contactNo: getMobileNo(formData.contactNo),
          emailId: formData.primaryEmail
        })
      });
      if (response.status === 200) {
        setRequestHitIds(response.data.data);
        setConsent({ stage: 'optReport' });
        setTimer({ disabled: true, value: Date.now() + 60 * 1000 });
        notifications.success({ message: 'OTP Sended Successfully.' });
      } else {
        notifications.error({
          message: response.data.error.message || 'Something went wrong.'
        });
      }
      if (refetch) {
        setTimer(timer => ({
          ...timer,
          disabled: !timer.disabled,
          value: Date.now() + 60 * 1000
        }));
        setPending(false);
      }
    }
  };

  const show = type => {
    switch (type) {
      case 'panNumber':
        if (
          isUpdate &&
          formData.panNumber &&
          (formData.panNumber !== formInitial.entityDetails.pan ||
            formData.entityName !== formInitial.entityDetails.entityName ||
            formData.incorporationDate.format('YYYY-MM-DD') !==
              dayjs(formInitial.entityDetails.dateOfIncorporation).format('YYYY-MM-DD')) &&
          !formInitial.entityDetails.panVerified
        ) {
          return true;
        }
        return !isUpdate && formData.panNumber ? true : false;
      case 'udyamNumber':
        if (
          isUpdate &&
          formData.udyamNumber &&
          (formData.udyamNumber !== formInitial.entityDetails.udyamRegNo ||
            formData.entityName !== formInitial.entityDetails.entityName) &&
          !formInitial.entityDetails.udyamRegNoVerified
        ) {
          return true;
        }
        return !isUpdate && formData.udyamNumber ? true : false;
      default:
        return false;
    }
  };

  const isLoading = useCallback(
    action => {
      return stage === action && loading;
    },
    [loading, stage]
  );

  const isDisable = useCallback(
    action => {
      return stage !== action;
    },
    [loading, stage]
  );

  useEffect(() => {
    form.setFieldsValue({
      panNumber: formData.panNumber,
      udyamNumber: formData.udyamNumber
    });
  }, []);

  const handleSubmit = async () => {
    const response = await onSubmit({
      ...formData,
      panVerified: panVerified,
      udyamRegNoVerified: udyamRegNoVerified
    });
    if (response) {
      setConsent({ ...consentInit });
    }
  };

  return (
    <Modal
      open={agree}
      className='ant-consent-modal'
      onCancel={() => setConsent({ agree: !agree })}
      footer={null}
    >
      {checked && <h1 className={tw`text-center text-lg font-serif`}>KYC Consent & Policy</h1>}
      <Form form={form} size='medium' autoComplete='off'>
        {checked && (
          <div>
            {show('panNumber') && (
              <div className={tw`mb-4 mt-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>PAN Number</h3>
                <Space>
                  <Form.Item name='panNumber' style={{ margin: 0 }}>
                    <Input
                      placeholder='ENTER PAN'
                      disabled
                      suffix={
                        (panKyc && panVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{ height: 31 }}
                    onClick={() => onConfim('panSubmit')}
                    loading={isLoading('panNumber')}
                    disabled={isDisable('panNumber')}
                  >
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
            {show('udyamNumber') && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>Udyam Number</h3>
                <Space>
                  <Form.Item name='udyamNumber' style={{ margin: 0 }}>
                    <Input
                      placeholder='ENTER UDYAM NO'
                      disabled
                      suffix={
                        (udyamRegNoKyc && udyamRegNoVerified && (
                          <MdCheckCircle color='green' />
                        )) || <MdCancel color='red' />
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{ height: 31 }}
                    onClick={() => onConfim('udyamSubmit')}
                    loading={isLoading('udyamNumber')}
                    disabled={isDisable('udyamNumber')}
                  >
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
            {stage === 'optReport' && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>OTP Number</h3>
                <Space
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <Form.Item
                    name='optReport'
                    style={{
                      margin: 0,
                      alignItems: 'start'
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Enter 6 digit OTP'
                      },
                      {
                        pattern: /^\d+$/,
                        whitespace: false,
                        message: 'must be number!'
                      }
                    ]}
                  >
                    <Input
                      placeholder='ENTER OTP*'
                      onChange={e => setOtp(e.target.value)}
                      suffix={
                        (otpReport && otpReportVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{
                      height: 31
                    }}
                    onClick={() => onConfim('optReportSubmit')}
                    loading={isLoading('optReport') && !pending}
                    disabled={isDisable('optReport')}
                  >
                    Confirm
                  </Button>
                  <Button
                    type='primary'
                    loading={loading && pending}
                    disabled={timer.disabled}
                    onClick={event => sendReportOTP(event, true)}
                  >
                    Resend OTP
                    <Countdown
                      value={timer.value}
                      valueStyle={{
                        fontSize: '14.55px',
                        marginTop: '10px'
                      }}
                      onFinish={() =>
                        setTimer(timer => ({
                          ...timer,
                          disabled: !timer.disabled
                        }))
                      }
                    />
                  </Button>
                </Space>
              </div>
            )}
            {panVerified === 1 && (
              <div className={tw`mb-4`}>
                <Space>
                  <Form.Item name='creditReport' style={{ margin: 0 }}>
                    <Checkbox
                      onChange={sendReportOTP}
                      disabled={show('udyamNumber') && !udyamRegNoKyc}
                    >
                      You agreed to pull credit report from credit bureaus
                    </Checkbox>
                  </Form.Item>
                </Space>
              </div>
            )}
            {checked && (
              <div className={tw`flex flex-row-reverse gap-2`}>
                <Button
                  type='primary'
                  danger
                  name='delete'
                  onClick={() => setConsent({ agree: !agree })}
                  htmlType='submit'
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  size='md'
                  loading={onSubmitLoader}
                  disabled={isDisable('submit')}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        )}
        {!checked && (
          <div>
            <div className={classNames.center}>
              <RiErrorWarningFill size={50} color='darkorange' />
              <p className={classNames.text}>Are you sure ?</p>
            </div>
            <div className={classNames.container}>
              <Form.Item>
                <Checkbox onChange={() => setChecked(() => !checked)}>
                  Yes, I understand and agree for the KYC Consent & Policy.
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default BuyerSellerConsent;

const classNames = {
  center: tw`flex flex-col justify-center items-center`,
  container: tw`flex justify-center mt-2`,
  text: tw`text-xl font-serif`
};
