export const message = {
  LOGIN: 'Login Successfully',
  LOGOUT: 'Logout Successfully',

  FINANCIER_CREATED: 'Financier Created Successfully',
  FINANCIER_UPDATED: 'Financier Updated Successfully',
  API_CONFIGURATION_UPDATED: 'API Configurations Updated Successfully',
  API_KEYS_UPDATED: 'API Keys Updated Successfully',

  USER_CREATED: 'User Created Successfully',
  USER_UPDATED: 'User Updated Successfully',

  ENTITY_CREATED: 'Entity Created Successfully',
  ENTITY_UPDATED: 'Entity Updated Successfully',

  ADDRESS_CREATED: 'Address Created Successfully',
  ADDRESS_UPDATED: 'Address Updated Successfully',
  ADDRESS_DELETED: 'Address Deleted Successfully',

  BANK_CREATED: 'Bank Created Successfully',
  BANK_UPDATED: 'Bank Updated Successfully',
  BANK_DELETED: 'Bank Deleted Successfully',

  PROMOTER_CREATED: 'Promoter Created Successfully',
  PROMOTER_UPDATED: 'Promoter Updated Successfully',
  PROMOTER_DELETED: 'Promoter Deleted Successfully',

  SANCTIONED_LIMIT_UPDATED: 'Sanctioned Limit Updated Successfully',

  DOCUMENT_UPLOADED: 'Document Uploaded Successfully',
  DOCUMENT_DELETED: 'Document Deleted Successfully',

  BUYER_SELLER_LINK_CREATED: 'BuyerSellerLink Created Successfully',
  BUYER_SELLER_LINK_UPDATED: 'BuyerSellerLink Updated Successfully',
  BUYER_SELLER_LINK_DELETED: 'BuyerSellerLink Deleted Successfully',

  FACTORING_CREATED: 'FactoringUnit Created Successfully',
  FACTORING_UPDATED: 'FactoringUnit Updated Successfully',
  FACTORING_DELETED: 'FactoringUnit Deleted Successfully',
  FACTORING_APPROVED: 'FactoringUnit Approved Successfully',

  FEE_AND_ROI_ADDED: 'Fees & ROI Added Successfully',
  OPEN_FOR_FINANCE_APPROVED: 'Factoring Unit Sent For Finance Successfully',
  OPEN_FOR_FINANCE_REJECTED: 'Factoring Unit Rejected Successfully',

  BUYER_SELLER_APPROVED: 'BuyerSeller Approved Successfully',
  PROFILE_UPDATED: 'Profile Updated Successfully',

  CHECKER_CREATED: 'Checker Created Successfully',
  CHECKER_UPDATED: 'Checker Updated Successfully',
  CHECKER_DELETED: 'Checker Deleted Successfully',

  INVOICE_CREATED: 'Invoice Created Successfully',
  INVOICE_UPDATED: 'Invoice Updated Successfully',
  INVOICE_DELETED: 'Invoice Deleted Successfully',

  ACCEPT_BANK_FINANCE_APPROVED: 'Factoring Unit Factored Successfully',
  ACCEPT_BANK_FINANCE_REJECTED: 'Factoring Unit Rejected Successfully',

  PAYMENT_CREATED: 'Payment Created Successfully',
  PAYMENT_UPDATED: 'Payment Updated Successfully',

  UPDATE_STATUS: 'Status Updated Successfully'
};
