import { Divider } from 'antd';
import { Typography } from 'antd';
import { ViewModal } from '../factoringUnit/viewFactoringUnit/viewFactoringUnit';
import { DateFilter, TextFilter } from '../../../utils/formFilters';
import dayjs from 'dayjs';

const { Text } = Typography;

export const notFactoredUnitColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Factoring Unit No</Text>
        <Divider />
        <TextFilter type='factoringUnitNo' />
      </div>
    ),
    key: 'factoringUnitNo',
    render: (_, record) => {
      return <ViewModal label={record.factoringUnitNo} data={record} />;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Seller Name</Text>
        <Divider />
        <TextFilter type='sellerName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.seller.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Factoring Amount</Text>
        <Divider />
        <TextFilter type='factoringAmount' />
      </div>
    ),
    dataIndex: 'factoringAmount',
    key: 'factoringAmount',
    render: (_, record) => {
      return <Text>{record.factoringAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Listed Date</Text>
        <Divider />
        <DateFilter type='fuListedDate' />
      </div>
    ),
    dataIndex: 'listedDate',
    key: 'listedDate',
    render: (_, record) => {
      const invoiceDate = dayjs(record.fuListedDate).format('DD-MM-YYYY');
      return <Text>{invoiceDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Due Date</Text>
        <Divider />
        <DateFilter type='fuDueDate' />
      </div>
    ),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_, record) => {
      const dueDate = dayjs(record.invoices[0].invoiceDueDate).format('DD-MM-YYYY');
      return <Text>{dueDate}</Text>;
    }
  }
];
