import { getURL } from '../configs/apiURL';
import { fetchData } from '../utils/fetchData';
import {
  paymentTypes,
  factoredUnitTypes,
  factoringUnitTypes,
  notFactoredUnitTypes,
  openForFinanceTypes,
  transactionHistoryTypes,
  invoiceDocumentTypes,
  checkerLevelTypes,
  factoringUnitByIdTypes,
  acceptBankFinance
} from '../redux/factoringUnit/types';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const getAllFactoringUnit = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      url = getURL(
        `factoring-units?status=PENDING&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    case 'CHECKER':
      url = getURL(
        `factoring-units?status=PENDING&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    default:
      url = getURL(`factoring-units?status=PENDING&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    factoringUnitTypes.GET_FACTORING_UNIT_LOADING,
    factoringUnitTypes.GET_FACTORING_UNIT_SUCCESS,
    factoringUnitTypes.GET_FACTORING_UNIT_FAILURE,
    'something went wrong'
  );
};

export const getAllOpenForFinance = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  const entityCategory = localStorage.getItem('entityCategory');
  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      if (entityCategory === 'FINANCIER') {
        url = getURL(
          `factoring-units?status=OPEN_FOR_FINANCE&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
        );
      } else {
        url = getURL(
          `factoring-units?status=OPEN_FOR_FINANCE&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
        );
      }
      break;
    case 'CHECKER':
      url = getURL(
        `factoring-units?status=OPEN_FOR_FINANCE&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    default:
      url = getURL(`factoring-units?status=OPEN_FOR_FINANCE&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    openForFinanceTypes.GET_OPEN_FOR_FINANCE_LOADING,
    openForFinanceTypes.GET_OPEN_FOR_FINANCE_SUCCESS,
    openForFinanceTypes.GET_OPEN_FOR_FINANCE_FAILURE,
    'something went wrong'
  );
};

export const getAllAcceptBankFinance = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  const entityCategory = localStorage.getItem('entityCategory');
  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      if (entityCategory === 'SELLER') {
        url = getURL(
          `factoring-units?status=ROI_ADDED&invCreatedByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
        );
      } else {
        url = getURL(
          `factoring-units?status=ROI_ADDED&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
        );
      }
      break;
    case 'CHECKER':
      url = getURL(
        `factoring-units?status=ROI_ADDED&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    default:
      url = getURL(`factoring-units?status=ROI_ADDED&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    acceptBankFinance.GET_ACCEPT_BANK_FINANCE_LOADING,
    acceptBankFinance.GET_ACCEPT_BANK_FINANCE_SUCCESS,
    acceptBankFinance.GET_ACCEPT_BANK_FINANCE_FAILURE,
    'something went wrong'
  );
};

export const getAllFactoredUnit = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  const currentTab = localStorage.getItem('currentTab');

  const status = {
    OPEN_FOR_DISBURSEMENT: 'AMT_DISBURSED',
    ACTIVE_DISBURSEMENT: 'ACTIVE_DISBURSEMENT',
    OVERDUE_DISBURSEMENT: 'OVERDUE_DISBURSEMENT',
    PAYMENT_CLOSED: 'PAYMENT_CLOSED'
  };

  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      url = getURL(
        `factoring-units?status=${status[currentTab]}&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    case 'CHECKER':
      url = getURL(
        `factoring-units?status=${status[currentTab]}&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    default:
      url = getURL(`factoring-units?status=${status[currentTab]}&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    factoredUnitTypes.GET_FACTORED_UNIT_LOADING,
    factoredUnitTypes.GET_FACTORED_UNIT_SUCCESS,
    factoredUnitTypes.GET_FACTORED_UNIT_FAILURE,
    'something went wrong'
  );
};

export const getAllNotFactoredUnit = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      url = getURL(
        `factoring-units?status=REJECTED&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    case 'CHECKER':
      url = getURL(
        `factoring-units?status=REJECTED&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    default:
      url = getURL(`factoring-units?status=REJECTED&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_LOADING,
    notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_SUCCESS,
    notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_FAILURE,
    'something went wrong'
  );
};

export const getFUPayments = async (FUId, params = '') => {
  params = params || '';
  let url = getURL(`factoring-units/${FUId}/payments?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    paymentTypes.GET_PAYMENTS_LOADING,
    paymentTypes.GET_PAYMENTS_SUCCESS,
    paymentTypes.GET_PAYMENTS_FAILURE,
    'something went wrong'
  );
};

export const getFactoringUnitById = async id => {
  let url = getURL(`factoring-units/${id}`);
  return fetchData(
    url,
    factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_LOADING,
    factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_SUCCESS,
    factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_FAILURE,
    'something went wrong'
  );
};

export const getAllTransactionHistory = async (id, params = '') => {
  params = params || '';
  let url = getURL(`factoring-units/${id}/transactions?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    transactionHistoryTypes.GET_TRANSACTION_HISTORY_LOADING,
    transactionHistoryTypes.GET_TRANSACTION_HISTORY_SUCCESS,
    transactionHistoryTypes.GET_TRANSACTION_HISTORY_FAILURE,
    'something went wrong'
  );
};

export const getAllInvoiceDocuments = async (id, params = '') => {
  params = params || '';
  let url = getURL(`invoices/${id}/documents?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    invoiceDocumentTypes.GET_INVOICE_DOCUMENT_LOADING,
    invoiceDocumentTypes.GET_INVOICE_DOCUMENT_SUCCESS,
    invoiceDocumentTypes.GET_INVOICE_DOCUMENT_FAILURE,
    'something went wrong'
  );
};

export const getAllCheckerLevel = async (params = '') => {
  params = params || '';
  let url = getURL(`platform/users/checker-levels?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    checkerLevelTypes.GET_CHECKER_LEVEL_LOADING,
    checkerLevelTypes.GET_CHECKER_LEVEL_SUCCESS,
    checkerLevelTypes.GET_CHECKER_LEVEL_FAILURE,
    'something went wrong'
  );
};
