import { combineReducers } from 'redux';
import user from './user/reducer';
import financier from './financier/reducer';
import buyerSeller from './buyerSeller/reducer';
import factoringUnit from './factoringUnit/reducer';
import mutations from './mutations/reducer';

const reducer = combineReducers({
  user,
  financier,
  buyerSeller,
  factoringUnit,
  mutations
});

const rootReducer = (state, action) =>
  reducer(action.type === 'USER_LOGOUT' ? undefined : state, action);

export default rootReducer;
