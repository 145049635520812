import React from 'react';
import dayjs from 'dayjs';
import SelectComponent from '../../../antdComponent/Select';
import DateComponent from '../../../antdComponent/Date';
import TextInput from '../../../antdComponent/Input';
import useFetch from '../../../../hooks/useFetch';
import { useMemo, useEffect } from 'react';
import { Button, Form, Row, Col, Typography, Spin } from 'antd';
import { tw } from 'twind';
import { RULES } from '../../../../utils/formValidations';
import { notifications } from '../../../../utils/notifications';
import { getURL } from '../../../../configs/apiURL';
import { useSelector } from 'react-redux';
import { getAllFactoredUnit } from '../../../../services/factoringUnit';
import { getCollectionAmount } from '../../../../utils/helpers';

const { Text } = Typography;

export const TransactionForm = ({
  isModalVisible,
  setIsModalVisible,
  fetchTransactionData,
  data
}) => {
  const {
    params,
    fUPayment = {},
    transactionHistoryData = {}
  } = useSelector(state => ({
    params: state.mutations.params,
    fUPayment: state.factoringUnit.fUPayment,
    transactionHistoryData: state.factoringUnit.transactionHistory
  }));

  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();

  const initialValue = useMemo(
    () =>
      Object.keys(data).length !== 0
        ? {
            factoredAmount: data.factoringAmount,
            disbursementAmount: fUPayment.totalPaymentAmount,
            factoredDate: dayjs(data.factoredDate),
            disbursementDate: dayjs(fUPayment.dateOfTransaction),
            roi: data.rateOfInterest,
            collectedAmount: transactionHistoryData.totalTransactionAmount,
            accruedInterest: data.buyerInterest,
            collectFrom: 'BUYER',
            fees: data.buyerFees,
            transactionAmount:
              getCollectionAmount(data) - transactionHistoryData.totalTransactionAmount,
            transactionDate: null,
            referenceNo: null,
            narration: 'Loan Amount Collected',
            remainingAmount: 0
          }
        : {},
    [data, transactionHistoryData]
  );

  const onSubmitTransactionDetails = async value => {
    const formData = {
      collectFrom: value.collectFrom,
      factoredAmount: parseFloat(value.factoredAmount),
      disbursementAmount: parseFloat(value.disbursementAmount),
      collectedAmount: parseFloat(value.collectedAmount),
      transactionAmount: parseFloat(value.transactionAmount),
      remainingAmount: parseFloat(value.remainingAmount),
      dateOfTransaction: value.transactionDate,
      factoredDate: value.factoredDate,
      disbursementDate: value.disbursementDate,
      rateOfInterest: parseFloat(value.roi),
      accruedInterest: parseFloat(value.accruedInterest),
      fees: parseFloat(value.fees),
      referenceNo: value.referenceNo,
      narration: value.narration
    };

    const res = await fetch(getURL(`factoring-units/${data.factoringUnitNo}/transactions`), {
      method: 'POST',
      body: JSON.stringify(formData)
    });

    if (res && res.status === 200) {
      if (
        parseFloat(
          parseFloat(transactionHistoryData.totalTransactionAmount) +
            parseFloat(value.transactionAmount)
        ).toFixed(2) >= getCollectionAmount(data)
      ) {
        setIsModalVisible(false);
        getAllFactoredUnit(params);
      } else {
        fetchTransactionData(data.factoringUnitNo);
      }
      notifications.success({
        message: 'Transaction details created successfully'
      });
    } else {
      notifications.error({
        message: res.data.error.message
      });
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue(initialValue);
    }
  }, [form, initialValue, isModalVisible]);

  return (
    <>
      {loading && (
        <div className='transaction-tabs content-center'>
          <Spin spinning={loading} />
        </div>
      )}
      <Form
        form={form}
        name='bank'
        size='large'
        onFinish={onSubmitTransactionDetails}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        {!loading && (
          <>
            <Row className={tw`my-6 md:mb-0`}>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Factored Amount'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='factoredAmount' rules={RULES.amount}>
                  <TextInput disabled placeHolder='Enter Factoring Amount' />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Disbursement Amount'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='disbursementAmount' rules={RULES.amount}>
                  <TextInput placeHolder='Enter Disbursement Amount' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Factored Date'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='factoredDate' rules={RULES.date}>
                  <DateComponent placeholder='Select Date' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Disbursement Date'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='disbursementDate' rules={RULES.date}>
                  <DateComponent placeholder='Select Date' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'ROI'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='roi' rules={RULES.rateOfInterest}>
                  <TextInput placeHolder='Enter ROI' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Collected Amount'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='collectedAmount' rules={RULES.amount}>
                  <TextInput placeHolder='Enter Collected Amount' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Fees'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='fees' rules={RULES.amount}>
                  <TextInput placeHolder='Enter Fees' disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Accrued Interest'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='accruedInterest' rules={RULES.amount}>
                  <TextInput placeHolder='Enter Accrued Interest' disabled />
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <Row className={tw`my-6 md:mb-0`}>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Collecting From'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='collectFrom' rules={RULES.selectRequired}>
                  <SelectComponent
                    placeholder='Buyer/Seller'
                    allowClear={true}
                    options={[
                      { label: 'Buyer', value: 'BUYER' },
                      { label: 'Seller', value: 'SELLER' }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Amount'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='transactionAmount' rules={RULES.amount}>
                  <TextInput
                    placeHolder='Enter Amount'
                    onChange={e => {
                      form.setFieldsValue({
                        transactionAmount: e.target.value,
                        remainingAmount:
                          getCollectionAmount(data) -
                          transactionHistoryData.totalTransactionAmount -
                          parseFloat(e.target.value || 0)
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Transaction Date'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='transactionDate' rules={RULES.date}>
                  <DateComponent placeholder='Select Date' />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Reference Number'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='referenceNo' rules={RULES.referenceNo}>
                  <TextInput placeholder='Enter Reference Number' />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Text className={classNames.baseText}>{'Narration'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='narration' rules={RULES.inputRequired}>
                  <TextInput placeholder='Enter Narration' />
                </Form.Item>
              </Col>
              <Col xs={24} md={1} />
              <Col xs={24} md={5}>
                <Text className={classNames.baseText}>{'Remaining Amount'}</Text>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item name='remainingAmount' rules={RULES.amount}>
                  <TextInput placeHolder='Enter Remaining Amount' disabled />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Button loading={loading} type='primary' htmlType='submit' size='middle'>
          Add
        </Button>
      </Form>
    </>
  );
};

const classNames = {
  baseText: tw`block leading-10 md:text-center`
};
