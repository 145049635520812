export const generateFilterQuery = (params, items) => {
  let tempObj = items
    ? JSON.parse('{"' + decodeURI(items.replace(/&/g, '","').replace(/=/g, '":"')) + '"}')
    : {};

  Object.entries(params).forEach(([key, value]) => {
    if (value) tempObj[key] = value;
    else delete tempObj[key];
  });

  const serialize = obj => {
    const str = [];
    for (let key in obj)
      if (obj.hasOwnProperty(key)) {
        const value = /^\d{4}-\d{2}-\d{2},\d{4}-\d{2}-\d{2}$/.test(obj[key])
          ? obj[key]
          : encodeURIComponent(obj[key]);
        str.push(encodeURIComponent(key) + '=' + value);
      }
    return str.join('&');
  };

  return serialize(tempObj);
};
