import React from 'react';
import BuyerSellerEntityConsent from '../../buyerSellerConsent/entityConsent';
import IsdCode from '../../antdComponent/Mobile';
import TextInput from '../../antdComponent/Input';
import useFetch from '../../../hooks/useFetch';
import SelectComponent from '../../antdComponent/Select';
import DateComponent from '../../antdComponent/Date';
import PasswordInput from '../../antdComponent/Password';
import dayjs from 'dayjs';
import { FaRegAddressCard } from 'react-icons/fa';
import { useEffect, useMemo, useState, Fragment } from 'react';
import { Form, Card, Button, Row, Col, Spin, Modal } from 'antd';
import { tw } from 'twind';
import { RULES } from '../../../utils/formValidations';
import { showPassword } from '../../../services/auth';
import { getURL } from '../../../configs/apiURL';
import {
  getAllLinkedGst,
  getPanKycDetails,
  getUdyamKycDetails
} from '../../../services/buyerSeller';
import { ACTION_TYPES, getIsdCode, getMobileNo } from '../../../utils/helpers';
import { onEntitySubmit } from '../../../utils/handlers';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { EntityKycDetails } from '../modals/viewEntity';

const BuyerSellerEntityForms = ({
  defaultValue: { adminDetails = {}, entityDetails = {}, apiProviders = {} },
  consent,
  setConsent,
  isUpdate,
  onSubmit,
  onSubmitLoader,
  setCurrentTab,
  state,
  dispatch,
  setActiveTabs
}) => {
  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();
  const [pending, setPending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [industryDropdownData, setIndustryDropDownData] = useState()
  const [industrySubSectorDropdownData, setIndustrySubSectorDropDownData] = useState()
  const [mobileIsdCode, setMobileIsdCode] = useState(getIsdCode(adminDetails?.mobileNo));
  const [contactIsdCode, setContactIsdCode] = useState(getIsdCode(entityDetails?.contactNo));

  const { panApiProvider = '', udyamRegNoApiProvider = '' } = apiProviders;

  // console.log(creditReportApiProvider);

  const initialValue = useMemo(() => {
    return Object.keys(entityDetails).length !== 0 && Object.keys(adminDetails).length !== 0
      ? {
        buyerSeller: entityDetails.entityCategory,
        entityTypeId: entityDetails.entityTypeId || entityDetails.entityType.id,
        entityName: entityDetails.entityName,
        registrationNumber: entityDetails.registrationNo,
        panNumber: entityDetails.pan,
        gstinNumber: entityDetails.gstin,
        udyamNumber: entityDetails.udyamRegNo,
        incorporationDate: entityDetails.dateOfIncorporation
          ? dayjs(entityDetails.dateOfIncorporation)
          : null,
        adminName: `${adminDetails.firstName} ${adminDetails.lastName}`,
        mobileNo: getMobileNo(adminDetails.mobileNo),
        email: adminDetails.emailId,
        startOfOperation: entityDetails.startOfOperation
          ? dayjs(entityDetails.startOfOperation)
          : null,
        businessSector: entityDetails.businessSectorId,
        industrySector: entityDetails.industrySectorId,
        industrySubsector: entityDetails.industrySubSectorId,
        salesInLastFY: entityDetails.salesInLastFy,
        profitInLastFY: entityDetails.profitInLastFy,
        contactNo: getMobileNo(entityDetails.contactNo),
        primaryEmail: entityDetails.emailId
      }
      : {};
  }, [entityDetails, adminDetails]);

  const fetchDropdown = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      const endPoints = [
        'entity-types',
        'business-sectors',
        'industry-sectors',
        'industry-sub-sectors'
      ].map(path => {
        const url = new URL(getURL(path));
        url.searchParams.set('active', 1);
        return fetch(url);
      });

      const [entity, business, industry, subSector] = await Promise.all(endPoints);
      let industrySector, industrySubSector, industryDropdown, subSectorDropdown;

      industrySector = industry.data.data.map(row => ({
        value: row.id,
        label: row.name,
        parentSector: row.businessSector.name
      }))

      industrySubSector = subSector.data.data.map(row => ({
        value: row.id,
        label: row.name,
        parentSector: row.industrySector.name
      }))



      if (isUpdate) {
        const selectedBusiness = business.data.data.find((row) => row.id === entityDetails.businessSectorId)
        const selectedIndustry = industry.data.data.find((row) => row.id === entityDetails.industrySectorId)

        if (selectedBusiness) {
          industryDropdown = industrySector.filter((row) => row.parentSector === selectedBusiness.name)
        }
        if (selectedIndustry) {
          subSectorDropdown = industrySubSector.filter((row) => row.parentSector === selectedIndustry.name)
        }
      }

      setIndustryDropDownData(industrySector)
      setIndustrySubSectorDropDownData(industrySubSector)

      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: {
          entityDropdown: entity.data.data.map(row => ({
            value: row.id,
            label: row.name
          })),
          businessDropdown: business.data.data.map(row => ({
            value: row.id,
            label: row.name
          })),
          industryDropdown: industryDropdown || [],
          subSectorDropdown: subSectorDropdown || []
        }
      });


    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  const onSelectChange = (value, type) => {

    let businessSector, industrySector;

    switch (type) {
      case 'BusinessSector':
        businessSector = state.dropDownData.businessDropdown.find((row) => row.value === value)
        dispatch({
          type: ACTION_TYPES.FETCH_SUCCESS,
          payload: {
            ...state.dropDownData,
            industryDropdown: industryDropdownData.filter((row) => row.parentSector === businessSector.label),
          }
        });
        break;
      case 'IndustrySector':
        industrySector = state.dropDownData.industryDropdown.find((row) => row.value === value)
        dispatch({
          type: ACTION_TYPES.FETCH_SUCCESS,
          payload: {
            ...state.dropDownData,
            subSectorDropdown: industrySubSectorDropdownData.filter((row) => row.parentSector === industrySector.label),
          }
        });
        break
      default:
        break
    }
  }

  const showUserPassword = async id => {
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      if (entityDetails.udyamRegNoVerified)
        getUdyamKycDetails(udyamRegNoApiProvider, entityDetails.udyamRegNo);
      if (entityDetails.panVerified) {
        getPanKycDetails(panApiProvider, entityDetails.pan);
        getAllLinkedGst(panApiProvider, entityDetails.pan);
        // getCreditReportDetails(creditReportApiProvider, entityDetails.pan);
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isUpdate) showUserPassword(adminDetails.id);
    form.setFieldsValue(initialValue);
    setMobileIsdCode(getIsdCode(adminDetails.mobileNo));
    setContactIsdCode(getIsdCode(entityDetails.contactNo));
  }, [initialValue]);

  useEffect(() => {
    fetchDropdown();
  }, []);

  if (loading || pending) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={loading || pending} />
      </div>
    );
  }

  return (
    <div className={tw`mb-5`}>
      {consent.agree && (
        <BuyerSellerEntityConsent
          isUpdate={isUpdate}
          onSubmit={onSubmit}
          onSubmitLoader={onSubmitLoader}
          consent={{
            ...consent,
            formInitial: { adminDetails, entityDetails, apiProviders }
          }}
          setConsent={data => setConsent(consent => ({ ...consent, ...data }))}
        />
      )}
      <Form
        form={form}
        size='large'
        initialValues={initialValue}
        onFinish={async value => {
          setPending(true);
          await onEntitySubmit({
            value,
            consent,
            setConsent,
            defaultValue: { entityDetails, adminDetails, apiProviders },
            isUpdate,
            onSubmit,
            mobileIsdCode,
            contactIsdCode
          });
          setPending(false);
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        onValuesChange={() => form.validateFields(['repassword'])}
        autoComplete='off'
      >
        <Card title='Entity Details' style={{ width: '100%' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='buyerSeller' rules={RULES.selectRequired}>
                <SelectComponent
                  label='Buyer/Seller'
                  placeholder='Buyer/Seller'
                  required
                  allowClear={true}
                  options={[
                    { label: 'Buyer', value: 'BUYER' },
                    { label: 'Seller', value: 'SELLER' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='entityTypeId' rules={[]}>
                <SelectComponent
                  label='Entity Type'
                  placeholder='Entity Type'
                  allowClear={true}
                  options={state.dropDownData.entityDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='entityName' rules={RULES.entityName}>
                <TextInput
                  label='Entity Name'
                  required
                  placeholder='Entity Name'
                  disabled={entityDetails.panVerified || consent.panVerified}
                  suffix={
                    entityDetails.panVerified || consent.panVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      isUpdate && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='panNumber' rules={RULES.panNumber}>
                <TextInput
                  label='PAN Number'
                  placeholder='Enter PAN'
                  required
                  maxLength={10}
                  disabled={entityDetails.panVerified || consent.panVerified}
                  onChange={e => form.setFieldValue('panNumber', e.target.value.toUpperCase())}
                  suffix={
                    entityDetails.panVerified || consent.panVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      isUpdate && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='udyamNumber' rules={RULES.udyamNumber}>
                <TextInput
                  label='Udyam Number'
                  placeholder='Enter Udyam No'
                  maxLength={19}
                  disabled={entityDetails.udyamRegNoVerified || consent.udyamRegNoVerified}
                  suffix={
                    entityDetails.udyamRegNoVerified || consent.udyamRegNoVerified ? (
                      <MdCheckCircle color='green' />
                    ) : (
                      isUpdate && <MdCancel color='red' />
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='registrationNumber' rules={RULES.optionalRegistrationNumber}>
                <TextInput
                  label='Registration Number'
                  placeholder='Enter Registration Number'
                  maxLength='12'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='incorporationDate' rules={RULES.date}>
                <DateComponent
                  required
                  label='Date of Incorporation'
                  placeholder='Select Date'
                  disabled={entityDetails.panVerified || consent.panVerified}
                  disabledDate={current => current.isAfter(dayjs().subtract(1, 'day'))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='startOfOperation'>
                <DateComponent label='Start of Operations' placeholder='Select Date' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='businessSector'>
                <SelectComponent
                  label='Business Sector'
                  placeholder='Business Sector'
                  allowClear={true}
                  options={state.dropDownData.businessDropdown}
                  onChange={(value) => onSelectChange(value, "BusinessSector")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='industrySector'>
                <SelectComponent
                  label='Industry Sector'
                  placeholder='Industry Sector'
                  allowClear={true}
                  options={state.dropDownData.industryDropdown}
                  onChange={(value) => onSelectChange(value, "IndustrySector")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='industrySubsector'>
                <SelectComponent
                  label='Industry Sub-sector'
                  placeholder='Industry Sub-sector'
                  allowClear={true}
                  options={state.dropDownData.subSectorDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='salesInLastFY' rules={RULES.optionalNumber}>
                <TextInput label='Sales in Last FY' placeholder='Enter Sales in Last FY' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='profitInLastFY' rules={RULES.optionalNumber}>
                <TextInput label='Profit in Last FY' placeholder='Profit in Last FY' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name='contactNo'
                rules={RULES.mobileNo}
                style={{
                  position: 'relative',
                  bottom: 7,
                  zIndex: 999
                }}
              >
                <TextInput
                  label='Primary Contact Number'
                  placeholder='Enter Number'
                  maxLength={10}
                  addonBefore={
                    <IsdCode
                      isdCode={contactIsdCode}
                      setIsdCode={code => setContactIsdCode(code)}
                    />
                  }
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='primaryEmail' rules={RULES.email}>
                <TextInput label='Primary Email' placeholder='Enter Email' required />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title='Admin Details' style={{ width: '100%', marginTop: '20px' }}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='adminName' rules={RULES.name}>
                <TextInput label='Admin Name' placeholder='Enter Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name='mobileNo'
                rules={RULES.mobileNo}
                style={{
                  position: 'relative',
                  bottom: 7,
                  zIndex: 999
                }}
              >
                <TextInput
                  label='Mobile No'
                  placeholder='Enter Number'
                  required
                  addonBefore={
                    <IsdCode isdCode={mobileIsdCode} setIsdCode={code => setMobileIsdCode(code)} />
                  }
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='email' rules={RULES.email}>
                <TextInput label='Email' placeholder='Enter Email' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='password' rules={RULES.password} hasFeedback>
                <PasswordInput label='Password' placeholder='Enter Password' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
                <PasswordInput label='Re-Password' placeholder='Enter Password' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              {isUpdate && (
                <Fragment>
                  <Button
                    style={{ borderRadius: '8px', padding: '10px 15px' }}
                    onClick={() => setIsModalVisible(true)}
                  >
                    <FaRegAddressCard size='20px' color='black' />
                  </Button>
                  <Modal
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={
                      <Button
                        key='back'
                        onClick={() => setIsModalVisible(false)}
                        type='primary'
                        size='middle'
                        className={tw`ml-auto`}
                      >
                        Close
                      </Button>
                    }
                    centered
                  >
                    <EntityKycDetails data={{ entityDetails, adminDetails, apiProviders }} />
                  </Modal>
                </Fragment>
              )}
            </Col>
          </Row>
        </Card>
        <div className={tw`flex gap-2 mt-5`}>
          <Button type='primary' size='middle' htmlType='submit' loading={onSubmitLoader}>
            {isUpdate ? 'Update' : 'Save'}
          </Button>
          <Button
            type='primary'
            size='middle'
            disabled={!isUpdate}
            onClick={() => {
              setCurrentTab('2');
              setActiveTabs('2');
            }}
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BuyerSellerEntityForms;
