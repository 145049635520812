import {
  openForFinanceTypes,
  factoredUnitTypes,
  notFactoredUnitTypes,
  transactionHistoryTypes,
  factoringUnitTypes,
  invoiceDocumentTypes,
  factoringUnitByIdTypes,
  checkerLevelTypes,
  acceptBankFinance,
  paymentTypes
} from './types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_LOADING:
      return {
        ...state,
        loadingFactoringUnitById: true,
        factoringUnitById: []
      };

    case factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_FAILURE:
      return {
        ...state,
        loadingFactoringUnitById: false,
        factoringUnitById: []
      };

    case factoringUnitByIdTypes.GET_FACTORING_UNIT_BY_ID_SUCCESS:
      return {
        ...state,
        loadingFactoringUnitById: false,
        factoringUnitById: action.payload
      };

    case factoringUnitTypes.GET_FACTORING_UNIT_FAILURE:
      return {
        ...state,
        loadingFactoringUnit: false,
        factoringUnit: {}
      };

    case factoringUnitTypes.GET_FACTORING_UNIT_LOADING:
      return {
        ...state,
        loadingFactoringUnit: true,
        factoringUnit: {}
      };

    case factoringUnitTypes.GET_FACTORING_UNIT_SUCCESS:
      const factoringUnit = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingFactoringUnit: false,
        factoringUnit: {
          data: factoringUnit,
          count: action.payload.count
        }
      };

    case acceptBankFinance.GET_ACCEPT_BANK_FINANCE_FAILURE:
      return {
        ...state,
        loadingacceptBankFinance: false,
        acceptBankFinance: {}
      };

    case acceptBankFinance.GET_ACCEPT_BANK_FINANCE_LOADING:
      return {
        ...state,
        loadingacceptBankFinance: true,
        acceptBankFinance: {}
      };

    case acceptBankFinance.GET_ACCEPT_BANK_FINANCE_SUCCESS:
      const acceptBankFinanceData = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingacceptBankFinance: false,
        acceptBankFinance: {
          data: acceptBankFinanceData,
          count: action.payload.count
        }
      };

    case checkerLevelTypes.GET_CHECKER_LEVEL_FAILURE:
      return {
        ...state,
        loadingCheckerLevel: false,
        checkerLevel: {}
      };

    case checkerLevelTypes.GET_CHECKER_LEVEL_LOADING:
      return {
        ...state,
        loadingCheckerLevel: true,
        checkerLevel: {}
      };

    case checkerLevelTypes.GET_CHECKER_LEVEL_SUCCESS:
      const checkerLevelData = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingCheckerLevel: false,
        checkerLevel: {
          data: checkerLevelData,
          count: action.payload.count
        }
      };

    case checkerLevelTypes.EDIT_CHECKER_LEVEL:
      return {
        ...state,
        editCheckerLevel: action.payload
      };

    case factoringUnitTypes.EDIT_FACTORING_UNIT:
      return {
        ...state,
        editFactoringUnit: action.payload
      };

    case openForFinanceTypes.GET_OPEN_FOR_FINANCE_FAILURE:
      return {
        ...state,
        loadingOpenForFinance: false,
        openForFinance: {}
      };

    case openForFinanceTypes.GET_OPEN_FOR_FINANCE_SUCCESS:
      const openForFinance = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingOpenForFinance: false,
        openForFinance: {
          data: openForFinance,
          count: action.payload.count
        }
      };

    case openForFinanceTypes.GET_OPEN_FOR_FINANCE_LOADING:
      return {
        ...state,
        loadingOpenForFinance: true,
        openForFinance: {}
      };

    case factoredUnitTypes.GET_FACTORED_UNIT_FAILURE:
      return {
        ...state,
        loadingFactoredUnit: false,
        factoredUnit: {}
      };

    case factoredUnitTypes.GET_FACTORED_UNIT_SUCCESS:
      const factoredUnit = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingFactoredUnit: false,
        factoredUnit: {
          data: factoredUnit,
          count: action.payload.count
        }
      };

    case factoredUnitTypes.GET_FACTORED_UNIT_LOADING:
      return {
        ...state,
        loadingFactoredUnit: true,
        factoredUnit: {}
      };

    case notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_FAILURE:
      return {
        ...state,
        loadingNotFactoredUnit: false,
        notFactoredUnit: {}
      };

    case notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_SUCCESS:
      const notFactoredUnit = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingNotFactoredUnit: false,
        notFactoredUnit: {
          data: notFactoredUnit,
          count: action.payload.count
        }
      };

    case notFactoredUnitTypes.GET_NOT_FACTORED_UNIT_LOADING:
      return {
        ...state,
        loadingNotFactoredUnit: true,
        notFactoredUnit: {}
      };

    case paymentTypes.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        loadingFUPayment: false,
        fUPayment: {}
      };

    case paymentTypes.GET_PAYMENTS_SUCCESS:
      const paymentData = action.payload.data.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingFUPayment: false,
        fUPayment: {
          data: paymentData,
          count: action.payload.count,
          totalPaymentAmount: action.payload.data.totalPaymentAmount
        }
      };

    case paymentTypes.GET_PAYMENTS_LOADING:
      return {
        ...state,
        loadingFUPayment: true,
        fUPayment: {}
      };

    case transactionHistoryTypes.GET_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        loadingTransactionHistory: false,
        transactionHistory: {}
      };

    case transactionHistoryTypes.GET_TRANSACTION_HISTORY_SUCCESS:
      const transactionHistory = action.payload.data.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingTransactionHistory: false,
        transactionHistory: {
          data: transactionHistory,
          count: action.payload.count,
          totalTransactionAmount: action.payload.data.totalTransactionAmount
        }
      };

    case transactionHistoryTypes.GET_TRANSACTION_HISTORY_LOADING:
      return {
        ...state,
        loadingTransactionHistory: true,
        transactionHistory: {}
      };

    case factoringUnitTypes.FACTORING_UNIT_FILTER_QUERY:
      return {
        ...state,
        factoringUnitQuery: action.payload
      };

    case factoredUnitTypes.FACTORED_UNIT_FILTER_QUERY:
      return {
        ...state,
        factoredUnitQuery: action.payload
      };

    case openForFinanceTypes.OPEN_FOR_FINANCE_FILTER_QUERY:
      return {
        ...state,
        openForFinanceQuery: action.payload
      };

    case notFactoredUnitTypes.NOT_FACTORED_UNIT_FILTER_QUERY:
      return {
        ...state,
        notFactoredUnitQuery: action.payload
      };

    case invoiceDocumentTypes.GET_INVOICE_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingInvoiceDocument: false,
        invoiceDocument: []
      };

    case invoiceDocumentTypes.GET_INVOICE_DOCUMENT_SUCCESS:
      const invoiceDocument = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingInvoiceDocument: false,
        invoiceDocument: {
          data: invoiceDocument,
          count: action.payload.count
        }
      };

    case invoiceDocumentTypes.GET_INVOICE_DOCUMENT_LOADING:
      return {
        ...state,
        loadingInvoiceDocument: true,
        invoiceDocument: []
      };

    default:
      return state;
  }
};

export default reducer;
