import { Typography } from 'antd';
import dayjs from 'dayjs';

const { Text } = Typography;

export const actionHistory = [
  {
    title: (
      <div>
        <Text>Action By</Text>
      </div>
    ),
    key: 'userType',
    render: (_, record) => {
      return <Text>{record.actionByUser.userType}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>User Name</Text>
      </div>
    ),
    key: 'userType',
    render: (_, record) => {
      return (
        <Text>
          {record.actionByUser.firstName} {record.actionByUser.lastName}
        </Text>
      );
    }
  },
  {
    title: (
      <div>
        <Text>Status</Text>
      </div>
    ),
    key: 'userType',
    render: (_, record) => {
      return <Text>{record.status}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Remarks</Text>
      </div>
    ),
    key: 'userType',
    render: (_, record) => {
      return <Text>{record.remarks}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Date & Time</Text>
      </div>
    ),
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (_, record) => {
      const invoiceDate = dayjs(record.createdAt).format('DD-MM-YYYY HH:mm:ss');
      return <Text>{invoiceDate}</Text>;
    }
  }
];
