import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DocumentInvoice from '../documentsInvoice/documentsInvoice';
import { Button, Modal, Table, Card } from 'antd';
import { tw } from 'twind';
import { factoringUnitViewColumns } from '../../factoringUnit/viewFactoringUnit/viewFactoringUnitColumns';
import { useSelector } from 'react-redux';
import { actionHistory } from '../approveInvoice/invoiceActionColumns';
import { getInvoiceById } from '../../../../services/buyerSeller';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const ViewInvoice = ({ label, data, showActionHistory = true }) => {
  const { loadingInvoiceById, invoiceById } = useSelector(state => ({
    loadingInvoiceById: state.buyerSeller.loadingInvoiceById,
    invoiceById: state.buyerSeller.invoiceById
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const factorUnitViewData = [
    {
      key1: 'Invoice No',
      value1: data.invoiceNo,
      key2: 'Factoring Unit',
      value2: data.factoringUnitNo || '-'
    },
    {
      key1: 'Buyer',
      value1: data.buyerSellerLink.buyer.entityName,
      key2: 'Seller',
      value2: data.buyerSellerLink.seller.entityName
    },
    {
      key1: 'Invoice Date',
      value1: dayjs(data.invoiceDate).format('DD-MM-YYYY'),
      key2: 'Due Date',
      value2: dayjs(data.invoiceDueDate).format('DD-MM-YYYY')
    },
    {
      key1: 'Invoice Amount',
      value1: data.invoiceAmount,
      key2: 'Discount Amount',
      value2: data.discountAmount
    },
    {
      key1: 'Tax Amount',
      value1: data.taxAmount,
      key2: 'Total',
      value2: data.totalAmount
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible && showActionHistory) {
      getInvoiceById(data.id);
    }
  }, [isModalVisible]);

  return (
    <>
      <div className={tw`flex justify-center`}>
        <Button style={{ borderRadius: '8px', padding: '4px' }} onClick={showModal}>
          {label}
        </Button>
      </div>
      <Modal
        title={`View Invoice`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Button key='back' onClick={handleCancel} type='primary' className={tw`ml-auto`}>
            Close
          </Button>
        }
        centered
      >
        <div className={`table-responsive mb-4`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={factorUnitViewData}
            rowKey='key'
            loading={false}
            bordered
            showHeader={false}
            pagination={false}
          />
        </div>

        <DocumentInvoice showDocuments={isModalVisible} data={data} />

        {showActionHistory && (
          <Card title='Action History' className='mb-4'>
            <div className='table-responsive'>
              <Table
                columns={actionHistory}
                dataSource={invoiceById?.data?.actionHistory}
                rowKey='key'
                loading={loadingInvoiceById}
                pagination={{
                  pageSize: PAGE_LIMIT,
                  total: invoiceById?.data?.actionHistory?.length
                }}
                page
                bordered
              />
            </div>
          </Card>
        )}
      </Modal>
    </>
  );
};
