import React, { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { Button, Modal, Table, Form, Input } from 'antd';
import { factoringUnitViewColumns } from '../factoringUnit/viewFactoringUnit/viewFactoringUnitColumns';
import { getURL } from '../../../configs/apiURL';
import { notifications } from '../../../utils/notifications';
import { getAllAcceptBankFinance } from '../../../services/factoringUnit';
import { useSelector } from 'react-redux';
import { RULES } from '../../../utils/formValidations';
import { tw } from 'twind';
import { message } from '../../../utils/messages';
import { invoiceViewColumns } from '../factoringUnit/viewFactoringUnit/viewInvoiceColumns';
import { FUDetails, FUBreakupData } from '../fuDetails';

const { TextArea } = Input;

export const ApproveBankFinance = ({ label, data }) => {
  const [form] = Form.useForm();
  const [fetch] = useFetch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { param } = useSelector(state => ({
    params: state.mutations.params
  }));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = async ({ approved }) => {
    try {
      const { remarks } = await form.validateFields();
      const res = await fetch(getURL(`factoring-units/${data.factoringUnitNo}/disburse-amount`), {
        method: 'PUT',
        body: JSON.stringify({
          status: 'ROI_ADDED',
          approved,
          remarks
        })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: approved
            ? message.ACCEPT_BANK_FINANCE_APPROVED
            : message.ACCEPT_BANK_FINANCE_REJECTED
        });
        form.resetFields();
        setIsModalVisible(false);
        getAllAcceptBankFinance(param);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  return (
    <>
      <Button
        name='approve-reject'
        style={{ borderRadius: '8px', padding: '4px' }}
        onClick={showModal}
      >
        {label}
      </Button>
      <Modal
        title={`Approve Factoring Unit`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className={tw`table-responsive mb-6`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUDetails(data)}
            rowKey='key'
            loading={false}
            pagination={false}
            showHeader={false}
            bordered
          />
        </div>
        <div className={tw`table-responsive my-6`}>
          <Table
            columns={invoiceViewColumns}
            dataSource={data.invoices}
            rowKey='key'
            loading={false}
            bordered
            pagination={false}
          />
        </div>
        <div className={tw`table-responsive mb-6`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUBreakupData(data)}
            rowKey='key'
            loading={false}
            bordered
            pagination={false}
          />
        </div>
        <Form
          form={form}
          size='large'
          className={tw`flex flex-col md:flex-row justify-center items-start md:items-center gap-3`}
          autoComplete='off'
        >
          <div style={{ flex: 1 }} className={tw`w-full`}>
            <Form.Item name='remarks' rules={RULES.inputRequired}>
              <TextArea rows={4} label='Remarks' placeholder='Enter Remarks' />
            </Form.Item>
          </div>
          <div className={tw`flex md:pt-14 gap-1`}>
            <Button type='primary' size='middle' onClick={() => onSubmit({ approved: 1 })}>
              Approve
            </Button>
            <Button danger type='primary' size='middle' onClick={() => onSubmit({ approved: 0 })}>
              Reject
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
