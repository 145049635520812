import React, { useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import useFetch from '../../hooks/useFetch';
import TextInput from '../antdComponent/Input';
import SelectComponent from '../antdComponent/Select';
import TableComponent from '../antdComponent/Table';
import { tw } from 'twind';
import { getURL } from '../../configs/apiURL';
import { notifications } from '../../utils/notifications';
import { editChecker } from '../../redux/factoringUnit/actions';
import { getAllUserByEntity } from '../../services/user';
import { getAllCheckerLevel } from '../../services/factoringUnit';
import { RULES } from '../../utils/formValidations';
import { Button, Card, Form, Row, Col, Typography } from 'antd';
import { checkerLevelColumn } from './checkerLevelColumn';
import { useSelector } from 'react-redux';
import { message } from '../../utils/messages';
import { Permissions } from '../../configs/permissions';

const { Text } = Typography;

const CheckerLevel = () => {
  const {
    params,
    editCheckerLevel = {},
    checkerLevel = {},
    checkerLevelUser = {},
    loadingCheckerLevel = false
  } = useSelector(state => ({
    params: state.mutations.params,
    editCheckerLevel: state.factoringUnit.editCheckerLevel,
    checkerLevel: state.factoringUnit.checkerLevel,
    checkerLevelUser: state.user.checkerLevelUser,
    loadingCheckerLevel: state.factoringUnit.loadingCheckerLevel
  }));

  const [form] = Form.useForm();
  const [fetch] = useFetch();

  const id = Cookies.get('entityId');

  const checkerDropdownData = useMemo(() => {
    if (editCheckerLevel.openEdit) {
      return [
        {
          value: editCheckerLevel.data.user.id,
          label: `${editCheckerLevel.data.user.firstName} ${editCheckerLevel.data.user.lastName}`
        }
      ];
    }
    return checkerLevelUser?.data?.map(item => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`
    }));
  }, [editCheckerLevel, checkerLevelUser]);

  const onSubmit = async value => {
    const data = {
      levelName: value.levelName,
      userId: value.userId
    };
    if (editCheckerLevel.openEdit) {
      const res = await fetch(getURL(`platform/users/checker-levels/${editCheckerLevel.data.id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({ message: message.CHECKER_UPDATED });
        editChecker(false, {});
        getAllCheckerLevel(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      const res = await fetch(getURL(`platform/users/checker-levels`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notifications.success({ message: message.CHECKER_CREATED });
        getAllCheckerLevel(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }
    getAllUserByEntity(id);
  };

  useEffect(() => {
    getAllUserByEntity(id);
  }, []);

  useEffect(() => {
    getAllCheckerLevel(params);
  }, [params]);

  useEffect(() => {
    form.setFieldsValue({
      levelName: editCheckerLevel?.data?.levelName,
      userId: editCheckerLevel?.data?.user?.id
    });
  }, [form, editCheckerLevel]);

  return (
    <div className={tw`content-divider`}>
      {Permissions(
        'checkerLevel',
        editCheckerLevel.openEdit ? 'editCheckerLevel' : 'addCheckerLevel'
      ) && (
        <Form
          form={form}
          size='large'
          initialValues={editCheckerLevel || {}}
          onFinish={value => {
            onSubmit(value);
            form.resetFields();
          }}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          autoComplete='off'
        >
          <Card title='Checker Details' style={{ width: '100%' }}>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='levelName' rules={RULES.inputRequired}>
                  <TextInput label='Level Name' placeholder='Enter Level Name' required />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='userId' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='Select Checker'
                    placeholder='Select'
                    required
                    allowClear={true}
                    options={checkerDropdownData}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              size='middle'
              type='primary'
              disabled={checkerLevel.length > 0 && !editCheckerLevel.openEdit}
              htmlType='submit'
            >
              {editCheckerLevel.openEdit ? 'Update' : 'Add'}
            </Button>
          </Card>
        </Form>
      )}
      <div className={tw`content-divider`}>
        <Text className={tw`text-xl`}>Checkers</Text>
      </div>
      <div className={tw`table-responsive`}>
        <TableComponent
          columns={checkerLevelColumn}
          data={checkerLevel}
          loading={loadingCheckerLevel}
        />
      </div>
    </div>
  );
};

export default CheckerLevel;
