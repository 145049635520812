import { TextFilter, StatusFilter } from '../../utils/formFilters';
import { platformRolesPermission } from '../../configs/permissions';
import { editFinancierConfigurationType } from '../../redux/financier/actions';
import { updateFinancierConfigTypeStatus } from '../../services/financier';
import { MdModeEditOutline } from 'react-icons/md';
import { tw } from 'twind';
import { Typography, Divider, Space, Button, Switch } from 'antd';

const { Text } = Typography;

export const configurationTypeColumns = (type, title) => {
  return [
    {
      title: (
        <div>
          <Text>{title}</Text>
          <Divider />
          <TextFilter type='name' />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Text>{record.name}</Text>
    },
    {
      title: (
        <div>
          <Text>Actions</Text>
          <Divider />
          <StatusFilter type='active' />
        </div>
      ),
      key: 'action',
      render: (_, record) => (
        <div className={tw`flex justify-center`}>
          <Space size='middle' align='center'>
            {platformRolesPermission('configurations', 'editConfiguration') && (
              <Button
                name='update-configuration'
                style={{ borderRadius: '8px', padding: '5px' }}
                onClick={() => editFinancierConfigurationType(true, record)}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
            {platformRolesPermission('configurations', 'editConfigurationStatus') && (
              <Switch
                checked={record.active ? true : false}
                onChange={checked =>
                  updateFinancierConfigTypeStatus(type, record.id, {
                    active: checked ? 1 : 0
                  })
                }
              />
            )}
          </Space>
        </div>
      )
    }
  ];
};
