import { store } from '../redux';
import { getURL } from '../configs/apiURL';
import { fetchData } from '../utils/fetchData';
import { notifications } from '../utils/notifications';
import { buyerSellerTypes } from '../redux/buyerSeller/types';
import { message } from '../utils/messages';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const getBuyerSellerProfile = async () => {
  const url = getURL('buyer-seller/profile');
  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_PROFILE_LOADING,
    buyerSellerTypes.GET_BUYER_SELLER_PROFILE_SUCCESS,
    buyerSellerTypes.GET_BUYER_SELLER_PROFILE_FAILURE,
    'something went wrong'
  );
};

export const getAllBuyerSeller = async (params = '') => {
  params = params ? `?limit=${PAGE_LIMIT}&${params}` : '';
  const url = getURL(`buyer-seller${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_LOADING,
    buyerSellerTypes.GET_BUYER_SELLER_SUCCESS,
    buyerSellerTypes.GET_BUYER_SELLER_FAILURE,
    'something went wrong'
  );
};

export const getAllBuyerSellerLink = async (params = '', all = false) => {
  params = params || '';
  const url = all
    ? getURL('buyer-seller/links')
    : getURL(`buyer-seller/links?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_LOADING,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_SUCCESS,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_FAILURE,
    'something went wrong'
  );
};

export const getAllInvoices = async (params = '') => {
  params = params || '';
  const userId = localStorage.getItem('userId');
  let url = '';
  switch (localStorage.getItem('userType')) {
    case 'MAKER':
      url = getURL(
        `invoices?approved=0&createdByUserId=${userId}&nextCheckerUserId=null&limit=${PAGE_LIMIT}&${params}`
      );
      break;
    case 'CHECKER':
      url = getURL(`invoices?approved=0&nextCheckerUserId=${userId}&limit=${PAGE_LIMIT}&${params}`);
      break;
    default:
      url = getURL(`invoices?approved=0&limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_LOADING,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_SUCCESS,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_FAILURE,
    'something went wrong'
  );
};

export const getInvoiceById = async id => {
  const url = getURL(`invoices/${id}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_LOADING_BY_ID,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_SUCCESS_BY_ID,
    buyerSellerTypes.GET_BUYER_SELLER_INVOICE_FAILURE_BY_ID,
    'something went wrong'
  );
};

export const getBuyerSellerBanks = async (id, params = '', all = false) => {
  params = params || '';
  const url = all
    ? getURL(`buyer-seller/${id}/bank-details`)
    : getURL(`buyer-seller/${id}/bank-details?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_BANKS_LOADING,
    buyerSellerTypes.GET_BANKS_SUCCESS,
    buyerSellerTypes.GET_BANKS_FAILURE,
    'something went wrong'
  );
};

export const getBuyerSellerAddress = async (id, params = '') => {
  params = params || '';
  const url = getURL(`buyer-seller/${id}/address-details?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_ADDRESS_LOADING,
    buyerSellerTypes.GET_ADDRESS_SUCCESS,
    buyerSellerTypes.GET_ADDRESS_FAILURE,
    'something went wrong'
  );
};

export const getBuyerSellerPromoters = async (id, params = '') => {
  params = params || '';
  const url = getURL(`buyer-seller/${id}/promoter-details?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_PROMOTER_LOADING,
    buyerSellerTypes.GET_PROMOTER_SUCCESS,
    buyerSellerTypes.GET_PROMOTER_FAILURE,
    'something went wrong'
  );
};

export const getBuyerSellerDocuments = async (id, params = '') => {
  params = params || '';
  const url = getURL(`buyer-seller/${id}/documents?limit=${1 || PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_DOCUMENT_LOADING,
    buyerSellerTypes.GET_DOCUMENT_SUCCESS,
    buyerSellerTypes.GET_DOCUMENT_FAILURE,
    'something went wrong'
  );
};

export const updateBuyerSellerStatus = async (_id, data) => {
  const url = getURL(`buyer-seller/${_id}`);

  const { userType, currentTab } = localStorage;
  const successCallBack = () => {
    const params = localStorage.getItem('params');
    const approved =
      ['ADMIN', 'OPERATION_MANAGER'].includes(userType) &&
      ['BUYER_SELLER_OPERATION'].includes(currentTab)
        ? 0
        : 1;
    getAllBuyerSeller(`approved=${approved}&${params ? params : ''}`);
  };

  return fetchData(
    url,
    null,
    null,
    null,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};

export const updateBuyerSellerLinkStatus = async (_id, data) => {
  const url = getURL(`buyer-seller/links/${_id}`);

  const successCallBack = () => {
    const params = localStorage.getItem('params');
    getAllBuyerSellerLink(params);
  };

  return fetchData(
    url,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_LOADING,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_SUCCESS,
    buyerSellerTypes.GET_BUYER_SELLER_LINK_FAILURE,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};

export const getAllLinkedGst = async (apiProvider, panNo, params = '') => {
  params = params || '';
  const url = getURL(
    `${apiProvider.toLowerCase()}/kyc/pan/${panNo}/linked-gstins?limit=${PAGE_LIMIT}&${params}`
  );
  return fetchData(
    url,
    buyerSellerTypes.GET_LINKED_GST_LOADING,
    buyerSellerTypes.GET_LINKED_GST_SUCCESS,
    buyerSellerTypes.GET_LINKED_GST_FAILURE,
    'something went wrong'
  );
};

export const getPanKycDetails = async (apiProvider, panNo) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/pan/${panNo}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_PAN_KYC_LOADING,
    buyerSellerTypes.GET_PAN_KYC_SUCCESS,
    buyerSellerTypes.GET_PAN_KYC_FAILURE,
    'something went wrong'
  );
};

export const getGstKycDetails = async (apiProvider, gstin) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/gst/${gstin}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_GST_KYC_LOAING,
    buyerSellerTypes.GET_GST_KYC_SUCCESS,
    buyerSellerTypes.GET_GST_KYC_FAILURE,
    'something went wrong'
  );
};

export const getGstItrDetails = async (apiProvider, gstin) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/gst-itr/${gstin}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_GST_ITR_KYC_LOAING,
    buyerSellerTypes.GET_GST_ITR_KYC_SUCCESS,
    buyerSellerTypes.GET_GST_ITR_KYC_FAILURE,
    'something went wrong'
  );
};

export const getUdyamKycDetails = async (apiProvider, udyamRegNo) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/udyam/${udyamRegNo}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_UDYAM_KYC_LOADING,
    buyerSellerTypes.GET_UDYAM_KYC_SUCCESS,
    buyerSellerTypes.GET_UDYAM_KYC_FAILURE,
    'something went wrong'
  );
};

export const getBankKycDetails = async (apiProvider, accountNo) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/bank-account/${accountNo}`);

  return fetchData(
    url,
    buyerSellerTypes.GET_BANK_KYC_LOADING,
    buyerSellerTypes.GET_BANK_KYC_SUCCESS,
    buyerSellerTypes.GET_BANK_KYC_FAILURE,
    'something went wrong'
  );
};

export const getBankStmtDetails = async (apiProvider, accountNo) => {
  const url = getURL(`${apiProvider.toLowerCase()}/stmt/bank-account/${accountNo}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_BANK_STMT_LOADING,
    buyerSellerTypes.GET_BANK_STMT_SUCCESS,
    buyerSellerTypes.GET_BANK_STMT_FAILURE,
    'something went wrong'
  );
};

export const getAadhaarKycDetails = async (apiProvider, aadhaarNo) => {
  const url = getURL(`${apiProvider.toLowerCase()}/kyc/aadhaar/${aadhaarNo}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_AADHAAR_KYC_LOADING,
    buyerSellerTypes.GET_AADHAAR_KYC_SUCCESS,
    buyerSellerTypes.GET_AADHAAR_KYC_FAILURE,
    'something went wrong'
  );
};

export const getCreditReportDetails = async (apiProvider, pan) => {
  const url = getURL(`creditap/credit-report/${pan}`);
  return fetchData(
    url,
    buyerSellerTypes.GET_CREDIT_REPORT_LOADING,
    buyerSellerTypes.GET_CREDIT_REPORT_SUCCESS,
    buyerSellerTypes.GET_CREDIT_REPORT_FAILURE,
    'something went wrong'
  );
};

export const getIfscData = async ifscCode => {
  store.dispatch({ type: buyerSellerTypes.GET_IFSC_DETAILS_LOADING });
  let _res = {};
  let data = [];
  try {
    _res = await fetch(`https://ifsc.razorpay.com/${ifscCode}`, {
      'Content-Type': 'application/json'
    });
    data = await _res.json();
  } catch (error) {
    console.warn(error);
    notifications.error({
      message: 'something went wrong'
    });
  } finally {
    if (_res.status === 404) {
      store.dispatch({ type: buyerSellerTypes.GET_IFSC_DETAILS_FAILURE });
      notifications.error({
        message: 'invalid ifsc code'
      });
    } else {
      store.dispatch({
        type: buyerSellerTypes.GET_IFSC_DETAILS_SUCCESS,
        payload: data
      });
    }
  }
};

export const getPinData = async pinCode => {
  store.dispatch({ type: buyerSellerTypes.GET_PIN_CODE_DETAILS_LOADING });
  let _res = {};
  let data = [];
  try {
    _res = await fetch(`https://api.postalpincode.in/pincode/${pinCode}`, {
      'Content-Type': 'application/json'
    });
    data = await _res.json();
  } catch (error) {
    console.warn(error);
    notifications.error({
      message: 'something went wrong'
    });
  } finally {
    if (data[0].Status === 'Error') {
      store.dispatch({
        type: buyerSellerTypes.GET_PIN_CODE_DETAILS_FAILURE
      });
      notifications.error({
        message: 'invalid pin code'
      });
    } else {
      store.dispatch({
        type: buyerSellerTypes.GET_PIN_CODE_DETAILS_SUCCESS,
        payload: data[0].PostOffice ? data[0].PostOffice[0] : {}
      });
    }
  }
};
