import React, { useState, useEffect } from 'react';
import { Button, Card, Modal, Table, Form, Input } from 'antd';
import { getAllFactoringUnit } from '../../../../services/factoringUnit';
import { getURL } from '../../../../configs/apiURL';
import { tw } from 'twind';
import { RULES } from '../../../../utils/formValidations';
import { getFactoringUnitById } from '../../../../services/factoringUnit';
import { notifications } from '../../../../utils/notifications';
import { factoringUnitViewColumns } from '../viewFactoringUnit/viewFactoringUnitColumns';
import { invoiceViewColumns } from '../viewFactoringUnit/viewInvoiceColumns';
import { useSelector } from 'react-redux';
import { actionHistory } from './factoringUnitActionColumns';
import useFetch from '../../../../hooks/useFetch';
import dayjs from 'dayjs';
import '../styles.css';

const { TextArea } = Input;

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const ApproveFactoringUnit = ({ label, data }) => {
  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  const { factoringUnitById = {}, loadingFactoringUnitById = false } = useSelector(state => ({
    factoringUnitById: state.factoringUnit.factoringUnitById,
    loadingFactoringUnitById: state.factoringUnit.loadingFactoringUnitById
  }));

  const [fetch] = useFetch();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const factorUnitViewData = [
    {
      key1: 'Factoring Unit No',
      value1: data.factoringUnitNo,
      key2: 'Listed Date',
      value2: data.fuListedDate ? dayjs(data.fuListedDate).format('DD-MM-YYYY') : '-'
    },
    {
      key1: 'Buyer',
      value1: data.buyerSellerLink.buyer.entityName,
      key2: 'Seller',
      value2: data.buyerSellerLink.seller.entityName
    },
    {
      key1: 'Invoice Date',
      value1: dayjs(data.invoices[0].invoiceDate).format('DD-MM-YYYY'),
      key2: 'Due Date',
      value2: data.invoices[0].invoiceDate
        ? dayjs(data.invoices[0].invoiceDueDate).format('DD-MM-YYYY')
        : '—'
    },
    {
      key1: 'Invoice Amount',
      value1: data.sumOfInvoiceAmounts.invoiceAmount,
      key2: 'Discount Amount',
      value2: data.sumOfInvoiceAmounts.discountAmount
    },
    {
      key1: 'Tax Amount',
      value1: data.sumOfInvoiceAmounts.taxAmount,
      key2: 'Total',
      value2: data.sumOfInvoiceAmounts.totalAmount
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = async ({ approved }) => {
    try {
      const { remarks } = await form.validateFields();
      const res = await fetch(getURL(`factoring-units/${data.factoringUnitNo}/send-for-finance`), {
        method: 'PUT',
        body: JSON.stringify({
          status: 'PENDING',
          approved,
          remarks
        })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: 'Factoring Unit is now Open For Finance'
        });
        form.resetFields();
        setIsModalVisible(false);
        getAllFactoringUnit(params);
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      getFactoringUnitById(data.factoringUnitNo);
    }
  }, [isModalVisible]);

  return (
    <>
      <Button style={{ borderRadius: '8px', padding: '4px' }} onClick={showModal}>
        {label}
      </Button>
      <Modal
        title={`Approve Factoring Unit `}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Form
            form={form}
            name='bank'
            size='large'
            className={tw`flex flex-col md:flex-row justify-center items-start md:items-center gap-3`}
            onFinishFailed={errorInfo => {
              console.log(errorInfo);
            }}
            autoComplete='off'
          >
            <div style={{ flex: 1 }} className={tw`w-full`}>
              <Form.Item name='remarks' rules={RULES.inputRequired}>
                <TextArea rows={4} label='Remarks' placeholder='Enter Remarks' />
              </Form.Item>
            </div>
            <div className={tw`flex md:pt-14 gap-1`}>
              <Button type='primary' size='middle' onClick={() => onSubmit({ approved: 1 })}>
                Approve
              </Button>
              <Button danger type='primary' size='middle' onClick={() => onSubmit({ approved: 0 })}>
                Reject
              </Button>
            </div>
          </Form>
        }
        centered
      >
        <div className='table-responsive mb-4'>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={factorUnitViewData}
            rowKey='key'
            loading={false}
            bordered
            showHeader={false}
            pagination={false}
          />
        </div>
        <Card title='Invoice Details' className='mb-4'>
          <div className='table-responsive'>
            <Table
              columns={invoiceViewColumns}
              dataSource={data.invoices}
              rowKey='key'
              loading={false}
              pagination={{
                pageSize: PAGE_LIMIT,
                total: data.invoices?.length
              }}
              bordered
            />
          </div>
        </Card>
        <Card title='Action History' className='mb-4'>
          <div className='table-responsive'>
            <Table
              columns={actionHistory}
              dataSource={factoringUnitById?.data?.actionHistory}
              rowKey='key'
              loading={loadingFactoringUnitById}
              pagination={{
                pageSize: PAGE_LIMIT,
                total: factoringUnitById?.data?.actionHistory?.length
              }}
              bordered
            />
          </div>
        </Card>
      </Modal>
    </>
  );
};
