import React from 'react';
import ConfigurationType from './configurationType';
import ConfigurationSector from './configurationSector';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Typography } from 'antd';
import { mutateClearParams } from '../../redux/mutations/actions';
import { tw } from 'twind';
import {
  editFinancierConfigurationSector,
  editFinancierConfigurationType
} from '../../redux/financier/actions';

const { TabPane } = Tabs;

const { Text } = Typography;

const Configurations = () => {
  const [currentTab, setCurrentTab] = useState('1');

  const {
    params,
    configurationType,
    loadingconfigurationType,
    configurationSector,
    loadingconfigurationSector,
    editConfigurationType,
    editConfigurationSector
  } = useSelector(state => ({
    params: state.mutations.params,
    configurationType: state.financier.configurationType,
    loadingconfigurationType: state.financier.loadingConfigurationType,
    configurationSector: state.financier.configurationSector,
    loadingconfigurationSector: state.financier.loadingConfigurationSector,
    editConfigurationType: state.financier.editConfigurationType,
    editConfigurationSector: state.financier.editConfigurationSector
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Text className={tw`text-lg`}>Manage Configurations</Text>
      </div>
      <div className='card-container'>
        <Tabs
          type='line'
          size='middle'
          tabPosition={'top'}
          activeKey={currentTab}
          onChange={key => {
            setCurrentTab(key);
            mutateClearParams();
            editFinancierConfigurationSector(false, {});
            editFinancierConfigurationType(false, {});
          }}
        >
          <TabPane tab='Entity Type' key='1'>
            {currentTab === '1' && (
              <ConfigurationType
                type='entity-types'
                title='Entity Type'
                params={params}
                configurationType={configurationType}
                loadingConfigurationType={loadingconfigurationType}
                editConfigurationType={editConfigurationType}
              />
            )}
          </TabPane>
          <TabPane tab='Account Type' key='2'>
            {currentTab === '2' && (
              <ConfigurationType
                type='bank-account-types'
                title='Bank Account Type'
                params={params}
                configurationType={configurationType}
                loadingConfigurationType={loadingconfigurationType}
                editConfigurationType={editConfigurationType}
              />
            )}
          </TabPane>
          <TabPane tab='Address Type' key='3'>
            {currentTab === '3' && (
              <ConfigurationType
                type='address-types'
                title='Address Type'
                params={params}
                configurationType={configurationType}
                loadingConfigurationType={loadingconfigurationType}
                editConfigurationType={editConfigurationType}
              />
            )}
          </TabPane>
          <TabPane tab='Business Sector' key='4'>
            {currentTab === '4' && (
              <ConfigurationType
                type='business-sectors'
                title='Business Sector'
                params={params}
                configurationType={configurationType}
                loadingConfigurationType={loadingconfigurationType}
                editConfigurationType={editConfigurationType}
              />
            )}
          </TabPane>
          <TabPane tab='Industry Sector' key='5'>
            {currentTab === '5' && (
              <ConfigurationSector
                type='industry-sectors'
                title='Industry Sector'
                params={params}
                configurationSector={configurationSector}
                loadingConfigurationSector={loadingconfigurationSector}
                editConfigurationSector={editConfigurationSector}
              />
            )}
          </TabPane>
          <TabPane tab='Industry Sub Sector' key='6'>
            {currentTab === '6' && (
              <ConfigurationSector
                type='industry-sub-sectors'
                title='Industry Sub Sector'
                params={params}
                configurationSector={configurationSector}
                loadingConfigurationSector={loadingconfigurationSector}
                editConfigurationSector={editConfigurationSector}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Configurations;
