import React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import TextInput from '../../antdComponent/Input';
import SelectComponet from '../../antdComponent/Select';
import bankIdentifier from '../../../configs/_banks.json';
import { BankUploadCardViewModal } from './bankUploadCard';
import { getURL } from '../../../configs/apiURL';
import { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Modal,
  Descriptions,
  Form,
  Card,
  Upload,
  Progress,
  Row,
  Col,
  Alert,
  Space
} from 'antd';
import { RULES } from '../../../utils/formValidations';
import { notifications } from '../../../utils/notifications';
import { tw } from 'twind';
import { RiRefreshLine } from 'react-icons/ri';
import { message } from '../../../utils/messages';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { getBankKycDetails, getBankStmtDetails } from '../../../services/buyerSeller';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;

export const BankCardViewModal = ({ customFetch, bankData = {}, kycData = {}, index }) => {
  const {
    profile = {},
    editBuyerSeller = {},
    bankStmtDetails = {},
    loadingBankStmtDetails
  } = useSelector(state => ({
    profile: state.user.profile,
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    bankStmtDetails: state.buyerSeller.bankStmtDetails,
    loadingBankStmtDetails: state.buyerSeller.loadingBankStmtDetails
  }));

  const [form] = Form.useForm();

  const [isReportsModalVisible, setIsReportsModalVisible] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState();

  const { bankAccApiProvider = '', bankStmtApiProvider = '' } =
    editBuyerSeller.data.apiProviders || {};

  const entityCategory = localStorage.getItem('entityCategory');

  const getFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = file => {
    const fileSizeKiloBytes = file.size / 1024;
    setError(fileSizeKiloBytes > MAX_FILE_SIZE ? 'File size is greater than maximum limit' : false);
    return false;
  };

  const onSubmit = async value => {
    if (error) return;
    setLoader(true);
    const file = value.uploadDocuments[0];
    const formData = new FormData();

    formData.append('accountNumber', bankData.accountNo);
    formData.append('bankName', value.bankName);
    formData.append('document', file.originFileObj, file.originFileObj.name);
    formData.append(
      'userId',
      ['BUYER', 'SELLER'].includes(entityCategory)
        ? profile.id
        : editBuyerSeller.data.adminDetails.id
    );
    formData.append(
      'entityId',
      ['BUYER', 'SELLER'].includes(entityCategory)
        ? profile.entity.id
        : editBuyerSeller.data.entityDetails.id
    );
    if (value.password) {
      formData.append('password', value.password);
    }

    const ACCESS_TOKEN = Cookies.get('ACCESS_TOKEN');

    let config = {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadPercentage(() => percentCompleted);
      }
    };

    const res = await axios.post(getURL(`finbox/stmt/bank-account/`), formData, config);
    if (res && res.status === 200) {
      form.resetFields();
      notifications.success({ message: message.DOCUMENT_UPLOADED });
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }
    setUploadPercentage(0);
    setLoader(false);
  };

  const kycDetails = useMemo(() => {
    let details = {};
    switch (bankAccApiProvider) {
      case 'KARZA':
        if (
          bankData.bankAccVerified &&
          Object.keys(kycData).length &&
          Object.keys(bankData).length
        ) {
          details = {
            ...details,
            accountName: kycData.data.source[0].data.accountName,
            bankName: bankData.bankName,
            branchName: bankData.branchName
          };
        }
        break;
      case 'GRIDLINES':
        if (
          bankData.bankAccVerified &&
          Object.keys(kycData).length &&
          Object.keys(bankData).length
        ) {
          details = {
            ...details,
            accountName: kycData.bank_account_data.name,
            bankName: kycData.bank_account_data.bank_name,
            branchName: kycData.bank_account_data.branch
          };
        }
        break;
      default:
        return {};
    }
    return details;
  }, [bankAccApiProvider, kycData]);

  useEffect(() => {
    if (isReportsModalVisible) {
      if (bankData.bankAccVerified) {
        getBankStmtDetails(bankStmtApiProvider, bankData.accountNo);
      }
    }
  }, [isReportsModalVisible]);

  useEffect(() => {
    if (customFetch) {
      if (bankData.bankAccVerified) {
        getBankKycDetails(bankAccApiProvider, bankData.accountNo);
      }
    }
  }, [customFetch]);

  return (
    <Card
      className={tw`${!customFetch && 'border-none bank-card'}`}
      title={index && `# Bank-Account - ${index}`}
    >
      <div className={tw`flex flex-col gap-3 mb-4`}>
        <h3 className={tw`text-lg font-medium flex items-center gap-4 ml-4`}>
          Bank{' '}
          {bankData.bankAccVerified ? <MdCheckCircle color='green' /> : <MdCancel color='red' />}
        </h3>
        <Descriptions layout='horizontal' bordered size='middle'>
          <Descriptions.Item label='Name' span={3}>
            {kycDetails.accountName || 'NA'}
          </Descriptions.Item>
          <Descriptions.Item label='Bank Name' span={3}>
            {kycDetails.bankName || 'NA'}
          </Descriptions.Item>
          <Descriptions.Item label='Branch' span={3}>
            {kycDetails.branchName || 'NA'}
          </Descriptions.Item>
        </Descriptions>
      </div>
      {bankData.bankAccVerified === 1 && (
        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          autoComplete='off'
        >
          <h3 className={tw`text-lg font-medium flex items-center gap-4 ml-4 mb-4`}>
            Upload Bank Statements{' '}
            {bankData.bankAccVerified ? <MdCheckCircle color='green' /> : <MdCancel color='red' />}
          </h3>
          <Card style={{ paddingTop: 8 }}>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='password'>
                  <TextInput label='PDF Password' placeholder='Enter PDF Password' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='bankName' rules={RULES.selectRequired}>
                  <SelectComponet
                    label='Bank Name'
                    placeholder='Select'
                    size='large'
                    required
                    allowClear={true}
                    options={bankIdentifier}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={3}>
                <Form.Item name='uploadDocuments' getValueFromEvent={getFile} rules={RULES.upload}>
                  <Upload accept={'.pdf'} beforeUpload={beforeUpload} maxCount={1}>
                    <Button className='w-40' size='large' icon={<UploadOutlined />}>
                      Browse
                    </Button>
                    <br />
                    <p className={tw`text-red-500`}>
                      {uploadPercentage ? (
                        <Progress className={tw`w-36`} percent={uploadPercentage} />
                      ) : (
                        error
                      )}
                    </p>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={2}>
                <Modal
                  open={isReportsModalVisible}
                  onCancel={() => setIsReportsModalVisible(false)}
                  footer={
                    <Space size='middle'>
                      <Button
                        key='back'
                        size='middle'
                        onClick={() =>
                          !loadingBankStmtDetails &&
                          getBankStmtDetails(bankStmtApiProvider, bankData.accountNo)
                        }
                        type='primary'
                        className={tw`ml-auto`}
                      >
                        <div className={tw`flex items-center gap-2`}>
                          Refresh <RiRefreshLine />
                        </div>
                      </Button>
                      <Button
                        key='back'
                        size='middle'
                        onClick={() => setIsReportsModalVisible(false)}
                        type='primary'
                        className={tw`ml-auto`}
                      >
                        Close
                      </Button>
                    </Space>
                  }
                  centered
                >
                  <BankUploadCardViewModal
                    kycData={bankStmtDetails}
                    loadingKycData={loadingBankStmtDetails}
                  />
                </Modal>
              </Col>
            </Row>
            <Space>
              <Button
                type='primary'
                name='process'
                htmlType='submit'
                loading={loader}
                disabled={!bankData.bankAccVerified}
              >
                Upload
              </Button>
              <Button type='primary' size='medium' onClick={() => setIsReportsModalVisible(true)}>
                View Reports
              </Button>
            </Space>
          </Card>

          {!customFetch && (
            <Alert
              className={tw`mt-4`}
              description='Note :  We do not support scanned PDF images. Please enter password for the pdf in case it is password protected.'
              type='warning'
              showIcon
            />
          )}
        </Form>
      )}
    </Card>
  );
};
