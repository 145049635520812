import React, { useMemo } from 'react';
import BuyerSellerAdd from '../buyerSeller/tabs';
import TableComponent from '../antdComponent/Table';
import { useEffect } from 'react';
import { Button, Typography } from 'antd';
import { RiAddCircleFill } from 'react-icons/ri';
import { tw } from 'twind';
import {
  editAddress,
  editBank,
  editPromter,
  editBuyerSeller as editBuyerSellers
} from '../../redux/buyerSeller/actions';
import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Columns } from './buyerSellerColumns';
import { Permissions } from '../../configs/permissions';
import { getAllBuyerSeller } from '../../services/buyerSeller';

const { Text } = Typography;

const BuyerSeller = () => {
  const {
    params,
    editBuyerSeller = { data: {}, openEdit: false },
    buyerSellerData = {},
    loadingBuyerSeller = false
  } = useSelector(state => ({
    params: state.mutations.params,
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    loadingBuyerSeller: state.buyerSeller.loadingBuyerSeller,
    buyerSellerData: state.buyerSeller.buyerSeller
  }));

  const [buyerSellerAdd, setBuyerSellerAdd] = useState(false);

  const { userType, currentTab } = localStorage;

  const buyerSellerRoot = useMemo(() => {
    let query;
    if (
      ['ADMIN', 'OPERATION_MANAGER'].includes(userType) &&
      ['BUYER_SELLER_OPERATION'].includes(currentTab)
    ) {
      query = `allDetailsFilled=1&approved=0`;
    } else if (['COMPLETED'].includes(currentTab)) {
      query = `approved=1`;
    } else {
      query = `allDetailsFilled=0&approved=0`;
    }
    return query;
  }, []);

  useEffect(() => {
    if (!buyerSellerAdd && !editBuyerSeller.openEdit) {
      getAllBuyerSeller(`${buyerSellerRoot}&${params ? params : ''}`);
    }
  }, [params, buyerSellerAdd, editBuyerSeller.openEdit]);

  return (
    <Fragment>
      {(buyerSellerAdd || editBuyerSeller.openEdit) && (
        <BuyerSellerAdd
          isUpdate={editBuyerSeller.openEdit}
          editBuyerSellerData={editBuyerSeller.openEdit ? editBuyerSeller.data : {}}
          setBuyerSellerAdd={setBuyerSellerAdd}
        />
      )}
      {!buyerSellerAdd && !editBuyerSeller.openEdit && (
        <Fragment>
          <div className={tw`flex flex-col md:flex-row justify-between content-divider`}>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '600px',
                textAlign: 'center'
              }}
            >
              Manage Buyer-Seller
            </Text>
            <div className={tw`ml-auto`}>
              {Permissions(
                localStorage.getItem('currentTab') === 'BUYER_SELLER_OPERATION'
                  ? 'approveBuyerSeller'
                  : 'manageBuyerSeller',
                'addBuyerSeller'
              ) && (
                <Button
                  name='add-entity'
                  type='primary'
                  onClick={() => {
                    setBuyerSellerAdd(true);
                    editBuyerSellers(false, {});
                    editAddress(false, {});
                    editBank(false, {});
                    editPromter(false, {});
                  }}
                >
                  <div className={tw`text - xs md: text - sm flex gap - 1 items - center`}>
                    <RiAddCircleFill size='20px' /> Add Buyer-Seller
                  </div>
                </Button>
              )}
            </div>
          </div>
          <TableComponent columns={Columns} data={buyerSellerData} loading={loadingBuyerSeller} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BuyerSeller;
