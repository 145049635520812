import React from 'react';
import TableComponent from '../../antdComponent/Table';
import { Fragment } from 'react';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  factoredUnitColumns,
  overdueFactoredUnitColumns,
  activeFactoredUnitColumns
} from './factoredUnitColumns';
import { getAllFactoredUnit } from '../../../services/factoringUnit';
import { tw } from 'twind';

const { Text } = Typography;

const FactoredUnit = () => {
  const {
    params,
    loadingFactoredUnit,
    factoredUnit = []
  } = useSelector(state => ({
    params: state.mutations.params,
    loadingFactoredUnit: state.factoringUnit.loadingFactoredUnit,
    factoredUnit: state.factoringUnit.factoredUnit
  }));

  const currentTab = localStorage.getItem('currentTab');
  const isApproveModalVisible = localStorage.getItem('activeTab');

  useEffect(() => {
    if (!isApproveModalVisible) {
      getAllFactoredUnit(params);
    }
  }, [params, currentTab]);

  const getColumns = () => {
    switch (currentTab) {
      case 'OPEN_FOR_DISBURSEMENT':
        return factoredUnitColumns;
      case 'ACTIVE_DISBURSEMENT':
        return activeFactoredUnitColumns;
      case 'OVERDUE_DISBURSEMENT':
        return overdueFactoredUnitColumns;
      case 'PAYMENT_CLOSED':
        return factoredUnitColumns;
      default:
        return [];
    }
  };

  return (
    <Fragment>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Text className=' text-xl'>Factored Unit</Text>
      </div>
      <TableComponent
        columns={getColumns()}
        loading={loadingFactoredUnit}
        size='small'
        data={factoredUnit}
      />
    </Fragment>
  );
};

export default FactoredUnit;
