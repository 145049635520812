import dayjs from 'dayjs';
import { Divider, Space } from 'antd';
import { Typography } from 'antd';
import { MdOutlinePreview } from 'react-icons/md';
import { ViewModal } from '../factoringUnit/viewFactoringUnit/viewFactoringUnit';
import { ApproveBankFinance } from './approveAcceptBankFinance';
import { DateFilter, TextFilter } from '../../../utils/formFilters';
import { Permissions } from '../../../configs/permissions';

const { Text } = Typography;

export const acceptBankFinanceColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Factoring Unit No</Text>
        <Divider />
        <TextFilter type='factoringUnitNo' />
      </div>
    ),
    key: 'factoringUnitNo',
    render: (_, record) => {
      return <ViewModal label={record.factoringUnitNo} data={record} />;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Financier Name</Text>
        <Divider />
        <TextFilter type='financierName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.createdByFinancier.entityName}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Factoring Amount</Text>
        <Divider />
        <TextFilter type='factoringAmount' />
      </div>
    ),
    dataIndex: 'factoringAmount',
    key: 'factoringAmount',
    render: (_, record) => {
      return <Text>{record.factoringAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Invoice Date</Text>
        <Divider />
        <DateFilter type='invoiceDate' />
      </div>
    ),
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (_, record) => {
      const invoiceDate = dayjs(record.invoices[0].invoiceDate).format('DD-MM-YYYY');
      return <Text>{invoiceDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }} className='max-w250'>
        <Text>Due Date</Text>
        <Divider />
        <DateFilter type='invoiceDueDate' />
      </div>
    ),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_, record) => {
      const dueDate = dayjs(record.invoices[0].invoiceDueDate).format('DD-MM-YYYY');
      return <Text>{dueDate}</Text>;
    }
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => {
      return (
        <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {Permissions('acceptBankFinance', 'approveAcceptBankFinance') && (
            <ApproveBankFinance
              label={<MdOutlinePreview size='20px' color='black' />}
              data={record}
            />
          )}
        </Space>
      );
    }
  }
];
