import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { tw } from 'twind';
import { FaRegAddressCard } from 'react-icons/fa';
import { getBankKycDetails } from '../../../services/buyerSeller';
import { useSelector } from 'react-redux';
import { BankCardViewModal } from './bankCard';

export const BankKycViewModal = ({ data }) => {
  const {
    bankKycDetails = {},
    editBuyerSeller = {},
    loadingBankKycDetails = false
  } = useSelector(state => ({
    bankKycDetails: state.buyerSeller.bankKycDetails,
    editBuyerSeller: state.buyerSeller.editBuyerSeller,
    loadingBankKycDetails: state.buyerSeller.loadingBankKycDetails
  }));

  const { bankAccApiProvider = '' } = editBuyerSeller.data.apiProviders || {};

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      if (data.bankAccVerified) {
        getBankKycDetails(bankAccApiProvider, data.accountNo);
      }
    }
  }, [isModalVisible]);

  return (
    <Fragment>
      <div className={tw`flex justify-center`}>
        <Button
          style={{
            borderRadius: '8px',
            padding: '0px 5px 0px 5px'
          }}
          onClick={() => setIsModalVisible(true)}
        >
          <FaRegAddressCard size='20px' color='black' />
        </Button>
      </div>
      <Modal open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null} centered>
        {loadingBankKycDetails && (
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <Spin spinning={loadingBankKycDetails} />
          </div>
        )}
        {!loadingBankKycDetails && (
          <BankCardViewModal
            bankData={data}
            kycData={bankKycDetails}
            loadingKycData={loadingBankKycDetails}
          />
        )}
      </Modal>
    </Fragment>
  );
};
