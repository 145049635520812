import DeletePopup from '../../deletePopUp';
import { Button, Space, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { Typography } from 'antd';
import { ModalTextFilter } from '../../../utils/formFilters';
import { editAddress } from '../../../redux/buyerSeller/actions';

const { Text } = Typography;

export const addressColumns = [
  {
    title: (
      <div>
        <Text>Address Type</Text>
        <Divider />
        <ModalTextFilter type='addressType' />
      </div>
    ),
    key: 'addressType',
    render: (_, record) => {
      return <Text>{record.addressType.name}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Address</Text>
        <Divider />
        <ModalTextFilter type='address' />
      </div>
    ),
    dataIndex: 'address',
    key: 'address',
    render: (_, record) => {
      const address = `${record.addressLineOne}, ${record.addressLineTwo}, ${record.subDistrict}, ${record.postOffice}, ${record.district}, ${record.state}, ${record.pinCode}`;
      return <Text>{address}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Pin Code</Text>
        <Divider />
        <ModalTextFilter type='address' />
      </div>
    ),
    dataIndex: 'pinCode',
    key: 'pinCode',
    render: (_, record) => {
      return <Text>{`${record.pinCode}`}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const address = `${record.addressLineOne} ${record.addressLineTwo} ${record.subDistrict}  ${record.postOffice} ${record.district} ${record.state} ${record.pinCode}`;
      return (
        <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            name='update-address'
            style={{ borderRadius: '8px', padding: '4px' }}
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              editAddress(true, record);
            }}
          >
            <MdModeEditOutline size='20px' color='black' />
          </Button>
          <DeletePopup type='address' id={record.id} entityId={record.entity.id} data={address} />
        </Space>
      );
    }
  }
];
