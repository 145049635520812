import { financierTypes } from '../redux/financier/types';
import { getURL } from '../configs/apiURL';
import { message } from '../utils/messages';
import { fetchData } from '../utils/fetchData';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const getAllFinancier = async (params = '') => {
  params = params || '';
  let url = getURL(`financiers?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    financierTypes.GET_FINANCIER_LOADING,
    financierTypes.GET_FINANCIER_SUCCESS,
    financierTypes.GET_FINANCIER_FAILURE,
    'something went wrong'
  );
};

export const getFinancierBanks = async (params = '') => {
  params = params || '';
  let url = getURL(`/financiers/profile/bank-details?limit=${PAGE_LIMIT}&${params}`);
  return fetchData(
    url,
    financierTypes.GET_FINANCIER_BANKS_LOADING,
    financierTypes.GET_FINANCIER_BANKS_SUCCESS,
    financierTypes.GET_FINANCIER_BANKS_FAILURE,
    'something went wrong'
  );
};

export const getFinancierConfigurationSector = async (type, params = '') => {
  params = params || '';
  let url = getURL(`${type}?limit=${PAGE_LIMIT}&${params ? params : ''}`);
  return fetchData(
    url,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_LOADING,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_SUCCESS,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_SECTOR_FAILURE,
    'something went wrong'
  );
};

export const getFinancierConfigurationType = async (type, params = '') => {
  params = params || '';
  let url = getURL(`${type}?limit=${PAGE_LIMIT}&${params ? params : ''}`);
  return fetchData(
    url,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_LOADING,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_SUCCESS,
    financierTypes.GET_FINANCIER_CONFIGURATIONS_TYPE_FAILURE,
    'something went wrong'
  );
};

export const updateFinancierConfigTypeStatus = async (type, id, data) => {
  let url = getURL(`${type}/${id}`);

  const params = localStorage.getItem('params') || '';

  const successCallBack = () => {
    getFinancierConfigurationType(type, params);
  };

  return fetchData(
    url,
    null,
    null,
    null,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};

export const updateFinancierConfigSectorStatus = async (type, id, data) => {
  let url = getURL(`${type}/${id}`);

  const params = localStorage.getItem('params') || '';

  const successCallBack = () => {
    getFinancierConfigurationSector(type, params);
  };

  return fetchData(
    url,
    null,
    null,
    null,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};

export const getFinancierProfile = async () => {
  let url = getURL(`financiers/profile`);
  return fetchData(
    url,
    financierTypes.GET_FINANCIER_PROFILE_LOADING,
    financierTypes.GET_FINANCIER_PROFILE_SUCCESS,
    financierTypes.GET_FINANCIER_PROFILE_FAILURE,
    'something went wrong'
  );
};

export const updateFinancierStatus = async (_id, data) => {
  const url = getURL(`financiers/${_id}`);

  const params = localStorage.getItem('params') || '';

  const successCallBack = () => {
    getAllFinancier(params);
  };

  return fetchData(
    url,
    null,
    null,
    null,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};
