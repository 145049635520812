import React from 'react';

const WhiteLogo = () => (
  <div style={styles.container}>
    {localStorage.getItem('logoImage') ? (
      <img
        alt='supply-chain-finance'
        style={styles.image}
        src={`${localStorage.getItem('logoImage')}`}
      />
    ) : (
      <h2 style={styles.textContainer}>
        <div style={styles.text1}></div>
        <div style={styles.text2}>Invoices</div>
      </h2>
    )}
  </div>
);

export default WhiteLogo;

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  textContainer: {
    color: 'white',
    fontSize: '25px',
    display: 'flex',
    fontFamily: 'Didot',
    letterSpacing: 2
  },
  image: {
    display: 'block',
    height: 'auto',
    maxHeight: '2.5rem',
    width: 'auto',
    maxWidth: 'min-content'
  },
  text1: {
    fontWeight: 'bold'
  },
  text2: {
    fontWeight: 'initial'
  }
};

// <img
//     src={"/logo.png"}
//     alt="Supply Chain Finance"
//     className={tw`block h-auto max-h-12 w-auto max-w-min`}
// />
