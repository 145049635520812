import { Button, Space, Divider } from 'antd';
import { MdModeEditOutline, MdCreditScore } from 'react-icons/md';
import { Permissions } from '../../../../configs/permissions';
import { Typography } from 'antd';
import { TextFilter, DateFilter } from '../../../../utils/formFilters';
import { ApproveInvoiceModal } from '../approveInvoice/approveInvoice';
import { editInvoice } from '../../../../redux/buyerSeller/actions';
import { ViewInvoice } from '../viewInvoice/viewInvoice';
import dayjs from 'dayjs';
import DeletePopup from '../../../deletePopUp';

const { Text } = Typography;

export const invoiceColumns = [
  {
    title: (
      <div>
        <Text>Invoice No</Text>
        <Divider />
        <TextFilter type='invoiceNo' />
      </div>
    ),
    key: 'InvoiceNo',
    render: (_, record) => {
      return <ViewInvoice label={record.invoiceNo} data={record} showActionHistory={false} />;
    }
  },
  {
    title: (
      <div>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    dataIndex: 'buyerName',
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Seller Name</Text>
        <Divider />
        <TextFilter type='sellerName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.buyerSellerLink.seller.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Invoice Amount</Text>
        <Divider />
        <TextFilter type='invoiceAmount' />
      </div>
    ),
    dataIndex: 'invoiceAmount',
    key: 'invoiceAmount',
    render: (_, record) => {
      return <Text>{record.invoiceAmount}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Invoice Date</Text>
        <Divider />
        <DateFilter type='invoiceDate' />
      </div>
    ),
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (_, record) => {
      const invoiceDate = dayjs(record.invoiceDate).format('DD-MM-YYYY');
      return <Text>{invoiceDate}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Due Date</Text>
        <Divider />
        <DateFilter type='invoiceDueDate' />
      </div>
    ),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_, record) => {
      const dueDate = dayjs(record.invoiceDueDate).format('DD-MM-YYYY');
      return <Text>{dueDate}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const invoiceDetail = `${record.invoiceNo} - ${record.buyerSellerLink.buyer.entityName} - ${record.buyerSellerLink.seller.entityName} `;
      const nextCheckerUser = localStorage.getItem('userId');
      return (
        <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {Permissions('invoices', 'editInvoice') &&
            (record.nextCheckerUser === null
              ? true
              : false || record.nextCheckerUser.id === nextCheckerUser) && (
              <Button
                name='update-invoice'
                style={{ borderRadius: '8px', padding: '4px' }}
                onClick={() => {
                  editInvoice(true, record);
                }}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
          {Permissions('invoices', 'approveInvoice') && (
            <ApproveInvoiceModal
              label={<MdCreditScore size='20px' color='black' />}
              data={record}
            />
          )}
          {Permissions('invoices', 'deleteInvoice') && (
            <DeletePopup type='invoiceManagement' id={record.id} data={invoiceDetail} />
          )}
        </Space>
      );
    }
  }
];
