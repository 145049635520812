import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Progress, Button, Card, Form, Modal, Upload } from 'antd';
import { getURL } from '../../../../configs/apiURL';
import { useForm } from 'antd/es/form/Form';
import { message } from '../../../../utils/messages';
import { tw } from 'twind';
import { RULES } from '../../../../utils/formValidations';
import { UploadOutlined } from '@ant-design/icons';
import { notifications } from '../../../../utils/notifications';
import { getAllFactoringUnit } from '../../../../services/factoringUnit';
import SelectComponent from '../../../antdComponent/Select';
import Cookies from 'js-cookie';
import axios from 'axios';

const { Text } = Typography;

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;
const FILE_EXT = process.env.REACT_APP_FILE_EXT;

const UploadFactoringUnit = ({
  title,
  name,
  setDisableDropdown,
  disableDropdown,
  buyerDropdown,
  sellerDropdown,
  buyerOnChange,
  params
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState();
  const [error, setError] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [form] = useForm();

  const entityId = Cookies.get('entityId');
  const entityCategory = localStorage.getItem('entityCategory');
  const userId = localStorage.getItem('userId');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDisableDropdown(true);
    switch (entityCategory) {
      case 'SELLER': {
        form.resetFields();
        form.setFieldValue('sellerName', entityId);
        break;
      }
      case 'BUYER': {
        form.resetFields();
        form.setFieldValue('buyerName', entityId);
        break;
      }
      default:
        form.resetFields();
    }
  };

  const getFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = file => {
    const fileSizeKiloBytes = file.size / 1024;
    setError(fileSizeKiloBytes > MAX_FILE_SIZE ? 'File size is greater than maximum limit' : false);
    return false;
  };

  const onSubmit = async value => {
    if (error) return;
    // setLoader(true);
    const file = value.uploadDocuments[0];
    const formData = new FormData();
    formData.append('buyerId', value.buyerName);
    formData.append('sellerId', value.sellerName);
    formData.append('createdByUserId', userId);
    formData.append('file', file.originFileObj, file.originFileObj.name);
    const ACCESS_TOKEN = Cookies.get('ACCESS_TOKEN');

    let config = {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadPercentage(() => percentCompleted);
      }
    };

    const res = await axios.post(getURL(`factoring-units-using-file`), formData, config);
    if (res && res.status === 200) {
      notifications.success({ message: message.DOCUMENT_UPLOADED });
      setIsModalVisible(false);
      handleCancel();
    } else {
      notifications.error({
        message: res.data.error.message || 'something went wrong'
      });
    }

    getAllFactoringUnit(params);
    setUploadPercentage(0);
    setLoader(false);
  };

  useEffect(() => {
    switch (entityCategory) {
      case 'SELLER': {
        form.setFieldsValue({
          sellerName: entityId
        });
        break;
      }
      case 'BUYER': {
        form.setFieldsValue({
          buyerName: entityId
        });
        break;
      }
      default:
        break;
    }
  }, []);

  return (
    <>
      <Button name={name} type='primary' className='rounded' onClick={showModal}>
        <div className={tw`text-xs md:text-sm flex items-center`}>{title}</div>
      </Button>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          size='large'
          onFinish={value => {
            onSubmit(value);
          }}
          onFinishFailed={errorInfo => {
            console.log(errorInfo);
          }}
          autoComplete='off'
        >
          <div className={tw`w-full flex flex-row gap-5 my-4`}>
            <Text className='mx-2 mt-2'>Download Sample File</Text>
            <a
              target={'_blank'}
              rel='noreferrer'
              href='http://3.108.139.108/dev-server/documents/FU_Invoice_Upload_Sample.xlsx'
            >
              <Button>Download</Button>
            </a>
          </div>
          <Card title='Upload Factoring Unit' className='mt-5'>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='buyerName' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='Buyer Name'
                    required
                    onChange={buyerId => {
                      buyerOnChange(buyerId);
                      if (entityCategory === 'FINANCIER')
                        form.setFieldsValue({
                          sellerName: undefined
                        });
                    }}
                    placeholder='Select'
                    allowClear={true}
                    options={buyerDropdown}
                    disabled={entityCategory === 'BUYER'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='sellerName' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='Seller Name'
                    required
                    placeholder='Select'
                    allowClear={true}
                    options={sellerDropdown}
                    disabled={(() => {
                      if (entityCategory === 'SELLER') {
                        return true;
                      } else if (disableDropdown && entityCategory === 'FINANCIER') {
                        return true;
                      }
                      return false;
                    })()}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className='w-full flex flex-row  flex-start '>
              <Form.Item name='uploadDocuments' getValueFromEvent={getFile} rules={RULES.upload}>
                <Upload accept={FILE_EXT} beforeUpload={beforeUpload} maxCount={1}>
                  <Button className='w-40' icon={<UploadOutlined />}>
                    Browse
                  </Button>
                  <br />
                  <p className={tw`text-red-500`}>
                    {uploadPercentage ? (
                      <Progress className={tw`w-36`} percent={uploadPercentage} />
                    ) : (
                      error
                    )}
                  </p>
                </Upload>
              </Form.Item>
            </div>
            <Button type='primary' htmlType='submit' size='middle' loading={loader}>
              Upload
            </Button>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default UploadFactoringUnit;
