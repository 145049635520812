import DeletePopup from '../deletePopUp';
import { Button, Space, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { Typography } from 'antd';
import { tw } from 'twind';
import { TextFilter } from '../../utils/formFilters';
import { Permissions } from '../../configs/permissions';
import { editFinancierBank } from '../../redux/financier/actions';

const { Text } = Typography;

export const financierUserBankAccountColumns = [
  {
    title: <Text> Default Account </Text>,
    key: 'defaultAccount',
    render: (_, record) => {
      return <Text>{record.isDefault === 1 ? 'Yes' : 'No'}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Type</Text>
        <Divider />
        <TextFilter type='bankAccountType' />
      </div>
    ),
    dataIndex: 'accountType',
    key: 'accountType',
    render: (_, record) => {
      return <Text>{record.bankAccountType.name}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Tittle</Text>
        <Divider />
        <TextFilter type='accountTitle' />
      </div>
    ),
    dataIndex: 'accountTitle',
    key: 'accountTitle',
    render: (_, record) => {
      return <Text>{record.accountTitle}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Number</Text>
        <Divider />
        <TextFilter type='accountNo' />
      </div>
    ),
    dataIndex: 'accountNo',
    key: 'accountNo',
    render: (_, record) => {
      return <Text>{record.accountNo}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>IFSC</Text>
        <Divider />
        <TextFilter type='ifsc' />
      </div>
    ),
    dataIndex: 'ifsc',
    key: 'ifsc',
    render: (_, record) => {
      return <Text>{record.ifsc}</Text>;
    }
  },

  {
    title: <Text> Action </Text>,
    key: 'action',
    render: (_, record) => {
      const bank = `${record.bankName} - ${record.accountNo}`;
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            {Permissions('bankAccount', 'editBankAccount') && (
              <Button
                name='update-bank'
                style={{ borderRadius: '8px', padding: '0px 5px 0px 5px' }}
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
                  editFinancierBank(true, record);
                }}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
            {Permissions('bankAccount', 'deleteBankAccount') && (
              <DeletePopup type='financier-bank' id={record.id} entityId={record.id} data={bank} />
            )}
          </Space>
        </div>
      );
    }
  }
];
