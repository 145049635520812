import Cookies from 'js-cookie';
import DeletePopup from '../deletePopUp';
import { Button, Space, Typography, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { tw } from 'twind';
import { editChecker } from '../../redux/factoringUnit/actions';
import { TextFilter } from '../../utils/formFilters';
import { Permissions } from '../../configs/permissions';

const { Text } = Typography;

export const checkerLevelColumn = [
  {
    title: (
      <div>
        <Text>Level Name</Text>
        <Divider />
        <TextFilter type='levelName' />
      </div>
    ),
    dataIndex: 'level',
    key: 'level',
    render: (_, record) => {
      return <Text>{record.levelName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Checker Name</Text>
        <Divider />
        <TextFilter type='nameOfCheckerUser' />
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      return (
        <Text>
          {record.user.firstName} {record.user.lastName}
        </Text>
      );
    }
  },
  {
    title: <Text>Actions</Text>,
    key: 'action',
    render: (_, record) => {
      const checkerDetail = `${record.id}`;
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            {Permissions('checkerLevel', 'editCheckerLevel') && (
              <Button
                name='update-checker'
                style={{ borderRadius: '8px', padding: '5px' }}
                onClick={() => {
                  editChecker(true, record);
                }}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
            {Permissions('checkerLevel', 'deleteCheckerLevel') && (
              <DeletePopup
                type='checker'
                id={record.id}
                entityId={Cookies.get('entityId')}
                data={checkerDetail}
              />
            )}
          </Space>
        </div>
      );
    }
  }
];
