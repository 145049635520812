import React from 'react';
import useFetch from '../../hooks/useFetch';
import { useEffect, useState } from 'react';
import { consentInit } from '../../utils/handlers';
import { tw } from 'twind';
import { getURL } from '../../configs/apiURL';
import { Modal, Button, Form, Checkbox, Space, Input } from 'antd';
import { RiErrorWarningFill } from 'react-icons/ri';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const Bank = ({
  isUpdate,
  onSubmit,
  consent: { agree, stage, formInitial, formData, bankAcckyc, bankAccVerified },
  setConsent,
  resetForm,
  editBuyerSellerData
}) => {
  const apiProviders = JSON.parse(localStorage.getItem('apiProviders'));

  const { editBanks = {}, editBuyerSeller = {} } = useSelector(state => ({
    editBanks: state.buyerSeller.editBank,
    editBuyerSeller: state.buyerSeller.editBuyerSeller
  }));

  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();
  const [checked, setChecked] = useState(false);

  const { bankAccApiProvider } = editBuyerSeller?.data?.apiProviders || apiProviders || {};

  const onConfim = async action => {
    let response, next;
    switch (action) {
      case 'accountSubmit':
        response = await fetch(getURL(`${bankAccApiProvider.toLowerCase()}/kyc/bank-account`), {
          method: 'POST',
          body: JSON.stringify({
            consent: 'Y',
            accountNumber: formData.accountNo,
            accountHolderName: editBuyerSellerData.entityDetails.entityName,
            ifsc: formData.ifscCode,
            nameMatchType: 'entity',
            bankAccId: editBanks?.data?.id
          })
        });
        setConsent({
          bankAccNo: formData.accountNo,
          bankAcckyc: 1,
          bankAccVerified: response.status === 200 ? 1 : 0
        });
        next = 'submit';
        break;
      default:
        next = 'accountNo';
        break;
    }
    setConsent({ stage: next });
  };

  const handleSubmit = async () => {
    const response = await onSubmit({
      ...formData,
      bankAccVerified: bankAccVerified
    });
    if (response && resetForm) {
      resetForm();
      setConsent({ ...consentInit });
    }
  };

  const show = type => {
    switch (type) {
      case 'accountNo':
        if (
          isUpdate &&
          formData.accountNo &&
          formData.accountNo !== formInitial.accountNo &&
          !formInitial.bankAccVerified
        ) {
          return true;
        }
        return !isUpdate && formData.accountNo ? true : false;
      default:
        return false;
    }
  };

  const isLoading = useCallback(
    action => {
      return stage === action && loading;
    },
    [loading, stage]
  );

  const isDisable = useCallback(
    action => {
      return stage !== action;
    },
    [loading, stage]
  );

  useEffect(() => {
    form.setFieldsValue({ accountNo: formData.accountNo });
  }, []);

  return (
    <Modal
      open={agree}
      className='ant-consent-modal'
      onCancel={() => setConsent({ agree: !agree })}
      footer={null}
    >
      {checked && <h1 className={tw`text-center text-lg font-serif`}>KYC Consent & Policy</h1>}
      <Form form={form} size='medium' autoComplete='off'>
        {checked && (
          <div>
            <br />
            {show('accountNo') && (
              <div className={tw`mb-4`}>
                <h3 className={tw`font-medium mb-2 ml-1`}>Account Number</h3>
                <Space>
                  <Form.Item name='accountNo' style={{ margin: 0 }}>
                    <Input
                      placeholder='ENTER Account Number'
                      disabled
                      suffix={
                        (bankAcckyc && bankAccVerified && <MdCheckCircle color='green' />) || (
                          <MdCancel color='red' />
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    style={{ height: 31 }}
                    onClick={() => onConfim('accountSubmit')}
                    loading={isLoading('accountNo')}
                    disabled={isDisable('accountNo')}
                  >
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
            {checked && (
              <div className={tw`flex flex-row-reverse gap-2`}>
                <Button
                  type='primary'
                  danger
                  name='delete'
                  onClick={() => setChecked(() => !checked)}
                  htmlType='submit'
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  size='md'
                  loading={isLoading('submit')}
                  disabled={isDisable('submit')}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        )}
        {!checked && (
          <div>
            <div className={classNames.center}>
              <RiErrorWarningFill size={50} color='darkorange' />
              <p className={classNames.text}>Are you sure ?</p>
            </div>
            <div className={classNames.container}>
              <Form.Item>
                <Checkbox onChange={() => setChecked(() => !checked)}>
                  Yes, I understand and agree for the KYC Consent & Policy.
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};
export default Bank;

const classNames = {
  center: tw`flex flex-col justify-center items-center`,
  container: tw`flex justify-center mt-2`,
  text: tw`text-xl font-serif`
};
