import React from 'react';
import TableComponent from '../antdComponent/Table';
import { useEffect } from 'react';
import { financierColumns } from './financierColumns';
import { getAllFinancier } from '../../services/financier';
import { Button, Typography } from 'antd';
import { tw } from 'twind';
import { useSelector } from 'react-redux';
import { RiAddCircleFill } from 'react-icons/ri';
import { platformRolesPermission } from '../../configs/permissions';

const { Text } = Typography;

const FinancierPlatform = ({ financierData, loading, setFinancierAdd }) => {
  const { params } = useSelector(state => ({
    params: state.mutations.params
  }));

  useEffect(() => {
    getAllFinancier(params);
  }, [params]);

  return (
    <div>
      <div className={tw`flex flex-col md:flex-row justify-between content-divider`}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: '600px',
            textAlign: 'center'
          }}
        >
          Manange Financier
        </Text>
        {platformRolesPermission('financier', 'addFinancier') && (
          <Button
            type='primary'
            name='add-financier'
            style={{ borderRadius: '4px' }}
            onClick={() => {
              setFinancierAdd(true);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4
              }}
            >
              <RiAddCircleFill size='20px' /> Add Financier
            </div>
          </Button>
        )}
      </div>
      <TableComponent columns={financierColumns} data={financierData} loading={loading} />
    </div>
  );
};

export default FinancierPlatform;
