import React from 'react';
import dayjs from 'dayjs';
import SelectComponent from '../../../antdComponent/Select';
import DateComponent from '../../../antdComponent/Date';
import TextInput from '../../../antdComponent/Input';
import useFetch from '../../../../hooks/useFetch';
import { useState, useEffect, useMemo } from 'react';
import { getURL } from '../../../../configs/apiURL';
import { Button, Form, Row, Col, Typography, Spin } from 'antd';
import { notifications } from '../../../../utils/notifications';
import { tw } from 'twind';
import { RULES } from '../../../../utils/formValidations';
import { getDisbursementAmount } from '../../../../utils/helpers';
import { getBuyerSellerBanks } from '../../../../services/buyerSeller';
import { useSelector } from 'react-redux';
import { message } from '../../../../utils/messages';
import { getAllFactoredUnit } from '../../../../services/factoringUnit';

const { Text } = Typography;

export const PaymentForm = ({ isModalVisible, setIsModalVisible, fetchPaymentData, data }) => {
  const {
    params,
    banksData = {},
    fUPayment = {},
    loadingFUPayment
  } = useSelector(state => ({
    params: state.mutations.params,
    banksData: state.buyerSeller.bank,
    fUPayment: state.factoringUnit.fUPayment,
    loadingFUPayment: state.factoringUnit.loadingFUPayment
  }));

  const [form] = Form.useForm();
  const [fetch, loading] = useFetch();
  const [releaseTo, setReleaseTo] = useState(null);
  const [status, setStatus] = useState(fUPayment.status);

  const defaultBank = useMemo(() => {
    return banksData?.data?.find(bank => bank.isDefault === 1) || {};
  }, [banksData, fUPayment]);

  const initialValue = useMemo(() => {
    if (Object.keys(data).length && Object.keys(defaultBank).length) {
      if (releaseTo) {
        return {
          releaseTo: releaseTo,
          bankDetailsId: defaultBank.id,
          accountHolderName: defaultBank.accountTitle,
          accountNo: defaultBank.accountNo,
          ifsc: defaultBank.ifsc
        };
      } else {
        return {
          releaseTo: 'SELLER',
          bankDetailsId: defaultBank.id,
          accountHolderName: defaultBank.accountTitle,
          accountNo: defaultBank.accountNo,
          ifsc: defaultBank.ifsc,
          collectedAmount: fUPayment.totalPaymentAmount,
          paymentAmount: getDisbursementAmount(data) - fUPayment.totalPaymentAmount,
          remainingAmount: 0,
          referenceNo: null,
          narration: 'Factored amount disbursed',
          initiateDate: dayjs(new Date()),
          status: 'INITIATED',
          transactionDate: null
        };
      }
    }
    return {};
  }, [data, defaultBank, fUPayment]);

  const bankDataDropDown = useMemo(() => {
    return banksData?.data?.map(item => ({
      value: item.id,
      label: item.bankName
    }));
  }, [banksData, fUPayment]);

  const onSubmit = async value => {
    const formData = {
      releaseTo: value.releaseTo,
      bankDetailsId: value.bankDetailsId,
      accountHolderName: value.accountHolderName,
      accountNo: value.accountNo,
      ifsc: value.ifsc,
      collectedAmount: parseFloat(value.collectedAmount),
      paymentAmount: parseFloat(value.paymentAmount),
      remainingAmount: parseFloat(value.remainingAmount),
      referenceNo: value.referenceNo,
      narration: value.narration,
      dateOfInitiate: value.initiateDate,
      status: value.status,
      dateOfTransaction: value.transactionDate
    };
    const res = await fetch(getURL(`factoring-units/${data.factoringUnitNo}/payments`), {
      method: 'POST',
      body: JSON.stringify(formData)
    });
    if (res && res.status === 200) {
      if (
        value.status === 'SUCCESSFUL' &&
        parseInt(fUPayment.totalPaymentAmount + parseInt(value.paymentAmount)) >=
          getDisbursementAmount(data)
      ) {
        setIsModalVisible(false);
        getAllFactoredUnit(params);
      } else {
        fetchPaymentData();
        setReleaseTo(null);
      }
      notifications.success({ message: message.PAYMENT_CREATED });
    } else {
      notifications.error({
        message: res.data.error.message
      });
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue(initialValue);
    }
  }, [form, initialValue, isModalVisible]);

  return (
    <>
      {loadingFUPayment && (
        <div className='payment-tabs content-center'>
          <Spin spinning={loadingFUPayment} />
        </div>
      )}
      <Form
        form={form}
        name='bank'
        size='large'
        onFinish={onSubmit}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        {!loadingFUPayment && (
          <Row className={tw`my-6 md:mb-0`}>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'Release To'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='releaseTo' rules={RULES.selectRequired}>
                <SelectComponent
                  placeholder='Buyer/Seller'
                  allowClear={true}
                  options={[
                    { label: 'Buyer', value: 'BUYER' },
                    { label: 'Seller', value: 'SELLER' }
                  ]}
                  onChange={value => {
                    getBuyerSellerBanks(data.buyerSellerLink[value.toLowerCase()].id, null, true);
                    setReleaseTo(value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'Bank Account'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='bankDetailsId' rules={RULES.selectRequired}>
                <SelectComponent
                  placeholder='Select'
                  allowClear={true}
                  options={bankDataDropDown}
                  onChange={bankId => {
                    const bank = banksData?.data?.find(bank => bank.id === bankId);
                    form.setFieldsValue({
                      bankDetailsId: bank.id,
                      accountHolderName: bank.accountTitle,
                      accountNo: bank.accountNo,
                      ifsc: bank.ifsc
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'A/C Holder Name'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='accountHolderName' rules={RULES.name}>
                <TextInput placeholder='Enter Account Holder Name' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'A/C Number'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='accountNo' rules={RULES.number}>
                <TextInput placeholder='Enter Account Number' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'IFSC'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='ifsc' rules={RULES.ifsc}>
                <TextInput placeholder='Enter IFSC' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'Collected Amount'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='collectedAmount' rules={RULES.amount}>
                <TextInput placeholder='Enter Collected Amount' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'Amount'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='paymentAmount' rules={RULES.amount}>
                <TextInput
                  placeholder='Enter Payment Amount'
                  onChange={e => {
                    form.setFieldsValue({
                      paymentAmount: e.target.value,
                      remainingAmount:
                        getDisbursementAmount(data) -
                        fUPayment.totalPaymentAmount -
                        parseFloat(e.target.value || 0)
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'Remaining Amount'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='remainingAmount' rules={RULES.amount}>
                <TextInput placeholder='Enter Remaining Amount' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'Date'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='initiateDate' rules={RULES.date}>
                <DateComponent placeholder='Select Date' />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'Narration'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='narration' rules={RULES.inputRequired}>
                <TextInput placeholder='Enter Narration' />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'Status'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='status' rules={RULES.selectRequired}>
                <SelectComponent
                  placeholder='Select'
                  allowClear={true}
                  options={[
                    { label: 'Initiated', value: 'INITIATED' },
                    { label: 'Successful', value: 'SUCCESSFUL' },
                    { label: 'Failed', value: 'FAILED' },
                    { label: 'Cancelled', value: 'CANCELLED' }
                  ]}
                  onChange={status => {
                    form.setFieldsValue({ status: status });
                    setStatus(status);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={1} />
            <Col xs={24} md={5}>
              <Text className={classNames.baseText}>{'Reference Number'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item
                name='referenceNo'
                rules={status === 'SUCCESSFUL' ? RULES.referenceNo : RULES.optionalReferenceNo}
              >
                <TextInput placeholder='Enter Reference Number' />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text className={classNames.baseText}>{'Transaction Date'}</Text>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item name='transactionDate' rules={status === 'SUCCESSFUL' ? RULES.date : []}>
                <DateComponent placeholder='Select Date' />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Button
          loading={loading || loadingFUPayment}
          type='primary'
          htmlType='submit'
          size='middle'
          className={tw`mb-4`}
        >
          Add
        </Button>
      </Form>
    </>
  );
};

const classNames = {
  baseText: tw`block leading-10 md:text-center`
};
