import Cookies from 'js-cookie';
import { userTypes } from '../redux/user/types';
import { store } from '../redux';
import { getURL } from '../configs/apiURL';
import { fetchRequest } from '../utils/fetchRequest';
import { notifications } from '../utils/notifications';
import { message } from '../utils/messages';
import { fetchData } from '../utils/fetchData';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;
const THEME = process.env.REACT_APP_THEME;

export const getAllUsers = async (params = '') => {
  params = params || '';
  const entityCategory = localStorage.getItem('entityCategory');
  const entityId = Cookies.get('entityId');
  let url = '';
  switch (entityCategory) {
    case 'BUYER':
    case 'SELLER':
    case 'FINANCIER':
      url = getURL(`platform/users/entity/${entityId}?limit=${PAGE_LIMIT}&${params}`);
      break;
    default:
      url = getURL(`platform/users?limit=${PAGE_LIMIT}&${params}`);
      break;
  }
  return fetchData(
    url,
    userTypes.GET_USER_LOADING,
    userTypes.GET_USER_SUCCESS,
    userTypes.GET_USER_FAILURE,
    'something went wrong'
  );
};

export const editUserStatus = async (_id, data) => {
  const url = getURL(`platform/users/${_id}`);

  const params = localStorage.getItem('params') || '';

  const successCallBack = () => {
    getAllUsers(params);
  };
  return fetchData(
    url,
    null,
    null,
    null,
    'something went wrong',
    message.UPDATE_STATUS,
    successCallBack,
    data
  );
};

export const getAllUserByEntity = async id => {
  const checkerExist = await fetchRequest(getURL(`platform/users/checker-levels`));

  const userIds = checkerExist.data.data.map(checker => checker.user.id);

  let url = userIds.length
    ? getURL(
        `platform/users/entity/${id}?userType=CHECKER&excludeUserIds=${encodeURIComponent(userIds)}`
      )
    : getURL(`platform/users/entity/${id}?userType=CHECKER`);

  return fetchData(
    url,
    userTypes.GET_USER_BY_ENTITY_LOADING,
    userTypes.GET_USER_BY_ENTITY_SUCCESS,
    userTypes.GET_USER_BY_ENTITY_FAILURE,
    'something went wrong'
  );
};

export const getProfile = async () => {
  store.dispatch({ type: userTypes.GET_PROFILE_LOADING });

  try {
    let response = await fetchRequest(getURL(`platform/users/profile`));

    if (response.hasError) {
      throw new Error('something went wrong');
    }

    const { id, themeHexcode, entityCategory, createdByFinancier } =
      response.data.data.entity || {};

    const {
      panApiProvider,
      aadhaarApiProvider,
      gstApiProvider,
      gstItrApiProvider,
      bankAccApiProvider,
      bankStmtApiProvider,
      creditReportApiProvider,
      udyamRegNoApiProvider: udyamApiProvider
    } = response.data.data.entity?.apiProviders || {};

    localStorage.setItem('createdByFinancierId', createdByFinancier?.id || null);
    localStorage.setItem(
      'themeHexcode',
      `#${createdByFinancier?.themeHexcode || themeHexcode || THEME.slice(1)}`
    );
    localStorage.setItem(
      'apiProviders',
      JSON.stringify({
        panApiProvider,
        udyamApiProvider,
        aadhaarApiProvider,
        gstApiProvider,
        gstItrApiProvider,
        bankAccApiProvider,
        bankStmtApiProvider,
        creditReportApiProvider
      })
    );

    store.dispatch({
      type: userTypes.GET_PROFILE_SUCCESS,
      payload: {
        ...response.data.data,
        entityCategory,
        entityId: id,
        themeHexcode: `#${createdByFinancier?.themeHexcode || themeHexcode || THEME.slice(1)}`
      }
    });
  } catch (error) {
    console.warn(error);
    store.dispatch({ type: userTypes.GET_PROFILE_FAILURE });
    if (error) {
      notifications.error({
        message: error.message
      });
    }
  }
};
