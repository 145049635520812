import dayjs from 'dayjs';

export const FUDetails = data => {
  return [
    {
      key1: 'Factoring Unit No',
      value1: data.factoringUnitNo,
      key2: 'Listed Date',
      value2: dayjs(data.fuListedDate).format('DD-MM-YYYY')
    },
    {
      key1: 'Buyer',
      value1: data.buyerSellerLink.buyer.entityName,
      key2: 'Seller',
      value2: data.buyerSellerLink.seller.entityName
    },
    {
      key1: 'Invoice Date',
      value1: dayjs(data.invoices[0].invoiceDate).format('DD-MM-YYYY'),
      key2: 'Due Date',
      value2: dayjs(data.invoices[0].invoiceDueDate).format('DD-MM-YYYY')
    },
    {
      key1: 'Invoice Amount',
      value1: data.sumOfInvoiceAmounts.invoiceAmount,
      key2: 'Discount Amount',
      value2: data.sumOfInvoiceAmounts.discountAmount
    },
    {
      key1: 'Tax Amount',
      value1: data.sumOfInvoiceAmounts.taxAmount,
      key2: 'Total',
      value2: data.sumOfInvoiceAmounts.totalAmount
    },
    {
      key1: 'Advance Tax (%)',
      value1: 0, // not-decided
      key2: ' Advance Tax Amount',
      value2: 0 // not-decided
    },
    {
      key1: 'Factored Date',
      value1: data.factoredDate ? dayjs(data.factoredDate).format('DD-MM-YYYY') : '-',
      key2: 'Credit Period Days',
      value2: data.creditPeriod
    },
    {
      key1: 'ROI (%)',
      value1: data.rateOfInterest || '-',
      key2: 'Interest',
      value2: data.interest || '-'
    },
    {
      key1: 'Cost Bearer',
      value1: data.costBearer,
      key2: 'Amount without Tax',
      value2: data.factoredUnitBreakup.taxDeductedAmount
    }
  ];
};

export const FUBreakupData = data => {
  return [
    {
      key1: 'Buyer Available Limit',
      value1: data.buyerSellerLink.buyer.sanctionedLimit,
      key2: 'Seller Available Limit',
      value2: data.buyerSellerLink.seller.sanctionedLimit
    },
    {
      key1: 'Buyer %',
      value1: data.buyerPercentage,
      key2: 'Seller %',
      value2: data.sellerPercentage
    },
    {
      key1: 'Buyer Fees',
      value1: data.buyerFees || '-',
      key2: 'Seller Fees',
      value2: data.sellerFees || '-'
    },
    {
      key1: 'Buyer Interest Sharing',
      value1: data.buyerInterest || '-',
      key2: 'Seller Interest Sharing',
      value2: data.sellerInterest || '-'
    },
    {
      key1: 'Buyer Interest Sharing & Fees',
      value1: data.factoredUnitBreakup.buyerInterestAndFees,
      key2: 'Seller Interest Sharing & Fees',
      value2: data.factoredUnitBreakup.sellerInterestAndFees
    },
    {
      key1: 'Interest Days',
      value1: data.factoredUnitBreakup.interestAccrued
        ? data.factoredUnitBreakup.interestDays
        : '-',
      key2: 'Interest Accrued',
      value2: data.factoredUnitBreakup.interestAccrued || '-'
    },
    {
      key1: 'Buyer Interest Accrued',
      value1: data.factoredUnitBreakup.buyerInterestAccrued || '-',
      key2: 'Seller Interest Accrued',
      value2: data.factoredUnitBreakup.sellerInterestAccrued || '-'
    },
    {
      key1: 'Collection Amount',
      value1: data.factoredUnitBreakup.collectedAmount,
      key2: 'Disbursement Amount',
      value2: data.factoredUnitBreakup.disbursementAmount
    }
  ];
};
