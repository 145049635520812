export const factoringUnitTypes = {
  GET_FACTORING_UNIT_FAILURE: 'GET_FACTORING_UNIT_FAILURE',
  GET_FACTORING_UNIT_LOADING: 'GET_FACTORING_UNIT_LOADING',
  GET_FACTORING_UNIT_SUCCESS: 'GET_FACTORING_UNIT_SUCCESS',

  EDIT_FACTORING_UNIT: 'EDIT_FACTORING_UNIT',
  FACTORING_UNIT_FILTER_QUERY: 'FACTORING_UNIT_FILTER_QUERY'
};

export const checkerLevelTypes = {
  GET_CHECKER_LEVEL_FAILURE: 'GET_CHECKER_LEVEL_FAILURE',
  GET_CHECKER_LEVEL_LOADING: 'GET_CHECKER_LEVEL_LOADING',
  GET_CHECKER_LEVEL_SUCCESS: 'GET_CHECKER_LEVEL_SUCCESS',

  EDIT_CHECKER_LEVEL: 'EDIT_CHECKER_LEVEL '
};

export const openForFinanceTypes = {
  GET_OPEN_FOR_FINANCE_LOADING: 'GET_OPEN_FOR_FINANCE_LOADING',
  GET_OPEN_FOR_FINANCE_SUCCESS: 'GET_OPEN_FOR_FINANCE_SUCCESS',
  GET_OPEN_FOR_FINANCE_FAILURE: 'GET_OPEN_FOR_FINANCE_FAILURE',

  OPEN_FOR_FINANCE_FILTER_QUERY: 'OPEN_FOR_FINANCE_FILTER_QUERY'
};

export const factoredUnitTypes = {
  GET_FACTORED_UNIT_LOADING: 'GET_FACTORED_UNIT_LOADING',
  GET_FACTORED_UNIT_SUCCESS: 'GET_FACTORED_UNIT_SUCCESS',
  GET_FACTORED_UNIT_FAILURE: 'GET_FACTORED_UNIT_FAILURE',

  FACTORED_UNIT_FILTER_QUERY: 'FACTORED_UNIT_FILTER_QUERY'
};

export const acceptBankFinance = {
  GET_ACCEPT_BANK_FINANCE_LOADING: 'GET_ACCEPT_BANK_FINANCE_LOADING',
  GET_ACCEPT_BANK_FINANCE_SUCCESS: 'GET_ACCEPT_BANK_FINANCE_SUCCESS',
  GET_ACCEPT_BANK_FINANCE_FAILURE: 'GET_ACCEPT_BANK_FINANCE_FAILURE'
};

export const notFactoredUnitTypes = {
  GET_NOT_FACTORED_UNIT_LOADING: 'GET_NOT_FACTORED_UNIT_LOADING',
  GET_NOT_FACTORED_UNIT_SUCCESS: 'GET_NOT_FACTORED_UNIT_SUCCESS',
  GET_NOT_FACTORED_UNIT_FAILURE: 'GET_NOT_FACTORED_UNIT_FAILURE',

  NOT_FACTORED_UNIT_FILTER_QUERY: 'NOT_FACTORED_UNIT_FILTER_QUERY'
};

export const paymentTypes = {
  GET_PAYMENTS_LOADING: 'GET_PAYMENTS_LOADING',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILURE: 'GET_PAYMENTS_FAILURE'
};

export const transactionHistoryTypes = {
  GET_TRANSACTION_HISTORY_LOADING: 'GET_TRANSACTION_HISTORY_LOADING',
  GET_TRANSACTION_HISTORY_SUCCESS: 'GET_TRANSACTION_HISTORY_SUCCESS',
  GET_TRANSACTION_HISTORY_FAILURE: 'GET_TRANSACTION_HISTORY_FAILURE'
};

export const invoiceDocumentTypes = {
  GET_INVOICE_DOCUMENT_LOADING: 'GET_INVOICE_DOCUMENT_LOADING',
  GET_INVOICE_DOCUMENT_SUCCESS: 'GET_INVOICE_DOCUMENT_SUCCESS',
  GET_INVOICE_DOCUMENT_FAILURE: 'GET_INVOICE_DOCUMENT_FAILURE'
};

export const factoringUnitByIdTypes = {
  GET_FACTORING_UNIT_BY_ID_SUCCESS: 'GET_FACTORING_UNIT_BY_ID_SUCCESS',
  GET_FACTORING_UNIT_BY_ID_LOADING: 'GET_FACTORING_UNIT_BY_ID_LOADING',
  GET_FACTORING_UNIT_BY_ID_FAILURE: 'GET_FACTORING_UNIT_BY_ID_FAILURE'
};
