import Cookies from 'js-cookie';
import { fetchRequest } from './fetchRequest';
import { store } from '../redux';
import { notifications } from './notifications';

export const fetchData = async (
  url,
  loadingType,
  successType,
  failureType,
  errorMessage,
  successMessage,
  onSuccess,
  data
) => {
  store.dispatch({ type: loadingType });
  try {
    let response;
    if (data) {
      response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ACCESS_TOKEN')}`
        }
      });
    } else {
      response = await fetchRequest(url);
    }
    if (response.hasError) {
      throw new Error(errorMessage);
    } else {
      store.dispatch({
        type: successType,
        payload: response.data
      });
      if (successMessage) {
        notifications.success({ message: successMessage });
      }
      if (onSuccess) {
        onSuccess();
      }
      return response.data;
    }
  } catch (error) {
    console.warn(error);
    store.dispatch({ type: failureType });
  }
};
