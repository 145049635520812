import { buyerSellerTypes } from './types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case buyerSellerTypes.GET_BUYER_SELLER_FAILURE:
      return {
        ...state,
        loadingBuyerSeller: false,
        buyerSeller: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_SUCCESS: {
      const buyerSeller = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingBuyerSeller: false,
        buyerSeller: {
          data: buyerSeller,
          count: action.payload.count
        }
      };
    }
    case buyerSellerTypes.GET_BUYER_SELLER_LOADING:
      return {
        ...state,
        loadingBuyerSeller: true,
        buyerSeller: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_PROFILE_FAILURE:
      return {
        ...state,
        loadingBuyerSellerProfile: false,
        buyerSellerProfile: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_PROFILE_LOADING: {
      return {
        ...state,
        loadingBuyerSellerProfile: true,
        buyerSellerProfile: {}
      };
    }
    case buyerSellerTypes.GET_BUYER_SELLER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingBuyerSellerProfile: false,
        buyerSellerProfile: action.payload
      };

    case buyerSellerTypes.GET_ADDRESS_FAILURE:
      return {
        ...state,
        loadingAddress: false,
        address: {}
      };

    case buyerSellerTypes.GET_ADDRESS_LOADING:
      return {
        ...state,
        loadingAddress: true,
        address: {}
      };

    case buyerSellerTypes.GET_ADDRESS_SUCCESS: {
      const address = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingAddress: false,
        address: {
          data: address,
          count: action.payload.count
        }
      };
    }
    case buyerSellerTypes.GET_BANKS_SUCCESS: {
      const banks = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingBank: false,
        bank: {
          data: banks,
          count: action.payload.count
        }
      };
    }

    case buyerSellerTypes.GET_BANKS_LOADING:
      return {
        ...state,
        loadingBank: true,
        bank: {}
      };

    case buyerSellerTypes.GET_BANKS_FAILURE:
      return {
        ...state,
        loadingBank: false,
        bank: {}
      };

    case buyerSellerTypes.GET_PROMOTER_SUCCESS: {
      const promoters = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingPromoters: false,
        promoters: {
          data: promoters,
          count: action.payload.count
        }
      };
    }

    case buyerSellerTypes.GET_PROMOTER_LOADING:
      return {
        ...state,
        loadingPromoters: true,
        promoters: {}
      };

    case buyerSellerTypes.GET_PROMOTER_FAILURE:
      return {
        ...state,
        loadingPromoters: false,
        promoters: {}
      };

    case buyerSellerTypes.GET_DOCUMENT_SUCCESS: {
      const documents = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });
      return {
        ...state,
        loadingDocument: false,
        documents: {
          data: documents,
          count: action.payload.count
        }
      };
    }

    case buyerSellerTypes.GET_DOCUMENT_LOADING:
      return {
        ...state,
        loadingDocument: true,
        documents: {}
      };

    case buyerSellerTypes.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingDocument: false,
        documents: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_LINK_FAILURE:
      return {
        ...state,
        loadingBsLink: false,
        buyerSellerLink: []
      };

    case buyerSellerTypes.GET_BUYER_SELLER_LINK_LOADING:
      return {
        ...state,
        loadingBsLink: true,
        buyerSellerLink: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_LINK_SUCCESS:
      const buyerSellerLink = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingBsLink: false,
        buyerSellerLink: {
          data: buyerSellerLink,
          count: action.payload.count
        }
      };

    case buyerSellerTypes.GET_LINKED_GST_FAILURE:
      return {
        ...state,
        loadingLinkedGst: false,
        linkedGst: {}
      };

    case buyerSellerTypes.GET_LINKED_GST_LOADING:
      return {
        ...state,
        loadingLinkedGst: true,
        linkedGst: {}
      };

    case buyerSellerTypes.GET_LINKED_GST_SUCCESS:
      const linkedGst = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingLinkedGst: false,
        linkedGst: {
          data: linkedGst,
          count: action.payload.count
        }
      };

    case buyerSellerTypes.GET_IFSC_DETAILS_FAILURE:
      return {
        ...state,
        loadingifscData: false,
        ifscData: []
      };

    case buyerSellerTypes.GET_IFSC_DETAILS_LOADING:
      return {
        ...state,
        loadingifscData: true,
        ifscData: []
      };

    case buyerSellerTypes.GET_IFSC_DETAILS_SUCCESS:
      return {
        ...state,
        loadingifscData: false,
        ifscData: action.payload
      };

    case buyerSellerTypes.GET_PIN_CODE_DETAILS_FAILURE:
      return {
        ...state,
        loadingpinCodeData: false,
        pinData: []
      };

    case buyerSellerTypes.GET_PIN_CODE_DETAILS_LOADING:
      return {
        ...state,
        loadingpinCodeData: true,
        pinData: []
      };

    case buyerSellerTypes.GET_PIN_CODE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingpinCodeData: false,
        pinData: action.payload
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_FAILURE:
      return {
        ...state,
        loadingInvoiceData: false,
        invoiceData: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_LOADING:
      return {
        ...state,
        loadingInvoiceData: true,
        invoiceData: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_SUCCESS:
      const invoiceData = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingInvoiceData: false,
        invoiceData: {
          data: invoiceData,
          count: action.payload.count
        }
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_FAILURE_BY_ID:
      return {
        ...state,
        loadingInvoiceById: false,
        invoiceById: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_LOADING_BY_ID:
      return {
        ...state,
        loadingInvoiceById: true,
        invoiceById: {}
      };

    case buyerSellerTypes.GET_BUYER_SELLER_INVOICE_SUCCESS_BY_ID:
      return {
        ...state,
        loadingInvoiceById: false,
        invoiceById: action.payload
      };

    case buyerSellerTypes.EDIT_BUYER_SELLER:
      return {
        ...state,
        editBuyerSeller: action.payload
      };

    case buyerSellerTypes.EDIT_INVOICE:
      return {
        ...state,
        editInvoice: action.payload
      };

    case buyerSellerTypes.EDIT_ADDRESS:
      return {
        ...state,
        editAddress: action.payload
      };

    case buyerSellerTypes.EDIT_BANK:
      return {
        ...state,
        editBank: action.payload
      };

    case buyerSellerTypes.EDIT_PROMOTER:
      return {
        ...state,
        editPromter: action.payload
      };

    case buyerSellerTypes.EDIT_BUYER_SELLER_LINK:
      return {
        ...state,
        editBuyerSellerLink: action.payload
      };

    case buyerSellerTypes.GET_PAN_KYC_FAILURE:
      return {
        ...state,
        loadingPanKycDetails: false,
        panKycDetails: {}
      };

    case buyerSellerTypes.GET_PAN_KYC_LOADING: {
      return {
        ...state,
        loadingPanKycDetails: true,
        panKycDetails: {}
      };
    }

    case buyerSellerTypes.GET_PAN_KYC_SUCCESS:
      return {
        ...state,
        loadingPanKycDetails: false,
        panKycDetails: action.payload.data
      };

    case buyerSellerTypes.GET_GST_KYC_FAILURE:
      return {
        ...state,
        loadingGstKycDetails: false,
        gstKycDetails: {}
      };

    case buyerSellerTypes.GET_GST_KYC_LOAING: {
      return {
        ...state,
        loadingGstKycDetails: true,
        gstKycDetails: {}
      };
    }

    case buyerSellerTypes.GET_GST_KYC_SUCCESS:
      return {
        ...state,
        loadingGstKycDetails: false,
        gstKycDetails: action.payload.data
      };

    case buyerSellerTypes.GET_GST_ITR_KYC_FAILURE:
      return {
        ...state,
        loadingGstItrDetails: false,
        gstItrDetails: {}
      };

    case buyerSellerTypes.GET_GST_ITR_KYC_LOAING: {
      return {
        ...state,
        loadingGstItrDetails: true,
        gstItrDetails: {}
      };
    }

    case buyerSellerTypes.GET_GST_ITR_KYC_SUCCESS:
      return {
        ...state,
        loadingGstItrDetails: false,
        gstItrDetails: action.payload.data
      };

    case buyerSellerTypes.GET_UDYAM_KYC_FAILURE:
      return {
        ...state,
        loadingUdyamKycDetails: false,
        udyamKycDetails: {}
      };

    case buyerSellerTypes.GET_UDYAM_KYC_LOADING: {
      return {
        ...state,
        loadingUdyamKycDetails: true,
        udyamKycDetails: {}
      };
    }

    case buyerSellerTypes.GET_UDYAM_KYC_SUCCESS:
      return {
        ...state,
        loadingUdyamKycDetails: false,
        udyamKycDetails: action.payload.data
      };

    case buyerSellerTypes.GET_BANK_KYC_FAILURE:
      return {
        ...state,
        loadingBankKycDetails: false,
        bankKycDetails: {}
      };

    case buyerSellerTypes.GET_BANK_KYC_LOADING: {
      return {
        ...state,
        loadingBankKycDetails: true,
        bankKycDetails: {}
      };
    }

    case buyerSellerTypes.GET_BANK_KYC_SUCCESS:
      return {
        ...state,
        loadingBankKycDetails: false,
        bankKycDetails: action.payload.data
      };

    case buyerSellerTypes.GET_BANK_STMT_FAILURE:
      return {
        ...state,
        loadingBankStmtDetails: false,
        bankStmtDetails: {}
      };

    case buyerSellerTypes.GET_BANK_STMT_LOADING: {
      return {
        ...state,
        loadingBankStmtDetails: true,
        bankStmtDetails: {}
      };
    }

    case buyerSellerTypes.GET_BANK_STMT_SUCCESS:
      return {
        ...state,
        loadingBankStmtDetails: false,
        bankStmtDetails: action.payload.data
      };

    case buyerSellerTypes.GET_AADHAAR_KYC_FAILURE:
      return {
        ...state,
        loadingAadhaarKycDetails: false,
        aadhaarKycDetails: {}
      };

    case buyerSellerTypes.GET_AADHAAR_KYC_LOADING: {
      return {
        ...state,
        loadingAadhaarKycDetails: true,
        aadhaarKycDetails: {}
      };
    }

    case buyerSellerTypes.GET_AADHAAR_KYC_SUCCESS:
      return {
        ...state,
        loadingAadhaarKycDetails: false,
        aadhaarKycDetails: action.payload.data
      };
    case buyerSellerTypes.GET_CREDIT_REPORT_FAILURE:
      return {
        ...state,
        loadingCreditReportDetails: false,
        creditReportDetails: {}
      };

    case buyerSellerTypes.GET_CREDIT_REPORT_LOADING: {
      return {
        ...state,
        loadingCreditReportDetails: true,
        creditReportDetails: {}
      };
    }

    case buyerSellerTypes.GET_CREDIT_REPORT_SUCCESS:
      return {
        ...state,
        loadingCreditReportDetails: false,
        creditReportDetails: action.payload.data
      };

    default:
      return state;
  }
};

export default reducer;
