export const RULES = {
  selectRequired: [
    {
      required: true,
      message: 'please select one!'
    }
  ],

  inputRequired: [
    {
      required: true,
      message: 'above field can not be empty!'
    }
  ],

  numberString: [
    {
      type: 'string',
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'please enter a valid input!'
    }
  ],

  upload: [
    {
      required: true,
      message: 'please upload the document!'
    }
  ],

  name: [
    {
      type: 'string',
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^[a-zA-Z ]{2,42}$/,
      message: 'only aplhabets required!'
    }
  ],

  amount: [
    {
      required: true,
      message: 'please enter the amount!'
    },
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'must be number !'
    }
  ],

  micrCode: [
    {
      required: true,
      message: 'please enter the MICR code!'
    },
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'please enter a valid MICR code!'
    }
  ],

  swiftCode: [
    {
      required: false,
      message: 'please enter the SWIFT code!'
    },
    {
      pattern: /^[a-zA-Z0-9]+$/,
      whitespace: false,
      message: 'please enter a valid SWIFT code!'
    }
  ],

  referenceNo: [
    {
      required: true,
      message: 'please enter the reference id!'
    },

    {
      pattern: /^[a-z0-9]+$/i,
      whitespace: false,
      message: 'please enter a valid input!'
    }
  ],

  optionalReferenceNo: [
    {
      pattern: /^[a-z0-9]+$/i,
      whitespace: false,
      message: 'please enter a valid input!'
    }
  ],

  gstinNumber: [
    {
      required: false,
      message: 'please enter the GST number!'
    },
    {
      len: 15,
      pattern: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/,
      message: 'please enter a valid GST number!'
    }
  ],

  password: [
    {
      required: true,
      message: 'please enter the password!'
    },
    {
      min: 8,
      message: 'minimum 8 characters required!'
    },
    {
      whitespace: false,
      message: 'enter a valid password!'
    }
  ],

  repassword: [
    {
      required: true,
      message: 'Please confirm the password!'
    },
    {
      min: 8,
      message: 'minimum 8 characters required!'
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || !getFieldValue('password') || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Password do not match!');
      }
    })
  ],

  udyamNumber: [
    {
      required: false,
      message: 'please enter the UDYAM  registration number!'
    },
    {
      whitespace: false,
      len: 19,
      pattern: /^UDYAM-[A-Z]{2}-[\d]{2}-[\d]{7}$/,
      message: 'enter your 19 digit UDYAM registration number (i.e. UDYAM-XX-00-0000000)!'
    }
  ],

  pinNo: [
    {
      required: true,
      message: 'please enter the 6 digit PIN!'
    },
    { length: 6, maxlength: 6, message: 'please enter 6 digit PIN!' },
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'PIN must be a number!'
    }
  ],

  accountNo: [
    {
      required: true,
      message: 'please enter the account number!'
    },
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'only number inputs allowed!'
    }
  ],

  ifsc: [
    {
      required: true,
      message: 'please enter the IFSC code!'
    },
    {
      pattern: /^[A-Z]{4}0[A-Z|\d]{6}$/,
      whitespace: false,
      message: 'please enter a valid IFSC code!'
    }
  ],

  rateOfInterest: [
    {
      required: true,
      message: 'ROI required!'
    },
    () => ({
      validator(_, value) {
        if (!value || (Number(value) >= 0 && value <= 100)) {
          return Promise.resolve();
        }
        return Promise.reject('enter valid ROI!');
      }
    })
  ],

  date: [
    {
      required: true,
      message: 'please select the date!'
    }
  ],

  invoiceNo: [
    {
      required: true,
      message: 'please enter the invoice number!'
    },
    {
      pattern: /^[a-zA-Z0-9-]+$/,
      whitespace: false,
      message: 'please enter a valid invoice number!'
    }
  ],

  dinCin: [
    {
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^[a-zA-Z0-9-]+$/i,
      whitespace: false,
      message: 'please enter a valid input!'
    }
  ],

  mobileNo: [
    {
      required: true,
      message: 'please enter the mobile number!'
    },
    {
      pattern: /^(\+\d{1,3}[- ]?)?[6789]\d{9}$/,
      len: 10,
      message: 'please enter the valid mobile number!'
    }
  ],

  optionalmobileNo: [
    {
      pattern: /^(\+\d{1,3}[- ]?)?[6789]\d{9}$/,
      len: 10,
      message: 'please enter the valid mobile number!'
    }
  ],

  email: [
    {
      required: true,
      message: 'please enter the email id!'
    },
    {
      type: 'email',
      message: 'enter a valid email id!'
    }
  ],

  optionalEmail: [
    {
      type: 'email',
      message: 'enter a valid email id!'
    }
  ],

  registrationNumber: [
    {
      type: 'string',
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'Please enter a valid input!'
    }
  ],

  optionalRegistrationNumber: [
    {
      type: 'string'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'Please enter a valid input!'
    }
  ],

  number: [
    {
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'must be number!'
    }
  ],

  optionalNumber: [
    {
      pattern: /^\d+$/,
      whitespace: false,
      message: 'must be number!'
    }
  ],

  panNumber: [
    {
      required: true,
      message: 'please enter the PAN number!'
    },
    {
      len: 10,
      pattern: /^[A-Za-z]{5}[\d]{4}[A-Za-z]{1}$/,
      message: 'enter a valid PAN number, must be 10 characters !'
    }
  ],

  optionalPanNumber: [
    {
      len: 10,
      pattern: /^[A-Za-z]{5}[\d]{4}[A-Za-z]{1}$/,
      message: 'enter a valid PAN number, must be 10 characters !'
    }
  ],

  address: [
    {
      type: 'string',
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'Please enter a valid input!'
    }
  ],

  entityName: [
    {
      type: 'string',
      required: true,
      message: 'above field can not be empty!'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'Please enter a valid input!'
    }
  ],

  optionalAddress: [
    {
      type: 'string'
    },
    {
      pattern: /^(\w*\s*[\#\-\,\/\.\(\)\&]*)+$/,
      message: 'Please enter a valid input!'
    }
  ]
};
