export const userTypes = {
  GET_USER_LOADING: 'GET_USER_LOADING',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  EDIT_USER: 'EDIT_USER',

  GET_PROFILE_LOADING: 'GET_PROFILE_LOADING',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  GET_USER_BY_ENTITY_LOADING: 'GET_USER_BY_ENTITY_LOADING',
  GET_USER_BY_ENTITY_SUCCESS: 'GET_USER_BY_ENTITY_SUCCESS',
  GET_USER_BY_ENTITY_FAILURE: 'GET_USER_BY_ENTITY_FAILURE'
};
