import { Typography } from 'antd';
import { ViewInvoice } from '../../invoice/viewInvoice/viewInvoice';

const { Text } = Typography;

export const invoiceViewColumns = [
  {
    title: <Text>Invoice No</Text>,
    key: 'invoiceNo',
    render: (_, record) => {
      return <ViewInvoice label={record.invoiceNo} data={record} />;
    }
  },
  {
    title: <Text>Invoice Amount</Text>,
    dataIndex: 'invoiceAmount',
    key: 'invoiceAmount',
    render: (_, record) => {
      return <Text>{record.invoiceAmount}</Text>;
    }
  },
  {
    title: <Text>Discount Amount</Text>,
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    render: (_, record) => {
      return <Text>{record.discountAmount}</Text>;
    }
  },
  {
    title: <Text>Tax Amount</Text>,
    dataIndex: 'taxAmount',
    key: 'taxAmount',
    render: (_, record) => {
      return <Text>{record.taxAmount}</Text>;
    }
  },
  {
    title: <Text>Total Amount</Text>,
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (_, record) => {
      return <Text>{record.totalAmount}</Text>;
    }
  }
];
