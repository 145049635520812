export const financierTypes = {
  GET_FINANCIER_LOADING: 'GET_FINANCIER_LOADING',
  GET_FINANCIER_SUCCESS: 'GET_FINANCIER_SUCCESS',
  GET_FINANCIER_FAILURE: 'GET_FINANCIER_FAILURE',

  GET_FINANCIER_PROFILE_LOADING: 'GET_FINANCIER_PROFILE_LOADING',
  GET_FINANCIER_PROFILE_SUCCESS: 'GET_FINANCIER_PROFILE_SUCCESS',
  GET_FINANCIER_PROFILE_FAILURE: 'GET_FINANCIER_PROFILE_FAILURE',

  GET_FINANCIER_BANKS_LOADING: 'GET_FINANCIER_BANKS_LOADING',
  GET_FINANCIER_BANKS_FAILURE: 'GET_FINANCIER_BANKS_FAILURE',
  GET_FINANCIER_BANKS_SUCCESS: 'GET_FINANCIER_BANKS_SUCCESS',

  GET_FINANCIER_CONFIGURATIONS_SECTOR_LOADING: 'GET_FINANCIER_CONFIGURATIONS_SECTOR_LOADING',
  GET_FINANCIER_CONFIGURATIONS_SECTOR_SUCCESS: 'GET_FINANCIER_CONFIGURATIONS_SECTOR_SUCCESS',
  GET_FINANCIER_CONFIGURATIONS_SECTOR_FAILURE: 'GET_FINANCIER_CONFIGURATIONS_SECTOR_FAILURE',

  GET_FINANCIER_CONFIGURATIONS_TYPE_LOADING: 'GET_FINANCIER_CONFIGURATIONS_TYPE_LOADING',
  GET_FINANCIER_CONFIGURATIONS_TYPE_SUCCESS: 'GET_FINANCIER_CONFIGURATIONS_TYPE_SUCCESS',
  GET_FINANCIER_CONFIGURATIONS_TYPE_FAILURE: 'GET_FINANCIER_CONFIGURATIONS_TYPE_FAILURE',

  EDIT_FINANCIER: 'EDIT_FINANCIER',

  EDIT_FINANCIER_BANK: 'EDIT_FINANCIER_BANK',

  EDIT_FINANCIER_CONFIGRATIONS_SECTOR: 'EDIT_FINANCIER_CONFIGRATIONS_SECTOR',

  EDIT_FINANCIER_CONFIGRATIONS_TYPE: 'EDIT_FINANCIER_CONFIGRATIONS_TYPE'
};
