import React, { Fragment } from 'react';
import UserSettings from '../../component/userSettings';
import Invoice from '../../component/finance/invoice';
import AcceptBankFinance from '../../component/finance/acceptBankFinance';
import FactoringUnitContainer from '../../component/finance/factoringUnit';
import FactoredUnitContainer from '../../component/finance/factoredUnit';
import CheckerLevel from '../../component/checkerLevel';
import NotFactoredUnitContainer from '../../component/finance/notFactoredUnit';
import BuyerSellerLink from '../../component/buyerSellerLink';
import BuyerSellerAdd from '../../component/buyerSeller/tabs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Permissions } from '../../configs/permissions';
import { Menu } from 'antd';
import { mutateClearModalParams, mutateClearParams } from '../../redux/mutations/actions';

const getItem = (label, key, children, component) => {
  return {
    key,
    label,
    children,
    component
  };
};

const SellerContainer = () => {
  const {
    profile,
    userLoading = false,
    users = {},
    editUser = {}
  } = useSelector(state => ({
    profile: state.user.profile,
    userLoading: state.user.loadingUsers,
    users: state.user.users,
    editUser: state.user.editUser
  }));

  const [buyerSellerAdd, setBuyerSellerAdd] = useState(false);
  const [current, setCurrent] = useState('HOME');

  const items = [
    Permissions(null, null, 'homeTab') &&
      Permissions('home', 'visibility') &&
      getItem('Home', 'HOME'),
    Permissions(null, null, 'invoiceTab') &&
      Permissions('invoices', 'visibility') &&
      getItem('Invoice', 'INVOICE'),
    Permissions(null, null, 'factoringUnitTab') &&
      Permissions('factoringUnit', 'visibility') &&
      getItem('Factoring Unit', 'FACTORING_UNIT'),
    Permissions(null, null, 'factoredTab') &&
      getItem('FactoredUnit', 'FACTORED', [
        Permissions('acceptBankFinance', 'visibility') &&
          getItem('Accept Bank Finance', 'BANK_FINANCE'),
        Permissions('factoredUnit', 'visibility') &&
          getItem('Successful Factored', 'FACTORED_UNIT', [
            Permissions('factoredUnit', 'openDisbursement') &&
              getItem('Open for disbursement', 'OPEN_FOR_DISBURSEMENT'),
            Permissions('factoredUnit', 'activeDisbursement') &&
              getItem('Active disbursementt', 'ACTIVE_DISBURSEMENT'),
            Permissions('factoredUnit', 'overdue') && getItem('Overdue', 'OVERDUE_DISBURSEMENT'),
            Permissions('factoredUnit', 'paymentClosed') &&
              getItem('Payment closed', 'PAYMENT_CLOSED')
          ]),
        Permissions('notFactoredUnit', 'visibility') &&
          getItem('UnSuccessful Factored', 'NOT_FACTORED_UNIT')
      ]),
    Permissions(null, null, 'settingsTab') &&
      getItem('Settings', 'settings', [
        Permissions('manageBuyerSellerLink', 'visibility') &&
          getItem('Buyer-Seller Link', 'BUYER_SELLER_LINK', [
            getItem('Approved', 'APPROVED'),
            getItem('In Progress', 'IN_PROGRESS')
          ]),
        Permissions('users', 'visibility') && getItem('Users', 'USERS'),
        Permissions('checkerLevel', 'visibility') && getItem('Checker Level', 'CHECKER_LEVEL')
      ]),
    Permissions(null, null, 'reportsTab') && getItem('Reports', 'REPORTS')
  ];

  const onClick = e => {
    setCurrent(e.key);
    mutateClearParams();
    mutateClearModalParams();
    localStorage.setItem('currentTab', e.key);
  };

  const editBuyerSellerData = {
    entityDetails: {
      ...profile?.entity
    },
    adminDetails: {
      ...profile
    },
    apiProviders: {
      ...profile?.entity?.apiProviders
    }
  };

  return (
    <Fragment>
      {profile?.entity?.approved === 0 && (
        <BuyerSellerAdd
          selfOnBoard={true}
          isUpdate={true}
          editBuyerSellerData={editBuyerSellerData}
          buyerSellerAdd={buyerSellerAdd}
          setBuyerSellerAdd={setBuyerSellerAdd}
        />
      )}
      {profile?.entity?.approved === 1 && (
        <Fragment>
          <Menu
            defaultSelectedKeys={['home']}
            defaultOpenKeys={['home']}
            mode='horizontal'
            items={items}
            onClick={onClick}
            selectedKeys={[current]}
          />
          {current === 'INVOICE' && <Invoice />}
          {current === 'FACTORING_UNIT' && <FactoringUnitContainer />}
          {current === 'CHECKER_LEVEL' && <CheckerLevel />}
          {current === 'APPROVED' && <BuyerSellerLink />}
          {current === 'IN_PROGRESS' && <BuyerSellerLink />}
          {current === 'NOT_FACTORED_UNIT' && <NotFactoredUnitContainer />}
          {current === 'BANK_FINANCE' && <AcceptBankFinance />}
          {current === 'OPEN_FOR_DISBURSEMENT' && <FactoredUnitContainer />}
          {current === 'ACTIVE_DISBURSEMENT' && <FactoredUnitContainer />}
          {current === 'OVERDUE_DISBURSEMENT' && <FactoredUnitContainer />}
          {current === 'PAYMENT_CLOSED' && <FactoredUnitContainer />}
          {current === 'USERS' && (
            <UserSettings
              isUpdate={editUser?.openEdit}
              defaultValue={editUser?.data}
              loading={userLoading}
              users={users}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SellerContainer;
