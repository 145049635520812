import React from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import FactoringUnitAdd from './addFactoringUnit/addFactoringUnit';
import useFetch from '../../../hooks/useFetch';
import TableComponent from '../../antdComponent/Table';
import UploadFactoringUnit from './uploadFactoringUnit/uploadFactoringUnit';
import { tw } from 'twind';
import { factoringUnitColumns } from './addFactoringUnit/factoringUnitColumns';
import { RiAddCircleFill } from 'react-icons/ri';
import { notification, Typography, Button } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { editFactoringUnit } from '../../../redux/factoringUnit/actions';
import { getAllFactoringUnit } from '../../../services/factoringUnit';
import { getURL } from '../../../configs/apiURL';
import { notifications } from '../../../utils/notifications';
import { message } from '../../../utils/messages';
import { Permissions } from '../../../configs/permissions';

const { Text } = Typography;

const FactoringUnitContainer = () => {
  const {
    params,
    loadingFactoringUnit = false,
    factoringUnit = {},
    editFactoringUnits = {}
  } = useSelector(state => ({
    params: state.mutations.params,
    loadingFactoringUnit: state.factoringUnit.loadingFactoringUnit,
    factoringUnit: state.factoringUnit.factoringUnit,
    editFactoringUnits: state.factoringUnit.editFactoringUnit
  }));

  const [fetch] = useFetch();
  const [showFactoringUnitAdd, setShowFactoringUnitAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [buyerDropdown, setBuyerDropdown] = useState();
  const [sellerDropdown, setSellerDropdown] = useState();
  const [disableDropdown, setDisableDropdown] = useState(true);
  const [combine] = useFetch();

  const entityId = Cookies.get('entityId');
  const { userId, entityCategory } = localStorage;

  const onSubmitFactoringUnitAdd = async value => {
    const data = {
      buyerId: value.buyerName,
      sellerId: value.sellerName,
      invoiceDetails: {
        invoiceDate: value.invoiceDate,
        creditPeriod: value.creditPeriod,
        invoiceDueDate: value.dueDate,
        invoiceAmount: parseFloat(value.invoiceAmount),
        discountAmount: parseFloat(value.discountAmount),
        taxAmount: parseFloat(value.taxAmount),
        invoiceNo: value.invoiceNumber,
        createdByUserId: userId
      },
      createdByUserId: userId
    };

    if (editFactoringUnits.openEdit) {
      if (editFactoringUnits.data.invoices.length > 1) {
        delete data.invoiceDetails;
      }
      const _id = editFactoringUnits.data.factoringUnitNo;
      const res = await fetch(getURL(`factoring-units/${_id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res && res.status === 200) {
        notification.success({
          message: message.FACTORING_UPDATED
        });
        setShowFactoringUnitAdd(false);
        editFactoringUnit(false, {});
        getAllFactoringUnit(params);
      } else {
        notifications.error({
          message: res.data.error.message
        });
      }
    } else {
      data['invoiceDetails']['invoiceNo'] = value.invoiceNumber;
      const res = await fetch(getURL(`factoring-units`), {
        method: 'POST',
        body: JSON.stringify({ ...data, status: 'PENDING' })
      });

      if (res && res.status === 200) {
        notification.success({
          message: message.FACTORING_CREATED
        });
        setShowFactoringUnitAdd(false);
        editFactoringUnit(false, {});
        setDisableDropdown(true);
        getAllFactoringUnit(params);
      } else {
        notifications.error({
          message: res.data.error.message
        });
      }
    }
  };

  const fetchBuyerSellerData = async () => {
    let res = {};
    switch (entityCategory) {
      case 'FINANCIER': {
        res = await fetch(getURL('buyer-seller?approved=1&active=1&entityCategory=BUYER'));
        if (res && res.status === 200) {
          setBuyerDropdown(
            res.data.data.map(item => ({
              value: item.entityDetails.id,
              label: item.entityDetails.entityName
            }))
          );
        }
        break;
      }
      case 'SELLER': {
        res = await fetch(getURL('buyer-seller/profile'));
        if (res.status === 200) {
          setSellerDropdown([
            {
              value: res.data.data.entityDetails.id,
              label: res.data.data.entityDetails.entityName
            }
          ]);
        }
        res = await fetch(getURL(`buyer-seller/links?approved=1&sellerId=${entityId}`));
        if (res && res.status === 200) {
          setBuyerDropdown(
            res.data.data.map(item => ({
              value: item.buyer.id,
              label: item.buyer.entityName
            }))
          );
        }
        break;
      }
      case 'BUYER': {
        res = await fetch(getURL('buyer-seller/profile'));
        if (res.status === 200) {
          setBuyerDropdown([
            {
              value: res.data.data.entityDetails.id,
              label: res.data.data.entityDetails.entityName
            }
          ]);
        }
        res = await fetch(getURL(`buyer-seller/links?approved=1&buyerId=${entityId}`));
        if (res && res.status === 200) {
          setSellerDropdown(
            res.data.data.map(item => ({
              value: item.seller.id,
              label: item.seller.entityName
            }))
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const combineFUHandler = async () => {
    const res = await combine(getURL('factoring-units/combine'), {
      method: 'PUT',
      body: JSON.stringify({
        factoringUnit1No: selectedRow[0].factoringUnitNo,
        factoringUnit2No: selectedRow[1].factoringUnitNo
      })
    });

    if (res && res.status === 200) {
      notifications.success({ message: 'Factoring Unit Combined' });
      getAllFactoringUnit(params);
    }
  };

  const combineDataCheck = useMemo(
    () =>
      selectedRow[0]?.buyerSellerLink.buyer.id === selectedRow[1]?.buyerSellerLink.buyer.id &&
      selectedRow[0]?.buyerSellerLink.seller.id === selectedRow[1]?.buyerSellerLink.seller.id &&
      dayjs(selectedRow[0]?.invoices[0].invoiceDate).format('DD-MM-YYYY') ===
        dayjs(selectedRow[1]?.invoices[0].invoiceDate).format('DD-MM-YYYY') &&
      dayjs(selectedRow[0]?.invoices[0].invoiceDueDate).format('DD-MM-YYYY') ===
        dayjs(selectedRow[1]?.invoices[0].invoiceDueDate).format('DD-MM-YYYY')
        ? true
        : false,
    [selectedRow]
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled:
        selectedRow.length < 2
          ? false
          : record.factoringUnitNo !== selectedRow[0].factoringUnitNo &&
            record.factoringUnitNo !== selectedRow[1].factoringUnitNo
    })
  };

  const buyerOnChange = async id => {
    const res = await fetch(getURL(`buyer-seller/links?approved=1&buyerId=${id}`));
    if (res.status === 200) {
      setSellerDropdown(
        res.data.data.map(item => ({
          value: item.seller.id,
          label: item.seller.entityName
        }))
      );
      setDisableDropdown(false);
    }
  };

  useEffect(() => {
    if (editFactoringUnits.openEdit) {
      setBuyerDropdown([
        {
          value: editFactoringUnits.data.buyerSellerLink.buyer.id,
          label: editFactoringUnits.data.buyerSellerLink.buyer.entityName
        }
      ]);
      setSellerDropdown([
        {
          value: editFactoringUnits.data.buyerSellerLink.seller.id,
          label: editFactoringUnits.data.buyerSellerLink.seller.entityName
        }
      ]);
    } else {
      fetchBuyerSellerData();
    }
  }, [editFactoringUnits.openEdit]);

  useEffect(() => {
    getAllFactoringUnit(params);
  }, [params]);

  return (
    <React.Fragment>
      {showFactoringUnitAdd || editFactoringUnits.openEdit ? (
        <div>
          <FactoringUnitAdd
            setShowFactoringUnitAdd={setShowFactoringUnitAdd}
            onSubmit={onSubmitFactoringUnitAdd}
            defaultValue={editFactoringUnits.openEdit ? editFactoringUnits.data : []}
            isUpdate={editFactoringUnits.openEdit}
            buyerOnChange={buyerOnChange}
            setDisableDropdown={setDisableDropdown}
            disableDropdown={disableDropdown}
            buyerDropdown={buyerDropdown}
            sellerDropdown={sellerDropdown}
          />
        </div>
      ) : (
        <div>
          <div className={tw`flex flex-col md:flex-row justify-between content-divider`}>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '600px',
                textAlign: 'center'
              }}
            >
              Factoring Unit
            </Text>
            <div className={tw`flex flex-row flex-wrap gap-2`}>
              {Permissions('factoringUnit', 'uploadFactoringUnit') && (
                <UploadFactoringUnit
                  title={'Upload Factoring Unit'}
                  name='upload-factoring'
                  setDisableDropdown={setDisableDropdown}
                  disableDropdown={disableDropdown}
                  buyerDropdown={buyerDropdown}
                  sellerDropdown={sellerDropdown}
                  buyerOnChange={buyerOnChange}
                  params={params}
                />
              )}
              {Permissions('factoringUnit', 'addFactoringUnit') && (
                <Button
                  type='primary'
                  name='add-factoring'
                  className='rounded'
                  onClick={() => {
                    setShowFactoringUnitAdd(true);
                  }}
                >
                  <div className={tw`text-xs md:text-sm flex gap-1 items-center`}>
                    <RiAddCircleFill size='20px' /> Add Factoring Unit
                  </div>
                </Button>
              )}
              {Permissions('factoringUnit', 'combineFactoringUnit') && (
                <Button
                  type='primary'
                  className='rounded'
                  disabled={selectedRow.length < 2}
                  onClick={() => {
                    if (combineDataCheck) {
                      combineFUHandler();
                    } else {
                      notifications.error({
                        message: 'Selected data cannot be combined'
                      });
                    }
                  }}
                >
                  <div className={tw`text-xs md:text-sm flex items-center`}>
                    Combine Factoring Unit
                  </div>
                </Button>
              )}
            </div>
          </div>
          <TableComponent
            columns={factoringUnitColumns}
            data={factoringUnit}
            loading={loadingFactoringUnit}
            rowSelection={{
              ...rowSelection
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FactoringUnitContainer;
