import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { tw } from 'twind';
import { getFactoringUnitById } from '../../../../services/factoringUnit';
import { factoringUnitViewColumns } from '../../factoringUnit/viewFactoringUnit/viewFactoringUnitColumns';
import { FUDetails, FUBreakupData } from '../../fuDetails';

export const ViewModal = ({ label, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      getFactoringUnitById(data.factoringUnitNo);
    }
  }, [isModalVisible]);

  return (
    <>
      <Button style={{ borderRadius: '8px', padding: '4px' }} onClick={showModal}>
        {label}
      </Button>
      <Modal
        title={`Factored Unit Breakup`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Button key='back' onClick={handleCancel} type='primary' className='ml-auto'>
            Close
          </Button>
        }
        centered
      >
        <div className={tw`table-responsive mb-6`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUDetails(data)}
            loading={false}
            rowKey='key'
            bordered
            pagination={false}
            showHeader={false}
          />
        </div>
        <div className={tw`table-responsive mb-6`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={FUBreakupData(data)}
            rowKey='key'
            loading={false}
            bordered
            pagination={false}
          />
        </div>
      </Modal>
    </>
  );
};
