import { userTypes } from './types';

const reducer = (state = [], action) => {
  switch (action.type) {
    case userTypes.GET_USER_LOADING:
      return {
        ...state,
        loadingUser: true,
        users: {}
      };

    case userTypes.GET_USER_SUCCESS: {
      const users = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingUser: false,
        users: {
          data: users,
          count: action.payload.count
        }
      };
    }

    case userTypes.GET_USER_FAILURE:
      return {
        ...state,
        loadingUser: false,
        users: {}
      };

    case userTypes.GET_USER_BY_ENTITY_LOADING:
      return {
        ...state,
        loadingCheckerLevelUser: true,
        checkerLevelUser: []
      };

    case userTypes.GET_USER_BY_ENTITY_SUCCESS: {
      const checkerLevelUser = action.payload.data.map((item, index) => {
        item['key'] = index;
        return item;
      });

      return {
        ...state,
        loadingCheckerLevelUser: false,
        checkerLevelUser: {
          data: checkerLevelUser,
          count: action.payload.count
        }
      };
    }

    case userTypes.GET_USER_BY_ENTITY_FAILURE:
      return {
        ...state,
        loadingCheckerLevelUser: false,
        checkerLevelUser: []
      };

    case userTypes.GET_PROFILE_LOADING:
      return {
        ...state,
        loadingProfile: true,
        profile: undefined
      };

    case userTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        loadingProfile: false,
        profile: action.payload
      };
    }

    case userTypes.GET_PROFILE_FAILURE:
      return {
        ...state,
        loadingProfile: false,
        profile: undefined
      };

    case userTypes.EDIT_USER:
      return {
        ...state,
        editUser: action.payload
      };

    case userTypes.PLATFORM_USER_FILTER_QUERY: {
      return {
        ...state,
        platformUserQuery: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
