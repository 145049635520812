import './style.css';
import React from 'react';
import { Select } from 'antd';

const SelectComponet = ({ label, required, ...rest }) => {
  return (
    <div>
      <Select
        {...rest}
        showSearch={true}
        filterOption={(input, option) =>
          (option.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <span className='floatinglabel'>
        {label ? label : null}
        {required ? <span>*</span> : null}
      </span>
    </div>
  );
};

export default SelectComponet;
