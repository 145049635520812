import React from 'react';
import Input from 'antd/es/input';
import './style.css';

const PasswordInput = ({
  name,
  label,
  required,
  placeholder,
  allowClear,
  bordered,
  defaultValue,
  disabled,
  id,
  maxLength,
  showCount,
  status,
  prefix,
  size,
  suffix,
  type,
  value,
  onChange,
  onPressEnter
}) => {
  return (
    <div>
      <Input.Password
        name={name}
        placeholder={placeholder}
        allowClear={allowClear}
        bordered={bordered}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        showCount={showCount}
        status={status}
        prefix={prefix}
        size={size}
        suffix={suffix}
        type={type}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        style={{
          padding: '0.5rem',
          borderRadius: '8px'
        }}
      />
      <span className='floatinglabel'>
        {label ? label : null}
        {required ? <span>*</span> : null}
      </span>
    </div>
  );
};

export default PasswordInput;
