import React from 'react';
import TextInput from '../antdComponent/Input';
import TableComponent from '../antdComponent/Table';
import useFetch from '../../hooks/useFetch';
import SelectComponent from '../antdComponent/Select';
import { Form, Card, Button, Checkbox, Row, Col } from 'antd';
import { tw } from 'twind';
import { dropDownInitialState, dropDownReducer, ACTION_TYPES } from '../../utils/helpers';
import { getURL } from '../../configs/apiURL';
import { financierUserBankAccountColumns } from './financierUserBankAccountColumns';
import { RULES } from '../../utils/formValidations';
import { useEffect, useMemo, useReducer } from 'react';
import { Permissions } from '../../configs/permissions';
import { useState } from 'react';
import { getIfscData } from '../../services/buyerSeller';
import { getFinancierBanks } from '../../services/financier';

const FinancierUserBankAccount = ({
  editBanks,
  tableData,
  loading,
  onSubmit,
  ifscBanksData,
  ifscDataloading,
  params
}) => {
  const [form] = Form.useForm();
  const [fetch] = useFetch();
  const [ifscFieldValue, setIfscFieldValue] = useState('');
  const [state, dispatch] = useReducer(dropDownReducer, dropDownInitialState);

  const initialValue = useMemo(() => {
    return Object.keys(editBanks).length !== 0
      ? {
          accountTypeId: editBanks.data.bankAccountType?.id,
          accountTitle: editBanks.data.accountTitle,
          accountNo: editBanks.data.accountNo,
          ifscCode: editBanks.data.ifsc,
          bankName: editBanks.data.bankName,
          branchName: editBanks.data.branchName,
          micrCode: editBanks.data.micrCode,
          swiftCode: editBanks.data.swiftCode,
          isDefault: editBanks.data.isDefault === 1 ? true : false
        }
      : {};
  }, [editBanks]);

  const onIfscChangeHandler = value => {
    if (value.length === 11) {
      setIfscFieldValue(value);
    }
  };

  const getDropDown = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    try {
      const url = new URL(getURL('bank-account-types'));
      url.searchParams.set('active', 1);
      const res = await fetch(url);
      dispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: {
          bankDropdown: res.data.data.map(row => ({
            value: row.id,
            label: row.name
          }))
        }
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (ifscFieldValue.length === 11) {
      getIfscData(ifscFieldValue);
    }
  }, [ifscFieldValue]);

  useEffect(() => {
    if (ifscFieldValue === ifscBanksData.IFSC && !ifscDataloading) {
      form.setFieldsValue({
        ifscCode: ifscFieldValue,
        bankName: ifscBanksData.BANK,
        branchName: ifscBanksData.BRANCH,
        micrCode: ifscBanksData.MICR,
        swiftCode: ifscBanksData.SWIFT
      });
    }
  }, [ifscBanksData]);

  useEffect(() => {
    getDropDown();
  }, []);

  useEffect(() => {
    getFinancierBanks(params);
  }, [params]);

  return (
    <div className={tw`content-divider`}>
      <Form
        form={form}
        size='large'
        onFinish={async value => {
          await onSubmit(value);
          form.resetFields();
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        <Card title='Bank Details'>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountTypeId' rules={RULES.selectRequired}>
                <SelectComponent
                  label='Account Type'
                  placeholder='Select'
                  required
                  allowClear={true}
                  options={state.dropDownData.bankDropdown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountTitle' rules={[]}>
                <TextInput label='Account Title' placeholder='Account Title' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='accountNo' rules={RULES.accountNo}>
                <TextInput label='Account Number' placeholder='Account Number' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='ifscCode' rules={RULES.ifsc}>
                <TextInput
                  onChange={event => {
                    onIfscChangeHandler(event.target.value);
                  }}
                  label='IFSC'
                  placeholder='IFSC'
                  required
                  maxLength={11}
                  uppercase
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='bankName' rules={RULES.inputRequired}>
                <TextInput label='Bank Name' placeholder='Bank Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='branchName' rules={RULES.inputRequired}>
                <TextInput label='Branch Name' placeholder='Branch Name' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='micrCode' rules={RULES.micrCode}>
                <TextInput label='MICR Code' placeholder='MICR Code' required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='swiftCode' rules={RULES.swiftCode}>
                <TextInput label='SWIFT Code' placeholder='SWIFT Code' uppercase />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='isDefault' valuePropName='checked'>
                <Checkbox className='mlv5'>Mark as default account</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {Permissions(
            'bankAccount',
            editBanks.openEdit ? 'editBankAccount' : 'addBankAccount'
          ) && (
            <Button size='middle' type='primary' htmlType='submit'>
              {editBanks.openEdit ? 'Update' : 'Add'}
            </Button>
          )}
        </Card>
      </Form>
      <br />
      <TableComponent
        columns={financierUserBankAccountColumns}
        data={tableData}
        loading={loading}
      />
    </div>
  );
};

export default FinancierUserBankAccount;
