import React from 'react';
import Cookies from 'js-cookie';
import useFetch from '../../hooks/useFetch';
import FinancierAdd from '../../component/platformFinancier/addFinancier';
import PlatformFinancier from '../../component/platformFinancier';
import PlatformSettings from '../../component/platformSettings';
import PlatformConfigurations from '../../component/platformConfigurations';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { getAllUsers } from '../../services/user';
import { notifications } from '../../utils/notifications';
import { message } from '../../utils/messages';
import { getURL } from '../../configs/apiURL';
import { platformRolesPermission } from '../../configs/permissions';
import { mutateClearModalParams, mutateClearParams } from '../../redux/mutations/actions';
import { editUser } from '../../redux/user/actions';
import { getAllFinancier } from '../../services/financier';
import { editFinancier } from '../../redux/financier/actions';

const getItem = (label, key, children, component) => {
  return {
    key,
    label,
    children,
    component
  };
};

const PlatformContainer = () => {
  const {
    params,
    financierData = {},
    financierLoading = false,
    editFinanciers = {},
    users = {},
    loadingUser = false,
    editUsers = {}
  } = useSelector(state => ({
    params: state.mutations.params,
    financierData: state.financier.financiers,
    financierLoading: state.financier.loadingFinancier,
    editFinanciers: state.financier.editFinancier,
    users: state.user.users,
    loadingUser: state.user.loadingUser,
    editUsers: state.user.editUser
  }));

  const [fetchRequest] = useFetch();
  const [current, setCurrent] = useState('HOME');
  const [loading, setLoading] = useState(false);
  const [financierAdd, setFinancierAdd] = useState(false);

  const items = [
    platformRolesPermission(null, null, 'homeTab') &&
    platformRolesPermission('home', 'visibility') &&
    getItem('Home', 'HOME'),
    platformRolesPermission(null, null, 'financierTab') &&
    platformRolesPermission('financier', 'visibility') &&
    getItem('Financier', 'FINANCIER'),
    platformRolesPermission(null, null, 'settingsTab') &&
    platformRolesPermission('settings', 'visibility') &&
    getItem('Settings', 'SETTINGS'),
    platformRolesPermission(null, null, 'configurationsTab') &&
    platformRolesPermission('configurations', 'visibility') &&
    getItem('Configurations', 'CONFIGURATIONS')
  ];

  const onClick = e => {
    setCurrent(e.key);
    mutateClearParams();
    mutateClearModalParams();
    localStorage.setItem('currentTab', e.key);
  };

  const onSubmitFinancier = async value => {
    setLoading(true);

    const ACCESS_TOKEN = Cookies.get('ACCESS_TOKEN');

    const {
      panVerified,
      udyamRegNoVerified,
      themeHexcode
    } = editFinanciers?.data?.entityDetails || {};

    const data = {
      entityDetails: {
        entityCategory: 'FINANCIER',
        entityTypeId: null,
        entityName: value.entityName,
        registrationNo: value.registrationNumber,
        pan: value.panNumber,
        udyamRegNo: value.udyamNumber,
        dateOfIncorporation: value.incorporationDate,
        themeHexcode: value.themeHexcode ? value.themeHexcode.slice(1, 7) : themeHexcode,
        panVerified: panVerified,
        udyamRegNoVerified: udyamRegNoVerified,
        active: 1
      },
      adminDetails: {
        userType: 'ADMIN',
        firstName: value.adminName?.split(' ')[0],
        lastName: value.adminName?.split(' ')[1] || "",
        mobileNo: value.mobileNo,
        emailId: value.email,
        password: value.password,
        userStatus: 1,
        active: 1
      },
      approvalDetails: {
        dateOfExpiry: value.expiryDate,
        approvalType: value.approvalType
      },
      apiProviders: {
        panApiProvider: value.panApiProvider,
        udyamRegNoApiProvider: value.udyamRegNoApiProvider,
        aadhaarApiProvider: value.aadhaarApiProvider,
        gstApiProvider: value.gstApiProvider,
        gstItrApiProvider: value.gstItrApiProvider,
        bankAccApiProvider: value.bankAccApiProvider,
        bankStmtApiProvider: value.bankStmtApiProvider,
        creditReportApiProvider: value.creditReportApiProvider,
        panKarzaApiKey: value.panKarzaApiKey,
        panGridlinesApiKey: value.panGridlinesApiKey,
        panScoreMeClientIdApiKey: value.panScoreMeClientIdApiKey,
        panScoreMeClientSecretApiKey: value.panScoreMeClientSecretApiKey,
        udyamRegNoKarzaApiKey: value.udyamRegNoKarzaApiKey,
        udyamRegNoGridlinesApiKey: value.udyamRegNoGridlinesApiKey,
        udyamRegNoScoreMeClientIdApiKey: value.udyamRegNoScoreMeClientIdApiKey,
        udyamRegNoScoreMeClientSecretApiKey: value.udyamRegNoScoreMeClientSecretApiKey,
        aadhaarKarzaApiKey: value.aadhaarKarzaApiKey,
        aadhaarGridlinesApiKey: value.aadhaarGridlinesApiKey,
        aadhaarScoreMeClientIdApiKey: value.aadhaarScoreMeClientIdApiKey,
        aadhaarScoreMeClientSecretApiKey: value.aadhaarScoreMeClientSecretApiKey,
        gstKarzaApiKey: value.gstKarzaApiKey,
        gstGridlinesApiKey: value.gstGridlinesApiKey,
        gstScoreMeClientIdApiKey: value.gstScoreMeClientIdApiKey,
        gstScoreMeClientSecretApiKey: value.gstScoreMeClientSecretApiKey,
        gstItrKarzaApiKey: value.gstItrKarzaApiKey,
        gstItrScoreMeClientIdApiKey: value.gstItrScoreMeClientIdApiKey,
        gstItrScoreMeClientSecretApiKey: value.gstItrScoreMeClientSecretApiKey,
        bankAccKarzaApiKey: value.bankAccKarzaApiKey,
        bankAccGridlinesApiKey: value.bankAccGridlinesApiKey,
        bankAccScoreMeClientIdApiKey: value.bankAccScoreMeClientIdApiKey,
        bankAccScoreMeClientSecretApiKey: value.bankAccScoreMeClientSecretApiKey,
        bankStmtFinboxApiKey: value.bankStmtFinboxApiKey,
        bankStmtScoreMeClientIdApiKey: value.bankStmtScoreMeClientIdApiKey,
        bankStmtScoreMeClientSecretApiKey: value.bankStmtScoreMeClientSecretApiKey,
        creditReportEquifaxApiKey: value.creditReportEquifaxApiKey,
        creditReportExperianApiKey: value.creditReportExperianApiKey,
        creditReportCrifApiKey: value.creditReportCrifApiKey,
        creditReportCibilApiKey: value.creditReportCibilApiKey
      }
    };

    if (editFinanciers?.data?.adminDetails?.id) {
      data['adminDetails'].id = editFinanciers.data.adminDetails.id;
    }

    const formData = new FormData();
    formData.append('entityDetails', JSON.stringify(data.entityDetails));
    formData.append('adminDetails', JSON.stringify(data.adminDetails));
    formData.append('approvalDetails', JSON.stringify(data.approvalDetails));
    formData.append('apiProviders', JSON.stringify(data.apiProviders));

    if (value.uploadDocuments && value.uploadDocuments[0]) {
      formData.append(
        'entityLogo',
        value.uploadDocuments[0].originFileObj,
        value.uploadDocuments[0].originFileObj.name
      );
    }

    let res;
    let _data = {};
    if (editFinanciers.openEdit === true) {
      const _id = editFinanciers.data.entityDetails.id;
      res = await fetch(getURL(`financiers/${_id}`), {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`
        }
      });
      if (res.headers.get('content-type').includes('application/json')) {
        _data = await res.json();
      }
      res = {
        data: _data,
        hasError: !(res.status === 200),
        status: res.status
      };
      if (res && res.status === 200) {
        getAllFinancier(params)
        editFinancier(true, res.data.data)
        notifications.success({ message: message.FINANCIER_UPDATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      res = await fetch(getURL(`financiers`), {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`
        }
      });
      if (res.headers.get('content-type').includes('application/json')) {
        _data = await res.json();
      }
      res = {
        data: _data,
        hasError: !(res.status === 200),
        status: res.status
      };
      if (res && res.status === 200) {
        getAllFinancier(params)
        notifications.success({ message: message.FINANCIER_CREATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }

    setLoading(false);

    return res;
  };

  const onSubmitUser = async value => {
    const name = value.name.split(' ');

    const _data = {
      firstName: name[0] || '',
      lastName: name[1] || '',
      emailId: value.emailId,
      mobileNo: value.mobileNo,
      userType: value.userType,
      entityId: null,
      entityCategory: null,
      active: 1,
      password: value.password
    };

    if (editUsers.openEdit === true) {
      const res = await fetchRequest(getURL(`platform/users/${editUsers.data.id}`), {
        method: 'PUT',
        body: JSON.stringify(_data)
      });
      if (res && res.status === 200) {
        getAllUsers(params);
        editUser(false, {});
        notifications.success({ message: message.USER_UPDATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
      return res;
    } else {
      const res = await fetchRequest(getURL(`platform/users`), {
        method: 'POST',
        body: JSON.stringify(_data)
      });
      if (res && res.status === 200) {
        getAllUsers(params);
        notifications.success({ message: message.USER_CREATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
      return res;
    }
  };

  return (
    <div>
      <Menu
        defaultSelectedKeys={['HOME']}
        defaultOpenKeys={['HOME']}
        mode='horizontal'
        items={items}
        onClick={onClick}
        selectedKeys={[current]}
      />
      {current === 'FINANCIER' && (financierAdd || editFinanciers.openEdit) && (
        <FinancierAdd
          isUpdate={editFinanciers.openEdit}
          defaultValue={editFinanciers.data || {}}
          onSubmit={onSubmitFinancier}
          onSubmitLoader={loading}
          setFinancierAdd={setFinancierAdd}
        />
      )}
      {current === 'FINANCIER' && !(financierAdd || editFinanciers.openEdit) && (
        <PlatformFinancier
          isUpdate={editFinanciers.openEdit}
          financierData={financierData}
          loading={financierLoading}
          setFinancierAdd={setFinancierAdd}
        />
      )}
      {current === 'SETTINGS' && (
        <PlatformSettings
          isUpdate={editUsers.openEdit}
          defaultValue={editUsers.data || {}}
          users={users}
          loading={loadingUser}
          onSubmit={onSubmitUser}
        />
      )}
      {current === 'CONFIGURATIONS' && <PlatformConfigurations />}
    </div>
  );
};

export default PlatformContainer;
