import React, { useState, Fragment, useEffect } from 'react';
import { Button, Card, Modal, Table } from 'antd';
import { factoringUnitViewColumns } from './viewFactoringUnitColumns';
import { invoiceViewColumns } from './viewInvoiceColumns';
import { actionHistory } from '../approveFactoringUnit/factoringUnitActionColumns';
import { tw } from 'twind';
import dayjs from 'dayjs';
import './styles.css';
import { getFactoringUnitById } from '../../../../services/factoringUnit';
import { useSelector } from 'react-redux';

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const ViewModal = ({ label, data, showActionHistory = true }) => {
  const { factoringUnitById = {}, loadingFactoringUnitById = false } = useSelector(state => ({
    factoringUnitById: state.factoringUnit.factoringUnitById,
    loadingFactoringUnitById: state.factoringUnit.loadingFactoringUnitById
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const factorUnitViewData = [
    {
      key1: 'Factoring Unit No',
      value1: data.factoringUnitNo,
      key2: 'Listed Date',
      value2: data.fuListedDate ? dayjs(data.fuListedDate).format('DD-MM-YYYY') : '-'
    },
    {
      key1: 'Buyer',
      value1: data.buyerSellerLink.buyer.entityName,
      key2: 'Seller',
      value2: data.buyerSellerLink.seller.entityName
    },
    {
      key1: 'Invoice Date',
      value1: dayjs(data.invoices[0].invoiceDate).format('DD-MM-YYYY'),
      key2: 'Due Date',
      value2: dayjs(data.invoices[0].invoiceDueDate).format('DD-MM-YYYY')
    },
    {
      key1: 'Invoice Amount',
      value1: data.sumOfInvoiceAmounts.invoiceAmount,
      key2: 'Discount Amount',
      value2: data.sumOfInvoiceAmounts.discountAmount
    },
    {
      key1: 'Tax Amount',
      value1: data.sumOfInvoiceAmounts.taxAmount,
      key2: 'Total',
      value2: data.sumOfInvoiceAmounts.totalAmount
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible && showActionHistory) {
      getFactoringUnitById(data.factoringUnitNo);
    }
  }, [isModalVisible]);

  return (
    <Fragment>
      <div className={tw`flex justify-center`}>
        <Button style={{ borderRadius: '8px', padding: '4px' }} onClick={showModal}>
          {label}
        </Button>
      </div>
      <Modal
        title={`View Factoring Unit`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Button key='back' onClick={handleCancel} type='primary'>
            Close
          </Button>
        }
        centered
      >
        <div className={tw`table-responsive mb-4`}>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={factorUnitViewData}
            rowKey='key'
            bordered
            loading={false}
            showHeader={false}
            pagination={false}
          />
        </div>
        <Card title='Invoice Details'>
          <div className='table-responsive'>
            <Table
              columns={invoiceViewColumns}
              dataSource={data.invoices.map(row => ({
                ...row,
                buyerSellerLink: data.buyerSellerLink
              }))}
              rowKey='key'
              bordered
              loading={false}
              pagination={{
                pageSize: PAGE_LIMIT,
                total: data.invoices?.length
              }}
            />
          </div>
        </Card>
        {showActionHistory && (
          <Card title='Action History' className='mb-4'>
            <div className='table-responsive'>
              <Table
                columns={actionHistory}
                dataSource={factoringUnitById?.data?.actionHistory}
                rowKey='key'
                loading={loadingFactoringUnitById}
                pagination={{
                  pageSize: PAGE_LIMIT,
                  total: factoringUnitById?.data?.actionHistory?.length
                }}
                bordered
              />
            </div>
          </Card>
        )}
      </Modal>
    </Fragment>
  );
};
