import Date from '../antdComponent/Date';
import IsdCode from '../antdComponent/Mobile';
import PasswordInput from '../antdComponent/Password';
import TextInput from '../antdComponent/Input';
import dayjs from 'dayjs';
import SelectComponent from '../antdComponent/Select';
import { useMemo, useEffect, useState, Fragment } from 'react';
import { tw } from 'twind';
import { showPassword } from '../../services/auth';
import { RULES } from '../../utils/formValidations';
import { GoLinkExternal } from 'react-icons/go';
import { Button, Card, Form, Row, Col, Upload, Input, Spin } from 'antd';
import { getMobileNo } from '../../utils/helpers';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE * 1024;
const THEME = process.env.REACT_APP_THEME;

export const FinancierDetailForm = ({
  isUpdate,
  defaultValue,
  form,
  onSubmitHandler,
  onSubmitLoader,
  isdCode,
  setIsdCode,
  error,
  setError
}) => {
  const [loading, setLoading] = useState(false);

  const initialValue = useMemo(() => {
    if (Object.keys(defaultValue).length) {
      const {
        entityDetails: {
          entityName,
          themeHexcode,
          registrationNo,
          pan,
          udyamRegNo,
          dateOfIncorporation
        },
        adminDetails: { firstName, lastName, mobileNo, emailId },
        approvalDetails: { dateOfExpiry, approvalType }
      } = defaultValue;

      return {
        entityName,
        registrationNumber: registrationNo,
        panNumber: pan,
        udyamNumber: udyamRegNo,
        incorporationDate: dateOfIncorporation ? dayjs(dateOfIncorporation) : null,
        adminName: `${firstName} ${lastName || ""}`,
        mobileNo: getMobileNo(mobileNo),
        email: emailId,
        expiryDate: dateOfExpiry ? dayjs(dateOfExpiry) : null,
        approvalType: approvalType,
        themeHexcode: themeHexcode ? `#${themeHexcode}` : THEME
      };
    }
    return { themeHexcode: THEME };
  }, [defaultValue]);

  const getFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = file => {
    const fileSizeKiloBytes = file.size / 1024;
    setError(fileSizeKiloBytes > MAX_FILE_SIZE ? 'File size is greater than maximum limit' : false);
    return false;
  };

  const showUserPassword = async id => {
    setLoading(true);
    const password = await showPassword(id);
    form.setFieldsValue({
      password: password,
      repassword: password
    });
    setLoading(false);
  };

  useEffect(() => {
    if (isUpdate) {
      showUserPassword(defaultValue.adminDetails.id);
    }
  }, [isUpdate]);

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [initialValue]);

  if (loading || onSubmitLoader) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin spinning={loading || onSubmitLoader} />
      </div>
    );
  }

  return (
    <Fragment>
      <Card title='Entity Details'>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24 },
            { xs: 12, sm: 16, md: 24 }
          ]}
          className={tw`mb-4 md:mb-0`}
        >
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='entityName' rules={RULES.entityName}>
              <TextInput label='Entity Name' placeholder='Enter Name' required />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='registrationNumber' rules={RULES.registrationNumber}>
              <TextInput
                label='Registration Number'
                placeholder='Enter Registration Number'
                required
                maxLength='12'
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='panNumber' rules={RULES.panNumber}>
              <TextInput
                label='PAN Number'
                placeholder='Enter PAN'
                required
                maxLength={10}
                onChange={e => form.setFieldValue('panNumber', e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='udyamNumber' rules={RULES.udyamNumber}>
              <TextInput label='Udyam Number' placeholder='Enter Udyam No' maxLength={19} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='incorporationDate' rules={RULES.date}>
              <Date
                required
                label='Date of Incorporation'
                placeholder='Select Date'
                disabledDate={current => current.isAfter(dayjs().subtract(1, 'day'))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={5}>
            <Form.Item name='uploadDocuments' getValueFromEvent={getFile} rules={[]}>
              <Upload accept="image/*" beforeUpload={beforeUpload} maxCount={1}>
                <Button icon={<UploadOutlined />}>Browse Entity Logo</Button>
                <br />
                <p className={tw`text-red-500`}>{error}</p>
              </Upload>
            </Form.Item>
          </Col>
          {isUpdate && defaultValue.entityDetails.finLogoDocId && (
            <Col xs={24} sm={8} md={1}>
              <div className={tw`mt-2`}>
                <Link
                  target={'_blank'}
                  to={{
                    pathname: `/financiers/logo/${defaultValue.entityDetails.finLogoDocId}/signed-url`,
                    search: '?action=VIEW&expiresIn=300'
                  }}
                >
                  <GoLinkExternal size={24} color='#228be6' />
                </Link>
              </div>
            </Col>
          )}
          <Col xs={24} sm={8} md={2}>
            <Form.Item name='themeHexcode' rules={[]}>
              <Input style={{ cursor: 'pointer' }} type='color' />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card
        title='Admin Details'
        style={{
          width: '100%',
          marginTop: '20px'
        }}
      >
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24 },
            { xs: 12, sm: 16, md: 24 }
          ]}
          className={tw`mb-4 md:mb-0`}
        >
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='adminName' rules={RULES.name}>
              <TextInput label='Admin Name' placeholder='Enter Name' required />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item
              name='mobileNo'
              rules={RULES.mobileNo}
              style={{ position: 'relative', bottom: 7 }}
            >
              <TextInput
                label='Mobile No'
                placeholder='Enter Number'
                required
                addonBefore={<IsdCode isdCode={isdCode} setIsdCode={code => setIsdCode(code)} />}
                maxLength={10}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='email' rules={RULES.email}>
              <TextInput label='Email' placeholder='Enter Email' required />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='password' rules={RULES.password} hasFeedback>
              <PasswordInput label='Password' placeholder='Enter Password' required />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='repassword' rules={RULES.repassword} hasFeedback>
              <PasswordInput label='Re-Password' placeholder='Re-enter Password' required />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title='Approval Details' style={{ width: '100%', marginTop: '20px' }}>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24 },
            { xs: 12, sm: 16, md: 24 }
          ]}
          className={tw`mb-4 md:mb-0`}
        >
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='expiryDate' className='' rules={RULES.date}>
              <Date label='Date of Expiry' disabled placeholder='Select Date' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7}>
            <Form.Item name='approvalType' rules={RULES.selectRequired}>
              <SelectComponent
                label='Trial/Permanent'
                placeholder='Select'
                allowClear={true}
                onChange={type => {
                  form.setFieldsValue({
                    approvalType: type,
                    expiryDate: type === 'TRIAL' ? dayjs().add(15, 'days') : dayjs().add(1, 'years')
                  });
                }}
                options={[
                  { label: 'Trial', value: 'TRIAL' },
                  {
                    label: 'Permanent',
                    value: 'PERMANENT'
                  }
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Button
        className={tw`my-5 w-full sm:w-32`}
        loading={onSubmitLoader}
        size='middle'
        type='primary'
        onClick={() => onSubmitHandler({ type: "FINANCIER_DETAILS" })}
      >
        {isUpdate ? 'Update' : 'Continue'}
      </Button>
    </Fragment>
  );
};
