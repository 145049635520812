import React from 'react';
import dayjs from 'dayjs';
import useFetch from '../../../../hooks/useFetch';
import { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Form, Input } from 'antd';
import { factoringUnitViewColumns } from '../../../finance/factoringUnit/viewFactoringUnit/viewFactoringUnitColumns';
import { invoiceDocumentColumns } from '../documentsInvoice/documentsColumns';
import { getInvoiceById } from '../../../../services/buyerSeller';
import { getAllInvoiceDocuments } from '../../../../services/factoringUnit';
import { useSelector } from 'react-redux';
import { RULES } from '../../../../utils/formValidations';
import { tw } from 'twind';
import { getURL } from '../../../../configs/apiURL';
import { notifications } from '../../../../utils/notifications';
import { actionHistory } from './invoiceActionColumns';
import { getAllInvoices } from '../../../../services/buyerSeller';
import TableComponent from '../../../antdComponent/Table';

const { TextArea } = Input;

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT;

export const ApproveInvoiceModal = ({ label, data }) => {
  const {
    params,
    modalParams,
    documentsLoading = false,
    documentsData = {},
    loadingInvoiceById = false,
    invoiceById = {}
  } = useSelector(state => ({
    params: state.mutations.params,
    modalParams: state.mutations.modalParams,
    documentsLoading: state.factoringUnit.loadingInvoiceDocument,
    documentsData: state.factoringUnit.invoiceDocument,
    loadingInvoiceById: state.buyerSeller.loadingInvoiceById,
    invoiceById: state.buyerSeller.invoiceById
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [update] = useFetch();

  const factorUnitViewData = [
    {
      key1: 'Invoice No',
      value1: data.invoiceNo,
      key2: 'Factoring Unit',
      value2: data.factoringUnitNo || '-'
    },
    {
      key1: 'Buyer',
      value1: data.buyerSellerLink.buyer.entityName,
      key2: 'Seller',
      value2: data.buyerSellerLink.seller.entityName
    },
    {
      key1: 'Invoice Date',
      value1: dayjs(data.invoiceDate).format('DD-MM-YYYY'),
      key2: 'Due Date',
      value2: dayjs(data.invoiceDueDate).format('DD-MM-YYYY')
    },
    {
      key1: 'Invoice Amount',
      value1: data.invoiceAmount,
      key2: 'Discount Amount',
      value2: data.discountAmount
    },
    {
      key1: 'Tax Amount',
      value1: data.taxAmount,
      key2: 'Total',
      value2: data.totalAmount
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = async ({ approved }) => {
    try {
      const { remarks } = await form.validateFields();
      const res = await update(getURL(`invoices/${data.id}/approve-or-reject`), {
        method: 'PUT',
        body: JSON.stringify({ approved, remarks })
      });
      if (res && res.status === 200) {
        notifications.success({
          message: `Invoice ${approved ? 'approved' : 'rejected'} successfully`
        });
        setIsModalVisible(false);
        getAllInvoices(params);
        form.resetFields();
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      getInvoiceById(data.id);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      getAllInvoiceDocuments(data.id, modalParams);
    }
  }, [isModalVisible, modalParams]);

  return (
    <>
      <Button style={{ borderRadius: '8px', padding: '4px' }} onClick={showModal}>
        {label}
      </Button>
      <Modal
        title={`Approve Invoice`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={
          <Form
            form={form}
            name='bank'
            size='large'
            className={tw`flex flex-col md:flex-row justify-center items-start md:items-center gap-3`}
            onFinishFailed={errorInfo => {
              console.log(errorInfo);
            }}
            autoComplete='off'
          >
            <div style={{ flex: 1 }} className={tw`w-full`}>
              <Form.Item name='remarks' rules={RULES.inputRequired}>
                <TextArea rows={4} label='Remarks' placeholder='Enter Remarks' />
              </Form.Item>
            </div>
            <div className={tw`flex md:pt-14 gap-1`}>
              <Button type='primary' size='middle' onClick={() => onSubmit({ approved: 1 })}>
                Approve
              </Button>
              <Button danger type='primary' size='middle' onClick={() => onSubmit({ approved: 0 })}>
                Reject
              </Button>
            </div>
          </Form>
        }
        centered
      >
        <div className='table-responsive mb-4'>
          <Table
            columns={factoringUnitViewColumns}
            dataSource={factorUnitViewData}
            rowKey='key'
            loading={false}
            pagination={false}
            showHeader={false}
            bordered
          />
        </div>
        <Card title='Document Details' className='mb-4'>
          <TableComponent
            columns={invoiceDocumentColumns}
            data={{
              ...documentsData,
              data: documentsData?.data?.map(document => ({
                ...document,
                invoice: data
              }))
            }}
            loading={documentsLoading}
            isModalTable={true}
          />
        </Card>
        <Card title='Action History' className='mb-4'>
          <div className='table-responsive'>
            <Table
              columns={actionHistory}
              dataSource={invoiceById?.data?.actionHistory}
              rowKey='key'
              loading={loadingInvoiceById}
              pagination={{
                pageSize: PAGE_LIMIT,
                total: invoiceById?.data?.actionHistory?.length
              }}
              bordered
            />
          </div>
        </Card>
      </Modal>
    </>
  );
};
