import React from 'react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Card, Tabs, Table, Typography, Space } from 'antd';
import { tw } from 'twind';
import { Link } from 'react-router-dom';
import { GoLinkExternal } from 'react-icons/go';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const { Text } = Typography;

export const BankUploadCardViewModal = ({ kycData = {}, loadingKycData = false }) => {
  const { profile = {}, editBuyerSeller = {} } = useSelector(state => ({
    profile: state.user.profile,
    editBuyerSeller: state.buyerSeller.editBuyerSeller
  }));

  const [currentTab, setCurrentTab] = useState('1');
  const entityCategory = localStorage.getItem('entityCategory');

  const BankStmtExcelColumns = [
    {
      title: <div className={tw`text-center flex flex-col items-center`}>#</div>,
      key: 'ExcelReportNo',
      render: (_, record, index) => <Text>{index + 1}</Text>
    },
    {
      title: <Text>Download</Text>,
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        const entityId = ['BUYER', 'SELLER'].includes(entityCategory)
          ? profile.entity.id
          : editBuyerSeller.data.entityDetails.id;
        return (
          <div className={tw`flex justify-center`}>
            <Space>
              <Link
                target={'_blank'}
                to={{
                  pathname: `/buyer-seller/${entityId}/documents/${record.link}/signed-url`,
                  search: '?action=DOWNLOAD&expiresIn=300'
                }}
              >
                <GoLinkExternal size={24} color='#228be6' />
              </Link>
            </Space>
          </div>
        );
      }
    }
  ];

  return (
    <Card
      className={tw`mt-6`}
      title={tw`${loadingKycData ? 'Preparing' : ''} Bank Statment Reports.`}
    >
      <Tabs
        type='line'
        size='middle'
        tabPosition={'left'}
        activeKey={currentTab}
        onChange={key => {
          setCurrentTab(key);
        }}
      >
        <TabPane tab="PDF's" key='1'>
          <Table
            columns={BankStmtColumns}
            dataSource={kycData?.stmtDocs}
            loading={loadingKycData}
            pagination={false}
          />
        </TabPane>
        <TabPane tab='Excel Reports' key='2'>
          <Table
            columns={BankStmtExcelColumns}
            dataSource={kycData?.stmtData?.xlsxReport?.reports}
            loading={loadingKycData}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export const BankStmtColumns = [
  {
    title: <div className={tw`text-center flex flex-col items-center`}>#</div>,
    key: 'statementtNo',
    render: (_, record, index) => {
      return <Text>{index + 1}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Account Number</Text>
      </div>
    ),
    dataIndex: 'account_number',
    key: 'account_number',
    render: (_, record) => {
      return <Text>{record.identity.account_number}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Statement From</Text>
      </div>
    ),
    dataIndex: 'from_date',
    key: 'from_date',
    render: (_, record) => {
      return <Text>{dayjs(record.date_range.from_date).format('DD-MM-YYYY')}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Statement To</Text>
      </div>
    ),
    dataIndex: 'to_date',
    key: 'to_date',
    render: (_, record) => {
      return <Text>{dayjs(record.date_range.to_date).format('DD-MM-YYYY')}</Text>;
    }
  }
];
