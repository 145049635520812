import React, { useMemo } from 'react';
import axios from 'axios';
import useFetch from '../../../hooks/useFetch';
import { useEffect, useState } from 'react';
import { Card, Descriptions, Spin } from 'antd';
import { tw } from 'twind';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { getURL } from '../../../configs/apiURL';
import { useSelector } from 'react-redux';

export const PromoterCardViewModal = ({
  customFetch = false,
  promoterData = {},
  panKycData = {},
  aadhaarKycData = {},
  index = 0
}) => {
  const { editBuyerSeller } = useSelector(state => ({
    editBuyerSeller: state.buyerSeller.editBuyerSeller
  }));

  const [fetch, loading] = useFetch();
  const [panKycDetails, setPanKycDetails] = useState(panKycData);
  const [aadhaarKycDetails, setAadhaarKycDetails] = useState(aadhaarKycData);

  const { panApiProvider = '', aadhaarApiProvider = '' } = editBuyerSeller.data.apiProviders || {};

  const kycDetails = useMemo(() => {
    let details = {};
    switch (panApiProvider) {
      case 'KARZA':
        if (promoterData.panVerified && Object.keys(panKycDetails).length) {
          details = {
            ...details,
            panNumber: panKycDetails.pan,
            entityName: panKycDetails.name,
            panDoi: panKycDetails.dob
          };
        }
        break;
      case 'GRIDLINES':
        if (promoterData.panVerified && Object.keys(panKycDetails).length) {
          details = {
            ...details,
            panNumber: panKycDetails.document_id,
            entityName: panKycDetails.name,
            panDoi: panKycDetails.date_of_birth
          };
        }
        break;
      default:
        return {};
    }
    switch (aadhaarApiProvider) {
      case 'KARZA':
        if (promoterData.aadhaarVerified && Object.keys(aadhaarKycDetails).length) {
          details = {
            ...details,
            aadhaarName: aadhaarKycDetails.dataFromAadhaar.name,
            aadhaarGenger: aadhaarKycDetails.dataFromAadhaar.gender,
            aadhaarDob: aadhaarKycDetails.dataFromAadhaar.dob,
            aadhaarAddress: aadhaarKycDetails.dataFromAadhaar.address.combinedAddress,
            aadhaarImage: aadhaarKycDetails.dataFromAadhaar.image
          };
        }
        break;
      case 'GRIDLINES':
        if (promoterData.aadhaarVerified && Object.keys(aadhaarKycDetails).length) {
          details = {
            ...details,
            aadhaarName: aadhaarKycDetails.aadhaar_data.name,
            aadhaarGenger: aadhaarKycDetails.aadhaar_data.gender,
            aadhaarDob: aadhaarKycDetails.aadhaar_data.date_of_birth,
            aadhaarImage: aadhaarKycDetails.aadhaar_data.photo_base64,
            aadhaarAddress: [
              'house',
              'street',
              'locality',
              'landmark',
              'state',
              'country',
              'pincode'
            ]
              .map(row => aadhaarKycDetails.aadhaar_data[row])
              .join(' ')
          };
        }
        break;
      default:
        return {};
    }
    return details;
  }, [panApiProvider, aadhaarApiProvider, panKycDetails, aadhaarKycDetails]);

  const getValue = (key, from = 'kyc') => {
    if (from === 'kyc') {
      return kycDetails[key] ? kycDetails[key] : 'NA';
    } else if (from === 'promoter') {
      return promoterData[key] ? promoterData[key] : null;
    }
    return null;
  };

  const fetchDetails = async (panNo, aadhaarNo) => {
    const [pan, aadhaar] = await axios.all(
      ['pan', 'aadhaar'].map(key =>
        fetch(
          getURL(
            `${key === 'pan' ? panApiProvider : aadhaarApiProvider}/kyc/${key}/${
              key === 'pan' ? panNo : aadhaarNo
            }`
          )
        )
      )
    );
    if (pan && pan.status === 200) {
      setPanKycDetails(pan.data.data);
    }
    if (aadhaar && aadhaar.status === 200) {
      setAadhaarKycDetails(aadhaar.data.data);
    }
  };

  useEffect(() => {
    if (customFetch) {
      fetchDetails(promoterData.pan, promoterData.aadhaarOrRegNo);
    }
  }, [panApiProvider, aadhaarApiProvider]);

  return (
    <Card
      className={tw`${!customFetch && 'border-none promoter-card'}`}
      title={index && `# Promoter - ${index}`}
    >
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Spin spinning={loading} />
        </div>
      )}
      {!loading && (
        <div style={styles.container}>
          <h3 className={classNames.heading}>
            {['INDIVIDUAL'].includes(getValue('promoterType', 'promoter'))
              ? 'PAN Individual'
              : 'PAN Entity'}{' '}
            {getValue('panVerified', 'promoter') ? (
              <MdCheckCircle color='green' />
            ) : (
              <MdCancel color='red' />
            )}
          </h3>
          <Descriptions layout='horizontal' bordered size='middle'>
            <Descriptions.Item label='PAN' span={3}>
              {getValue('panNumber')}
            </Descriptions.Item>
            <Descriptions.Item label='Name' span={3}>
              {getValue('entityName')}
            </Descriptions.Item>
            <Descriptions.Item label='DOI' span={3}>
              {getValue('panDoi')}
            </Descriptions.Item>
          </Descriptions>
          {['INDIVIDUAL'].includes(getValue('promoterType', 'promoter')) && (
            <h3 className={classNames.heading}>
              {'Aadhaar'}{' '}
              {getValue('aadhaarVerified', 'promoter') ? (
                <MdCheckCircle color='green' />
              ) : (
                <MdCancel color='red' />
              )}
            </h3>
          )}
          {['INDIVIDUAL'].includes(getValue('promoterType', 'promoter')) && (
            <div className={tw`flex gap-10`}>
              <Descriptions
                title=''
                layout='horizontal'
                bordered
                size='middle'
                style={{ flexGrow: 1 }}
              >
                <Descriptions.Item label='Name' span={3}>
                  {getValue('aadhaarName')}
                </Descriptions.Item>
                <Descriptions.Item label='DOB' span={3}>
                  {getValue('aadhaarDob')}
                </Descriptions.Item>
                <Descriptions.Item label='Gender' span={3}>
                  {getValue('aadhaarGenger')}
                </Descriptions.Item>
                <Descriptions.Item label='Address' span={3}>
                  {getValue('aadhaarAddress')}
                </Descriptions.Item>
              </Descriptions>
              <div style={{ width: getValue('aadhaarImage') ? 150 : 200 }}>
                <img
                  alt='aadhaar'
                  height={170}
                  width={getValue('aadhaarImage') ? 150 : 200}
                  style={styles.image}
                  src={
                    getValue('aadhaarImage')
                      ? `data:image/jpeg;charset=utf-8;base64,${getValue('aadhaarImage')}`
                      : 'user.png'
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  image: {
    borderRadius: 7.5,
    backgroundColor: '#fafafa'
  }
};

const classNames = {
  heading: tw`text-lg font-medium flex items-center gap-4`
};
