import React, { useMemo } from 'react';
import useFetch from '../../hooks/useFetch';
import BuyerSellerLinkAdd from './addBuyerSellerLink';
import TableComponent from '../antdComponent/Table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllBuyerSellerLink } from '../../services/buyerSeller';
import { getURL } from '../../configs/apiURL';
import { notifications } from '../../utils/notifications';
import { editBuyerSellerLink } from '../../redux/buyerSeller/actions';
import { message } from '../../utils/messages';
import { Button, Typography } from 'antd';
import { RiAddCircleFill } from 'react-icons/ri';
import { tw } from 'twind';
import { Permissions } from '../../configs/permissions';
import { BuyerSellerLinkColumns } from './buyerSellerLinkColumns';

const { Text } = Typography;

const BuyerSellerLinkContainer = () => {
  const {
    params,
    editBuyerSellerLinks = { data: {}, openEdit: false },
    buyerSellerLinkData = {},
    loadingBuyerSellerLink = false
  } = useSelector(state => ({
    params: state.mutations.params,
    editBuyerSellerLinks: state.buyerSeller.editBuyerSellerLink,
    buyerSellerLinkData: state.buyerSeller.buyerSellerLink,
    loadingBuyerSellerLink: state.buyerSeller.loadingBsLink
  }));

  const [fetch] = useFetch();
  const [buyerSellerLinkAdd, setBuyerSellerLinkAdd] = useState(false);
  const [buyerDropdown, setBuyerDropdown] = useState();
  const [sellerDropdown, setSellerDropdown] = useState();
  const [disableDropdown, setDisableDropdown] = useState(true);

  const { entityId, entityCategory, userType, currentTab } = localStorage;

  const BSLinkRoot = useMemo(() => {
    let query;
    if (
      ['ADMIN', 'OPERATION_MANAGER'].includes(userType) &&
      ['BUYER_SELLER_LINK_OPERATION'].includes(currentTab)
    ) {
      query = `approved=0`;
    } else if (['APPROVED'].includes(currentTab)) {
      query = `approved=1`;
    } else {
      query = `approved=0`;
    }
    return query;
  }, []);

  const onSubmitBsLinkAdd = async value => {
    let buyerStartDate = null;
    let buyerEndDate = null;
    let sellerStartDate = null;
    let sellerEndDate = null;
    let totalPeriod = parseInt(value.creditPeriod) + parseInt(value.extendedCreditPeriod);
    let tillDays = parseInt(value.tillDays);

    if (value.costBearer === 'PERIODIC_SPLIT' && value.splitBuyerSeller === 'BUYER') {
      buyerStartDate = 1;
      buyerEndDate = tillDays;
      sellerStartDate = tillDays + 1;
      sellerEndDate = totalPeriod;
    } else if (value.costBearer === 'PERIODIC_SPLIT' && value.splitBuyerSeller === 'SELLER') {
      sellerStartDate = 1;
      sellerEndDate = tillDays;
      buyerStartDate = tillDays + 1;
      buyerEndDate = totalPeriod;
    }
    const data = {
      buyerId: value.buyerName,
      sellerId: value.sellerName,
      creditPeriod: parseInt(value.creditPeriod),
      extendedCreditPeriod: parseInt(value.extendedCreditPeriod),
      sendForFinance: value.approvedFinance,
      acceptPayment: value.acceptPayment,
      costBearer: value.costBearer,
      buyerPercentage: parseInt(value.buyerPercent),
      sellerPercentage: parseInt(value.sellerPercent),
      buyerStartDays: buyerStartDate,
      buyerEndDays: buyerEndDate,
      sellerStartDays: sellerStartDate,
      sellerEndDays: sellerEndDate,
      approved: editBuyerSellerLinks.data.approved || value.approved || 0,
      remarks: editBuyerSellerLinks.data.remarks || value.remarks || null
    };

    if (editBuyerSellerLinks.openEdit) {
      const _id = editBuyerSellerLinks.data.id;
      const res = await fetch(getURL(`buyer-seller/links/${_id}`), {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      if (res.status === 200) {
        getAllBuyerSellerLink(`${BSLinkRoot}&${params ? params : ''}`);
        editBuyerSellerLink(false, {});
        notifications.success({ message: message.BUYER_SELLER_LINK_UPDATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    } else {
      const res = await fetch(getURL(`buyer-seller/links`), {
        method: 'POST',
        body: JSON.stringify(data)
      });
      if (res.status === 200) {
        getAllBuyerSellerLink(`${BSLinkRoot}&${params ? params : ''}`);
        setBuyerSellerLinkAdd(false);
        notifications.success({ message: message.BUYER_SELLER_LINK_CREATED });
      } else {
        notifications.error({
          message: res.data.error.message || 'something went wrong'
        });
      }
    }
  };

  const fetchBuyerSellerData = async () => {
    let res = {};
    switch (entityCategory) {
      case 'FINANCIER': {
        res = await fetch(getURL('buyer-seller?approved=1&active=1&entityCategory=BUYER'));
        if (res.status === 200) {
          setBuyerDropdown(
            res.data.data.map(item => ({
              value: item.entityDetails.id,
              label: item.entityDetails.entityName
            }))
          );
        }
        break;
      }
      case 'SELLER': {
        res = await fetch(getURL('buyer-seller/profile'));
        if (res.status === 200) {
          setSellerDropdown([
            {
              value: res.data.data.entityDetails.id,
              label: res.data.data.entityDetails.entityName
            }
          ]);
        }
        const buyerLinkedIds = await fetch(
          getURL(`buyer-seller/links?approved=1&sellerId=${entityId}`)
        );
        res = await fetch(
          getURL(
            buyerLinkedIds.data.data.length
              ? `buyer-seller?approved=1&active=1&entityCategory=BUYER&excludeEntityIds=${encodeURIComponent(
                  buyerLinkedIds.data.data.map(item => item.buyer.id)
                )}`
              : `buyer-seller?approved=1&active=1&entityCategory=BUYER`
          )
        );
        if (res.status === 200) {
          setBuyerDropdown(
            res.data.data.map(item => ({
              value: item.entityDetails.id,
              label: item.entityDetails.entityName
            }))
          );
        }
        break;
      }
      case 'BUYER': {
        res = await fetch(getURL('buyer-seller/profile'));
        if (res.status === 200) {
          setBuyerDropdown([
            {
              value: res.data.data.entityDetails.id,
              label: res.data.data.entityDetails.entityName
            }
          ]);
        }
        const sellerLinkedIds = await fetch(
          getURL(`buyer-seller/links?approved=1&buyerId=${entityId}`)
        );
        res = await fetch(
          getURL(
            sellerLinkedIds.data.data.length
              ? `buyer-seller?approved=1&active=1&entityCategory=SELLER&excludeEntityIds=${encodeURIComponent(
                  sellerLinkedIds.data.data.map(item => item.seller.id)
                )}`
              : `buyer-seller?approved=1&active=1&entityCategory=SELLER`
          )
        );
        if (res.status === 200) {
          setSellerDropdown(
            res.data.data.map(item => ({
              value: item.entityDetails.id,
              label: item.entityDetails.entityName
            }))
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const buyerOnChange = async id => {
    let res = {};
    const sellerLinkedIds = await fetch(getURL(`buyer-seller/links?approved=1&buyerId=${id}`));
    res = await fetch(
      getURL(
        sellerLinkedIds.data.data.length
          ? `buyer-seller?approved=1&active=1&entityCategory=SELLER&excludeEntityIds=${encodeURIComponent(
              sellerLinkedIds.data.data.map(item => item.seller.id)
            )}`
          : `buyer-seller?approved=1&active=1&entityCategory=SELLER`
      )
    );
    if (res.status === 200) {
      setSellerDropdown(
        res.data.data.map(item => ({
          value: item.entityDetails.id,
          label: item.entityDetails.entityName
        }))
      );
      setDisableDropdown(false);
    }
  };

  const bsLinkAddToggleHandler = bool => {
    setBuyerSellerLinkAdd(bool);
  };

  useEffect(() => {
    if (editBuyerSellerLinks.openEdit) {
      setBuyerDropdown([
        {
          value: editBuyerSellerLinks.data.buyer.id,
          label: editBuyerSellerLinks.data.buyer.entityName
        }
      ]);
      setSellerDropdown([
        {
          value: editBuyerSellerLinks.data.seller.id,
          label: editBuyerSellerLinks.data.seller.entityName
        }
      ]);
    } else {
      fetchBuyerSellerData();
    }
  }, [editBuyerSellerLinks.openEdit]);

  useEffect(() => {
    getAllBuyerSellerLink(`${BSLinkRoot}&${params ? params : ''}`);
  }, [params]);

  return (
    <React.Fragment>
      {buyerSellerLinkAdd || editBuyerSellerLinks.openEdit ? (
        <BuyerSellerLinkAdd
          onSubmit={onSubmitBsLinkAdd}
          isUpdate={editBuyerSellerLinks.openEdit}
          buyerSellerLinkAdd={buyerSellerLinkAdd}
          buyerSellerLinkAddToggle={bsLinkAddToggleHandler}
          entityDefaultValue={editBuyerSellerLinks.openEdit ? editBuyerSellerLinks.data : []}
          editBuyerSellerLinks={editBuyerSellerLinks}
          data={buyerSellerLinkData}
          buyerOnChange={buyerOnChange}
          setDisableDropdown={setDisableDropdown}
          disableDropdown={disableDropdown}
          buyerDropdown={buyerDropdown}
          sellerDropdown={sellerDropdown}
        />
      ) : (
        <div>
          <div className={tw`flex flex-col md:flex-row justify-between content-divider`}>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: '600px',
                textAlign: 'center'
              }}
            >
              Buyer-Seller Link
            </Text>
            <div className={tw`ml-auto`}>
              {Permissions('manageBuyerSellerLink', 'addBuyerSellerLink') && (
                <Button
                  name='add-link'
                  type='primary'
                  style={{ borderRadius: '4px' }}
                  onClick={() => {
                    bsLinkAddToggleHandler(true);
                  }}
                >
                  <div className={tw`text-xs md:text-sm flex gap-1 items-center`}>
                    <RiAddCircleFill size='20px' /> Add Buyer-Seller Link
                  </div>
                </Button>
              )}
            </div>
          </div>
          <TableComponent
            columns={BuyerSellerLinkColumns.filter(column => {
              if (column.key === 'action') {
                if (
                  Permissions('manageBuyerSellerLink', 'editBuyerSellerLink') &&
                  Permissions('manageBuyerSellerLink', 'deleteBuyerSellerLink')
                ) {
                  return true;
                }
                return false;
              }
              return true;
            })}
            data={buyerSellerLinkData}
            loading={loadingBuyerSellerLink}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BuyerSellerLinkContainer;
