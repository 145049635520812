import React from 'react';
import jwt_decode from 'jwt-decode';
import LoginPage from './auth/login';
import PasswordResetPage from './auth/resetPassword';
import HomePage from '../pages/home';
import * as ROUTES from '../configs/routes';
import DocumentView from '../component/documentView';
import FinancierPage from '../pages/financier';
import NotFound from '../component/antdComponent/NotFound';
import Platform from '../pages/platform';
import ProfilePage from '../pages/profile';
import SellerPage from '../pages/seller';
import BuyerPage from '../pages/buyer';
import AuthGuard from '../configs/authGuard';
import Cookies from 'js-cookie';
import './index.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { getURL } from '../configs/apiURL';
import { useSelector } from 'react-redux';

const THEME = process.env.REACT_APP_THEME;

const MainRouter = () => {
  const { profile } = useSelector(state => ({
    profile: state.user.profile
  }));

  const { logoImage, themeHexcode } = localStorage;

  useEffect(() => {
    let intervalCall;
    // Refreshing logo in every 30 minutes if user is loggined.
    logoImage
      ? (intervalCall = setInterval(async () => {
          const { token_payload } = await jwt_decode(Cookies.get('ACCESS_TOKEN'));
          const url = `/financiers/logo/${token_payload.finLogoDocId}/signed-url?action=VIEW&expiresIn=1800`;
          const options = {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${Cookies.get('ACCESS_TOKEN')}`
            }
          };
          const res = await fetch(getURL(url), options);

          if (res.status === 200) {
            const {
              data: { url }
            } = await res.json();
            localStorage.setItem('logoImage', url);
          }
        }, 1790000))
      : clearInterval(intervalCall);

    return () => clearInterval(intervalCall);
  }, [logoImage]);

  const theme = {
    token: {
      colorPrimary: themeHexcode || profile?.themeHexcode || THEME,
      borderRadius: '4px',
      defaultThemeColor: THEME
    }
  };

  return (
    <ConfigProvider theme={theme}>
      <Router>
        <Routes>
          {/* Login route & permisssions */}
          <Route path={ROUTES['LOGIN']['path']} element={<LoginPage />} />

          {/* Login route & permisssions */}
          <Route path={ROUTES['RESET_PASSWORD']['path']} element={<PasswordResetPage />} />

          {/* Home route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['HOME']['permissions']} />}>
            <Route path={ROUTES['HOME']['path']} element={<HomePage />} />
          </Route>

          {/* Profile route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['PROFILE']['permissions']} />}>
            <Route path={ROUTES['PROFILE']['path']} element={<ProfilePage />} />
          </Route>

          {/* Platform route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['FINANCIER_PLATFORM']['permissions']} />}>
            <Route path={ROUTES['FINANCIER_PLATFORM']['path']} element={<Platform />} />
          </Route>

          {/* Financier route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['FINANCIER']['permissions']} />}>
            <Route path={ROUTES['FINANCIER']['path']} element={<FinancierPage />} />
          </Route>

          {/* Buyer route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['BUYER']['permissions']} />}>
            <Route path={ROUTES['BUYER']['path']} element={<BuyerPage />} />
          </Route>

          {/* Seller route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['SELLER']['permissions']} />}>
            <Route path={ROUTES['SELLER']['path']} element={<SellerPage />} />
          </Route>

          {/* Documents route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['DOCUMENT_VIEW']['permissions']} />}>
            <Route path={ROUTES['DOCUMENT_VIEW']['path']} element={<DocumentView />} />
          </Route>

          {/* Invoice-documents route & permisssions */}
          <Route
            element={<AuthGuard allowedRoles={ROUTES['INVOICE_DOCUMENT_VIEW']['permissions']} />}
          >
            <Route path={ROUTES['INVOICE_DOCUMENT_VIEW']['path']} element={<DocumentView />} />
          </Route>

          {/* Logo-view route & permisssions */}
          <Route element={<AuthGuard allowedRoles={ROUTES['LOGO_VIEW']['permissions']} />}>
            <Route path={ROUTES['LOGO_VIEW']['path']} element={<DocumentView />} />
          </Route>

          {/* Page not found */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default MainRouter;
