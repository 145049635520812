import DeletePopup from '../deletePopUp';
import { Button, Divider, Space } from 'antd';
import { MdModeEditOutline, MdCreditScore } from 'react-icons/md';
import { Typography } from 'antd';
import { Permissions } from '../../configs/permissions';
import { tw } from 'twind';
import { editBuyerSellerLink } from '../../redux/buyerSeller/actions';
import { TextFilter, BSLinkCostBearer } from '../../utils/formFilters';

const { Text } = Typography;

export const BuyerSellerLinkColumns = [
  {
    title: (
      <div>
        <Text>Buyer Name</Text>
        <Divider />
        <TextFilter type='buyerName' />
      </div>
    ),
    key: 'buyerName',
    render: (_, record) => {
      return <Text>{record.buyer.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Seller Name</Text>
        <Divider />
        <TextFilter type='sellerName' />
      </div>
    ),
    dataIndex: 'sellerName',
    key: 'sellerName',
    render: (_, record) => {
      return <Text>{record.seller.entityName}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Credit Period</Text>
        <Divider />
        <TextFilter type='creditPeriod' />
      </div>
    ),
    dataIndex: 'creditPeriod',
    key: 'creditPeriod',
    render: (_, record) => {
      return <Text>{record.creditPeriod}</Text>;
    }
  },
  {
    title: (
      <div>
        <Text>Cost Bearer</Text>
        <Divider />
        <BSLinkCostBearer type='costBearer' />
      </div>
    ),
    dataIndex: 'costBearer',
    key: 'costBearer',
    render: (_, record) => {
      return <Text>{record.costBearer}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const buyerSellerLink = `${record.buyer.entityName} - ${record.seller.entityName} - ${record.creditPeriod} - ${record.costBearer}`;
      return (
        <div className={tw`flex justify-center`}>
          <Space size='middle'>
            {Permissions('manageBuyerSellerLink', 'editBuyerSellerLink') && (
              <Button
                name='update-link'
                style={{ borderRadius: '8px', padding: '4px' }}
                onClick={() => {
                  editBuyerSellerLink(true, { ...record, edit: true });
                }}
              >
                <MdModeEditOutline size='20px' color='black' />
              </Button>
            )}
            {Permissions('manageBuyerSellerLink', 'deleteBuyerSellerLink') && (
              <DeletePopup
                type='buyerSellerLink'
                id={record.id}
                data={buyerSellerLink}
                entityId={record.createdByFinancierId}
              />
            )}
            {Permissions(
              localStorage.getItem('currentTab') === 'BUYER_SELLER_LINK_OPERATION'
                ? 'approveBuyerSellerLink'
                : 'manageBuyerSellerLink',
              'approveBuyerSellerLink'
            ) && (
              <Button
                name='approve-entity'
                style={{
                  borderRadius: '8px',
                  padding: '4px'
                }}
                onClick={() => editBuyerSellerLink(true, { ...record, approve: true })}
              >
                <MdCreditScore size='20px' color='black' />
              </Button>
            )}
          </Space>
        </div>
      );
    }
  }
];
