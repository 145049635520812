import DeletePopup from '../../deletePopUp';
import dayjs from 'dayjs';
import { Button, Space, Divider } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { PromoterKycViewModal } from '../modals/viewPromoter';
import { Typography } from 'antd';
import { tw } from 'twind';
import { ModalDateFilter, ModalTextFilter } from '../../../utils/formFilters';
import { editPromter } from '../../../redux/buyerSeller/actions';

const { Text } = Typography;

export const promoterColumns = [
  {
    title: (
      <div className={tw`text-center flex flex-col items-center`}>
        <Text>Promoter Type</Text>
        <Divider />
        <ModalTextFilter type='promoterType' />
      </div>
    ),
    key: 'promoterType',
    render: (_, record) => {
      return <Text>{record.promoterType}</Text>;
    }
  },
  {
    title: (
      <div className={tw`text-center flex flex-col items-center`}>
        <Text>Name</Text>
        <Divider />
        <ModalTextFilter type='nameOrEntityName' />
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      return <Text>{record.nameOrEntityName}</Text>;
    }
  },
  {
    title: (
      <div className={tw`text-center flex flex-col items-center`}>
        <Text>DOB/DOI</Text>
        <Divider />
        <ModalDateFilter type='dobOrDoi' />
      </div>
    ),
    dataIndex: 'dob',
    key: 'dob',
    render: (_, record) => {
      return <Text>{dayjs(record.dobOrDoi).format('DD-MM-YYYY')} </Text>;
    }
  },
  {
    title: (
      <div className={tw`text-center flex flex-col items-center`}>
        <Text>PAN number</Text>
        <Divider />
        <ModalTextFilter type='pan' />
      </div>
    ),
    dataIndex: 'pan',
    key: 'pan',
    render: (_, record) => {
      return <Text> {record.pan}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      const promoter = `${record.name}`;
      return (
        <Space size='middle' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <PromoterKycViewModal data={record} />
          <Button
            name='update-promoter'
            style={{ borderRadius: '8px', padding: '4px' }}
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              editPromter(true, record);
            }}
          >
            <MdModeEditOutline size='20px' color='black' />
          </Button>
          <DeletePopup type='promoter' id={record.id} entityId={record.entity.id} data={promoter} />
        </Space>
      );
    }
  }
];
