import './style.css';
import React from 'react';
import { DatePicker } from 'antd';

const Date = ({ label, required, ...rest }) => {
  return (
    <div>
      <DatePicker {...rest} style={{ padding: '0.5rem', borderRadius: '8px', width: '100%' }} />
      <span className='floatinglabel'>
        {label ? label : null}
        {required ? <span>*</span> : null}
      </span>
    </div>
  );
};

export default Date;
