export const LOGIN = {
  path: '/login',
  permissions: null
};

export const HOME = {
  path: '/',
  permissions: {
    entityCategory: [null, 'SELLER', 'BUYER', 'FINANCIER'],
    userType: ['ADMIN', 'SUPER_ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const PROFILE = {
  path: '/profile',
  permissions: {
    entityCategory: [null, 'SELLER', 'BUYER', 'FINANCIER'],
    userType: ['ADMIN', 'SUPER_ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const FINANCIER_PLATFORM = {
  path: '/platform/financier',
  permissions: {
    entityCategory: [null],
    userType: ['ADMIN', 'OPERATION_MANAGER']
  }
};

export const FINANCIER = {
  path: '/financier',
  permissions: {
    entityCategory: ['FINANCIER'],
    userType: ['ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const BUYER = {
  path: '/buyer',
  permissions: {
    entityCategory: ['BUYER'],
    userType: ['ADMIN', 'MAKER', 'CHECKER']
  }
};

export const SELLER = {
  path: '/seller',
  permissions: {
    entityCategory: ['SELLER'],
    userType: ['ADMIN', 'MAKER', 'CHECKER']
  }
};

export const LOGO_VIEW = {
  path: '/financiers/logo/:logoId/signed-url',
  permissions: {
    entityCategory: ['SELLER', 'BUYER', 'FINANCIER', null],
    userType: ['ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const DOCUMENT_VIEW = {
  path: '/buyer-seller/:entityId/documents/:documentId/signed-url',
  permissions: {
    entityCategory: ['SELLER', 'BUYER', 'FINANCIER'],
    userType: ['ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const INVOICE_DOCUMENT_VIEW = {
  path: '/invoices/:invoiceId/documents/:documentId/signed-url',
  permissions: {
    entityCategory: ['SELLER', 'BUYER', 'FINANCIER'],
    userType: ['ADMIN', 'MAKER', 'CHECKER', 'OPERATION_MANAGER']
  }
};

export const RESET_PASSWORD = {
  path: '/reset-password/:userId/:token',
  permissions: null
};
