import { mutateTypes } from './types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case mutateTypes.MUTATE_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case mutateTypes.MUTATE_MODAL_PARAMS:
      return {
        ...state,
        modalParams: action.payload
      };
    case mutateTypes.MUTATE_CLEAR_PARAMS:
      return {
        ...state,
        params: action.payload,
        modalParams: action.payload
      };
    case mutateTypes.MUTATE_CLEAR_MODAL_PARAMS:
      return {
        ...state,
        modalParams: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
