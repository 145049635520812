import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { Button, Form, Typography, Radio, Card, Row, Col, Tabs, Input } from 'antd';
import { MdOutlineArrowBack } from 'react-icons/md';
import { tw } from 'twind';
import { editBuyerSellerLink } from '../../redux/buyerSeller/actions';
import { RULES } from '../../utils/formValidations';
import TextInput from '../antdComponent/Input';
import Cookies from 'js-cookie';
import SelectComponent from '../antdComponent/Select';

const { Text } = Typography;

const { TabPane } = Tabs;

const { TextArea } = Input;

const BuyerSellerLinkAdd = ({
  onSubmit,
  buyerSellerLinkAddToggle,
  buyerSellerLinkAdd,
  editBuyerSellerLinks,
  isUpdate,
  buyerOnChange,
  setDisableDropdown,
  disableDropdown,
  buyerDropdown,
  sellerDropdown
}) => {
  const [form] = Form.useForm();
  const costBearer = Form.useWatch('costBearer', form);
  const sellerPercent = Form.useWatch('sellerPercent', form);
  const buyerPercent = Form.useWatch('buyerPercent', form);

  const [currentTab, setCurrentTab] = useState('1');

  const entityId = Cookies.get('entityId');
  const entityCategory = localStorage.getItem('entityCategory');

  const initialValues = useMemo(
    () =>
      isUpdate
        ? {
            buyerName: editBuyerSellerLinks.data.buyer.id,
            sellerName: editBuyerSellerLinks.data.seller.id,
            creditPeriod: editBuyerSellerLinks.data.creditPeriod,
            extendedCreditPeriod: editBuyerSellerLinks.data.extendedCreditPeriod,
            approvedFinance: editBuyerSellerLinks.data.sendForFinance,
            acceptPayment: editBuyerSellerLinks.data.acceptPayment,
            costBearer: editBuyerSellerLinks.data.costBearer,
            buyerPercent: editBuyerSellerLinks.data.buyerPercentage,
            sellerPercent: editBuyerSellerLinks.data.sellerPercentage,
            buyerStartDays: null,
            buyerEndDays: null,
            sellerStartDays: null,
            sellerEndDays: null,
            splitBuyerSeller: editBuyerSellerLinks.data.buyerStartDays === 1 ? 'BUYER' : 'SELLER',
            tillDays:
              editBuyerSellerLinks.data.buyerStartDays === 1
                ? editBuyerSellerLinks.data.buyerEndDays
                : editBuyerSellerLinks.data.sellerEndDays
          }
        : {},
    [isUpdate]
  );

  const onBuyerSellerLinkSubmit = async ({ approved }) => {
    try {
      const values = await form.validateFields();
      onSubmit({ ...initialValues, ...values, approved });
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (costBearer === 'SELLER') {
      form.setFieldsValue({ buyerPercent: 0, sellerPercent: 100 });
    } else if (costBearer === 'BUYER') {
      form.setFieldsValue({ buyerPercent: 100, sellerPercent: 0 });
    } else if (costBearer === 'PERIODIC_SPLIT') {
      form.resetFields(['sellerPercent', 'buyerPercent']);
    } else if (costBearer === 'PERCENTAGE_SPLIT' || 'SELLER' || 'BUYER') {
      form.resetFields(['splitBuyerSeller', 'tillDays']);
    } else if (costBearer === 'PERCENTAGE_SPLIT') {
    }
  }, [costBearer]);

  useEffect(() => {
    if (sellerPercent) {
      form.setFieldsValue({
        buyerPercent: 100 - form.getFieldValue(['sellerPercent'])
      });
    }
  }, [sellerPercent]);

  useEffect(() => {
    if (buyerPercent) {
      form.setFieldsValue({
        sellerPercent: 100 - form.getFieldValue(['buyerPercent'])
      });
    }
  }, [buyerPercent]);

  useEffect(() => {
    switch (entityCategory) {
      case 'SELLER': {
        form.setFieldsValue({
          sellerName: entityId
        });
        break;
      }
      case 'BUYER': {
        form.setFieldsValue({
          buyerName: entityId
        });
        break;
      }
      default:
        break;
    }
  }, []);

  const BSLinkCard = () => {
    return (
      <Fragment>
        <Card className='mb-4' title={'Buyer-Seller Link Details'}>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
            style={{ marginBottom: 10 }}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='buyerName' rules={RULES.selectRequired} className='w-full'>
                <SelectComponent
                  label='Buyer Name'
                  placeholder='Select'
                  required
                  onChange={buyerId => {
                    buyerOnChange(buyerId);
                    if (entityCategory === 'FINANCIER') {
                      form.setFieldsValue({
                        sellerName: undefined
                      });
                    }
                  }}
                  allowClear={true}
                  options={buyerDropdown}
                  disabled={isUpdate || entityCategory === 'BUYER'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='sellerName' rules={RULES.selectRequired} className='w-full'>
                <SelectComponent
                  label='Seller Name'
                  placeholder='Select'
                  required
                  allowClear={true}
                  options={sellerDropdown}
                  disabled={
                    isUpdate ||
                    entityCategory === 'SELLER' ||
                    (disableDropdown && entityCategory === 'FINANCIER')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24 },
              { xs: 12, sm: 16, md: 24 }
            ]}
            className={tw`mb-4 md:mb-0`}
          >
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='creditPeriod' rules={RULES.number}>
                <TextInput required label='Credit Period' placeholder='Credit Period' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='extendedCreditPeriod' rules={RULES.number}>
                <TextInput
                  required
                  label='Extended Credit Period'
                  placeholder='Extended Credit Period'
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={tw`w-full flex flex-row`}>
            <Text className={tw`w-1/4 mt-2`}>Approved For Finance*</Text>
            <Form.Item name='approvedFinance' rules={RULES.selectRequired} className=''>
              <Radio.Group>
                <Radio value='AUTO'>Auto</Radio>
                <Radio value='MANUAL'>Manual</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={tw`w-full flex flex-row`}>
            <Text className={tw`w-1/4 mt-2`}>Accept Payment*</Text>
            <Form.Item name='acceptPayment' rules={RULES.selectRequired}>
              <Radio.Group>
                <Radio value='AUTO'>Auto</Radio>
                <Radio value='MANUAL'>Manual</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={tw`w-full flex flex-row`}>
            <Text className={tw`w-1/4 mt-2`}>Cost Bearer*</Text>
            <Form.Item name='costBearer' rules={RULES.selectRequired}>
              <Radio.Group>
                <Radio value='SELLER'>Seller</Radio>
                <Radio value='BUYER'>Buyer</Radio>
                <Radio value='PERCENTAGE_SPLIT'>Percentage Split</Radio>
                <Radio value='PERIODIC_SPLIT'>Periodic Split</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Card>
        {costBearer === 'PERCENTAGE_SPLIT' || costBearer === 'SELLER' || costBearer === 'BUYER' ? (
          <Card title='Percentage Split' className='flex-start mb-4'>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='sellerPercent' rules={RULES.number}>
                  <TextInput
                    label='Seller'
                    placeholder='Percentage'
                    type='number'
                    max={100}
                    disabled={costBearer === 'SELLER' || costBearer === 'BUYER'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='buyerPercent' rules={RULES.number}>
                  <TextInput
                    label='Buyer'
                    type='number'
                    placeholder='Percentage'
                    max={100}
                    disabled={costBearer === 'SELLER' || costBearer === 'BUYER'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        ) : (
          ''
        )}
        {costBearer === 'PERIODIC_SPLIT' ? (
          <Card title='Periodic Split' className='flex-start mb-4'>
            <Row
              gutter={[
                { xs: 8, sm: 16, md: 24 },
                { xs: 12, sm: 16, md: 24 }
              ]}
              className={tw`mb-4 md:mb-0`}
            >
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='splitBuyerSeller' rules={RULES.selectRequired}>
                  <SelectComponent
                    label='Buyer/Seller'
                    placeholder='Select'
                    required
                    allowClear={true}
                    options={[
                      {
                        value: 'BUYER',
                        label: 'Buyer'
                      },
                      {
                        value: 'SELLER',
                        label: 'Seller'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name='tillDays' rules={RULES.number}>
                  <TextInput label='Till Days' placeholder='Till Days' type='number' required />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        ) : (
          ''
        )}
        <Button className={tw`mt-2 mb-4`} size='middle' type='primary' htmlType='submit'>
          {editBuyerSellerLinks.openEdit ? 'Update' : 'Add'}
        </Button>
      </Fragment>
    );
  };

  const BSApproveCard = () => {
    return (
      <Form form={form} size='large' autoComplete='off'>
        <div className={tw`flex flex-col gap-1`}>
          <div style={{ flex: 1 }} className={tw`md:w-2/4`}>
            <Form.Item name='remarks' rules={RULES.inputRequired}>
              <TextArea rows={4} placeholder='Enter Feedback*' />
            </Form.Item>
          </div>
        </div>
        <div className={tw`flex gap-3 mt-1`}>
          <Button
            type='primary'
            size='middle'
            onClick={() => onBuyerSellerLinkSubmit({ approved: 1 })}
          >
            Approve
          </Button>
          <Button
            danger
            type='primary'
            size='middle'
            onClick={e => onBuyerSellerLinkSubmit({ approved: 0 })}
          >
            Reject
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Button
          type='default'
          onClick={() => {
            setDisableDropdown(true);
            editBuyerSellerLink(false, {});
            buyerSellerLinkAddToggle(false);
          }}
        >
          <MdOutlineArrowBack size='20px' />
        </Button>
        <Text className={tw`text-lg`}>
          {editBuyerSellerLinks.openEdit ? 'Update' : 'Add'} Buyer-Seller Link
        </Text>
      </div>
      <Form
        form={form}
        size='large'
        initialValues={initialValues}
        onFinish={value => {
          onSubmit(value);
        }}
        onFinishFailed={errorInfo => {
          console.log(errorInfo);
        }}
        autoComplete='off'
      >
        {buyerSellerLinkAdd || editBuyerSellerLinks.data.edit ? (
          BSLinkCard()
        ) : (
          <Tabs
            type='line'
            activeKey={currentTab}
            onChange={async key => {
              setCurrentTab(key);
            }}
          >
            <TabPane tab='Buyer-Seller Link' key='1'>
              {currentTab === '1' && BSLinkCard()}
            </TabPane>
            <TabPane tab='Approve-Reject' key='2'>
              {currentTab === '2' && BSApproveCard()}
            </TabPane>
          </Tabs>
        )}
      </Form>
    </div>
  );
};

export default BuyerSellerLinkAdd;
