import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { tw } from 'twind';
import { getAllOpenForFinance } from '../../../services/factoringUnit';
import { openForFinanceColumns } from './openForFinanceColumns';
import { useSelector } from 'react-redux';
import TableComponent from '../../antdComponent/Table';

const { Text } = Typography;

const OpenForFinanceContainer = () => {
  const {
    params,
    loadingOpenForFinance = false,
    openForFinance = []
  } = useSelector(state => ({
    params: state.mutations.params,
    loadingOpenForFinance: state.factoringUnit.loadingOpenForFinance,
    openForFinance: state.factoringUnit.openForFinance
  }));

  useEffect(() => {
    getAllOpenForFinance(params);
  }, [params]);

  return (
    <Fragment>
      <div className={tw`flex items-center gap-4 content-divider`}>
        <Text className={tw`text-xl`}>Open For Finance</Text>
      </div>
      <TableComponent
        columns={openForFinanceColumns}
        data={openForFinance}
        loading={loadingOpenForFinance}
      />
    </Fragment>
  );
};

export default OpenForFinanceContainer;
