import React from 'react';
import dayjs from 'dayjs';
import { PaymentViewModal } from './paymentModal';
import { Divider, Space, Typography } from 'antd';
import {
  ModalDateFilter,
  ModalEntityFilter,
  ModalTextFilter,
  ModalPaymentStatusFilter
} from '../../../../utils/formFilters';
import { MdOutlinePreview } from 'react-icons/md';

const { Text } = Typography;

export const paymentColumns = [
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Release To</Text>
        <Divider />
        <ModalEntityFilter type='releaseTo' />
      </div>
    ),
    key: 'releaseTo',
    render: (_, record) => {
      return <Text>{record.releaseTo}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Amount</Text>
        <Divider />
        <ModalTextFilter type='paymentAmount' />
      </div>
    ),
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
    render: (_, record) => {
      return <Text>{record.paymentAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Payment Date</Text>
        <Divider />
        <ModalDateFilter type='dateOfInitiate' />
      </div>
    ),
    dataIndex: 'initiateDate',
    key: 'initiateDate',
    render: (_, record) => {
      const initiateDate = dayjs(record.dateOfInitiate).format('YYYY-MM-DD');
      return <Text>{initiateDate}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Remaining Amount</Text>
        <Divider />
        <ModalTextFilter type='remainingAmount' />
      </div>
    ),
    dataIndex: 'remainingAmount',
    key: 'remainingAmount',
    render: (_, record) => {
      return <Text>{record.remainingAmount}</Text>;
    }
  },
  {
    title: (
      <div style={{ textAlign: 'center' }}>
        <Text>Status</Text>
        <Divider />
        <ModalPaymentStatusFilter type='status' />
      </div>
    ),
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => {
      return <Text>{record.status}</Text>;
    }
  },
  {
    title: <Text>Action</Text>,
    key: 'action',
    render: (_, record) => {
      return (
        <Space size='small' style={{ display: 'flex', flexDirection: 'column' }}>
          <PaymentViewModal label={<MdOutlinePreview size='20px' color='black' />} data={record} />
        </Space>
      );
    }
  }
];
